/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/kisaltmalar?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    const renderTables = () => {
      if (Object.keys(data).length === 0) {
        return <SoftTypography variant="caption" color="textSecondary"></SoftTypography>;
      }
      return Object.entries(data).map(([tableName, tableData]) => {
        const tableRows = tableData.map(item => ({
          "Türkçe Uzun": <SoftTypography variant="caption" color="text" fontWeight="medium"> {item.turkce_uzun} </SoftTypography>,
          "Türkçe Kısa": <SoftTypography variant="caption" color="text" fontWeight="medium"> {item.turkce_kisa} </SoftTypography>,
          "Çeviri Uzun": <SoftTypography variant="caption" color="text" fontWeight="medium"> {item.ceviri_uzun} </SoftTypography>,
          "Çeviri Kısa": <SoftTypography variant="caption" color="text" fontWeight="medium"> {item.ceviri_kisa} </SoftTypography>,
        }));

        const projectsTableData = {
          columns: [
            { name: "Türkçe Uzun", align: "Left", searchable: true },
            { name: "Türkçe Kısa", align: "Left", searchable: true },
            { name: "Çeviri Uzun", align: "Left", searchable: true },
            { name: "Çeviri Kısa", align: "Left", searchable: true },
          ],
          rows: tableRows
        };

        return (
          <Grid item xs={12} md={12} xl={12} key={tableName}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6">{tableName}</SoftTypography>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
              </SoftBox>
            </Card>
          </Grid>
        );
      });
    };






    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} >
          {renderTables()}
        </Grid>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
