/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import axios from "axios"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";



// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import team2 from "assets/images/team-2.jpg";

//const BASE_URL = process.env.REACT_APP_URL

function Author({ image, name, email, tel }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="dark">
          {email}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {tel}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];


function Tables() {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/performans?format=json`);
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  const rows = data.map(item => ({

    Adı: <Author image={item.user.personel_fotograf} name={item.user.name+ " " + item.user.surname} email={item.email_adres} tel={item.kisisel_cep_no} />,
    Pozisyon: <Function job={item.user.trt_arabi_unvan} org={item.calisma_yeri} />,
    "Kategori": <SoftBadge variant="gradient" badgeContent={item.kategori.adi} color="light" size="xs" container />,
    "Yapılan Özel İşler": <SoftBadge variant="gradient" badgeContent={item.yapilan_ozel_isler.length} color="default" size="xs" container />,
    "Son Yapılan İşler": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.yapilan_is_sayisi}/<strong>{item.aylik_hedefler > 0 ? item.aylik_hedefler : 0}
    </strong> </SoftTypography>,
    "Aylık Performans": (<SoftBox width="8rem" textAlign="left">
      <SoftProgress value=
        {Math.min(100, item.aylik_hedefler > 0 ? Math.round((item.yapilan_is_sayisi / item.aylik_hedefler) * 100) : 0
        )}
        color="info" variant="gradient" label={false} /> <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {item.aylik_hedefler > 0 ? Math.round((item.yapilan_is_sayisi / item.aylik_hedefler) * 100) : 0}
        % </SoftTypography>
    </SoftBox>),
    "Tarih": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {ayIsimleri[item.ay - 1]} / {item.yil} </SoftTypography>,
    "field_name": item.user.name + " " + item.user.surname ,
    "field_position": item.user.trt_arabi_unvan,
    "field_kategori": item.kategori.adi,
    "field_ozelis": item.yapilan_ozel_isler.length,
  }));






  const columns = [
    { name: "Adı", align: "left", exportableField: "field_name" },
    { name: "Pozisyon", align: "left", exportableField: "field_position" },
    { name: "Kategori", align: "center", exportableField: "field_kategori" },
    { name: "Yapılan Özel İşler", align: "center", exportableField: "field_ozelis" },
    { name: "Son Yapılan İşler", align: "center" },
    { name: "Aylık Performans", align: "center" },
    { name: "Tarih", align: "center" },
  ]

  return (
    <Grid item xs={12} md={12} xl={12}>
      <SoftBox pt={3} px={3}></SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Çalışan Performans Listesi (Aylık)</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </Card>
    </Grid>

  );
}

export default Tables;
