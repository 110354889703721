/* eslint-disable react/prop-types */

import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDatePicker from "components/SoftDatePicker";
import SoftSelect from "components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import { faPencilSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function Tables() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState({});
    const [search, setSearch] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/gorevlendirme/rapor?time_period=weekly&time_selected=${search}&format=json`, { withCredentials: true });
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [search]);

    const selectData = Object.keys(data.results || {}).map(key => {
        return {
            value: key,
            label: key
        };
    });

    function Author({ id, name, loc, tel }) {
        return (
            <a href={`/profile/${id}`}>
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox display="flex" flexDirection="column" ml={2}>
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {loc}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </a>
        );
    }

    const tableRows = data.results?.[search]?.gorevler?.map(item => {
        return {
            "Başlık": <Author name={item.konu} loc={item.yer} />,
            "Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.tarih}</SoftTypography>,
        };
    }) || [];

    const projectsTableData = {
        columns: [
            { name: "Başlık", align: "left" },
            { name: "Tarih", align: "left" },
        ],
        rows: tableRows
    };

    const yurtiçiCount = data.results?.[search]?.tur_sayilari?.Yurtiçi || 0;
    const yurtdışıCount = data.results?.[search]?.tur_sayilari?.Yurtdışı || 0;
    const istanbulCount = data.results?.[search]?.tur_sayilari?.İstanbul || 0;
    const sınırCount = data.results?.[search]?.tur_sayilari?.Sınır || 0;


    return (
        <>
            <SoftBox mt={2} mb={0} style={{ marginInline: "3%" }}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                        Seçilen Tarih
                    </SoftTypography>
                </SoftBox>
                <Grid style={{ display: "flex", gap: "5%" }}>
                    <SoftSelect placeholder="Tarih Periodu" options={selectData} onChange={(selected) => setSearch(selected.value)} />
                </Grid>
            </SoftBox>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Yurtiçi Görevleri</span>}
                        count={yurtiçiCount}
                        description="Tamamlanmış"
                        color={yurtiçiCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Yurtdışı Görevleri</span>}
                        count={yurtdışıCount}
                        description="Tamamlanmış"
                        color={yurtdışıCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>İstanbul Görevleri</span>}
                        count={istanbulCount}
                        description="Tamamlanmış"
                        color={istanbulCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Sınır Görevleri</span>}
                        count= {sınırCount}
                        description="Tamamlanmış"
                        color={sınırCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Görevlendirme Talepleri</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default Tables;
