import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// Redux Persist Config
const persistConfig = {
  key: 'root',
  storage,
  // İsteğe bağlı olarak hangi reducer'ların persist edileceğini belirleyebilirsiniz.
  whitelist: ['userKanal'],
  // blacklist: ['reducerAdı3'], // Persist edilmeyecek reducer'lar
};

// Persisted Reducer'ın Oluşturulması
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Persisted Reducer ile Store'un Oluşturulması
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Persistor'un Oluşturulması
const persistor = persistStore(store);

export { store, persistor };
