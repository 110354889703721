/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
    maxHeight: '90vh',
    maxWidth: '85%',
    overflowY: 'auto',
};

const useStyles = makeStyles({
    stepper: {
        fontSize: '10px !important',
    },
    stepLabel: {
        fontSize: '10px !important',
    },
});

const CustomStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        fontSize: '14px !important',
    },
}));

function createItems(title, text, mudurluk) {
    let items = [];



    if (title) {
        items.push(
            <SoftBox pt={3} px={3} key={title}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong>  <br></br>
                    <SoftTypography variant="caption" color="dark" fontWeight="medium" fontSize="12px"> {mudurluk}</SoftTypography>
                    <br/> <br/>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                </SoftTypography>

              

            </SoftBox>
        );
    }

    return items;
}


export default function BasicModal({ open, handleClose, content }) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {createItems(content.pozisyon, content.is_tanimi, content.mudurluk?.isim)}
                    <SoftBadge variant="gradient" badgeContent={content.durum} color={content.durum === "acik" ? "info" : "error"} size="md" style={{ maxHeight: "50px" }} />
                </div>

            </SoftBox>
        </Modal>
    );
}
