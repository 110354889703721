/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';




// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TableAcord from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import BasicModal from 'layouts/istanimi/modal';


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  
  const handleModalOpen = (index) => {
    setModalContent(index);
    setModalOpen(true);
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/istanimi?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ id, image, name, email, tel, index }) {
      return (
          <a onClick={() => handleModalOpen(index)}>
              <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                  <SoftBox mr={2}>
                      <SoftAvatar src="" size="xl" variant="rounded" icon="description" color="#e5e8ed" />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column">
                      <SoftTypography variant="button" fontWeight="medium">
                          {name}
                      </SoftTypography>
                      <SoftTypography variant="caption" color="dark">
                          {email}
                      </SoftTypography>
                      <SoftTypography variant="caption" color="secondary">
                          {tel}
                      </SoftTypography>
                  </SoftBox>
              </SoftBox>
          </a>
      );
  }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };




    const tableRows = data.map(item => {
      return {
        "Pozisyon": [
          <img key="logoNewspaper" src="" alt="Logo" />,
          <span key="task1">{item.pozisyon}</span>
        ],
        "Pozisyon": <Author id={item.id} name={item?.pozisyon} image={window.BASE_URL + "/" + item.goruntu} tel={"Önizleme İçin Tıklayın"} index={item} />,
        "Müdürlük": <SoftTypography variant="caption" color="text" fontWeight="medium">           {item.mudurluk?.isim}        </SoftTypography>,
        "detail": <><SoftTypography variant="caption" fontWeight="medium">İş Tanımı: <br /> </SoftTypography><SoftTypography variant="caption">{item.is_tanimi} <br />      </SoftTypography></>,
      };
    });

    const projectsTableData = {
      columns: [
        { name: "Pozisyon", align: "left" },
        { name: "Müdürlük", align: "Center" },
      ],
      rows: tableRows
    };








    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid item xs={12} md={12} xl={12}>
        <BasicModal open={modalOpen} handleClose={handleModalClose} content={modalContent} />
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">İş Tanımı Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <TableAcord columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>


        </Grid>


      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
