import { useMemo } from "react";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";
import Header from "layouts/rota/calendar/components/Header";
import NextEvents from "layouts/rota/calendar/components/NextEvents";
import ProductivityChart from "layouts/rota/calendar/components/ProductivityChart";
import Button from "@mui/material/Button";
import { Icon } from "@mui/material";

function Calendar() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tarihBas, setTarihBas] = useState(new Date().toISOString().split("T")[0]);
  const [tarihSon, setTarihSon] = useState(new Date().toISOString().split("T")[0]);
  const [filterColumn, setFilterColumn] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/rota/newsroom`, { withCredentials: true });
        setData(response.data);
        setFilteredData(response.data);  // Initially set filteredData to data
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleSelect = (arg) => {
    setTarihBas(`${arg.startStr}`);
    setTarihSon(`${arg.endStr}`);
  };

  const handleFilterColumnChange = (event) => {
    setFilterColumn(event.target.value);
  };

  const handleSelectAll = () => {
    const allDates = data.map(event => new Date(event.start));
    const minDate = new Date(Math.min(...allDates));
    const maxDate = new Date(Math.max(...allDates));
    setTarihBas(minDate.toISOString().split("T")[0]);
    setTarihSon(maxDate.toISOString().split("T")[0]);
  };

  useEffect(() => {
    if (filterColumn === "" || filterColumn === "TÜMÜ") {
      const groupedEvents = data.reduce((acc, curr) => {
        const key = `${curr.unit}-${curr.start}-${curr.end}`;
        if (!acc[key]) {
          acc[key] = {
            title: `${curr.unit}`,
            start: curr.start,
            end: curr.end,
            hours: "",
            className: "info",
            unit: curr.unit,
            role: "",
            count: 0
          };
        }
        acc[key].count += 1;
        return acc;
      }, {});

      const groupedData = Object.values(groupedEvents).map(event => ({
        ...event,
        title: `(${event.count}) ${event.unit} `
      }));

      setFilteredData(groupedData);
    } else {
      const filteredByUnit = data.filter(event => event.unit === filterColumn);
      setFilteredData(filteredByUnit);
    }
  }, [filterColumn, data]);

  const uniqueUnits = [...new Set(data.map(item => item.unit))];
  const columns = [{ name: "TÜMÜ" }, ...uniqueUnits.map(unit => ({ name: unit }))];

  return (
    <Grid item xs={12} md={12} xl={12}>
      <SoftBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  header={{ "date": new Date().toISOString().split("T")[0], "title": "Rota İzleme Takvimi" }}
                  initialView="dayGridMonth"
                  initialDate={new Date().toISOString().split("T")[0]}
                  events={filteredData}
                  handleFilterColumnChange={handleFilterColumnChange}
                  select={handleSelect}
                  columns={columns}
                  filterColumn={filterColumn}
                  selectable
                  editable
                />
              ),
              [filteredData, filterColumn, data]
            )}
          </Grid>
          <Grid item xs={12} xl={3}>
            <SoftBox mb={3}>
              <SoftButton variant="contained" onClick={handleSelectAll} fullWidth>
                <Icon>select_all</Icon>
                Hepsini Seç
                
              </SoftButton>
            </SoftBox>
            <SoftBox mb={3}>
              <NextEvents dateStart={tarihBas} dateEnd={tarihSon} data={(filterColumn === "" || filterColumn === "TÜMÜ") ? data : filteredData} />
            </SoftBox>

            {/* <SoftBox mb={3}>
              <ProductivityChart />
            </SoftBox> */}
          </Grid>
        </Grid>
      </SoftBox>
    </Grid>
  );
}

export default Calendar;
