import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import store from "store";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";

import breakpoints from "assets/theme/base/breakpoints";

import TalepForm from "layouts/planlama/gorev-takip/form";
import TalepList from "layouts/planlama/gorev-takip/list";

import Tumu from "layouts/banka-iban/tumu-bilgi";
import Banka from "layouts/banka-iban/banka-bilgi";
import TkBilgi from "layouts/banka-iban/tk-bilgi";


import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faBuildingColumns, faCalendarCheck, faCalendarMinus, faCar, faChartLine, faCirclePlus, faCity, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faPlaneDeparture, faRoadBarrier, faSpellCheck, faSquarePlus, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'



import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';




function Overview() {
  const [tabIndex, setTabIndex] = useState(0);


  const { id } = useParams();
  const navigate = useNavigate();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);

  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/list?format=json`, { withCredentials: true });
        setData(response.data);

      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, navigate]);


  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/user-bilgi?format=json`, { withCredentials: true });
        setDataUser(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox position="absoloute" margin-top="200px">
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "absoloute",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
            marginTop: 2
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={(event, newValue) => handleSetTabValue(newValue)}
                  sx={{ background: "transparent" }}
                >
                  <Tab value={0} label="Tümü" icon={<FontAwesomeIcon icon={faListAlt} />} />
                  <Tab value={1} label="Banka Bilgileri" icon={<FontAwesomeIcon icon={faBuildingColumns} />} />
                  <Tab value={2} label="TK Numarası" icon={<FontAwesomeIcon icon={faPlaneDeparture} />} />

                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>

          {tabValue === 0 && (
            <>
              {/* <TalepList data={} category="all" /> */}
              <Tumu data={dataUser} category="all" />


            </>
          )}
          {tabValue === 1 && (
            <>
              {/* <TalepList data={} category="all" /> */}
              <Banka data={data} category="Yurtiçi" />


            </>
          )}
          {tabValue === 2 && (
            <>
              {/* <TalepList data={} category="all" /> */}
              <TkBilgi data={data} category="Yurtdışı" />


            </>
          )}
          {tabValue === 3 && (
            <>
              {/* <TalepList data={} category="all" /> */}
              <Banka data={data} category="İstanbul" />


            </>
          )}
          {tabValue === 4 && (
            <>
              {/* <TalepList data={} category="all" /> */}
              <Banka data={data} category="Sınır" />


            </>
          )}
          {tabValue === 5 && (
            <>
              <TalepForm />

            </>
          )}



        </Grid>
      </SoftBox>


      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
