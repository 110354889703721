/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Modal from '@mui/material/Modal';
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography"
import SoftBadge from "components/SoftBadge";
import TimelineItem from "examples/Timeline/TimelineItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
    maxHeight: '90vh',
    maxWidth: '85%',
    overflowY: 'auto',
};

function createItems(title, text) {
    return title ? (
        <SoftBox pt={3} px={3}>
            <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                </Icon>
                <strong>{title} </strong> <br />
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px">
                    {text}
                </SoftTypography>
            </SoftTypography>
        </SoftBox>
    ) : null;
}

// Recursive function to render TimelineItems
function renderTimelineItems(dataItem) {
    return (
        <TimelineItem
            key={dataItem.id}
            color={dataItem.files ? "info" : "secondary"}  // Dosyası varsa info, yoksa warning
            icon={dataItem.files ? "insert_drive_file" : "folder"}  // Dosyası varsa dosya, yoksa klasör simgesi
            title={dataItem.title}
            dateTime={dataItem.modification_date ? `Değişiklik Tarihi: ${dataItem.modification_date}` : ""}
            description={
                <>
                    {dataItem.files ? <a href={window.BASE_URL +"/"+dataItem.files} target="_blank" rel="noopener noreferrer"><SoftTypography variant="caption" color="dark" fontWeight="medium" >Dosyayı görüntülemek için tıklayınız</SoftTypography></a> : ""}
                    {dataItem.children && dataItem.children.length > 0 && (
                        <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                            {dataItem.children.map(child => (
                                <Box key={child.id}>
                                    {renderTimelineItems(child)}
                                    {child.files ? <Divider/> : ""}
                                </Box>
                                
                            ))}
                        </div>
                    )}
                </>
            }
        />
    );
}

export default function BasicModal({ open, handleClose, content }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "-15px" }}>
                    
                    {createItems("Evrak Önizleme", content.title, content.files)}
                    
                </div>

                <Divider sx={{ my: 2 }} />

                {/* Recursive TimelineItems'ı göster */}
                <SoftBox style={{background: "#f8f9fa", borderRadius: "1rem", padding: "10px", paddingBottom: "35px"}}> 
                    {renderTimelineItems(content)}
                </SoftBox>
            </SoftBox>
        </Modal>
    );
}
