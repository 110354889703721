
/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

const index = (data) => {

    try {
        

        function Author({ image, name }) {
            return (
              <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                  <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography variant="button" fontWeight="medium">
                    {name}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }


    const truncatedTextStyle = {
        width: '300px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        height: '20px',
        WebkitBoxOrient: 'vertical'
    };

    

        const { size } = typography;

        let tableRows = data.data.on_eleme.map(index => {
            return {
              "İsim": [
                <img key="logoNewspaper" src="" alt="Logo" />,
                <span key="task1" > {index.ad_soyad} </span>
              ],
              "Yaşadığı Yer": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.yasadigi_yer}</SoftTypography>,
              "Pozisyon": <SoftBadge variant="gradient" badgeContent={index.pozisyon} color="success" size="xs" container />,
              "E-posta": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.eposta_adresi}</SoftTypography>,
              "Telefon": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cep_telefonu}</SoftTypography>,
              "Değerlendirme": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.degerlendirenler}</SoftTypography>,
              "Elenme Nedeni": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.elenme_nedeni}</SoftTypography>,
        
            };
          });


        const projectsTableData = {
            columns: [
                { name: "İsim", align: "left" },
                { name: "Pozisyon", align: "Center" },
                { name: "Yaşadığı Yer", align: "left" },
                { name: "E-posta", align: "left" },
                { name: "Telefon", align: "left" },
                { name: "Değerlendirme", align: "left" },
                { name: "Elenme Nedeni", align: "left" },
            ],

            rows: [
                ...tableRows,

            ],
        };








        let table2Rows = data.data.on_mulakat.map(index => {
            return {
              "İsim": [
                <img key="logoNewspaper" src="" alt="Logo" />,
                <span key="task1" > {index.ad_soyad} </span>
              ],
              "Görüşme Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.gorusme_tarihi}</SoftTypography>,
              "Pozisyon": <SoftBadge variant="gradient" badgeContent={index.pozisyon} color="success" size="xs" container />,
              "Durumu": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.durumu}</SoftTypography>,
              "Yaşadığı Yer": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.yasadigi_yer}</SoftTypography>,
              "E-posta": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.eposta_adresi}</SoftTypography>,
              "Telefon": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cep_telefonu}</SoftTypography>,
              "Saat": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.saat_aralik}</SoftTypography>,
              "Maaş": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cinsiyet}</SoftTypography>,
              "Güncel Maaş": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.guncel_maas}</SoftTypography>,
              "Ücret Beklentisi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.ucret_beklentisi}</SoftTypography>,
              "Görüşme Yeri": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.gorusme_yeri}</SoftTypography>,
              "Görüşmeciler": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.gorusmeciler}</SoftTypography>,
              "Notlar": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.notlar}</SoftTypography>,
              "Sonuç": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.sonuc}</SoftTypography>,
              "İdarenin Görüşü": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.idarenin_gorusu}</SoftTypography>,
                    
        
            };
          });


        const projectsTableData2 = {
            columns: [
                { name: "İsim", align: "left" },
                { name: "Görüşme Tarihi", align: "Center" },
                { name: "Durumu", align: "Center" },
                { name: "Pozisyon", align: "Center" },
                { name: "Yaşadığı Yer", align: "Center" },
                { name: "E-posta", align: "Center" },
                { name: "Telefon", align: "Center" },
                { name: "Saat", align: "Center" },
                { name: "Maaş", align: "Center" },
                { name: "Güncel Maaş", align: "Center" },
                { name: "Ücret Beklentisi", align: "Center" },
                { name: "Görüşme Yeri", align: "Center" },
                { name: "Görüşmeciler", align: "Center" },
                { name: "Notlar", align: "Center" },
                { name: "Sonuç", align: "Center" },
                { name: "İdarenin Görüşü", align: "Center" },
                
            

            ],

            rows: [
                ...table2Rows,

            ],
        };


        return (
            <>
            <Grid item xs={12} md={12} xl={12}>
                <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Ön Eleme Listesi</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                    </SoftBox>
                </Card>
                
            </Grid>

            <Grid item xs={12} md={12} xl={12}>
                <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Ön Mülakat Listesi</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={projectsTableData2.columns} rows={projectsTableData2.rows} />
                    </SoftBox>
                </Card>
                
            </Grid>
            </>
        );
    }
    catch (error) {
        console.log(error);
        return(
        <Grid item xs={12} md={12} xl={12}>
            <SoftBox pt={3} px={3}>
                <SoftTypography variant="h2" fontWeight="medium">
                    Yetkilendirilmemiş Giriş
                </SoftTypography>
            </SoftBox>
        </Grid>
        );
    }
}

export default index;
