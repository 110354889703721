/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";



function Tables({ data, type }) {




    try {


        function Author({ id, image, name, email, tel, link }) {
            return (
              <a href={`/program/${id}`}>
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                  <SoftBox mr={2}>
                    <SoftAvatar src={image} size="xl" variant="rounded" />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography variant="button" fontWeight="medium">
                      {name}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="dark">
                      {email}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="secondary">
                      {tel}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </a>
            );
          }


        const truncatedTextStyle = {
            width: '300px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            height: '20px',
            WebkitBoxOrient: 'vertical'
        };


        const tableRows = data.map(item => {
            return {
                "Program": <Author id={item.id} name={item?.program_adi} image={window.BASE_URL + "/" + item.goruntu} tel={item.ozet} />,
                "Yayın Başlangıç": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.yayin_baslangic_tarihi}</SoftTypography>,        
                "Tür": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.tur}</SoftTypography>,
                "Kategori": <SoftBadge variant="gradient" badgeContent={item.kategori} color="info" size="xs"> </SoftBadge>,

                "Kanal": <SoftBadge variant="gradient" badgeContent={item.kanal.kanal_adi} color="dark" size="xs"> </SoftBadge>,

                "field_program": item.program_adi,
                "field_kanal": item.kanal.kanal_adi,

                "field_kategori": item.kanal.kanal_adi,

            };
        });

        const projectsTableData = {
            columns: [
                { name: "Program", align: "left" , exportableField: `field_program`, searchable: true, searchField: [`field_program`]  },
                { name: "Tür", align: "center" },
                { name: "Yayın Başlangıç", align: "center" },
                
            ],
            rows: tableRows
        };

        const userKanal = useSelector(state => state.auth.userKanal);

        if (type == "all") {
            projectsTableData.columns.splice(1, 0, { name: "Kategori", align: "center", exportableField: `field_kategori`, searchable: true, searchField: [`field_kategori`],});
        }

        if (userKanal == "Global") {
            projectsTableData.columns.splice(1, 0, { name: "Kanal", align: "center", exportableField: `field_kanal`, searchable: true, searchField: [`field_kanal`],});
        }










        return (
            <Grid item xs={12} md={12} xl={12}>
                <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Yapımlar Listesi</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                    </SoftBox>
                </Card>

            </Grid>
        );
    }
    catch (error) {
        console.log(error);
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <PermissionNavbar />
            </DashboardLayout>
        );
    }
}

export default Tables;
