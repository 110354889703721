// sweetalert2 components
import Swal from "sweetalert2";
import SoftBadge from "components/SoftBadge";
// Sweet Alerts page components

/* eslint-disable react/prop-types */

function WithAttachedFunction({title,color,stage}) {

  let desc = ""
  if (stage == 1){
    desc = "Aşama 1"
  }
  else if (stage == 2){
    desc = "Aşama 2"
  }
  else if (stage == 3){
    desc = "Aşama 3"
  }
  else if (stage == 4){
    desc = "Aşama 4"
  }
  else if (stage == 5){
    desc = "Aşama 5"
  }
  else if (stage == 6){
    desc = "Aşama 6"
  }
  else if (stage == 7){
    desc = "Aşama 7"
  }
  else if (stage == 8){
    desc = "Aşama 8"
  }
  else if (stage == 9){
    desc = "Aşama 9"
  }
  else {
    desc = "Aşama bilgisi bulunmuyor"
  }

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        popup: 'custom-swal-popup',
        confirmButton: "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-fzc9z6-MuiButtonBase-root-MuiButton-root",
        cancelButton: "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-1s9f0ro-MuiButtonBase-root-MuiButton-root",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Görev Aşamaları",
        text: desc,
        showCancelButton: true,
        confirmButtonText: "Aşamayı Tamamla!",
        cancelButtonText: "Devam Ediyor",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          newSwal.fire({
            title: "Aşama Tamamlandı!",
            text: "Bir sonraki aşamaya başarıyla geçildi",
            icon: "success",
            confirmButtonText: "Tamam"
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire({
            title: "Aşama Devam Ediyor",
            text: "Şuandaki aşama henüz devam etmektedir",
            icon: "info",
            confirmButtonText: "Tamam"
          });
          
        }
      });
  };

  return (
    <SoftBadge variant="gradient" onClick={showAlert} badgeContent={title} color={color} size="xs" container />
  );
}

export default WithAttachedFunction;

// Custom CSS for SweetAlert2 popup
const style = document.createElement('style');
style.innerHTML = `
  .swal2-container {
    z-index: 1200 !important;
    backdrop-filter: blur(4px);
    background: linear-gradient(198deg, #ffffffc4, #dadadacc);

  },
  .swal2-popup {
    border-radius: 30px;
  }
`;
document.head.appendChild(style);
