
/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";


import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

import React, { useEffect, useState } from 'react';

const index = (data) => {

    try {

        function Author({ image, name }) {
            return (
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox mr={2}>
                        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            );
        }


        const truncatedTextStyle = {
            width: '300px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            height: '20px',
            WebkitBoxOrient: 'vertical'
        };



        const { size } = typography;

        let tableRows = data.data.map(index => {
            return {
                "İsim": [
                    <img key="logoNewspaper" src="" alt="Logo" />,
                    <span key="task1" > {index.ad_soyad} </span>
                ],
                "Alım Dönemi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.alim_ayi} / {index.alim_yili}</SoftTypography>,
                "Pozisyon": <SoftBadge variant="gradient" badgeContent={index.pozisyon} color="success" size="xs" container />,
                "Brut Maaş": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.maas} {index.maas_turu}</SoftTypography>,
                "Uyruk": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.uyruk}</SoftTypography>,
                "Statü": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.statu}</SoftTypography>,
                "Müdürlük": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.mudurluk}</SoftTypography>,
                "Birim": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.birim}</SoftTypography>,
                "Cinsiyet": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cinsiyet}</SoftTypography>,
                "Notlar": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.notlar}</SoftTypography>,
                "İşe başlangıç tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.ise_baslangic_tarihi}</SoftTypography>,

                /*"detail": <><SoftTypography variant="caption">Sorumlu Kişi: {index.ad_soyad} ({index.ad_soyad}) <br />Dosya No: {index.ad_soyad} <br /> <SoftBadge variant="gradient" badgeContent={index.ad_soyad} color="default" size="xs" container /> <br /> <Icon sx={{ fontWeight: "bold", color: "error"}}>
                label_important
            </Icon>  Açıklama: {index.ad_soyad} <br />
                {index.ad_soyad === "null" ? "ChatBubble" : <><br /><SoftBadge variant="gradient" badgeContent="KARAR" color="error" size="xs" container /> <br /> {index.ad_soyad}  </>}
                </SoftTypography></>,*/

                //<SoftTypography variant="caption" color="text" fontWeight="medium">{index.aciklama}</SoftTypography>


            };
        });


        const projectsTableData = {
            columns: [
                { name: "İsim", align: "left" },
                { name: "Alım Dönemi", align: "Center" },
                { name: "Cinsiyet", align: "left" },
                { name: "Uyruk", align: "left" },
                { name: "Statü", align: "left" },
                { name: "Müdürlük", align: "left" },
                { name: "Birim", align: "left" },
                { name: "Pozisyon", align: "left" },
                { name: "Brut Maaş", align: "left" },
                { name: "İşe başlangıç tarihi", align: "left" },
                { name: "Notlar", align: "left" },



            ],

            rows: [
                ...tableRows,

            ],
        };




        function onDocumentLoadFail(error) {
            console.error("PDF yüklenirken bir hata oluştu: ", error);
        }

        const filePath = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf';
        const encodedFilePath = encodeURIComponent(filePath);






        const [numPages, setNumPages] = useState(null);
        const [pageNumber, setPageNumber] = useState(1);

        function onDocumentLoadSuccess({ numPages }) {
            setNumPages(numPages);
        }

        function goToPrevPage() {
            setPageNumber(prevPageNumber => prevPageNumber > 1 ? prevPageNumber - 1 : 1);
        }
        
        function goToNextPage() {
            setPageNumber(prevPageNumber => prevPageNumber < numPages ? prevPageNumber + 1 : numPages);
        }
        




        return (

            <Grid item xs={12} md={12} xl={12}>
                {/* <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">İşe Alım Listesi</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                    </SoftBox>
                </Card> */}

                <Card style={{ width: '100%' }}>
                    <   SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">TRT Anayasa hukuku kanunları bildirgesi</SoftTypography>
                    </SoftBox>

                    <   SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} paddingTop="0">
                        <SoftTypography variant="button" fontWeight="light">Anayasa ve Kanun</SoftTypography>
                    </SoftBox>

                    <div style={{ width: '100%', marginLeft: "10px" }}>
                        <Document style={{ width: '100%' }}
                            file="https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf"
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} renderTextLayer={false} />
                        </Document>

                        <div style={{ width: '100%', padding: "30px", paddingTop: "0px" }}>

                            <SoftTypography variant="button" fontWeight="regular" >
                                Sayfa {pageNumber} / {numPages}
                            </SoftTypography>

                            <SoftButton onClick={goToPrevPage} sx={{ ml: 2 }} >
                                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                    arrow_left
                                </Icon> Önceki sayfa
                            </SoftButton>

                            <SoftButton onClick={goToNextPage} sx={{ ml: 2 }} >
                                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                    arrow_right
                                </Icon> Sonraki sayfa
                            </SoftButton>

                            <SoftButton onClick={goToNextPage} sx={{ ml: 2 }} >
                                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                    get_app
                                </Icon> PDF indir
                            </SoftButton>

                        </div>
                    </div>
                </Card>

            </Grid>
        );
    }
    catch (error) {
        console.log(error);
        return (
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h2" fontWeight="medium">
                        Yetkilendirilmemiş Giriş
                    </SoftTypography>
                </SoftBox>
            </Grid>
        );
    }
}

export default index;
