import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import worldCountries from "examples/Charts/MapCharts/map.json";
import SoftTypography from "components/SoftTypography";
/* eslint-disable react/prop-types */

const countryNameMap = {
    "T.C.": "Turkey",
    "Suriye": "Syria",
    "Mısır": "Egypt",
    "Filistin": "Palestine",
    "Irak": "Iraq",
    "Ürdün": "Jordan",
    "Tunus": "Tunisia",
    "Lübnan": "Lebanon",
    "Sudan": "Sudan",
    "Cezayir": "Algeria",
    "Yemen": "Yemen",
    "Moritanya": "Mauritania",
    "Libya": "Libya",
    "Fas": "Morocco",
    "İran": "Iran",
    "Suudi Arabistan": "Saudi Arabia"
};

// Veriyi hazırlama fonksiyonu
const prepareData = (data) => {
    return data.map(item => {
        const countryLabel = item.label.split(" (")[0]; // label'dan ülke adını çıkartıyoruz
        return {
            country: countryNameMap[countryLabel] || countryLabel,
            value: item.value,
            percentage: parseFloat(item.label2.replace('%', '')) // yüzde oranını sayıya çeviriyoruz
        };
    });
};

const getColorByPercentage = (percentage) => {
    const colors = ['#4463b4', '#354868', '#5c73aa', '#7387b1', '#7891ba', '#2787f4'];
    const index = Math.floor((percentage / 100) * (colors.length - 1));
    const color = colors[index];
    const opacity = (percentage / 100) + 0.5; // Opaklık, 0.5 ile 1 arasında olacak şekilde ayarlanıyor
    const opacityHex = Math.round(opacity * 255).toString(16).padStart(2, '0'); // Opaklığı hex formatına çevirme
    return color + opacityHex; // Renkle opaklık değerini birleştirme
};

const getCountryColor = (countryName, preparedData) => {
    const countryData = preparedData.find(item => item.country === countryName);
    if (countryData && countryData.value > 0) {
        return getColorByPercentage(countryData.percentage);
    }
    return "#E0E0E0"; // Eğer veri yoksa gri renkle göster
};

const MapChart = ({ data }) => {
    const preparedData = prepareData(data);
    const [tooltipContent, setTooltipContent] = useState(""); // Tooltip içeriği için state
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // Tooltip pozisyonu için state

    return (
        <div style={{ position: 'relative' }}>
            <ComposableMap>
                <Geographies geography={worldCountries}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const countryName = geo.properties.name;
                            const countryData = preparedData.find(item => item.country === countryName);
                            const fillColor = getCountryColor(countryName, preparedData);

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default: {
                                            fill: fillColor,
                                            outline: "none"
                                        },
                                        hover: {
                                            fill: fillColor,
                                            outline: "none"
                                        },
                                        pressed: {
                                            fill: fillColor,
                                            outline: "none"
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        if (countryData) {
                                            setTooltipContent(`${countryName}: ${countryData.value} kişi, %${countryData.percentage}`);
                                        } else {
                                            setTooltipContent(""); // Veri yoksa tooltip içeriğini temizle
                                        }
                                    }}
                                    onMouseMove={(evt) => {
                                        if (tooltipContent) { // Sadece tooltip içeriği varsa pozisyon güncellenir
                                            setTooltipPosition({ x: evt.clientX, y: evt.clientY });
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipContent(""); // Mouse uzaklaşınca içeriği temizle
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
            {tooltipContent && (
                <div style={{
                    position: 'fixed',
                    top: `${tooltipPosition.y + 10}px`, // Tooltip imlecin biraz üzerinde kalsın
                    left: `${tooltipPosition.x + 10}px`, // Tooltip imlecin biraz sağında kalsın
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    pointerEvents: 'none', // Tooltip'in fare etkileşimlerini engellemek için
                    zIndex: 1000 // Tooltip'in haritanın üzerinde olmasını sağlamak için
                }}>
                                    <SoftTypography variant="h6" fontWeight="medium" style={{ color: 'white' }}>
                                    {tooltipContent}
                </SoftTypography>
                    
                </div>
            )}
        </div>
    );
};

export default MapChart;
