import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
    console.log(data);
    const safeValue = (value) => value === null ? "-" : value;
    
    return (
        <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="Kimlik Bilgileri"
              description="Kimlik bilgileri detayları"
              info={{
                "İsim": safeValue(data.data.name) + " " + safeValue(data.data.surname),
                //"Telefon Numarası": safeValue(data.data.kisisel_cep_no),
                //"İş Numarası": safeValue(data.data.is_cep_no),
                //"Email": safeValue(data.data.kisisel_mail),
                //"Trt Email": safeValue(data.data.trt_mail),
                //"Trt Arabi Email": safeValue(data.data.trt_arabi_mail),
                //"Konumu": safeValue(data.data.calisma_yeri),
                "Doğum Tarihi": safeValue(data.data.dogum_tarihi),
                "Doğum Yeri": safeValue(data.data.dogum_yeri),
                "Kimlik Numarası": safeValue(data.data.kimlik_no),
                "Passport Numarası": safeValue(data.data.passport_no),
                "Uyruk": safeValue(data.data.uyruk),
                "Vatandaşlık": safeValue(data.data.vatandaslik),
                "Ehliyet": safeValue(data.data.ehliyet_turu),
                "Medeni Durum": safeValue(data.data.medeni_durum),
                "Cinsiyet": safeValue(data.data.cinsiyet),
                //"Ev adresi": safeValue(data.data.ev_adresi),
                //"Hastalık Bilgisi": safeValue(data.data.hastalik_bilgisi),
                //"Kan Grubu": safeValue(data.data.kan_grubu),
                "Askerlik Bilgisi": safeValue(data.data.askerlik_bilgileri),
                
          
                

              }}
              social={[
              ]}
              action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
            />
          </Grid>
    )
}

export default index
