import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios"
// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

import TimelineItem from "examples/Timeline/TimelineItem";

import { Document, Packer, Paragraph, Table, TableCell, TableRow, WidthType, AlignmentType, BorderStyle, TextRun } from 'docx';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";

import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoAsana from "assets/images/small-logos/logo-atlassian.svg";

// Settings page components
import FormField from "layouts/planlama/gorev-bilgi/components/FormField";

// Data
import selectData from "layouts/planlama/gorev-bilgi/components/BasicInfo/data/selectData";

function BasicInfo() {
  const { id } = useParams();

  if (id == ":id") {
    window.location.href = '/gorevlendirme/gorev-bilgi/yeni-gorevlendirme'
  }


  const numberToWords = (num, doviz) => {
    const ones = ['', 'bir', 'iki', 'üç', 'dört', 'beş', 'altı', 'yedi', 'sekiz', 'dokuz'];
    const teens = ['on', 'onbir', 'oniki', 'onüç', 'ondört', 'onbeş', 'onaltı', 'onyedi', 'onsekiz', 'ondokuz'];
    const tens = ['', '', 'yirmi', 'otuz', 'kırk', 'elli', 'altmış', 'yetmiş', 'seksen', 'doksan'];
    const thousands = ['', 'bin', 'milyon', 'milyar'];

    if (num === 0) return 'sıfır';

    let word = '';
    let i = 0;

    while (num > 0) {
      const chunk = num % 1000;

      if (chunk > 0) {
        const chunkWord = chunkToWords(chunk);
        word = `${chunkWord}${thousands[i]}${word}`.trim();
      }

      num = Math.floor(num / 1000);
      i++;
    }

    // Remove 'birbin' and replace with 'bin'
    word = word.replace('birbin', 'bin');

    return word.trim() + dovizToWords(doviz);

    function chunkToWords(chunk) {
      const hundred = Math.floor(chunk / 100);
      const ten = Math.floor((chunk % 100) / 10);
      const one = chunk % 10;

      let chunkWord = '';

      if (hundred > 0) {
        if (hundred > 1) {
          chunkWord += `${ones[hundred]}yüz`;
        } else {
          chunkWord += `yüz`;
        }
      }

      if (ten > 0) {
        if (ten === 1) {
          chunkWord += teens[one];
          return chunkWord;
        }
        chunkWord += `${tens[ten]}`;
      }

      if (one > 0) {
        chunkWord += `${ones[one]}`;
      }

      return chunkWord.trim();
    }

    function dovizToWords(doviz) {
      if (doviz === "USD") {
        return "AmerikanDoları";
      } else if (doviz === "EUR") {
        return "Euro";
      } else if (doviz === "TL") {
        return "TürkLirası";
      }
      return "";
    }
  };


  function yolculuktoWords(tur) {
    if (tur === "Yataklı Tren" || tur === "Yüksek Hızlı Tren") {
      return "Tren";
    } else if (tur === 'Araç / Karayolu' || tur === 'Kiralık Araç' || tur === 'Kurum Aracı' || tur === 'Özel Araç') {
      return 'Kiralık Araç';
    } else if (tur === 'Vapur / Gemi') {
      return "Deniz Aracı";
    }
    return tur;
  }

  const formatName = (name) => {
    return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const formatSurname = (surname) => {
    return surname.toUpperCase();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("00")) {
      return "+" + phoneNumber.slice(2);
    }
    return phoneNumber;
  };

  const loadFile = (url, callback) => {
    axios.get(url, { responseType: 'arraybuffer' })
      .then(response => callback(null, response.data))
      .catch(error => callback(error));
  };

  const createWordDocument = (data) => {
    loadFile("/template.docx", (error, content) => {
      if (error) { throw error; }

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      // Şablon değişkenlerini ayarla
      const personel = data.muhabir_bilgi || [];
      const placeholders = {};
      personel.forEach((p, index) => {
        placeholders[`adiSoyadi${index + 1}`] = `${formatName(p.name)} ${formatSurname(p.surname)}` || '';
        placeholders[`tcNo${index + 1}`] = p.kimlik_no || '';
        placeholders[`unvan${index + 1}`] = p.trt_unvan || '';
        placeholders[`unit${index + 1}`] = "TRT " + p.kanal.kanal_adi || 'TRT Arabi';
        placeholders[`sicilNo${index + 1}`] = p.sicil_no || '';
        placeholders[`yabanciDil${index + 1}`] = "Çok İyi" || '';
        placeholders[`telefon${index + 1}`] = formatPhoneNumber(p.kisisel_cep_no) || '';
      });

      for (let i = personel.length + 1; i <= 10; i++) {
        placeholders[`adiSoyadi${i}`] = '';
        placeholders[`tcNo${i}`] = '';
        placeholders[`unvan${i}`] = '';
        placeholders[`unit${i}`] = '';
        placeholders[`sicilNo${i}`] = '';
        placeholders[`yabanciDil${i}`] = '';
        placeholders[`telefon${i}`] = '';
      }

      let yolculukData = [];
      try {
        yolculukData = JSON.parse(data.yolculuk);
      } catch (e) {
        console.error('Invalid JSON format in yolculuk:', e);
      }



      const yolculukBilgileri = yolculukData.map(y => {
        let yolculukText = `${y.nereden} - ${y.nereye} (${yolculuktoWords(y.yolculuk_turu)}) `;
        if (y.gidisDonus) {
          yolculukText += `\n${y.nereye} - ${y.nereden} (${yolculuktoWords(y.yolculuk_turu)})`;
        }
        return yolculukText;
      }).join('\n');

      const yolculukTuru = yolculukData.map(y => y.yolculuk_turu);

      const isAvansiText = `${data.is_avansi} ${data.is_avansi_doviz} (${numberToWords(data.is_avansi, data.is_avansi_doviz)})`;

      const formatDateString = (dateStr) => dateStr.replace(/\//g, '.');

      const gorevTarihleri = `${formatDateString(data.tarih)} - ${formatDateString(data.bitis_tarih)}`;


      doc.setData({
        gorevKonusu: data.konu || '',
        gorevTarihleri: gorevTarihleri || '',
        ulkeSehir: yolculukBilgileri || '',
        yolculukNot: data.yolculuk_not || '',
        ucak: yolculukTuru.includes('Uçak') ? '☒' : '☐',
        otobus: yolculukTuru.includes('Otobüs') ? '☒' : '☐',
        tren: yolculukTuru.includes('Tren') || yolculukTuru.includes('Yataklı Tren') || yolculukTuru.includes("Yüksek Hızlı Tren") ? '☒' : '☐',
        denizAraci: yolculukTuru.includes('Vapur / Gemi') || yolculukTuru.includes('Deniz Aracı') ? '☒' : '☐',
        kiralikArac: yolculukTuru.includes('Araç / Karayolu') || yolculukTuru.includes('Kiralık Araç') || yolculukTuru.includes('Kurum Aracı') || yolculukTuru.includes('Özel Araç') ? '☒' : '☐',
        katilimGerekcesi: data.aciklama || '',
        konaklama: data.konaklama ? '☒' : '☐',
        yol: data.yol ? '☒' : '☐',
        harcirah: data.harcirah ? '☒' : '☐',
        yemek: data.yemek ? '☒' : '☐',
        tumMasraflar: data.tum_masraf ? '☒' : '☐',
        isAvansi: isAvansiText || '',
        katilimUcreti: data.katilim_ucreti || '',
        fazlaBagaj: data.fazla_bagaj ? '☒' : '☐',
        fazlaBagajYok: data.fazla_bagaj ? '☐' : '☒',
        dovizTur: data.is_avansi_doviz || "",
        ...placeholders
      });

      try {
        doc.render();
      } catch (error) {
        console.log(JSON.stringify({ error: error }, (key, value) => {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce((error, key) => {
              error[key] = value[key];
              return error;
            }, {});
          }
          return value;
        }));
        throw error;
      }

      const blob = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(blob, 'YurtdisiGorevlendirmeFormu.docx');
    });
  };


  const formatDateString = (startDate, endDate) => {
    console.log("tarihs");
    console.log(startDate, endDate);
    if (!startDate || !endDate) return '';

    const months = [
      "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];

    const formatSingleDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return {
        day: parseInt(day, 10) || '',
        month: parseInt(month, 10) || '',
        year: parseInt(year, 10) || '',
        monthName: months[parseInt(month, 10) - 1] || ''
      };
    };

    const start = formatSingleDate(startDate);
    const end = formatSingleDate(endDate);

    if (!start.day || !start.month || !start.year || !end.day || !end.month || !end.year) {
      return '';
    }

    if (start.year === end.year) {
      if (start.month === end.month) {
        return `${start.day} – ${end.day} ${end.monthName} ${end.year}`;
      } else {
        return `${start.day} ${start.monthName} - ${end.day} ${end.monthName} ${end.year}`;
      }
    } else {
      return `${start.day} ${start.monthName} ${start.year} - ${end.day} ${end.monthName} ${end.year}`;
    }
  };

  const createMailTemplate = (data) => {
    loadFile("/template-mail.docx", (error, content) => {
      if (error) { throw error; }

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        nullGetter: () => ''
      });

      const capitalizeWords = (str) => {
        if (!str) return '';
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      };

      const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        if (phoneNumber.startsWith("00")) {
          return "+" + phoneNumber.slice(2);
        }
        return phoneNumber;
      };

      const personel = data.muhabir_bilgi || [];
      const muhabirler = personel.map(p => `${p.unvan || ''} ${capitalizeWords(p.name)} ${p.surname ? p.surname.toUpperCase() : ''}`).join(', ');

      let yolculukData = [];
      try {
        yolculukData = JSON.parse(data.yolculuk);
      } catch (e) {
        console.error('Invalid JSON format in yolculuk:', e);
      }

      const ulkeler = yolculukData.map(y => y.nereye).filter(country => country.toLowerCase() !== 'türkiye').join(', ');

      const isAvansiText = `${data.is_avansi} ${data.is_avansi_doviz} `;

      const placeholders = {
        amac: data.aciklama || '',
        muhabirler: muhabirler || '',
        tarih: formatDateString(data.tarih, data.bitis_tarih) || '',
        ulke: ulkeler || '',
        isAvans: isAvansiText || '',
        odemePersonel: data.avans_personel_bilgi.name + " " + data.avans_personel_bilgi.surname || '',
        Avans1: `${data.Avans1} ${data.is_avansi_doviz} ` || '',
        Avans2: `${data.Avans2} ${data.is_avansi_doviz} ` || '',
        Avans3: `${data.Avans3} ${data.is_avansi_doviz} ` || '',
        Avans4: `${data.Avans4} ${data.is_avansi_doviz} ` || '',
        Avans5: `${data.Avans5} ${data.is_avansi_doviz} ` || '',
        AvansToplam: isAvansiText || '',

        arabicAmac: data.arabic_aciklama,
        arabicUlke: ulkeler || '',
        arabicMuhabirler: muhabirler,
      };

      doc.setData(placeholders);

      try {
        doc.render();
      } catch (error) {
        console.log(JSON.stringify({ error: error }, (key, value) => {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce((error, key) => {
              error[key] = value[key];
              return error;
            }, {});
          }
          return value;
        }));
        throw error;
      }

      const blob = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(blob, 'TemplateMail.docx');
    });
  };



  const createMakamTemplate = (data) => {
    loadFile("/template-makam.docx", (error, content) => {
      if (error) { throw error; }

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        nullGetter: () => ''
      });

      const capitalizeWords = (str) => {
        if (!str) return '';
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      };

      const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        if (phoneNumber.startsWith("00")) {
          return "+" + phoneNumber.slice(2);
        }
        return phoneNumber;
      };

      const formatDateString = (startDate, endDate) => {
        if (!startDate || !endDate) return '';

        const months = [
          "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
          "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        const formatSingleDate = (dateStr) => {
          const [day, month, year] = dateStr.split('.');
          return {
            day: parseInt(day, 10) || '',
            month: parseInt(month, 10) || '',
            year: parseInt(year, 10) || '',
            monthName: months[parseInt(month, 10) - 1] || ''
          };
        };

        const start = formatSingleDate(startDate);
        const end = formatSingleDate(endDate);

        if (!start.day || !start.month || !start.year || !end.day || !end.month || !end.year) {
          return '';
        }

        if (start.year === end.year) {
          if (start.month === end.month) {
            return `${start.day} – ${end.day} ${end.monthName} ${end.year}`;
          } else {
            return `${start.day} ${start.monthName} - ${end.day} ${end.monthName} ${end.year}`;
          }
        } else {
          return `${start.day} ${start.monthName} ${start.year} - ${end.day} ${end.monthName} ${end.year}`;
        }
      };

      const numberToWords = (num, doviz) => {
        const ones = [
          '', 'bir', 'iki', 'üç', 'dört', 'beş', 'altı', 'yedi', 'sekiz', 'dokuz'
        ];
        const teens = [
          'on', 'onbir', 'oniki', 'onüç', 'ondört', 'onbeş', 'onaltı', 'onyedi', 'onsekiz', 'ondokuz'
        ];
        const tens = [
          '', '', 'yirmi', 'otuz', 'kırk', 'elli', 'altmış', 'yetmiş', 'seksen', 'doksan'
        ];
        const thousands = [
          '', 'bin', 'milyon', 'milyar'
        ];

        if (num === 0) return 'sıfır';

        let word = '';
        let i = 0;

        while (num > 0) {
          const chunk = num % 1000;

          if (chunk > 0) {
            const chunkWord = chunkToWords(chunk);
            word = `${chunkWord}${thousands[i]}${word}`.trim();
          }

          num = Math.floor(num / 1000);
          i++;
        }

        let dovizString = "";
        if (doviz === "USD") {
          dovizString = "AmerikanDoları";
        }
        if (doviz === "EUR") {
          dovizString = "Euro";
        }
        if (doviz === "TL") {
          dovizString = "TürkLirası";
        }

        word = word.replace('birbin', 'bin');

        return word.trim() + "" + dovizString;

        function chunkToWords(chunk) {
          const hundred = Math.floor(chunk / 100);
          const ten = Math.floor((chunk % 100) / 10);
          const one = chunk % 10;

          let chunkWord = '';

          if (hundred > 0) {
            chunkWord += `${ones[hundred]}yüz`;
          }

          if (ten > 0) {
            if (ten === 1) {
              chunkWord += teens[one];
              return chunkWord;
            }
            chunkWord += `${tens[ten]}`;
          }

          if (one > 0) {
            chunkWord += `${ones[one]}`;
          }

          return chunkWord.trim();
        }
      };

      const calculateDays = (startDate, endDate) => {
        const start = new Date(startDate.split('.').reverse().join('-'));
        const end = new Date(endDate.split('.').reverse().join('-'));
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      };

      const personel = data.muhabir_bilgi || [];
      const muhabirler = personel.map(p => {
        const name = p.name ? capitalizeWords(p.name) : '';
        const surname = p.surname ? p.surname.toUpperCase() : '';
        const sicil = p.sicil_no ? p.sicil_no : '';
        const unvan = p.trt_unvan ? p.trt_unvan : '';
        return `${sicil} sicil numarası ile ${unvan} olarak çalışan ${name} ${surname}`;
      }).join(', ');

      let yolculukData = [];
      try {
        yolculukData = JSON.parse(data.yolculuk);
      } catch (e) {
        console.error('Invalid JSON format in yolculuk:', e);
      }

      const ulkeler = yolculukData.map(y => y.nereye).filter(country => country.toLowerCase() !== 'türkiye').join(', ');

      const yolculuk = yolculukData.map(y => {
        let yolculukText = `${y.nereden} – ${y.nereye} (${y.yolculuk_turu})`;
        if (y.gidisDonus) {
          yolculukText += ` / ${y.nereye} – ${y.nereden} (${y.yolculuk_turu})`;
        }
        return yolculukText;
      }).join(' / ');


      const avans = `${data.is_avansi} ${data.is_avansi_doviz} (${numberToWords(data.is_avansi, data.is_avansi_doviz)})`;

      const iban = data.is_avansi_doviz === 'USD' ? data.avans_personel_bilgi.usd_iban 
      : data.is_avansi_doviz === 'EUR' ? data.avans_personel_bilgi.eur_iban 
      : data.avans_personel_bilgi.tr_iban || 'TR';

      const placeholders = {
        amac: data.konu || '',
        muhabirler: muhabirler || '',
        tarih: data.tarih && data.bitis_tarih ? `${data.tarih.replace(/\./g, '/')} - ${data.bitis_tarih.replace(/\./g, '/')}` : '',
        ulke: ulkeler || '',
        yolculuk: yolculuk || '',
        yolculukNot: "(" + data.yolculuk_not + ")" || '',
        gunSayi: `${calculateDays(data.tarih, data.bitis_tarih)} (${numberToWords(calculateDays(data.tarih, data.bitis_tarih), '')})` || '',
        avans: avans || '',
        odemeMuhabir: data.avans_personel_bilgi.name + " " + data.avans_personel_bilgi.surname || '',
        iban: iban || 'TR',
        avans_doviz: data.is_avansi_doviz || ''
      };

      doc.setData(placeholders);

      try {
        doc.render();
      } catch (error) {
        console.log(JSON.stringify({ error: error }, (key, value) => {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce((error, key) => {
              error[key] = value[key];
              return error;
            }, {});
          }
          return value;
        }));
        throw error;
      }

      const blob = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(blob, 'MakamOluru.docx');
    });
  };






  const [data, setData] = useState([]);
  const [avans2FA, setAvans2FA] = useState(false);

  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/list/user?format=json`, { withCredentials: true });
        setDataUser(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    id: id,
    aciklama: '',
    calisma: '',
    organizator: '',
    akreditasyon: '',
    etkinlik_bilgi_baglantisi: '',
    konu: '',
    yer: '',
    tarih: '',
    saat: '',
    bitis_tarih: '',
    bitis_saat: '',
    kanal_cikis_saati: '',
    iletisim_numarasi: '',
    muhabir: [],
    yolculuk: '',
    yolculuk_not: '',
    ekipman: '',
    notlar: '',
    tur: '',
    is_avansi: 0,
    is_avansi_doviz: 'tl',
    konaklama: false,
    yol: false,
    harcirah: false,
    yemek: false,
    tum_masraf: false,
    katilim_ucreti: '',
    fazla_bagaj: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/organizasyon/${id}?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const convertDate = (date) => {
      if (!date) return '';
      const dateParts = date.split('/');
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts;
        return `${year}-${month}-${day}`;
      }
      return date;
    };

    if (data.length > 0) {
      const fields = ["id", "aciklama", "calisma", "organizator", "akreditasyon", "etkinlik_bilgi_baglantisi", "konu", "yer", "tarih", "saat", "bitis_tarih", "bitis_saat", "kanal_cikis_saati", "iletisim_numarasi", "muhabir", "yolculuk_not", "notlar", "tur", "is_avansi", "is_avansi_doviz", "konaklama", "yol", "harcirah", "yemek", "tum_masraf", "ekipman", "katilim_ucreti", "fazla_bagaj"];
      const newFormData = {};
      fields.forEach(field => {
        if (field === "tarih" || field === "bitis_tarih") {
          newFormData[field] = convertDate(data[0][field]) || '';
        } else {
          newFormData[field] = data[0][field] || '';
        }
      });
      setFormData(newFormData);

      if (data[0].muhabir) {
        const formattedMuhabir = formatFilteredOptions(dataUser, data[0].muhabir);
        console.log(formattedMuhabir)
        setSelectedOptions(formattedMuhabir);
      }

      if (data[0].yolculuk) {
        const parsedYolculuklar = JSON.parse(data[0].yolculuk);
        if (Array.isArray(parsedYolculuklar)) {
          setYolculuklar(parsedYolculuklar);
        } else {
          console.error("Invalid format for yolculuklar: ", parsedYolculuklar);
        }
      }
    }
  }, [data, dataUser]);



  const formatOptions = () => {
    return dataUser.map((item) => ({
      value: item.id,
      label: item.name + " " + item.surname + " (" + item.trt_arabi_unvan + ")",
    }));
  };

  const formatFilteredOptions = (dataUser, filterList) => {
    if (!filterList || filterList.length === 0) {
      return [];
    }
    return dataUser
      .filter(item => filterList.includes(item.id))
      .map(item => ({
        value: item.id,
        label: `${item.name} ${item.surname} (${item.trt_arabi_unvan})`
      }));
  };

  const handleSelectChange = (selected, action) => {
    setFormData({
      ...formData,
      [action.name]: selected.value
    });
  };

  const checkData = (name) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }

    const firstItem = data[0];
    if (!firstItem.hasOwnProperty('talep')) {
      return null;
    }


    const value = firstItem[name];
    if (value != "" || value != null) {
      const talepValue = firstItem.talep[name];
      if (talepValue === "" || talepValue === null) {
        return null;
      }
      return talepValue;
    }
    return value;
  };

  const filteredOptions = formatFilteredOptions(dataUser, checkData("muhabir"));

  const [selectedOptions, setSelectedOptions] = useState(filteredOptions);



  const handleMuhabirSelectChange = (selected) => {
    setSelectedOptions(selected);
    setFormData({
      ...formData,
      "muhabir": selected
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date, name) => {
    const formattedDate = formatDate(date);
    setFormData({
      ...formData,
      [name]: formattedDate
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };



  //const [yolculuklar, setYolculuklar] = useState([{ id: 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' }]);
  const [yolculuklar, setYolculuklar] = useState([]);

  const handleYolculukFieldChange = (id, fieldName, value) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [fieldName]: value } : yolculuk
      )
    );
  };

  const handleYolculukSelectChange = (id, fieldName) => (selectedOption) => {
    handleYolculukFieldChange(id, fieldName, selectedOption.value);
  };

  const handleYolculukInputChange = (id) => (e) => {
    const { name, value } = e.target;
    handleYolculukFieldChange(id, name, value);
  };

  const handleBooleanChange = (id, field) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [field]: !yolculuk[field] } : yolculuk
      )
    );
  };

  const handleBooleanMasrafChange = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name]
    });
  };

  const handleYolculukSil = (id) => {
    setYolculuklar(prevState => prevState.filter(yolculuk => yolculuk.id !== id));
  };

  const handleYolculukEkle = () => {
    const newYolculuk = { id: yolculuklar.length + 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' };
    setYolculuklar([...yolculuklar, newYolculuk]);
  };

  const handleChangeLog = () => {
    setAvans2FA(!avans2FA)
  }

  const CreateBox = (title, index, size) => {
    index = index == false ? "Yok" : index
    index = index == true ? "Var" : index
    return (
      <Grid item xs={12} sm={size ? size : 12} >
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              {title}
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
            {index ? index : "-"}
          </SoftTypography>
        </SoftBox>
      </Grid>
    )
  }

  return (
    <Grid>
      <Card id="profile" style={{ marginTop: "-24px" }}>
        <SoftBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src="-"
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
                icon="assignment"
                bgColor={data[0]?.talep ? "info" : "light"}

              />


            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {data[0]?.talep ? formData.konu : "Talep Yok"}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {data[0]?.talep ? data[0]?.talep.tur + " Görevlendirme Formu" : "Yeni Oluşturulan Form"}
                </SoftTypography>
              </SoftBox>
            </Grid>

          </Grid>
        </SoftBox>
      </Card>



      <Card id="gorev-bilgi" sx={{ overflow: "visible" }} style={{ marginTop: "24px" }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">Görev Bilgileri</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <FormField name="konu" label="Görevin Konusu" placeholder="Görevin Konusu" value={formData.konu} onChange={handleInputChange} disabled  />
            </Grid> */}

            {CreateBox("Görev Konusu", data[0]?.konu)}
            {CreateBox("Görev Kategori", data[0]?.tur)}
            {CreateBox("Görevin Açıklaması", data[0]?.aciklama)}
            {CreateBox("Görev Başlangıç Tarihi", data[0]?.aciklama, 6)}
            {CreateBox("Görev Bitiş Tarihi", data[0]?.aciklama, 6)}



            <Grid item xs={12} >
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talep Edilen Kişiler
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                    &nbsp;&nbsp;
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  value={selectedOptions}
                  options={formatOptions()}
                  placeholder="Kişi ekle..."
                  onChange={"handleSelectChange"}
                  isMulti
                  disabled
                />
              </SoftBox>
            </Grid>


            {CreateBox("Organizatör", data[0]?.organizator)}
            {CreateBox("Akreditasyon", data[0]?.akreditasyon)}
            {CreateBox("Etkinlik Bilgi Bağlantısı", data[0]?.etkinlik_bilgi_baglantisi)}
            {CreateBox("İletişim Numarası", data[0]?.iletisim_numarasi)}
            {CreateBox("Katılım Gerekçesi", data[0]?.katilim_gerekcesi)}



          </Grid>
        </SoftBox>
      </Card>





      <Card id="ulasim" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Ulaşım</SoftTypography>
          </SoftBox>
          {/* <SoftTypography variant="button" color="text" fontWeight="regular">
          Here you can setup and manage your integration settings.
        </SoftTypography> */}
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>

          <div>
            {yolculuklar.map((yolculuk) => (
              <div key={yolculuk.id}>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <SoftBox display="flex" alignItems="center">
                    <SoftAvatar src="-" alt="logo" variant="rounded" icon="add_road" bgColor="info" />
                    <SoftBox ml={2}>
                      <SoftTypography variant="h5" fontWeight="medium">
                        Yolculuk Bilgileri
                      </SoftTypography>
                      <SoftBox display="flex" alignItems="flex-end">
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          Yol Detayları
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <SoftBox lineHeight={0} mx={1}>
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        Gidiş Dönüş
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mr={4}>
                      <Switch checked={yolculuk.gidisDonus} onChange={null} />
                    </SoftBox>

                  </SoftBox>
                </SoftBox>
                <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Yolculuk rotasının ve ulaşım yolu
                  </SoftTypography>
                  <SoftBox
                    bgColor="grey-100"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={3}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >
                    <SoftTypography variant="button" fontWeight="medium" color="text">
                      Nereden
                    </SoftTypography>
                    <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                      <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                        {yolculuk.nereden}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    bgColor="grey-100"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={3}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >
                    <SoftTypography variant="button" fontWeight="medium" color="text">
                      Nereye
                    </SoftTypography>
                    <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                      <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                        {yolculuk.nereye}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    bgColor="grey-100"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    my={3}
                    py={1}
                    pl={{ xs: 1, sm: 2 }}
                    pr={1}
                  >
                    <SoftTypography variant="button" fontWeight="medium" color="text">
                      Yolculuk Türü
                    </SoftTypography>
                    <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                      <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                        {yolculuk.yolculuk_turu}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>

                </SoftBox>

                <Divider />
              </div>
            ))}

          </div>


          {CreateBox("Yolculuk Not", data[0]?.yolculuk_not)}

        </SoftBox>
      </Card>





      <Card id="finans" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Finans</SoftTypography>
          </SoftBox>
          {/* <SoftTypography variant="button" color="text" fontWeight="regular">
          Here you can setup and manage your integration settings.
        </SoftTypography> */}
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="account_balance" bgColor={avans2FA ? "info" : "secondary"} />
              <SoftBox ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  İş Avansı
                </SoftTypography>
                <SoftBox display="flex" alignItems="flex-end">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Ödeme Detayları
                  </SoftTypography>

                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {avans2FA ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={avans2FA} onChange={"handleSetAvans2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {formData.is_avansi ? <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
            <SoftTypography variant="button" color="text" fontWeight="regular">
              İş Avansı (İş avansı oluru örneği eklenecektir.)
            </SoftTypography>
            <SoftBox
              bgColor="grey-100"
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              my={3}
              py={1}
              pl={{ xs: 1, sm: 2 }}
              pr={1}
            >
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Avans Ücreti
              </SoftTypography>
              <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
                <Tooltip title="Copy" placement="top">
                  <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                    {formData.is_avansi}
                  </SoftTypography>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox
              bgColor="grey-100"
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              my={3}
              py={1}
              pl={{ xs: 1, sm: 2 }}
              pr={1}
            >
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Para Birimi
              </SoftTypography>
              <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>


                <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                  {formData.is_avansi_doviz}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox
              bgColor="grey-100"
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              my={3}
              py={1}
              pl={{ xs: 1, sm: 2 }}
              pr={1}
            >
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Ödeme Yapılacak Personel
              </SoftTypography>
              <SoftBox width={{ xs: "100%", sm: "35%", md: "35%" }} mt={{ xs: 1, sm: 0 }}>


                <SoftTypography variant="button" fontWeight="regular" color="text" ml={1} >
                  {data[0]?.avans_personel_bilgi?.name + " " + data[0]?.avans_personel_bilgi?.surname}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

          </SoftBox> : null}
          <Divider />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="bed" bgColor={formData.konaklama ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Konaklama
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.konaklama ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={formData.konaklama} onChange={"handleSetKonak2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" variant="rounded" icon="directions_car" bgColor={formData.yol ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Yol
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.yol ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={formData.yol} onChange={"handleSetYol2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="account_balance_wallet" bgColor={formData.harcirah ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Harcırah
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.harcirah ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={formData.harcirah} onChange={"handleSetHarc2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="local_dining" bgColor={formData.yemek ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Yemek
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.yemek ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={formData.yemek} onChange={"handleSetYemek2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="paid" bgColor={formData.tum_masraf ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Tüm Masraflar
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width={{ xs: "100%", sm: "auto" }}
              mt={{ xs: 1, sm: 0 }}
            >
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.tum_masraf ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch checked={formData.tum_masraf} onChange={"handleSetTum2FA"} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>



      <Card id="ek-bilgi" sx={{ overflow: "visible", marginTop: "24px" }} >
        <SoftBox p={3}>
          <SoftTypography variant="h5">Ek Bilgiler</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {CreateBox("Katılım Ücreti", data[0]?.katilim_ucreti)}
                {CreateBox("Fazla Bagaj", data[0]?.fazla_bagaj)}
                {CreateBox("Ekipman", data[0]?.ekipman)}
                {CreateBox("Not", data[0]?.notlar)}






              </Grid>
            </Grid>


          </Grid>
        </SoftBox>
      </Card>


      <Card id="belge" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Belge Oluştur</SoftTypography>
          </SoftBox>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Bu sekmede değişiklik yapılamaz sadece önizleme amaçlıdır. Değişiklik yapmak için düzenle butonunu kullanın!
          </SoftTypography>
        </SoftBox>
        <SoftBox
          pb={3}
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>

          </SoftBox>
          <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>

            <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>

              <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={() => createMailTemplate(data[0])}>
                Mail Format
              </SoftButton>
            </SoftBox>
            <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>

              <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={() => createWordDocument(data[0])}>
                Geçici Görevlendirme
              </SoftButton>
            </SoftBox>
            <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>

              <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={() => createMakamTemplate(data[0])} >
                Makam Oluru
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>




      <Card id="kaydet" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Düzenleme Yap</SoftTypography>
          </SoftBox>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Bu sekmede değişiklik yapılamaz sadece önizleme amaçlıdır. Değişiklik yapmak için düzenle butonunu kullanın!
          </SoftTypography>
        </SoftBox>
        <SoftBox
          pb={3}
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>

          </SoftBox>
          <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>

            <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <SoftButton variant="gradient" color="dark" sx={{ height: "100%" }}>
                Düzenle
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>

      <Card id="changelog" style={{ marginTop: "24px", background: "transparent" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Değişim Geçmişi</SoftTypography>
          </SoftBox>
          <SoftBox component="form" pt={3} px={3}>


            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              marginBottom={3}
            >
              <SoftBox display="flex" alignItems="center">
                <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="manage_history" bgColor={avans2FA ? "dark" : "dark"} />
                <SoftBox ml={2} lineHeight={0}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    Yapılan Değişiklikler
                  </SoftTypography>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Son aktiviteler
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <SoftBox lineHeight={0} mx={2}>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    {avans2FA ? "Detayları Kapat" : "Detay Göster"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mr={1}>
                  <Switch checked={avans2FA} onChange={handleChangeLog} />
                </SoftBox>
              </SoftBox>
            </SoftBox>

            {avans2FA ?

              data[0] ? data[0].changelogs.map(item => (
                <TimelineItem
                  key={item.id}
                  color={item.terfi_tenzil === "Terfi" ? "success" : "info"}
                  icon={item.terfi_tenzil === "Terfi" ? "unarchive" : "archive"}
                  description={<>
                    <strong>Eski Değer:</strong> {item.old_value}
                    <br />
                    <br />
                    <strong>Yeni Değer:</strong> {item.new_value}
                  </>}
                  title={`${item.field_name.toUpperCase()} (${item.timestamp})`}
                  dateTime={item.user.name + " " + item.user.surname}
                  badges={[item.field_name]}
                />)) : "" : ""}
          </SoftBox>
        </SoftBox>
      </Card>




    </Grid>


  );
}

export default BasicInfo;
