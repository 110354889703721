import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import store from "store";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftDatePicker from "components/SoftDatePicker";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";

import breakpoints from "assets/theme/base/breakpoints";

import TalepHaftalik from "layouts/planlama/personel-rapor/haftalik";
import TalepAylik from "layouts/planlama/personel-rapor/aylik";
import TalepYillik from "layouts/planlama/personel-rapor/yillik";
import TalepTarih from "layouts/planlama/personel-rapor/tarih";


import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarCheck, faCalendarMinus, faCalendarPlus, faChartLine, faCirclePlus, faClock, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faSpellCheck, faSquarePlus, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'



import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';


import BlueLogo from "assets/images/TRT_blue_web_wide_logo_square.png"


function Overview() {
  const [tabIndex, setTabIndex] = useState(0);


  const today = new Date().toISOString().split('T')[0];

  const [formData, setFormData] = useState({
    dateto: "2050-01-01",
    datefrom: "1950-01-01",

    dateto: today,
    datefrom: today,
  });

  const selectData = [
    
      { value: "male", label: "2023-3" },
      { value: "female", label: "2023-4" },
    
  ]

  const { id } = useParams();
  const navigate = useNavigate();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }


    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date
    });
  };

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox position="absoloute" margin-top="200px">
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "absoloute",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
            marginTop: 2
          }}
        >
          <Grid container spacing={3} alignItems="center">

            <Grid item>
              <SoftAvatar
                src={BlueLogo}
                alt="profile-image"
                variant="rounded"
                size="xxl"
                shadow="sm"

              />
            </Grid>

            <Grid item>
              <a href={`/profile/${"props.data.id"}`}>
                <SoftBox height="100%" mt={0.5} lineHeight={1}>
                  <SoftTypography variant="button" color="secondary" fontWeight="light" fontSize="12px">
                    Görevlendirme Detayları ve Durumları
                  </SoftTypography>
                  <SoftTypography variant="h5" fontWeight="medium" sx={{ width: "30ch" }}>
                    Personel Görevlendirme Raporu
                  </SoftTypography>
                  <SoftTypography variant="button" color="text" fontWeight="medium">
                    Çalışan Performans Analizi
                  </SoftTypography>


                </SoftBox>
              </a>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={(event, newValue) => handleSetTabValue(newValue)}
                  sx={{ background: "transparent" }}
                >
                  {/* <Tab value={0} label="Gün Sayısı" icon={<FontAwesomeIcon icon={faListAlt} />} />
                  <Tab value={1} label="Görevlendirme Sayısı" icon={<FontAwesomeIcon icon={faListAlt} />} /> */}

                  <Tab value={0} label="Haftalık" icon={<FontAwesomeIcon icon={faListAlt} />} />
                  <Tab value={1} label="Aylık" icon={<FontAwesomeIcon icon={faListAlt} />} />
                  <Tab value={2} label="Yıllık" icon={<FontAwesomeIcon icon={faListAlt} />} />
                  <Tab value={3} label="Tarih" icon={<FontAwesomeIcon icon={faClock} />} />


                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Card>



      </SoftBox>

      {/* <SoftBox mt={2} mb={0} style={{ marginInline: "3%" }}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography variant="button" color="text" fontWeight="medium">
            Seçilen {tabValue === 3 ? "Aralık" : "Tarih "}
          </SoftTypography>
        </SoftBox>
        <Grid style={{ display: "flex", gap: "5%" }}>
          {tabValue === 3 ? (<><SoftDatePicker value={formData.datefrom} onChange={(datefrom) => handleDateChange("tarih", datefrom)} />
            <SoftDatePicker value={formData.dateto} onChange={(dateto) => handleDateChange("tarih", dateto)} /></>) :
            (<SoftSelect placeholder="Tarih Periodu" options={selectData.gender} />)
          }
        </Grid>
      </SoftBox> */}

      <SoftBox mt={2} mb={3}>
        <Grid container spacing={0}>

          {tabValue === 0 && (
            <>
              <TalepHaftalik />


            </>
          )}
          {tabValue === 1 && (
            <>
              <TalepAylik />

            </>
          )}
          {tabValue === 2 && (
            <>
              <TalepYillik />

            </>
          )}

          {tabValue === 3 && (
            <>
              <TalepTarih />

            </>
          )}



        </Grid>
      </SoftBox>



    </DashboardLayout>
  );
}

export default Overview;
