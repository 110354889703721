/* eslint-disable react/prop-types */

import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox"

import SoftTypography from "components/SoftTypography";

// Charts and Layouts
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

function Tables() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevler-rapor/${id}`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);

  if (!data) return null; // Loading state

  // Aylık verileri grafik için ayarla
  const aylikLabels = Object.keys(data.genel_aylik_toplamlar); // "2024-01", "2024-02" gibi değerler
  const aylikDatasets = Object.values(data.genel_aylik_toplamlar); // Aylık verilerin sayısal değerleri

  const aylikBarChartData = {
    chart: {
      labels: aylikLabels,
      datasets: { label: "Aylık Toplamlar", data: aylikDatasets },
    },
  };

  // Kategoriye göre toplamların ilk 12'sini al
  const sortedCategories = Object.entries(data.kategoriye_gore_toplamlar)
    .sort((a, b) => b[1] - a[1]) // Değere göre büyükten küçüğe sıralama
    .slice(0, 12); // İlk 12 kategori

  const kategoriItems = sortedCategories.map(([key, value]) => ({
    icon: { color: "info", component: "library_books" },
    label: key,
    progress: {
      content: value, // Kategoriye ait toplam değer
      percentage: (value / data.genel_toplam) * 100, // Yüzde olarak toplamdan aldığı pay
    },
  }));

  const reportsBarChartData = {
    chart: {
      labels: aylikLabels,
      datasets: { label: "Aylık Toplamlar", data: aylikDatasets },
    },
    items: kategoriItems, // Alt kısımda gösterilecek itemler
  };

  return (

      
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} xl={12}>
            <Card>
              <ReportsBarChart
                title="Aylık Haber Grafiği ve Kategoriler"
                description={<>Toplam: (<strong>{data.genel_toplam}</strong>) içerik</>}
                chart={reportsBarChartData.chart} // Grafik kısmı
                items={reportsBarChartData.items} // Kategoriye göre items kısmı
                size={2}
              />
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
  );
}

export default Tables;
