/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import YapimTable from "layouts/yapimlar/table"


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/programlar/advertising?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    return (
      <DashboardLayout>
        < DashboardNavbar/>
        <YapimTable data={data}/>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
