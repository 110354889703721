// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
     flex-wrap: wrap;
    flex-direction: row; 
}

.css-i4bv87-MuiSvgIcon-root {
    color: #103c5d
}

.css-d573ua-MuiTypography-root {
    color: "white";
}

.css-11xmu8s {
    --tree-line-color: #d5dee4;
}

.css-1vs3jbz::before, .css-1vs3jbz::after {
    border-color: #e9edf1;
}

.css-10bt4tj-MuiPaper-root-MuiCard-root .MuiCardHeader-content .MuiTypography-root.MuiCardHeader-subheader {
    color: #8eb5d9;
}

`, "",{"version":3,"sources":["webpack://./src/layouts/orgchar/style.css"],"names":[],"mappings":"AAAA;KACK,eAAe;IAChB,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["ul {\n     flex-wrap: wrap;\n    flex-direction: row; \n}\n\n.css-i4bv87-MuiSvgIcon-root {\n    color: #103c5d\n}\n\n.css-d573ua-MuiTypography-root {\n    color: \"white\";\n}\n\n.css-11xmu8s {\n    --tree-line-color: #d5dee4;\n}\n\n.css-1vs3jbz::before, .css-1vs3jbz::after {\n    border-color: #e9edf1;\n}\n\n.css-10bt4tj-MuiPaper-root-MuiCard-root .MuiCardHeader-content .MuiTypography-root.MuiCardHeader-subheader {\n    color: #8eb5d9;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
