/* eslint-disable react/prop-types */

import axios from "axios";

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Chart component
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Layout
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Tables({ year }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (year) {
          response = await axios.get(`${window.BASE_URL}/yayinoranlari/${year}?format=json`, { withCredentials: true });
        } else {
          response = await axios.get(`${window.BASE_URL}/yayinoranlari?format=json`, { withCredentials: true });
        }
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, navigate, year]);

  // Verilerin varlığını kontrol eden güvenli erişim fonksiyonu
  const safeAccess = (data, key, defaultValue = 0) => {
    return data && data[key] ? data[key] : defaultValue;
  };

  /* ######################################    GRAFİK DETAILS   ############################# */

  const regions = [
    "Taze (Fresh) içerik oranları",
    "Canlı (Live) yayın oranı",
    "Yüksek profil (High Profile) konuk oranı",
    "Özel (VIP) konuk oranı",
  ];

  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  // Oranları çekerken güvenli bir şekilde erişim sağlıyoruz
  let items6 = [];
  let datasetsData6 = [];

  if (data?.yayin_oranlari?.["0"]) {
    items6 = [
      {
        icon: { color: "info", component: "library_books" },
        label: "Taze (Fresh) içerik oranları",
        progress: {
          content: "%" + safeAccess(data.yayin_oranlari["0"], "taze_icerik_sayisi"),
          percentage:
            (safeAccess(data.yayin_oranlari["0"], "taze_icerik_sayisi") /
              safeAccess(data.yayin_oranlari["0"], "toplam", 1)) *
            100,
        },
      },
      {
        icon: { color: "info", component: "library_books" },
        label: "Canlı (Live) yayın oranı",
        progress: {
          content: "%" + safeAccess(data.yayin_oranlari["0"], "canli_yayin_sayisi"),
          percentage:
            (safeAccess(data.yayin_oranlari["0"], "canli_yayin_sayisi") /
              safeAccess(data.yayin_oranlari["0"], "toplam", 1)) *
            100,
        },
      },
      {
        icon: { color: "info", component: "library_books" },
        label: "Yüksek profil (High Profile) konuk oranı",
        progress: {
          content: "%" + safeAccess(data.yayin_oranlari["0"], "yuksek_profil_konuk_sayisi"),
          percentage:
            (safeAccess(data.yayin_oranlari["0"], "yuksek_profil_konuk_sayisi") /
              safeAccess(data.yayin_oranlari["0"], "toplam", 1)) *
            100,
        },
      },
      {
        icon: { color: "info", component: "library_books" },
        label: "Özel (VIP) konuk oranı",
        progress: {
          content: "%" + safeAccess(data.yayin_oranlari["0"], "ozel_konuk_sayisi"),
          percentage:
            (safeAccess(data.yayin_oranlari["0"], "ozel_konuk_sayisi") /
              safeAccess(data.yayin_oranlari["0"], "toplam", 1)) *
            100,
        },
      },
    ];

    datasetsData6 = [
      safeAccess(data.yayin_oranlari["0"], "taze_icerik_sayisi"),
      safeAccess(data.yayin_oranlari["0"], "canli_yayin_sayisi"),
      safeAccess(data.yayin_oranlari["0"], "yuksek_profil_konuk_sayisi"),
      safeAccess(data.yayin_oranlari["0"], "ozel_konuk_sayisi"),
    ];
  } else {
    // Eğer veri yoksa 0 olarak set et
    datasetsData6 = regions.map(() => 0);
  }

  const safeDivision = (numerator, denominator) => {
    if (denominator === 0) {
      return 0; // 0/0 ise 0 döndür
    }
    return Math.round((numerator / denominator) * 100); 
  };

  let items61 = [];
  let datasetsData61 = [];
  let datasetsDataObj = [];

  if (data && data.taze_icerik_veri) {
    datasetsData61 = processTazeIcerikVeri(data.taze_icerik_veri,"int");
    datasetsDataObj = processTazeIcerikVeri(data.taze_icerik_veri,"obj");

    items61 = datasetsDataObj.map((item, index) => ({
      icon: { color: "info", component: "library_books" },
      label: months[index],
      progress: {
        content: "%" + safeDivision(item.taze_icerik_sayisi,item.aylik_saat) + "//" + item.taze_icerik_sayisi + " Saat",
        percentage: 0,
      },
    }));

  } else {
    datasetsData61 = months.map(() => 0);
  }

  let totalTazeIcerik = 0; 
  let totalAylikSaat = 0;  
  
  if (data && data.taze_icerik_veri) {
    data.taze_icerik_veri.forEach((item) => {
      totalTazeIcerik += item.taze_icerik_sayisi; 
      totalAylikSaat += item.aylik_saat;          
    });
  }
  
  const genelOrtalama = totalAylikSaat === 0 ? 0 : ((totalTazeIcerik / totalAylikSaat)*100).toFixed(2);

  let totalYayinSaat = safeAccess(data?.yayin_oranlari?.["0"], "toplam", 0);

  function processTazeIcerikVeri(tazeIcerikVeri, resultType) {
    let result = [];

    tazeIcerikVeri.forEach((item) => {
      result.push({
        taze_icerik_sayisi: item.taze_icerik_sayisi,
        aylik_saat: item.aylik_saat,
        ay: item.ay,
        yil: item.yil,
      });
    });

    // 1'den 12'ye kadar aylar için eksik ayları doldurur
    for (let i = 1; i <= 12; i++) {
      if (!result.some((item) => item.ay === i)) {
        result.push({
          taze_icerik_sayisi: 0, 
          aylik_saat: 0,
          ay: i,
          yil: year || new Date().getFullYear(), // Yıl dinamik hale getirildi
        });
      }
    }

    // Son olarak ay sırasına göre sonuçları sıralar
    result.sort((a, b) => a.ay - b.ay);

    if (resultType == "int") {
      return result.map((item) => item.taze_icerik_sayisi);
    }
    else {
      return result;
    }
  }

  const reportsBarChartData6 = {
    chart: {
      labels: regions,
      datasets: { label: "Oran", data: datasetsData6 },
    },
    items: items6,
  };

  const reportsBarChartData61 = {
    chart: {
      labels: months,
      datasets: { label: "Taze İçerik Oranı", data: datasetsData61 },
    },
    items: items61,
  };

  return (
    <>
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="h6" fontWeight="medium" ml={1}>
          Yayın Faaliyet Grafiği
        </SoftTypography>
      </SoftBox>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <ReportsBarChart
              title={"Yayın Oranları " + (year ? year : "Genel") + " Yılı Dağılımı"}
              color="dark"
              description={
                <>
                  Toplam Süre (<strong>{totalYayinSaat} Saat</strong>)
                </>
              }
              chart={reportsBarChartData6.chart}
              items={reportsBarChartData6.items}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <ReportsBarChart
              title={"Taze İçerik Oranları " + (year ? year : "Genel") + " Yılı Dağılımı"}
              color="secondary"
              description={
                <>
                  Toplam Oran (<strong>%{genelOrtalama}</strong>)
                </>
              }
              chart={reportsBarChartData61.chart}
              items={reportsBarChartData61.items}
              size={2}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Tables;
