/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import axios from "axios"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";



// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import team2 from "assets/images/team-2.jpg";

//const BASE_URL = process.env.REACT_APP_URL

function Author({ image, name, email, tel }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="dark">
          {email}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {tel}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];


function Tables() {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/performans?format=json`);
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  // Tarihleri karşılaştırma fonksiyonu
  function tarihKarsilastir(a, b) {
    const tarihA = new Date(a.yil, a.ay - 1);
    const tarihB = new Date(b.yil, b.ay - 1);
    return tarihB - tarihA;
  }

  // Kullanıcı ve tarih bazında verileri gruplandırma ve sıralama fonksiyonu
  function gruplaVeSiralaVeriler(veriler) {
    const gruplanmisVeriler = {};

    veriler.forEach(item => {
      const kullaniciId = item.user.id;

      if (!gruplanmisVeriler[kullaniciId]) {
        gruplanmisVeriler[kullaniciId] = [];
      }

      gruplanmisVeriler[kullaniciId].push({ ay: item.ay, yil: item.yil, yapilanIsSayisi: item.yapilan_is_sayisi, name: item.user.name, surname: item.user.surname, personel_fotograf: item.user.personel_fotograf, ozelIs: item.yapilan_ozel_isler.length, unvan: item.user.trt_arabi_unvan, hedefler: item.aylik_hedefler });
    });

    // Her kullanıcı için verileri tarihe göre sırala
    for (const kullaniciId in gruplanmisVeriler) {
      gruplanmisVeriler[kullaniciId].sort(tarihKarsilastir);
    }

    return gruplanmisVeriler;
  }

  // Son üç aydaki toplam iş sayısını hesaplama fonksiyonu
  function hesaplaToplamIsSayisi(kullaniciVerileri) {
    let toplam = 0;

    console.log("KOOKL")
    console.log(kullaniciVerileri)
    //const sonUcAy = kullaniciVerileri.slice(0, 12); // En son veriden başlayarak ilk üç ayı al

    kullaniciVerileri.forEach(veri => {
      toplam += veri.yapilanIsSayisi;
    });

    return toplam;
  }

  function hesaplaToplamOzelIsSayisi(kullaniciVerileri) {
    let toplam = 0;
    //const sonUcAy = kullaniciVerileri.slice(0, 12); // En son veriden başlayarak ilk üç ayı al

    kullaniciVerileri.forEach(veri => {
      toplam += veri.ozelIs;
    });

    return toplam;
  }

  function hesaplaToplamHedefSayisi(kullaniciVerileri) {
    let toplam = 0;


    kullaniciVerileri.forEach(veri => {
      toplam += veri.hedefler;
    });

    return toplam;
  }

  function aylariHesapla(kullaniciVerileri) {
    let toplam = "";
    //const sonUcAy = kullaniciVerileri.slice(0, 6); // En son veriden başlayarak ilk üç ayı al

    kullaniciVerileri.forEach(veri => {
      const ayIsmi = String(ayIsimleri[veri.ay-1]);
      if (!toplam.includes(ayIsmi)) { // toplam içinde bu ay ismi yoksa
        toplam += ayIsmi;
        toplam += "-";
      }
    });

    return toplam;
}


  // Verileri grupla ve sırala
  const index = gruplaVeSiralaVeriler(data);

  let rows = []

  // Kullanıcının son üç aydaki toplam iş sayısını hesapla
  for (const kullaniciId in index) {
    const kullaniciVerileri = index[kullaniciId];


    const item = kullaniciVerileri["0"];

    const toplamIsSayisi = hesaplaToplamIsSayisi(kullaniciVerileri);
    const toplamOzelIsSayisi = hesaplaToplamOzelIsSayisi(kullaniciVerileri);
    const toplamHedefSayisi = hesaplaToplamHedefSayisi(kullaniciVerileri);

    const aylar = aylariHesapla(kullaniciVerileri);
    const aycount = aylar.split('-').length - 1;

    //console.log(`Kullanıcı ID: ${kullaniciId}, Son Üç Aydaki Toplam İş Sayısı: ${toplamIsSayisi}`);

    rows.push({
      Adı: <Author image={item.personel_fotograf} name={item.name+ " " + item.surname} email={item.email_adres} tel="" />,
      Pozisyon: <Function job={item.unvan} org="" />,
      "Kategori": <SoftBadge variant="gradient" badgeContent="TÜMÜ" color="dark" size="xs" container />,
      "Yapılan Özel İşler": <SoftBadge variant="gradient" badgeContent={toplamOzelIsSayisi} color="default" size="xs" container />,
      "Son Yapılan İşler": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {toplamIsSayisi}/<strong>{toplamHedefSayisi}</strong> </SoftTypography>,
      "Aylık Performans": (
        <SoftBox width="8rem" textAlign="left">
          <SoftProgress value={Math.min(100, Math.round((toplamIsSayisi / toplamHedefSayisi) * 100))} color="info" variant="gradient" label={false} />
          <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {Math.round((toplamIsSayisi / toplamHedefSayisi) * 100)}% </SoftTypography>
        </SoftBox>
      ),
      "Tarih": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {aylar} </SoftTypography>,
      "field_name": item.name + " " + item.surname,
      "field_position": item.unvan,
      "field_kategori": "TÜMÜ",
      "field_ozelis": toplamOzelIsSayisi,
    });


  }
  
  

  const columns = [
    { name: "Adı", align: "left" , exportableField: "field_name"},
    { name: "Pozisyon", align: "left" , exportableField: "field_position"},
    { name: "Kategori", align: "center" , exportableField: "field_kategori"},
    { name: "Yapılan Özel İşler", align: "center", exportableField: "field_ozelis"},
    { name: "Son Yapılan İşler", align: "center" },
    { name: "Aylık Performans", align: "center" },
    { name: "Tarih", align: "center" },
  ]

  return (
    <Grid item xs={12} md={12} xl={12}>
      <SoftBox pt={3} px={3}></SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Çalışan Performans Listesi (Genel)</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </Card>
    </Grid>

  );
}

export default Tables;
