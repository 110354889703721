/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/headers/NormalHeader"
import MuhabirGraph from "layouts/servis/graphs"

import curved0 from "assets/images/curved-images/curved-trt-servis-hd1.webp";

function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  let data = {
    "title":"Servis Saatleri",
    "line1":"Personel için kapsamlı servis saatleri rehberi",
    "line2":"+90 536 866 09 27 / +90 505 756 00 76",
    "image": curved0,
  }

  try{

    return (
      <DashboardLayout>
        <DashboardNavbar />
          <Header data={data} />
          <MuhabirGraph/>
      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
