/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import TimelineItem from "examples/Timeline/TimelineItem";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/servis-saatleri`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);

  let girisler;
  if (data) {
    // Opsiyonel zincirleme ile güvenli bir şekilde erişim sağlayın
    girisler = data["Ulus"]?.["GIRIS"]?.length ?? 0;
  }

  let cikislar;
  if (data) {
    // Opsiyonel zincirleme ile güvenli bir şekilde erişim sağlayın
    cikislar = data["Ulus"]?.["CIKIS"]?.length ?? 0;
  }

  let girislerRows
  let cikislarRows;

  girislerRows = data["Ulus"]?.["GIRIS"]?.map(time => (
    <TimelineItem
      key={time.id}
      color="info"
      icon="departure_board"
      title={`Saat ${time} `}
      dateTime={`TRT Ulus `}
    />
  ));

  cikislarRows = data["Ulus"]?.["CIKIS"]?.map(time => (
    <TimelineItem
      key={time.id}
      color="error"
      icon="departure_board"
      title={`Saat ${time} `}
      dateTime={`TRT Ulus `}
    />
  ));

  return (
    <SoftBox py={3}>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={6}>
            <Card className="h-100">
              <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Ulus Giriş Saatleri
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                      <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                        watch_later
                      </Icon>
                    </SoftTypography>
                    &nbsp;
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {girisler}
                    </SoftTypography>{" "}
                    farklı saat seçeneği
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox p={2}>
                {girislerRows}
              </SoftBox>
            </Card>
          </Grid>




          <Grid item xs={12} sm={6} xl={6}>
            <Card className="h-100">
              <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Ulus Çıkış Saatleri
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                      <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                        watch_later
                      </Icon>
                    </SoftTypography>
                    &nbsp;
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {cikislar}
                    </SoftTypography>{" "}
                    farklı saat seçeneği
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox p={2}>
                {cikislarRows}
              </SoftBox>
            </Card>
          </Grid>


        </Grid>
      </SoftBox>
    </SoftBox>
  );

}

export default Tables;
