/* eslint-disable react/prop-types */
import Header from "layouts/kanal-rapor/Header";
import DivHeader from "layouts/headers/NormalHeader"
import axios from "axios";
import curved0 from "assets/images/studio-5.jpg";

import InewsGraph from "layouts/kanal-rapor/graphs"

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";



import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ListItemText } from "@mui/material";

import { PieChart } from '@mui/x-charts/PieChart';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];



import YayinGraph from "layouts/yayin/graphs"
import MuhabirGraph from "layouts/muhabir/graphs"


const theme = createTheme({
    typography: {
        //fontSize: '12px !important',
        color: "#aaa",

        fontFamily: [
            "Roboto", "Helvetica", "Arial",
            'sans-serif'
            // Diğer fontlar...
        ].join(','),
    },
    // Diğer tema özelleştirmeleri...
});

let dataHeader3 = {
    "title": "Haber Dağılımı",
    "line1": "Kanaldaki Haber Veri Grafikler",
    "image": curved0,
    "color": "dark"


  }

  let dataHeader4 = {
    "title": "Inews Grafiği",
    "line1": "Kanaldaki Inews Veri Grafikler",
    "image": curved0,
    "color": "dark"


  }

function index() {

    const navigate = useNavigate();
    const printDocument = () => {
        window.print();
    }

    const { id } = useParams();

    if (id == ":id") {
        const dateCurrent = new Date().getFullYear();
        navigate(`/raporlama/kanal-rapor/${dateCurrent}`);
    }


    const [dataSelect, setDataSelect] = useState([]);
    const [newData, setNewData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/haber-yil?format=json`, { withCredentials: true });
                setDataSelect(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id, navigate]);


    const [skorData, setSkorData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/yayinoranlari/${id}?format=json`, { withCredentials: true });
                setSkorData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id, navigate]);


    useEffect(() => {
        const processedData = dataSelect?.unique_years?.map(item => ({
            label: `Yıl ${item}`,
            value: item
        }));

        setNewData(processedData);
    }, [dataSelect]);


    const handleSelectChange = (selected) => {
        const selectedYear = selected.value;
        navigate(`/raporlama/kanal-rapor/${selectedYear}`);
    };


    let data

    return (

        <DashboardLayout>
            <div style={{ position: 'absolute', zIndex: '1', right: '10%', top: '280px', display: "flex", flexWrap: "wrap", justifyContent: "space-between", flexDirection: "row-reverse", gap: "10px" }}>
                <SoftButton id="printButton" onClick={printDocument} sx={{ ml: 2 }} color="dark">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                        get_app
                    </Icon> PDF indir
                </SoftButton>
                <div style={{ marginLeft: "100px" }}>
                    <div style={{ width: "150px" }}>
                        <SoftSelect placeholder="Tarih Periodu" options={newData} onChange={handleSelectChange} style={{ paddingLeft: "120px" }} />
                    </div>
                </div>


            </div>

            <div style={{ position: 'absolute', zIndex: '1', right: '10%', top: '280px', marginRight: '150px' }}>

            </div>

            <div
                id="divToPrint"
            >


                <Header onChange={e => onTabChange(e)} data={data} id={id} />


                <Grid container spacing={0} >
                    <Grid item xs={11.8} md={11.8} xl={11.8} >
                        <Grid container spacing={-1} justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Yayın Performans
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        %{skorData?.yayin_oranlari_score} Performans
                                    </SoftTypography>
                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'Performans', value: skorData?.yayin_oranlari_score || 0 },
                                                    { label: 'Eksik Hedef', value: 100 - (skorData?.yayin_oranlari_score || 0) },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Taze İçerik Performans
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        %{skorData?.taze_icerik_score} Performans
                                    </SoftTypography>



                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'Performans', value: skorData?.taze_icerik_score },
                                                    { label: 'Eksik Hedef', value: 100 - skorData?.taze_icerik_score },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Performans Skor
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        Aktif Değil
                                    </SoftTypography>
                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'Tahmini Skor', value: 100 },
                                                    { label: 'Eksik Başarı', value: 200 },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#354868", "#648bfc", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
                <YayinGraph year={id} />
                <br />
                <DivHeader data={dataHeader3} />
                <br />
                <MuhabirGraph />
                <DivHeader data={dataHeader4} />
                <InewsGraph />
            </div>



        </DashboardLayout>

    )
}

export default index;