/* eslint-disable react/prop-types */
import Header from "layouts/rapor/Header";
import axios from "axios";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import MenuItem from "@mui/material/MenuItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";


import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ListItemText } from "@mui/material";

import { PieChart } from '@mui/x-charts/PieChart';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];



const theme = createTheme({
    typography: {
        //fontSize: '12px !important',
        color: "#aaa",

        fontFamily: [
            "Roboto", "Helvetica", "Arial",
            'sans-serif'
            // Diğer fontlar...
        ].join(','),
    },
    // Diğer tema özelleştirmeleri...
});

function createTimelineItems(branch, mudurluk) {
    let items = [];

    // Öğe için TimelineItem oluştur
    if (branch.Sorumlu_kisi) {
        items.push(
            <TimelineItem
                key={branch.id}
                color="info"
                icon="subdirectory_arrow_right"
                title={(branch.Yetkili_birim_adi == "-" ? mudurluk : branch.Yetkili_birim_adi).toUpperCase()}
                dateTime={branch.Sorumlu_kisi ? branch.Sorumlu_kisi.name + " " + branch.Sorumlu_kisi.surname : ''}
            />
        );
    }

    // Eğer ust_birim öğesi varsa, onun için de TimelineItem oluştur
    if (branch.ust_birim) {
        items = items.concat(createTimelineItems(branch.ust_birim, mudurluk));
    }


    return items;
}



function index() {

    const navigate = useNavigate();
    const printDocument = () => {
        window.print();
    }





    const { id, view } = useParams();



    const viewbool = view === "aylik";

    const changeView = () => {
        navigate(`/rapor/${id}/${view == "aylik" ? "yillik" : "aylik"}`)
    }

    const [data, setData] = useState({ egitimler: [], istecrubeler: [], meslekegitimler: [], egitimdiller: [], branchler: [], terfiler: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/user/${id}?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    let [dataPer, setDataPer] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/performans/${id}?format=json`, { withCredentials: true });
                setDataPer(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    let [dataPuan, setDataPuan] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/notlar/puanlama/${id}/${currentYear}?format=json`, { withCredentials: true });
                setDataPuan(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    let [dataNews, setDataNews] = useState({ veriler: [] });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/inews-rapor/${id}`, { withCredentials: true });
                setDataNews(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    let [dataHedef, setDataHedef] = useState({ veriler: [] });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/inews-hedef/${id}`, { withCredentials: true });
                setDataHedef(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    //console.log("Param")
    //console.log(monthTotals)

    const [izindata, setIzinData] = useState({ izinler: [], yillik_toplam: {} }); // izinler özelliği ile başlat

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/izinler/${id}`, { withCredentials: true });
                setIzinData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id]);


    let terfiRows2 = [];
    if (izindata && izindata.total_izinler) {
        terfiRows2 = Object.entries(izindata.total_izinler)
            .sort(([year1], [year2]) => year2 - year1) // Sorting years in descending order
            .map(([year, index]) => (
                <TimelineItem
                    key={year}
                    color="dark" // or any logic for determining color
                    icon="today" // or any logic for determining icon
                    description={[
                        <span key="line1">Yıllık İzin:<strong> {index.yillik} Gün</strong></span>,
                        <br key="br1" />,
                        <span key="line2">Sağlık Raporu:<strong> {index.rapor} Gün </strong></span>,
                        index.annelik !== "0" && <><br key="br2" /><span key="line3">*Annelik:<strong> {index.annelik} Gün </strong></span></>
                    ]}

                    title={`${year}`}
                    dateTime={``} // replace with actual data if available
                />
            ));
    }


    const terfiRows1 = data.branchler.flatMap(branch => createTimelineItems(branch, data.mudurluk + "/" + data.birim));

    let terfiRows = data.terfiler.map(terfi => (
        <TimelineItem
            key={terfi.id}
            color={terfi.terfi_tenzil === "Terfi" ? "success" : "error"}
            icon={terfi.terfi_tenzil === "Terfi" ? "unarchive" : "archive"}
            description={`Eski Unvan: ${terfi.eski_rutbe}`}
            title={`${terfi.yeni_rutbe} (${terfi.ilgili_bolum})`}
            dateTime={terfi.degisiklik_tarihi}
        />
    ));


    const monthData = dataPer ? dataPer : [];
    const monthData2 = dataNews ? dataNews : [];
    console.log("montshdatan")
    console.log(monthData);
    console.log(monthData2)


    function calculateTotalPerformanceForMonth(monthData) {
        return monthData.reduce((total, item) => total + item.yapilan_is_sayisi, 0);
    }

    function calculateTotalHedefForMonth(monthData) {
        return monthData.reduce((total, item) => total + item.aylik_hedefler, 0);
    }

    function calculateTotalOzelForMonth(monthData) {
        return monthData.reduce((total, item) => total + item.yapilan_ozel_isler.length, 0);
    }

    // Aylara göre gruplandırma
    const groupedByMonth = monthData.reduce((acc, item) => {
        const key = `${item.ay}-${item.yil}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    console.log(groupedByMonth)

    const aylikVeriler = [];

    let minyil = Infinity; // Başlangıçta en büyük sayı ile başla
    let minay = Infinity; // Başlangıçta en büyük sayı ile başla

    dataNews.veriler.forEach(yilVerisi => {
        const yil = yilVerisi.yil;
        Object.keys(yilVerisi.aylar).forEach(ay => {
            const ayVerisi = yilVerisi.aylar[ay];
            aylikVeriler.push({
                tarih: `${ay}-${yil}`,
                ay_toplam: ayVerisi.ay_toplam,
                haber_turleri: ayVerisi.haber_turleri
            });

            // Yeni bulunan yıl, minimum yıldan küçükse, veya aynı yıl ama ay daha küçükse
            if (yil < minyil || (yil === minyil && ay < minay)) {
                minyil = yil;
                minay = ay;
            }
        });
    });

    console.log(`En erken tarih: Ay: ${minay}, Yıl: ${minyil}`);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript'te ay 0'dan başlar, bu yüzden 1 ekliyoruz.
    console.log(`En erken tarih: Ay: ${currentMonth}, Yıl: ${currentYear}`);

    // Bulunan en erken ay ve yıl için
    const minYear = minyil;
    const minMonth = minay;

    // Her iki tarihi "toplam ay" cinsinden hesapla
    const totalMonthsCurrent = ((currentYear - minyil) * 12);
    const totalMonthsMin = currentMonth - minay;

    // Toplam aylar arasındaki farkı hesapla
    const monthDifference = totalMonthsCurrent + totalMonthsMin + 1;

    console.log(`Aradaki ay farkı: ${monthDifference}`);

    const [ayparam, setAyparam] = useState(12);

    useEffect(() => {
        if (monthDifference && parseInt(monthDifference) > 0) {
            setAyparam(monthDifference);
        }
    }, [monthDifference]);


    const [months, setMonths] = useState([{ month: [], year: [] }]);
    const [monthNames, setMonthNames] = useState([]);
    useEffect(() => {

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() - 1; // JavaScript'te Ocak 0, Aralık 11'dir.
        const currentYear = currentDate.getFullYear();

        const monthArray = [];
        const monthNameArray = [];
        for (let i = 0; i < ayparam; i++) {
            let calculatedMonth = currentMonth - i;
            let year = currentYear;

            // Eğer hesaplanan ay 0'dan küçükse, yılı azalt ve aya 12 ekleyerek ayı ayarla.
            while (calculatedMonth < 0) {
                calculatedMonth += 12; // Ayı 12'ye kadar çıkararak hesaplama
                year--; // Yılı bir geri al
            }

            const month = calculatedMonth + 1; // Ayı 1 ile 12 arasında bir değere dönüştürmek için +1 ekleyin
            monthArray.unshift({ month, year });
            monthNameArray.unshift(ayIsimleri[calculatedMonth]); // Ay isimlerini diziye ekler
        }
        setMonths(monthArray);
        setMonthNames(monthNameArray);
    }, [ayparam]);
    console.log("BRADA")
    console.log(months)
    console.log(monthNames)
    const monthTotals = months.map(({ month, year }) => {
        console.log(month)
        const yearData = (dataNews.veriler || []).find(v => v.yil === year);
        return yearData && yearData.aylar[month] ? yearData.aylar[month].ay_toplam : 0;
    });

    // Tarihe göre büyükten küçüğe sırala
    aylikVeriler.sort((a, b) => {
        const tarihA = new Date(a.tarih.split('-').reverse().join('-'));
        const tarihB = new Date(b.tarih.split('-').reverse().join('-'));
        return tarihB - tarihA; // Büyükten küçüğe sıralama
    });

    console.log(aylikVeriler);
    console.log("param")




    let labels5 = []
    let datalabel5 = []
    let datalabel2 = []
    let datalabel3 = []


    let genelPerformans = Math.round(calculateTotalPerformanceForMonth(monthData) / calculateTotalHedefForMonth(monthData) * 100);

    genelPerformans = isNaN(genelPerformans) ? 0 : genelPerformans;


    let yillikData = monthData;

    let farkliTarihler = new Set();
    let sliceIndex = 0;

    for (let i = 0; i < yillikData.length; i++) {
        let item = yillikData[i];
        let tarih = `${item.ay}-${item.yil}`;
        farkliTarihler.add(tarih);

        if (farkliTarihler.size <= ayparam) {
            sliceIndex = i + 1;
        } else {
            break;
        }
    }

    yillikData = yillikData.slice(0, sliceIndex);
    let yillikPerformans = Math.round(calculateTotalPerformanceForMonth(yillikData) / calculateTotalHedefForMonth(yillikData) * 100);

    yillikPerformans = isNaN(yillikPerformans) ? 0 : yillikPerformans;

    let totalPerformancerate = []
    let ortalamaPerfomans = 0
    let lastyearper = 0

    // Gruplanmış verileri kullanarak tablo satırlarını oluşturma
    const rows = [];
    Object.keys(groupedByMonth).forEach(monthYear => {
        const items = groupedByMonth[monthYear];
        const totalPerformance = calculateTotalPerformanceForMonth(items);
        const totalOzel = calculateTotalOzelForMonth(items);
        const totalHedef = calculateTotalHedefForMonth(items);

        labels5.push(ayIsimleri[items[0].ay - 1]);
        datalabel5.push(totalPerformance);
        datalabel2.push(totalOzel);
        datalabel3.push(totalHedef);
        //const datalabel5 = data.map(item => (Number(item.yapilan_is_sayisi)))


        // Önce ayın toplam performansını ekleyin
        rows.push({
            "ay": ayIsimleri[items[0].ay - 1],
            "title": <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                </Icon>
                <strong>{ayIsimleri[items[0].ay - 1]} {items[0].yil} </strong>  <br></br>
                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px"><strong>Toplam: {totalPerformance} / {totalHedef} </strong></SoftTypography>
                <SoftProgress sx={{ width: 100 }} value={Math.min(100, Math.round((totalPerformance / totalHedef) * 100))} color="info" variant="gradient" label={false} /> <SoftTypography variant="caption" color="secondary" fontWeight="medium"> Aylık Performans %{Math.round((totalPerformance / totalHedef) * 100)} </SoftTypography>
            </SoftTypography>
            ,

        });

        // Sonra ayın detaylarını ekleyin
        items.forEach(item => {
            rows.push({
                "title": <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">{item.kategori.adi}: {item.yapilan_is_sayisi} / {item.aylik_hedefler} </SoftTypography>
            });
        });
    });

    let rows3 = []

    let yillikVeri = {};

    dataNews.veriler.forEach(yilVerisi => {
        // Yıl başına toplam ve paketlerin sayılarını tutacak nesne oluştur
        yillikVeri[yilVerisi.yil] = { toplam: 0, paketler: {} };

        // Her ay için haber türlerinin sayısını topla
        Object.values(yilVerisi.aylar).forEach(ay => {
            yillikVeri[yilVerisi.yil].toplam += ay.ay_toplam;

            Object.entries(ay.haber_turleri).forEach(([tur, sayi]) => {
                yillikVeri[yilVerisi.yil].paketler[tur] = (yillikVeri[yilVerisi.yil].paketler[tur] || 0) + sayi;
            });
        });
    });

    console.log("YILLIK")
    let siraliYillikVeri = Object.keys(yillikVeri).sort((a, b) => b - a).map(yil => ({
        [yil]: yillikVeri[yil]
    }));

    const calculateWeightedScore = (dataPuan) => {
        const weights = {
          DC: 4,
          MG: 3,
          BP: 1,
          EP: 3,
          NC: 1,
        };
      
        let totalWeight = 0;
        let weightedSum = 0;
        let availableWeights = 0;
      
        // Calculate initial weights sum and check available weights
        for (let key in weights) {
          if (dataPuan?.survey?.[key]?.ortalama_skor !== null) {
            availableWeights += weights[key];
          }
        }
      
        // Calculate the total weight for normalization
        for (let key in weights) {
          if (dataPuan?.survey?.[key]?.ortalama_skor !== null) {
            totalWeight += weights[key];
          }
        }
      
        // Calculate the weighted sum
        for (let key in weights) {
          if (dataPuan?.survey?.[key]?.ortalama_skor !== null) {
            weightedSum += (dataPuan?.survey?.[key]?.ortalama_skor * weights[key]);
          }
        }
      
        // Normalize the score to be out of 60
        let normalizedScore = (weightedSum / totalWeight) * (availableWeights / totalWeight) * 4;

        normalizedScore = normalizedScore ? normalizedScore : 0
      
        return normalizedScore.toFixed(2); // Rounded to 2 decimal places
      };

    const calculateRating = (yearRating) => {
        if (yearRating >= 100) return 15;
        if (yearRating <= 80) return 0;
        return ((yearRating - 80) / 20 * 15).toFixed(1);
      };

      const calculateRaporPuan = (gunSayi) => {
        if (gunSayi >= 40) return 0;
        if (gunSayi <= 10) return 15;
        return ((40 - gunSayi) / 30 * 15).toFixed(1);
    };

    const calculateDisiplinPuan = (gunSayi) => {
        if (gunSayi >= 7) return 0;
        if (gunSayi <= 0) return 15;
        return ((7 - gunSayi) / 7 * 15).toFixed(1);
    };

    const calculateMesaiPuan = (gunSayi) => {
        if (gunSayi >= 50) return 0;
        if (gunSayi <= 10) return 15;
        return ((50 - gunSayi) / 40 * 15).toFixed(1);
    };




    let yearRatingScore = 0
    let yearRaporScore = calculateRaporPuan(dataPuan?.saglik_raporu?.saglik_raporu_sayisi)
    let yearDisiplinScore = calculateDisiplinPuan(dataPuan?.disiplin?.sikayet_edilen_sayisi)
    let yearMesaiScore = calculateMesaiPuan(dataPuan?.mesai_uyum?.ihlal_sayisi)
    let yearDegerlendirmeScore = calculateWeightedScore(dataPuan)


    siraliYillikVeri.forEach(yilVerisi => {
        // Yıl bilgisini al (Yıl, nesnenin anahtarı olarak bulunur)

        const yil = Object.keys(yilVerisi)[0];

        // Yıla ait verileri al
        const veri = yilVerisi[yil];

        const totalPerformance = veri.toplam;
        const totalOzel = 13;
        const totalHedef = dataHedef?.hedefler?.["TOPLAM_HEDEF"] ?? 0;



        console.log('Paketler:');
        let paketToplam = 0;
        Object.entries(veri.paketler).forEach(([paket, sayi]) => {
            // Hedefler ve tur puanları için varlık kontrolü
            const hedef = dataHedef["hedefler"]?.[paket];
            const turPuan = dataHedef["tur_puanlari"]?.[paket];

            // Hedefin varlığını ve pozitif olduğunu kontrol et
            if (hedef && hedef > 0 && turPuan !== undefined) {
                console.log(`  ${paket}: ${sayi} ${hedef}`);
                paketToplam += (sayi / hedef) * turPuan;
            } else {
                console.log(`Hedef veya puan tanımsız: ${paket}`);
            }
        });

        //console.log(`Yıllık Hesaplama: ${yil}`)
        let yearRating = dataHedef?.hedefler?.TOPLAM ? ((paketToplam / dataHedef.hedefler.TOPLAM) * 100).toFixed(1) : 0;
        yearRating = dataNews?.performans[yil];

        
        //console.log(yearRating)
        totalPerformancerate.push(yearRating)

        if (lastyearper == 0){
            yearRating = (yearRating * (12 / (currentMonth-1))).toFixed(1)
        }

        lastyearper = lastyearper == 0 ? yearRating : lastyearper;


        // Yıl ve toplam bilgisini yazdır
        console.log(`Yıl: ${yil}, Toplam: ${veri.toplam}`);
        console.log("heres")
        rows3.push({
            "ay": yil,
            "title": <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                </Icon>
                <strong>{yil} Yılı Verileri </strong>  <br></br>
                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px"><strong>Toplam: {totalPerformance} / {totalHedef} </strong></SoftTypography>
                <SoftProgress sx={{ width: 100 }} value={Math.min(100, yearRating)} color={yearRating < 0 ? "error" : yearRating <= 95 ? "error" : "info"} variant="gradient" label={false} />
                <MenuItem sx={(theme) => ({
                    ...menuItem(theme),
                    height: '35px',
                    marginTop: '10px',
                    marginBottom: '0px',
                    paddingBottom: '0px'
                })}>
                    <SoftBox
                        width="1.5rem"
                        height="1.5rem"
                        mt={0.25}
                        mr={1}
                        ml={-2}
                        mb={.25}
                        borderRadius=".45rem"
                        sx={(theme) => menuImage(theme, {
                            color: yearRating === 0 ? "secondary" : yearRating <= 95 ? "error" : "info"
                        })}
                    >
                        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                            assignment
                        </Icon>
                    </SoftBox>
                    <SoftTypography variant="button" textTransform="capitalize" fontWeight="small">
                        <strong>
                            {yearRating === 0 ? "Hedef Belirlenmemiş" : yearRating <= 95 ? "Beklenen Performansın Altında" : "Beklenen Performansın Üstünde"}
                        </strong>
                    </SoftTypography>
                </MenuItem>


                <SoftTypography variant="caption" color="secondary" fontWeight="medium"> Yıllık Performans <strong> %{yearRating} </strong> </SoftTypography>
            </SoftTypography>

            ,

        });
        console.log("Bura")

        rows3.push({
            "title": (
                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                    {Object.entries(veri?.paketler).map(([key, value]) => (

                        <>↠ {key}: <strong>{value} / {dataHedef?.["hedefler"]?.[key] ?? "-"} </strong> </>

                    ))}
                </SoftTypography>
            )
        });

        console.log("Buras")

        // Paketleri ve sayılarını yazdır

    });

    yearRatingScore = calculateRating(lastyearper)

    let yearTotalSubjectScore =  (parseFloat(yearRatingScore) + parseFloat(yearDisiplinScore) + parseFloat(yearMesaiScore) + parseFloat(yearRaporScore)).toFixed(1);

    let yearTotalScore = (parseFloat(yearDegerlendirmeScore)  +  parseFloat(yearTotalSubjectScore)).toFixed(1);

    let yearLostScore = (100-yearTotalScore).toFixed(1);


    let toplamper = totalPerformancerate.reduce((acc, currentValue) => acc + Number(currentValue), 0);

    ortalamaPerfomans = dataNews?.genel_performans;


    let year_total_done = 0;
    const rows2 = []; // AYLIK
    Object.keys(aylikVeriler).forEach(monthYear => {
        const items = aylikVeriler[monthYear].haber_turleri;
        console.log(items)
        const totalPerformance = aylikVeriler[monthYear].ay_toplam;
        const totalOzel = 13;
        const totalHedef = null;


        const timeName = aylikVeriler[monthYear].tarih; // Örneğin "10-2023"


        let [ay, yil] = timeName.split('-'); // ay ve yılı ayır

        const ayIndex = parseInt(ay, 10) - 1; // Ay numarasını dizinin indexine dönüştür
        const ayIsmi = ayIsimleri[ayIndex]; // Ayın ismini al

        const yeniTimeName = `${ayIsmi} ${yil}`;

        ay = parseInt(ay)
        yil = parseInt(yil)

        if (months.some(m => m.month === ay && m.year === yil)) {
            year_total_done += totalPerformance;
            rows2.push({
                "ay": monthYear,
                "title": <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{yeniTimeName} </strong>  <br></br>
                    <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px"><strong>Toplam: {totalPerformance} / {totalHedef} </strong></SoftTypography>
                    <SoftProgress sx={{ width: 100 }} value={Math.min(100, Math.round((totalPerformance / totalHedef) * 100))} color="info" variant="gradient" label={false} /> <SoftTypography variant="caption" color="secondary" fontWeight="medium"> Aylık Performans %{Math.round((totalPerformance / totalHedef) * 100)} </SoftTypography>
                </SoftTypography>
                ,

            });

            rows2.push({
                "title": (
                    <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                        {Object.entries(items).map(([key, value]) => (

                            <>{key}: <strong>{value} </strong> </>

                        ))}
                    </SoftTypography>
                )
            });
        }





        // Sonra ayın detaylarını ekleyin
        // Object.entries(items).forEach(([key, value]) => {
        //     rows2.push({
        //         "title": <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">{key}: {value} </SoftTypography>
        //     });
        // });

    })

    let toplamHaberTurleri = {};

    aylikVeriler.forEach(ay => {
        // Ay ve yıl bilgisini ayır (örneğin "12-2023" formatındaki bir tarih için)
        const [ayNumarasi, yil] = ay.tarih.split('-').map(Number);

        // Eğer months dizisi bu ay ve yıl kombinasyonunu içeriyorsa, işlem yap
        if (months.some(m => m.month === ayNumarasi && m.year === yil)) {
            Object.keys(ay.haber_turleri).forEach(tur => {
                if (toplamHaberTurleri[tur]) {
                    toplamHaberTurleri[tur] += ay.haber_turleri[tur];
                } else {
                    toplamHaberTurleri[tur] = ay.haber_turleri[tur];
                }
            });
        }
    });



    const convertToDesiredFormat = (obj, all_count) => {
        // toplamHaberTurleri nesnesini sırala
        const sorted = Object.entries(obj).sort((a, b) => b[1] - a[1]);

        // İlk dört öğeyi al
        const topFour = sorted.slice(0, 8).map(([label, progress]) => ({
            icon: { color: "info", component: "assignment" },
            label: label,
            progress: { content: `${progress}` }
        }));

        // Kalan öğeleri bir stringe dönüştür
        const others = sorted.slice(4).map(([label, progress]) => `➜ ${label}: ${progress}`).join('   ');

        return { topFour, others };
    };

    let itemsPaket, othersString;
    if (toplamHaberTurleri) {
        const result = convertToDesiredFormat(toplamHaberTurleri, year_total_done);
        itemsPaket = result.topFour;
        othersString = result.others;
    }

    console.log("rows")
    console.log(rows)
    console.log(rows2)

    let strayparam = `${ayparam} Aylık Performans Verileri`;

    console.log(months)
    return (
        <DashboardLayout>

            <div style={{ position: 'absolute', zIndex: '1', right: '10%', top: '120px' }}>
                <SoftButton id="printButton" onClick={printDocument} sx={{ ml: 2 }} color="dark">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                        get_app
                    </Icon> PDF indir
                </SoftButton>

            </div>

            <div style={{ position: 'absolute', zIndex: '1', right: '10%', top: '120px', marginRight: '150px' }}>
                <SoftButton id="printButton" onClick={changeView} sx={{ ml: 2 }} color="white">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                        visibility
                    </Icon> {viewbool ? " Aylık" : " Yıllık"}
                </SoftButton>

            </div>

            <div
                id="divToPrint"
            >
                <div>
                    <img src="/static/media/TRT_blue_logo.7560177b32c04ebe9f8e.png" alt="logo" style={{ position: 'absolute', width: '7%', right: '10%', top: '60px', zIndex: '1' }} />
                </div>

                <Header onChange={e => onTabChange(e)} data={data} />
                <Grid container spacing={3} py={1} >
                    <Grid item xs={12} lg={12}  >
                        <ReportsBarChart
                            title=""
                            // title={"Yıllık Görünüm " + monthNames[0] + " " + months[0].year + " - " + monthNames[ayparam - 1] + " " + (months.length > ayparam - 1 ? months[ayparam - 1].year : "-")}
                            description={
                                <>
                                    (<strong>{dataNews.genel_toplam}</strong>) Toplam Yapılan İş <br />

                                    (<strong>{year_total_done}</strong>) {ayparam} Aylık Toplam Yapılan İş
                                </>
                            }
                            altdesc={""}
                            maintitle={"Genel Performans Grafiği "}
                            chart={
                                {
                                    labels: monthNames,
                                    datasets: { label: "Yapılan iş", data: monthTotals }
                                }
                            }
                            items={itemsPaket}

                            color="dark"
                            size="1.5"
                            xdesc="true"
                        />

                    </Grid>
                </Grid>
                <Grid container spacing={0} >
                    <Grid item xs={11.8} sm={11.8} xl={11.8}>
                        <Grid container spacing={-1} justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Genel Performans
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        %{ortalamaPerfomans} Performans
                                    </SoftTypography>
                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'Performans', value: ortalamaPerfomans },
                                                    { label: 'Eksik Hedef', value: 100 - ortalamaPerfomans },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Yıllık Performans
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        %{lastyearper} Performans
                                    </SoftTypography>



                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'Performans', value: lastyearper },
                                                    { label: 'Eksik Hedef', value: 100 - lastyearper },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#354868"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4} xl={4}>
                                <Card className="h-100" sx={{
                                    mt: 2,
                                    ml: 3,
                                    py: 0,
                                    px: 0,
                                    marginBottom: 0
                                }}>
                                    <SoftBox display="flex" alignItems="center">

                                        <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                            Performans Puanı
                                        </SoftTypography>

                                    </SoftBox>

                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" style={{ paddingInline: '24px', marginTop: '-20px' }}>

                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                        {yearTotalScore} / 100 Puan
                                    </SoftTypography>
                                    <ThemeProvider theme={theme}>
                                        <PieChart
                                            series={[
                                                {
                                                    paddingAngle: 5,
                                                    innerRadius: 50,
                                                    outerRadius: 70,
                                                    data: [{ label: 'İş Performansı', value: yearTotalSubjectScore },
                                                    { label: 'Değerlendirme', value: yearDegerlendirmeScore },
                                                    { label: 'Eksik Başarı', value: yearLostScore },]
                                                },
                                            ]}
                                            legend={{ hidden: true }}
                                            width={300}
                                            height={200}
                                            labelClassName="custom-label"
                                            colors={["#2274d2", "#0097ff", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                                        />
                                    </ThemeProvider>
                                </Card>
                            </Grid>

                        </Grid>

                    </Grid>





                    <Grid container spacing={3} justifyContent="center" alignItems="center" mt={0}>
                        <Grid item xs={12} sm={3} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "Üretim Başarı" }}
                                count={dataPuan?.performans?.["skor"] ?? "0"}
                                percentage={{ color: "secondary", text: "/15 Puan" }}
                                icon={{ color: "info", component: "peopleAltIcon" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "Sağlık Raporu" }}
                                count={dataPuan?.saglik_raporu?.["skor"] ?? "0"}
                                percentage={{ color: "secondary", text: "/15 Puan" }}
                                icon={{ color: "info", component: "workHistory" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "Disiplin Cezaları" }}
                                count={dataPuan?.disiplin?.["skor"] ?? "0"}
                                percentage={{ color: "secondary", text: "/15 Puan" }}
                                icon={{ color: "info", component: "assignmentTurnedInIcon" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "Mesai Uyum" }}
                                count={dataPuan?.mesai_uyum?.["skor"] ?? "0"}
                                percentage={{ color: "secondary", text: "/15 Puan" }}
                                icon={{
                                    color: "info",
                                    component: "public",
                                }}
                            />
                        </Grid>
                    </Grid>



                    <Grid item xs={12} md={12} xl={12} mt={3} >
                        <ReportsBarChart
                            title=""

                            altdesc={""}
                            maintitle={"Yetkili Değerlendirmeleri (40 Puan) "}
                            chart={{}}

                            items={[
                                {
                                    icon: { color: yearDegerlendirmeScore > 0 ? "dark" : "dark", component: "people" },
                                    label: "DEĞERLENDİRME PUANI",
                                    progress: { content: `${dataPuan?.survey?.["skor"] ?? "-"}` }
                                },
                                {
                                    icon: { color: dataPuan?.survey?.["DC"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                                    label: "Director of TRT Arabi (" + dataPuan?.survey?.["DC"]?.degerlendirme_sayisi + ")",
                                    progress: { content: `${dataPuan?.survey?.["DC"]?.ortalama_skor ?? "-"}` }
                                },
                                {
                                    icon: { color: dataPuan?.survey?.["MG"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                                    label: "Manager (" + dataPuan?.survey?.["MG"]?.degerlendirme_sayisi + ")",
                                    progress: { content: `${dataPuan?.survey?.["MG"]?.ortalama_skor ?? "-"}` }
                                },
                                {
                                    icon: { color: dataPuan?.survey?.["EP"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                                    label: "Executive Producer (" + dataPuan?.survey?.["EP"]?.degerlendirme_sayisi + ")",
                                    progress: { content: `${dataPuan?.survey?.["EP"]?.ortalama_skor ?? "-"}` }
                                },
                                {
                                    icon: { color: dataPuan?.survey?.["BP"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                                    label: "Business Partner (" + dataPuan?.survey?.["BP"]?.degerlendirme_sayisi + ")",
                                    progress: { content: `${dataPuan?.survey?.["BP"]?.ortalama_skor ?? "-"}` }
                                },
                                {
                                    icon: { color: dataPuan?.survey?.["NC"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                                    label: "Newsroom Coordinator (" + dataPuan?.survey?.["NC"]?.degerlendirme_sayisi + ")",
                                    progress: { content: `${dataPuan?.survey?.["NC"]?.ortalama_skor ?? "-"}` }
                                },


                            ]}

                            color="dark"
                            size="2"
                            xdesc="true"

                        />

                    </Grid>




                    <Grid item xs={12} sm={4} xl={4}>
                        <Card className="h-100" sx={{
                            mt: 2,
                            ml: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 10
                        }}>
                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Kurum Bilgileri
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            {/* <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                label_important
                                            </Icon> */}
                                        </SoftTypography>
                                        {/* &nbsp; */}
                                        <SoftTypography variant="button" color="text" fontWeight="medium">

                                        </SoftTypography>{" "}
                                        <strong>Sicil No:</strong> {data.sicil_no}<br />
                                        <strong>Sözleşme Türü:</strong> {data.sozlesme}<br />
                                        <strong>Sözleşme Başlangıç:</strong> {data.sozlesme_baslangic}<br />

                                        {/* <strong>Sözleşme Bitiş:</strong> {data.sozlesme_bitis}<br /> */}
                                        {/* <strong>TRT Ünvan:</strong> {data.trt_unvan}<br /> */}
                                        <strong>TRT Başlanıç:</strong> {data.trt_baslangic}<br />
                                        <strong>Çalışma Yeri:</strong> {data.calisma_yeri}<br />

                                        {/* <strong>Müdürlük:</strong> {data.mudurluk}<br />
                                        <strong>Birim:</strong> {data.birim}<br /> */}
                                        {/* <strong>Local / Expat:</strong> {data.local_expat}<br /> */}
                                        {/* <strong>Çalışma İzni Bitiş:</strong> {data.calisma_izni_bitis_tarihi}<br /> */}


                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>



                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Üst Birim Şeması
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>

                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows1}
                            </SoftBox>

                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Terfi geçmişi
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                                                arrow_upward
                                            </Icon>
                                        </SoftTypography>
                                        &nbsp;
                                        <SoftTypography variant="button" color="text" fontWeight="medium">
                                            {terfiRows.length}
                                        </SoftTypography>{" "}
                                        Terfi Güncellemesi
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows}
                            </SoftBox>



                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    İzin geçmişi
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                                                arrow_upward
                                            </Icon>
                                        </SoftTypography>
                                        &nbsp;
                                        <SoftTypography variant="button" color="text" fontWeight="medium">
                                            {terfiRows2.length}
                                        </SoftTypography>{" "}
                                        İzin Güncellemesi
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows2}
                            </SoftBox>



                            <SoftBox p={2}>

                            </SoftBox>
                        </Card>
                    </Grid>







                    <Grid item xs={12} sm={8} xl={8}>
                        {/* <Card className="h-100" sx={{
                            mt: 2,
                            mx: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 10,
                            padddingRight: 20,
                            //width: "70%",
                            heigh: "60%"
                        }}>
                        <GradientLineChart
                            title="Veri Grafik Görünümü"
                            description={
                                <SoftBox display="flex" alignItems="center" style={{ width: "100px"}}>
                                    <SoftBox fontSize={6} color="success" mb={0.3} mr={0.5} lineHeight={0}>

                                    </SoftBox>
                                    <SoftTypography variant="button" color="text" fontWeight="medium">
                                        
                                        <SoftTypography variant="button" color="text" fontWeight="regular">
                                            Performans
                                        </SoftTypography>
                                    </SoftTypography>
                                </SoftBox>
                            }
                            width= "70%"
                            height="70%"
                            //witdh="100vw"
                            style={{ width: "100px"}}
                            chart={

                                {
                                    labels: labels5,
                                    datasets: [
                                        { label: "Yapılan iş", data: datalabel5, color: "dark", },
                                        { label: "Yapılan özel iş", data: datalabel2, color: "info", },
                                        { label: "Hedeflenen iş", data: datalabel3, color: "light", },
                                    ]
                                }

                            }
                        />
                        </Card> */}
                        <Card className="h-100" sx={{
                            mt: 2,
                            mx: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 0
                        }}>
                            <SoftBox pt={3} px={3}>







                                <SoftTypography variant="h6" fontWeight="medium">
                                    {(viewbool ? strayparam : "Yıllık Performans Verileri")}
                                </SoftTypography>
                                <SoftBox mt={0} mb={0} px={0} >





                                    {(viewbool ? rows2 : rows3).map(item => (
                                        <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px" key={item.key}>
                                            <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" key={item.key}>
                                                {item.title} <br />
                                            </SoftTypography>
                                        </SoftTypography>
                                    ))}





                                </SoftBox>











                            </SoftBox>
                            <SoftBox p={2}>

                            </SoftBox>
                        </Card>
                    </Grid>



                </Grid>

            </div>
        </DashboardLayout>

    )
}

export default index;