

const reportsBarChartData = {
  chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Sales", data: [450, 200, 100, 220, 500, 100, 400, 230, 500] },
  },
  items: [
    {
      icon: { color: "info", component: "library_books" },
      label: "paket-1",
      progress: { content: "0", percentage: 60 },
    },
    {
      icon: { color: "info", component: "touch_app" },
      label: "paket-2",
      progress: { content: "0", percentage: 90 },
    },
    {
      icon: { color: "info", component: "payment" },
      label: "paket-3",
      progress: { content: "0", percentage: 30 },
    },
    {
      icon: { color: "info", component: "extension" },
      label: "paket-4",
      progress: { content: "0", percentage: 50 },
    },
  ],
};

export default reportsBarChartData;
