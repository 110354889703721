/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("VERİ")
      try {
        const response = await axios.get(`${window.BASE_URL}/intibak?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ id, image, name, email, tel }) {
      return (
        <a href={`/profile/${id}`}>
          <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
              <SoftAvatar src={image} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="dark">
                {email}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </a>
      );
    }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };



    const tableRows = data.map(item => {
      return {
          "İsim": <Author id={item.user.id} image={window.BASE_URL + item.user.personel_fotograf} name={item.user.name + " " + item.user.surname } email={item.user.trt_arabi_unvan}  />,
          "Başlangıç Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.user.ise_baslangic}</SoftTypography>,
          "statu": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.user.sozlesme}</SoftTypography>,

          "trt eposta": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.trt_eposta}</SoftTypography>,
          "trt arabi eposta": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.trt_arabi_eposta}</SoftTypography>,
          "birim grubu": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.birim_grubu}</SoftTypography>,
          "all team eposta": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.all_team_eposta}</SoftTypography>,
          "kurum kartlari": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.kurum_kartlari}</SoftTypography>,
          "aa": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.aa}</SoftTypography>,
          "ap": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.ap}</SoftTypography>,
          "afp": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.afp}</SoftTypography>,
          "reuters": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.reuters}</SoftTypography>,
          "foresight": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.foresight}</SoftTypography>,
          "inews": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.inews}</SoftTypography>,
          "inews kullanici": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.inews_kullanici}</SoftTypography>,

          "stratus": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.stratus}</SoftTypography>,
          "edius": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.edius}</SoftTypography>,
          "trt go": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.trt_go}</SoftTypography>,
          "ebys": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.ebys}</SoftTypography>,
          "gusto": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.gusto}</SoftTypography>,
          "ana grup": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.ana_grup}</SoftTypography>,
          "birim grubu whatsapp": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.birim_grubu_whatsapp}</SoftTypography>,
          "koordinasyonla tanisma": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.koordinasyonla_tanisma}</SoftTypography>,
          "promosyon urunleri": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.promosyon_urunleri}</SoftTypography>,
          "vesikalik fotograf": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.vesikalik}</SoftTypography>,

          "search_name": item.user.name,
      };
  });
  
  const projectsTableData = {
      columns: [
          { name: "İsim", align: "left",  exportableField: "search_name" },
          { name: "Başlangıç Tarihi", align: "Center"},
          { name: "statu", align: "Center"},

          { name: "trt eposta", align: "Center"},
          { name: "trt arabi eposta", align: "Center" },
          { name: "birim grubu", align: "Center" },
          { name: "all team eposta", align: "Center" },
          { name: "kurum kartlari", align: "Center" },
          { name: "aa", align: "Center" },
          { name: "ap", align: "Center" },
          { name: "afp", align: "Center" },
          { name: "reuters", align: "Center" },
          { name: "foresight", align: "Center" },
          { name: "inews", align: "Center" },
          { name: "inews kullanici", align: "Center" },
          { name: "stratus", align: "Center" },
          { name: "edius", align: "Center" },
          { name: "trt go", align: "Center" },
          { name: "ebys", align: "Center" },
          { name: "gusto", align: "Center" },
          { name: "ana grup", align: "Center" },
          { name: "birim grubu whatsapp", align: "Center" },
          { name: "koordinasyonla tanisma", align: "Center" },
          { name: "promosyon urunleri", align: "Center" },
          { name: "vesikalik fotograf", align: "Center" },
      ],
      rows: tableRows
  };
  










    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">İşe Giriş İşlemleri Takip Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>

        </Grid>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
