import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import axios from "axios"

import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

// @mui icons
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
    //console.log(data);
    const safeValue = (value) => value === null ? "-" : value;
    const [izindata, setIzinData] = useState({ izinler: [],yillik_toplam: {} }); // izinler özelliği ile başlat

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/izinler/${data.data.id}`, { withCredentials: true });
                setIzinData(response.data);
                // ... diğer kodlar ...
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [data.data.id]);

    console.log(data.data.id);


    let terfiRows = izindata.izinler.map(terfi => (
      <TimelineItem
          key={terfi.id}
          color={terfi.terfi_tenzil === "Terfi" ? "success" : "info"}
          icon={terfi.terfi_tenzil === "Terfi" ? "unarchive" : "archive"}
          description={`İzin Süresi: ${terfi.gun_sayisi} Gün`}
          title={`${terfi.izin_tarihi} (${terfi.yil})`}
          dateTime={terfi.notlar}
      />));

      let terfiRows2 = Object.entries(izindata.yillik_toplam)
      .sort(([year1], [year2]) => year2 - year1) // Sorting years in descending order
      .map(([year, gun_sayisi]) => (
          <TimelineItem
              key={year}
              color="dark" // or any logic for determining color
              icon="today" // or any logic for determining icon
              description={`İzin Süresi: ${gun_sayisi} Gün`}
              title={`${year}`}
              dateTime={``} // replace with actual data if available
          />
      ));
  
    
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={6} className="h-100" style={{ marginTop: '30px'}}>
            <ProfileInfoCard
              className="h-100" style={{ marginTop: '30px'}}
              title="Genel İzin Bilgileri"
              description="İzine dair bilgileri detayları"
              info={{
                "Toplam Kullanılan İzin Günü": izindata.toplam_gun + " Gün",
                "Sözleşme Türü": safeValue(data.data.sozlesme),
                "Sözleşme Başlangıç": safeValue(data.data.sozlesme_baslangic),
              
                "TRT Başlanıç": safeValue(data.data.trt_baslangic),
            
                
                
          
                "Yıllık": terfiRows2,

              }}
              social={[]}
              action={[]}
            />
            
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
              
                <Card className="h-100" style={{ marginTop: '30px'}}>
                    <SoftBox pt={3} px={3}>
                        <SoftTypography variant="h6" fontWeight="medium">
                            İzin geçmişi
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                    <Icon sx={{ fontWeight: "bold", color: ({ palette: { info } }) => info.main }}>
                                        arrow_upward
                                    </Icon>
                                </SoftTypography>
                                &nbsp;
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    {terfiRows.length}
                                </SoftTypography>{" "}
                                İzin Güncellemeleri
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox p={2}>
                        {terfiRows}
                    </SoftBox>
                </Card>
            </Grid>
          </Grid>

    )
}

export default index
