/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";


function Tables() {






  const navigate = useNavigate();
  //const [data, setData] = useState([]);

  function Target({ id, image, name, email, tel }) {
    return (
      <a href={`/profile/${id}`}>
        <SoftBadge variant="gradient" badgeContent={

          <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{width:"300px"}}>
            <SoftBox mr={2}>
              <SoftAvatar src={image} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="dark">
                {email}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
            </SoftBox>
          </SoftBox>

        } color="light" size="xl" container />


      </a>
    );
  }

  function Author({ id, image, name, email, tel }) {
    return (
      <a href={`/profile/${id}`}>
        {/* <SoftBadge variant="gradient" badgeContent={ */}

        <SoftBox display="flex" alignItems="center" px={1} py={0.5} >
          {/* <SoftBox mr={2}>
              <SoftAvatar src={image} size="sm" variant="rounded" />
            </SoftBox> */}
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="dark">
              {email}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {tel}
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        {/* } color="light" size="xs" container /> */}


      </a>
    );
  }

  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const [data, setData] = useState([]);
  const [dataName, setDataName] = useState([]);
  const [columns, setColumns] = useState([]); // Sütunlar için bir state

  const userKanal = useSelector(state => state.auth.userKanal);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/notlar/users?format=json`, { withCredentials: true });
        const responseData = response.data.results || []; // 'results' anahtarını kontrol et, eğer yoksa boş dizi kullan



        // Eğer gelen veri bir obje ve bu objenin içinde veri dizisi varsa
        if (response.data) {
          setData(response.data.kategorize_notlar.results);
          setDataName(response.data.short_labels); 
          console.log("KEY")
          const columnData = [{
            name: `hedef_user`,
            align: 'left',
            exportableField: `field_hedef`,
            searchable: true,
            searchField: [`field_hedef`],
            altName: "hedef personel"
          },
          {
            name: `not_yazan_user`,
            align: 'left',
            exportableField: `field_yazan`,
            searchable: true,
            searchField: [`field_yazan`],
            altName: "değerlendiren"
          },
          {
            name: `toplam`,
            align: 'center',
            exportableField: `field_toplam`,
            searchable: true,
            searchField: [`field_toplam`],
            altName: "genel not"
          },
          {
            name: `tarih`,
            align: 'center',
            exportableField: `field_tarih`,
            searchable: true,
            searchField: [`field_tarih`],
            altName: "Tarih"
          }
          ];

          
          if (userKanal == "Global")  {
            columnData.splice(2, 0, { name: "Kanal", align: "center", exportableField: `field_kanal`, searchable: true, searchField: [`field_kanal`], });
          }

          // Anahtarları (örn. "Web Developer", "Web Developer2", ...) ve onların değerlerini al
          let one_time = true
          Object.entries(response.data).forEach(([key, value]) => {



            // Her anahtar için (örn. "Web Developer", "Web Developer2", ...) "CVR" ve "PKG" değerlerini al
            if (value.degerlendiren !== undefined && one_time) {

              one_time = false
              Object.entries(value).forEach(([key2, value2]) => {

                // else if (key2 == "hedef_user") {
                //   columnData.push({
                //     name: `${key2}`,
                //     align: 'left',
                //     exportableField: `field_hedef`,
                //     searchable: true,
                //     searchField: [`field_hedef`],
                //   });
                // }
                if (key2 != "id" && key2 != "not_yazan_user_alt" && key2 != "notu" && key2 != "hedef_user" && key2 != "degerlendiren" && key2 != "not_yazan_user" && key2 != "toplam" && key2 != "toplam_performans" && key2 != "toplam_deger") {
                  console.log(key2)
                  columnData.push({
                    name: `${key2}`,
                    align: 'center',
                  });
                }
                //columnData.push(key2)
              })
            }
          });
          setColumns(columnData);

        } else {
          setData([]); // Eğer beklenen yapıda veri yoksa, data'yı boş dizi olarak ayarla
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  let egitimRows = []
  if (data) {

    const dataArray = Object.values(data);
    console.log(data)
    egitimRows = dataArray.map((item) => {


      return {

        ...item,

        "not_yazan_user": <Author id={item.not_yazan_user?.id} image={window.BASE_URL + item.not_yazan_user?.personel_fotograf} name={item.not_yazan_user?.name + " " + item.not_yazan_user?.surname} email={dataName[item.degerlendiren]} />,
        "hedef_user": <Target id={item.hedef_user?.id} image={window.BASE_URL + item.hedef_user?.personel_fotograf} name={item.hedef_user?.name + " " + item.hedef_user?.surname} email={item.hedef_user?.trt_arabi_unvan} />,
        "toplam": <SoftBadge variant="gradient" badgeContent={item.skor} color={item.aktif ? "dark" : "secondary"} size="xs" container />,
        "tarih": <SoftBadge variant="gradient" badgeContent={item.degerlendirme_tarihi} color={"info"} size="xs" container />,

        "Kanal": <SoftBadge variant="gradient" badgeContent={item.hedef_user?.kanal.kanal_adi} color="dark" size="xs"> </SoftBadge>,

        "field_kanal": item.hedef_user?.kanal.kanal_adi,
        "field_yazan": item.not_yazan_user?.name + " " + item.not_yazan_user?.surname,
        "field_hedef": item.hedef_user?.name + " " + item.hedef_user?.surname,
        "field_toplam": item.toplam,
        "field_tarih": item.degerlendirme_tarihi,
        "field_toplam_performans": item.toplam_performans,
      };
    });



  }

  const projectsTableData = {
    columns: columns,
    rows: [
      ...egitimRows,
    ],
  };


  
  if (data){


  }


  // console.log(egitimRows);
  // console.log(projectsTableData);







  return (

    <Grid item xs={12} md={12} xl={12}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Çalışan Değerlendirme Notları</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
        </SoftBox>
      </Card>

    </Grid>


  );

}

export default Tables;
