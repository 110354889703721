import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
    console.log(data);
    const safeValue = (value) => value === null ? "-" : value;
    
    return (
        <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="Sağlık Bilgileri"
              description="Sağlık bilgileri detayları"
              info={{
                
                "Kan Grubu": safeValue(data.data.kan_grubu),
                "Hastalık Bilgisi": safeValue(data.data.hastalik_bilgisi),
                "Kullandığı İlaçlar": safeValue(data.data.kullandigi_ilaclar),
                "Alerji Bilgisi": safeValue(data.data.alerji_bilgisi),
                "Ameliyat Geçmişi": safeValue(data.data.ameliyat_gecmisi),
                
              }}
              social={[
              ]}
              action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
            />
          </Grid>
    )
}

export default index
