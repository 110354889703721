import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import store from "store";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";

import breakpoints from "assets/theme/base/breakpoints";

import TalepForm from "layouts/planlama/gorev-talep/form";
import TalepList from "layouts/planlama/gorev-talep/list";


import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarCheck, faCalendarMinus, faChartLine, faCirclePlus, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faSpellCheck, faSquarePlus, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'



import { useState , useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';




function Overview() {
  const [tabIndex, setTabIndex] = useState(0);


  const { id } = useParams();
  const navigate = useNavigate();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

    const handleSetTabValue = (newValue) => {
      setTabValue(newValue);
      
    };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox position="absoloute" margin-top="200px">
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "absoloute",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          marginTop: 2
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                <Tab value= {0} label="Güncel Liste" icon={<FontAwesomeIcon icon={faListAlt} />} />
                <Tab value= {1} label="Yeni Talep" icon={<FontAwesomeIcon icon={faSquarePlus} />} />
                
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>

          {tabValue === 0 && (
            <>
              <TalepList />
              
              
            </>
          )}
          {tabValue === 1 && (
            <>
              <TalepForm  />
              
            </>
          )}
     
          

        </Grid>
      </SoftBox>
      

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
