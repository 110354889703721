import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";

// Settings page components
import FormField from "layouts/planlama/gorev-organizasyon/components/FormField";

// Data
import selectData from "layouts/planlama/gorev-organizasyon/components/BasicInfo/data/selectData";

import { v4 as uuidv4 } from 'uuid';

function BasicInfo() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (id === ":id") {
    window.location.href = '/gorevlendirme/gorev-organizasyon/yeni-gorevlendirme';
  }

  const [data, setData] = useState([]);
  const [avans2FA, setAvans2FA] = useState(false);

  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/list/user?format=json`, { withCredentials: true });
        setDataUser(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    id: id,
    aciklama: '',
    calisma: '',
    organizator: '',
    akreditasyon: '',
    etkinlik_bilgi_baglantisi: '',
    konu: '',
    yer: '',
    tarih: '',
    saat: '',
    bitis_tarih: '',
    bitis_saat: '',
    kanal_cikis_saati: '',
    iletisim_numarasi: '',
    muhabir: [],
    avans_personel: null,
    yolculuk: '',
    yolculuk_not: '',
    ekipman: '',
    notlar: '',
    tur: '',
    is_avansi: 0,
    is_avansi_doviz: 'tl',
    konaklama: false,
    yol: false,
    harcirah: false,
    yemek: false,
    tum_masraf: false,
    katilim_ucreti: '',
    fazla_bagaj: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/organizasyon/${id}?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const convertDate = (date) => {
      if (!date) return '';
      const dateParts = date.split('/');
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts;
        return `${year}-${month}-${day}`;
      }
      return date;
    };

    if (data.length > 0) {
      const fields = ["id", "aciklama", "calisma", "organizator", "akreditasyon", "etkinlik_bilgi_baglantisi", "konu", "yer", "tarih", "saat", "bitis_tarih", "bitis_saat", "kanal_cikis_saati", "iletisim_numarasi", "muhabir", "yolculuk_not", "notlar", "tur", "is_avansi", "is_avansi_doviz", "avans_personel", "konaklama", "yol", "harcirah", "yemek", "tum_masraf", "ekipman", "katilim_ucreti", "fazla_bagaj"];
      const newFormData = {};
      fields.forEach(field => {
        if (field === "tarih" || field === "bitis_tarih") {
          newFormData[field] = convertDate(data[0][field]) || '';
        } else {
          newFormData[field] = data[0][field] || '';
        }
      });
      setFormData(newFormData);

      if (data[0].muhabir) {
        const formattedMuhabir = formatFilteredOptions(dataUser, data[0].muhabir);
        console.log(formattedMuhabir)
        setSelectedOptions(formattedMuhabir);
      }

      if (data[0].yolculuk) {
        const parsedYolculuklar = JSON.parse(data[0].yolculuk);
        if (Array.isArray(parsedYolculuklar)) {
          setYolculuklar(parsedYolculuklar);
        } else {
          console.error("Invalid format for yolculuklar: ", parsedYolculuklar);
        }
      }
    }
  }, [data, dataUser]);



  const formatOptions = () => {
    return dataUser.map((item) => ({
      value: item.id,
      label: item.name + " " + item.surname + " (" + item.trt_arabi_unvan + ")",
    }));
  };

  const formatFilteredOptions = (dataUser, filterList) => {
    if (!filterList || filterList.length === 0) {
      return [];
    }
    return dataUser
      .filter(item => filterList.includes(item.id))
      .map(item => ({
        value: item.id,
        label: `${item.name} ${item.surname} (${item.trt_arabi_unvan})`
      }));
  };

  const handleSelectChange = (selected, action) => {
    setFormData({
      ...formData,
      [action.name]: selected.value
    });
  };

  const checkData = (name) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }

    const firstItem = data[0];
    if (!firstItem.hasOwnProperty('talep')) {
      return null;
    }


    const value = firstItem[name];
    if (value != "" || value != null) {
      const talepValue = firstItem.talep[name];
      if (talepValue === "" || talepValue === null) {
        return null;
      }
      return talepValue;
    }
    return value;
  };

  const filteredOptions = formatFilteredOptions(dataUser, checkData("muhabir"));

  const [selectedOptions, setSelectedOptions] = useState(filteredOptions);



  const handleMuhabirSelectChange = (selected) => {
    setSelectedOptions(selected);
    setFormData({
      ...formData,
      "muhabir": selected
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date, name) => {
    const formattedDate = formatDate(date);
    setFormData({
      ...formData,
      [name]: formattedDate
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const findPersonel = (id) => {

    let muhabir_name = ""
    dataUser.forEach(muhabir => {
      if (String(muhabir.id) === String(id)) {
        muhabir_name = muhabir.name + " " + muhabir.surname
      }

    })
    return `${muhabir_name}`;
  };


  //const [yolculuklar, setYolculuklar] = useState([{ id: 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' }]);
  const [yolculuklar, setYolculuklar] = useState([]);

  const handleYolculukFieldChange = (id, fieldName, value) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [fieldName]: value } : yolculuk
      )
    );
  };

  const handleYolculukSelectChange = (id, fieldName) => (selectedOption) => {
    handleYolculukFieldChange(id, fieldName, selectedOption.value);
  };

  const handleYolculukInputChange = (id) => (e) => {
    const { name, value } = e.target;
    handleYolculukFieldChange(id, name, value);
  };

  const handleBooleanChange = (id, field) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [field]: !yolculuk[field] } : yolculuk
      )
    );
  };

  const handleBooleanMasrafChange = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name]
    });
  };

  const handleYolculukSil = (id) => {
    setYolculuklar(prevState => prevState.filter(yolculuk => yolculuk.id !== id));
  };

  const handleYolculukEkle = () => {
    const newYolculuk = { id: yolculuklar.length + 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' };
    setYolculuklar([...yolculuklar, newYolculuk]);
  };

  const handleSubmit = async () => {
    const finalFormData = {
      ...formData,
      yolculuk: JSON.stringify(yolculuklar?.map(yolculuk => ({
        id: yolculuk.id,
        nereden: yolculuk.nereden,
        nereye: yolculuk.nereye,
        yolculuk_turu: yolculuk.yolculuk_turu,
        gidisDonus: yolculuk.gidisDonus,
      })))
    };

    try {
      const response = await axios.post(`${window.BASE_URL}/gorevlendirme/save`, finalFormData, { withCredentials: true });
      if (response.status === 200) {
        navigate(`/gorevlendirme/gorev-bilgi/${id}`);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };



  return (
    <Grid>
      {/* Profile Card */}
      <Card id="profile" style={{ marginTop: "-24px" }}>
        <SoftBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src="-"
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
                icon="assignment"
                bgColor={data[0]?.talep ? "info" : "light"}
              />
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {data[0]?.talep ? formData.konu : "Talep Yok"}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {data[0]?.talep?.tur ? data[0]?.talep.tur + " Görevlendirme Formu" : (data[0]?.tur ? data[0]?.tur + " Görevlendirme Formu" : "Yeni Oluşturulan Form")}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      {/* Görev Bilgileri Card */}
      <Card id="gorev-bilgi" sx={{ overflow: "visible" }} style={{ marginTop: "24px" }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">Görev Bilgileri</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField name="konu" label="Görevin Konusu" placeholder="Görevin Konusu" value={formData.konu} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Görev Kategori
                  </SoftTypography>
                </SoftBox>
                {data[0]?.talep?.tur ? (
                  <SoftInput value={data[0].talep.tur} disabled />
                ) : (data[0]?.tur ?
                  <SoftInput value={data[0].tur} disabled /> : <SoftSelect placeholder="Kategori Seçin" options={selectData.gorevtur} />

                )}
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <FormField name="aciklama" label="Görevin Açıklaması" placeholder="Görevin Açıklaması" value={formData.aciklama} onChange={handleInputChange} multiline rows={3} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Görev Başlangıç Tarihi
                  </SoftTypography>
                </SoftBox>
                <SoftDatePicker name="tarih" placeholder="Başlangıç Tarihi" value={formData.tarih} onChange={(date) => handleDateChange(date, 'tarih')} />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                    Görev Bitiş Tarihi
                  </SoftTypography>
                </SoftBox>
                <SoftDatePicker name="bitis_tarih" placeholder="Bitiş Tarihi" value={formData.bitis_tarih} onChange={(date) => handleDateChange(date, 'bitis_tarih')} />
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talep Edilen Kişiler
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                    &nbsp;&nbsp;(opsiyonel)
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  value={selectedOptions}
                  options={formatOptions()}
                  placeholder="Kişi ekle..."
                  onChange={handleMuhabirSelectChange}
                  isMulti
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <FormField name="organizator" label="Organizatör" placeholder="Organizatör" value={formData.organizator} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12}>
              <FormField name="akreditasyon" label="Akreditasyon" placeholder="Akreditasyon" value={formData.akreditasyon} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12}>
              <FormField name="etkinlik_bilgi_baglantisi" label="Etkinlik Bilgi Bağlantısı" placeholder="Etkinlik Bilgi Bağlantısı" value={formData.etkinlik_bilgi_baglantisi} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12}>
              <FormField name="iletisim_numarasi" label="İletişim Numarası" placeholder="İletişim Numarası" value={formData.iletisim_numarasi} onChange={handleInputChange} />
            </Grid>
            <Grid item xs={12}>
              <FormField label="Katılım Gerekçesi" placeholder="Katılım Gerekçesi" />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      {/* Ulaşım Card */}
      <Card id="ulasim" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Ulaşım</SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          {yolculuklar?.map((yolculuk) => (
            <div key={yolculuk.id}>
              <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                <SoftBox display="flex" alignItems="center">
                  <SoftAvatar src="-" alt="logo" variant="rounded" icon="edit_road" bgColor="info" />
                  <SoftBox ml={2}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      Yolculuk Ekle
                    </SoftTypography>
                    <SoftBox display="flex" alignItems="flex-end">
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        Yol Detayları
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
                  <SoftBox lineHeight={0} mx={1}>
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      Gidiş Dönüş
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mr={4}>
                    <Switch checked={yolculuk.gidisDonus} onChange={() => handleBooleanChange(yolculuk.id, 'gidisDonus')} />
                  </SoftBox>
                  <SoftButton variant="gradient" color="error" size="small" onClick={() => handleYolculukSil(yolculuk.id)}>
                    SİL
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Yolculuk rotasını giriniz
                </SoftTypography>
                <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                  <SoftTypography variant="button" fontWeight="medium" color="text">
                    Nereden
                  </SoftTypography>
                  <SoftBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                    <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0} />
                    <SoftSelect
                      variant="gradient"
                      size="small"
                      name="nereden"
                      value={selectData.country.find(option => option.value === yolculuk.nereden)}
                      options={selectData.country}
                      onChange={handleYolculukSelectChange(yolculuk.id, 'nereden')}
                    />
                  </SoftBox>
                </SoftBox>
                <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                  <SoftTypography variant="button" fontWeight="medium" color="text">
                    Nereye
                  </SoftTypography>
                  <SoftBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                    <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0} />
                    <SoftSelect
                      variant="gradient"
                      color="error"
                      size="small"
                      name="nereye"
                      value={selectData.country.find(option => option.value === yolculuk.nereye)}
                      options={selectData.country}
                      onChange={handleYolculukSelectChange(yolculuk.id, 'nereye')}
                    />
                  </SoftBox>
                </SoftBox>
                <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                  <SoftTypography variant="button" fontWeight="medium" color="text">
                    Yolculuk Türü
                  </SoftTypography>
                  <SoftBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                    <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0} />
                    <SoftSelect
                      variant="gradient"
                      color="error"
                      size="small"
                      name="yolculuk_turu"
                      value={selectData.yolculuk_tur.find(option => option.value === yolculuk.yolculuk_turu)}
                      options={selectData.yolculuk_tur}
                      onChange={handleYolculukSelectChange(yolculuk.id, 'yolculuk_turu')}
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <Divider />
            </div>
          ))}


          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="add_road" bgColor={avans2FA ? "info" : "secondary"} />
              <SoftBox ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Yolculuk Ekle
                </SoftTypography>
                <SoftBox display="flex" alignItems="flex-end">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Yol Detayları
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftButton variant="gradient" color="secondary" sx={{ height: "100%" }} onClick={handleYolculukEkle}>
                Ekle
              </SoftButton>
            </SoftBox>
          </SoftBox>

          <Grid item xs={12} sm={12} pt={3}>
            <FormField name="yolculuk_not" label="Yolculuk Not" placeholder="Yolculuk Notlar" value={formData.yolculuk_not} onChange={handleInputChange} multiline rows={5} />
          </Grid>
        </SoftBox>
      </Card>

      {/* Finans Card */}
      <Card id="finans" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Finans</SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="account_balance" bgColor={formData.is_avansi ? "info" : "secondary"} />
              <SoftBox ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  İş Avansı
                </SoftTypography>
                <SoftBox display="flex" alignItems="flex-end">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Ödeme Detayları
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.is_avansi ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              {/* <SoftBox mr={1}>
                <Switch checked={formData.is_avansi} onChange={() => handleBooleanChange('is_avansi')} />
              </SoftBox> */}
            </SoftBox>
          </SoftBox>

          <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
            <SoftTypography variant="button" color="text" fontWeight="regular">
              İş Avansı (İş avansı oluru örneği eklenecektir.)
            </SoftTypography>
            <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Avans Ücreti
              </SoftTypography>
              <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>

                <SoftInput size="small" name="is_avansi" value={formData.is_avansi} onChange={handleInputChange} />

              </SoftBox>
            </SoftBox>
            <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Para Birimi
              </SoftTypography>
              <SoftBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0} />
                <SoftSelect variant="gradient" color="error" size="small" name="is_avansi_doviz" value={{ value: formData.is_avansi_doviz, label: formData.is_avansi_doviz }} options={selectData.currency} onChange={handleSelectChange} />
              </SoftBox>
            </SoftBox>

            <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Ödeme Yapılacak Personel
              </SoftTypography>
              <SoftBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0} />
                <SoftSelect variant="gradient" color="error" size="small" name="avans_personel" value={{ value: findPersonel(formData.avans_personel), label: findPersonel(formData.avans_personel) }} options={selectedOptions} onChange={handleSelectChange} />
              </SoftBox>
            </SoftBox>

          </SoftBox>

          <Divider />
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="bed" bgColor={formData.konaklama ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Konaklama
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.konaklama ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="konaklama" checked={formData.konaklama} onChange={() => handleBooleanMasrafChange('konaklama')} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" variant="rounded" icon="directions_car" bgColor={formData.yol ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Yol
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.yol ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="yol" checked={formData.yol} onChange={() => handleBooleanMasrafChange('yol')} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="account_balance_wallet" bgColor={formData.harcirah ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Harcırah
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.harcirah ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="harcirah" checked={formData.harcirah} onChange={() => handleBooleanMasrafChange('harcirah')} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="local_dining" bgColor={formData.yemek ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Yemek
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.yemek ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="yemek" checked={formData.yemek} onChange={() => handleBooleanMasrafChange('yemek')} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="paid" bgColor={formData.tum_masraf ? "info" : "light"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Tüm Masraflar
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Davet Giderleri
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.tum_masraf ? "Dahil" : "Hariç"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="tum_masraf" checked={formData.tum_masraf} onChange={() => handleBooleanMasrafChange('tum_masraf')} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>

      {/* Ek Bilgiler Card */}
      <Card id="ek-bilgi" sx={{ overflow: "visible", marginTop: "24px" }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">Ek Bilgiler</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField name="katilim_ucreti" label="Katılım Ücreti" placeholder="Katılım Ücreti" value={formData.katilim_ucreti} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize">
                        Fazla Bagaj
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect name="fazla_bagaj" placeholder="Bagaj Durumu" value={{ value: formData.fazla_bagaj == true || formData.fazla_bagaj == "Var" ? "Var" : "Yok", label: formData.fazla_bagaj == true || formData.fazla_bagaj == "Var" ? "Var" : "Yok" }} options={selectData.have} onChange={handleSelectChange} />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField name="ekipman" label="Ekipman" placeholder="Ekipman Bilgileri" value={formData.ekipman} onChange={handleInputChange} multiline rows={5} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField name="notlar" label="Not" placeholder="Notlar" value={formData.notlar} onChange={handleInputChange} multiline rows={5} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      {/* Değişiklikleri Kaydet Card */}
      <Card id="kaydet" style={{ marginTop: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Değişiklikleri Kaydet</SoftTypography>
          </SoftBox>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Bu sekmede yaptığınız değişiklikleri ve düzenlemeleri kaydedebilirsiniz.
          </SoftTypography>
        </SoftBox>
        <SoftBox pb={3} px={3} display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
            <Switch />
            <SoftBox ml={2} lineHeight={0}>
              <SoftTypography display="block" variant="button" fontWeight="medium">
                Onayla
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                Yapılan değişiklikler adınıza kaydedilecektir.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <SoftButton variant="outlined" color="secondary">
              Kaydetme
            </SoftButton>
            <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={handleSubmit}>
                Kaydet
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  );
}

export default BasicInfo;
