/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */

import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";

// Data
//import data from "layouts/dashboard/components/Projects/data";
function Author({ id, image, name, email, tel }) {
  return (
    <a href={`/profile/${id}`}>
      {/* <SoftBadge variant="gradient" badgeContent={ */}

        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
           <SoftBox mr={2}>
            <SoftAvatar src={image} size="sm" variant="rounded" />
          </SoftBox> 
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="dark">
              {email}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {tel}
            </SoftTypography>
          </SoftBox>
        </SoftBox>

      {/* } color="light" size="xs" container /> */}


    </a>
  );
}


const truncatedTextStyle = {
  width: '300px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  height: '20px',
  WebkitBoxOrient: 'vertical'
};

function Projects() {
  
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/userlistmini?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
        
      }
    };

    fetchData();
  }, []);


  let egitimRows = data.map(index => {
    return {
      "Adı": [
        <img key="logoNewspaper" src="" alt="Logo" />,
        <span key="task1" style={truncatedTextStyle}> {index.name + " " + index.surname} </span>
      ],
      "Adı": <Author id={index.id} name={index.name + " " + index.surname}/>,
      "Pozisyon": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.trt_arabi_unvan}</SoftTypography>,
      "Kanal": <SoftBadge variant="gradient" badgeContent={index.kanal.kanal_adi} color="dark" size="xs"> </SoftBadge>,
      "field_name": index.name + " " + index.surname,
      "field_kanal": index.kanal.kanal_adi,
    


    };
  });

  const projectsTableData = {
    columns: [
      { name: "Adı", align: "left",  exportableField: `field_name`, searchable: true, searchField: [`field_name`], },
      { name: "Pozisyon", align: "center" },

      //{ name: "Durum", align: "left" },

    ],

    rows: [
      ...egitimRows,

    ],
  };

  const userKanal = useSelector(state => state.auth.userKanal);
  if (userKanal == "Global") {
    projectsTableData.columns.splice(1, 0, { name: "Kanal", align: "center", exportableField: `field_kanal`, searchable: true, searchField: [`field_kanal`],});
  }

  
  const { columns, rows } = projectsTableData;

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftBox>
          <SoftTypography variant="h6" gutterBottom>
            Çalışan Listesi
          </SoftTypography>
          {/* <SoftBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>30 done</strong> this month
            </SoftTypography>
          </SoftBox> */}
        </SoftBox>
        {/* <SoftBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </SoftBox> */}
        {renderMenu}
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
      </SoftBox>
    </Card>
  );
}

export default Projects;
