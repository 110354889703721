import { useMemo } from "react";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Modal from '@mui/material/Modal';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import DefaultItem from "examples/Items/DefaultItem";
import SoftButton from "components/SoftButton";

/* eslint-disable react/prop-types */

function calculateDateDifference(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const timeDifference = Math.abs(endDate - startDate);
  const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference;
}

function filterAndSortEventsByDate(events, start, end, search) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  endDate.setDate(endDate.getDate() - 1);

  const filtered = events.filter(event => {
    const eventDate = new Date(event.start);
    return (
      eventDate >= startDate &&
      eventDate <= endDate &&
      (event.title.toLowerCase().includes(search) || event.role.toLowerCase().includes(search))
    );
  });

  return filtered.sort((a, b) => new Date(a.start) - new Date(b.start));
}

function groupEventsByDateAndUnit(events) {
  return events.reduce((acc, event) => {
    const date = event.start;
    const unit = event.unit;

    if (!acc[date]) {
      acc[date] = {};
    }

    if (!acc[date][unit]) {
      acc[date][unit] = [];
    }

    acc[date][unit].push(event);
    return acc;
  }, {});
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function NextEvents({ dateStart, dateEnd, data }) {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dateDifference = calculateDateDifference(dateStart, dateEnd);

  const handleSearchChange = (event) => setSearch(event.target.value.toLowerCase());

  useEffect(() => {
    const sortedAndFiltered = filterAndSortEventsByDate(data, dateStart, dateEnd, search);
    setFilteredEvents(sortedAndFiltered);
  }, [dateStart, dateEnd, data, search]);

  const groupedEvents = groupEventsByDateAndUnit(filteredEvents);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <SoftBox pt={2} px={2} style={{ display: "flex", justifyContent: "space-between" }}>
          <SoftTypography variant="h6" fontWeight="medium">
            Seçili Günler ({dateDifference} Gün)
          </SoftTypography>
          <a onClick={handleOpenModal}>
            <Icon>
              open_in_full
            </Icon>
          </a>
        </SoftBox>

        <SoftBox style={{ marginRight: "15px", marginTop: "10px" }}>
          <SoftInput
            placeholder="Yazınız..."
            icon={{ component: "search", direction: "left" }}
            value={search}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1, ml: 1 }}
            style={{ paddingInline: "15px" }}
          />
        </SoftBox>
        <SoftBox p={2}>
          {Object.keys(groupedEvents).length > 0 ? (
            Object.keys(groupedEvents).map(date => {
              const eventDate = new Date(date);
              const formattedDate = eventDate.toLocaleDateString('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit' });
              const dayName = eventDate.toLocaleDateString('tr-TR', { weekday: 'long' });

              return (
                <div key={date}>
                  <SoftTypography variant="button" fontWeight="medium" color="text">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                      label_important
                    </Icon>{dayName} {formattedDate}
                  </SoftTypography>

                  {Object.keys(groupedEvents[date]).map(unit => (
                    <div key={unit}>
                      <SoftTypography variant="button" fontWeight="medium" color="text" ml={2}>
                        {capitalizeFirstLetter(unit)}
                      </SoftTypography>
                      {groupedEvents[date][unit].map(event => (
                        <SoftBox mt={3.5} key={event.start + event.title}>
                          <DefaultItem
                            color={event.className}
                            icon={event.className === "info" ? "work" : "do_not_disturb"}
                            title={event.title}
                            dateTime={event.role}
                            description={`${event.hours}`}
                          />
                        </SoftBox>
                      ))}
                      <Divider />
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <SoftBox mt={3.5}>
              <DefaultItem
                color="warning"
                icon="warning"
                title="Çalışan Bulunamadı"
                dateTime=""
                description="Seçili tarihlerde herhangi bir çalışan bulunamadı."
              />
            </SoftBox>
          )}
        </SoftBox>
      </Card>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <SoftBox sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          borderRadius: '5px',
          maxHeight: '90vh', // Maksimum yüksekliği belirleyin
          maxWidth: '150vw',  // Maksimum genişliği belirleyin
          overflowY: 'auto', // Y ekseninde kaydırmayı etkinleştirin
        }}>
          <SoftBox pt={2} px={2} style={{ display: "flex", justifyContent: "space-between" }}>
            <SoftTypography variant="h6" fontWeight="medium">
              Seçili Günler ({dateDifference} Gün)
            </SoftTypography>
            <a onClick={handleCloseModal}>
              <Icon>
                close
              </Icon>
            </a>
          </SoftBox>

          <SoftBox style={{ marginRight: "15px", marginTop: "10px" }}>
            <SoftInput
              placeholder="Yazınız..."
              icon={{ component: "search", direction: "left" }}
              value={search}
              onChange={handleSearchChange}
              sx={{ flexGrow: 1, ml: 1 }}
              style={{ paddingInline: "15px" }}
            />
          </SoftBox>
          <SoftBox p={2}>
            {Object.keys(groupedEvents).length > 0 ? (
              Object.keys(groupedEvents).map(date => {
                const eventDate = new Date(date);
                const formattedDate = eventDate.toLocaleDateString('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit' });
                const dayName = eventDate.toLocaleDateString('tr-TR', { weekday: 'long' });

                return (
                  <div key={date}>
                    <SoftTypography variant="button" fontWeight="medium" color="text">
                      <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                      </Icon>{dayName} {formattedDate}
                    </SoftTypography>

                    {Object.keys(groupedEvents[date]).map(unit => (
                      <div key={unit}>
                        <SoftTypography variant="button" fontWeight="medium" color="text" ml={2}>
                          {capitalizeFirstLetter(unit)}
                        </SoftTypography>
                        {groupedEvents[date][unit].map(event => (
                          <SoftBox mt={3.5} key={event.start + event.title}>
                            <DefaultItem
                              color={event.className}
                              icon={event.className === "info" ? "work" : "do_not_disturb"}
                              title={event.title}
                              dateTime={event.role}
                              description={`${event.hours}`}
                            />
                          </SoftBox>
                        ))}
                        <Divider />
                      </div>
                    ))}
                  </div>
                );
              })
            ) : (
              <SoftBox mt={3.5}>
                <DefaultItem
                  color="warning"
                  icon="warning"
                  title="Çalışan Bulunamadı"
                  dateTime=""
                  description="Seçili tarihlerde herhangi bir çalışan bulunamadı."
                />
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
}

export default NextEvents;
