// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
/* eslint-disable react/prop-types */

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import trLocale from '@fullcalendar/core/locales/tr';
import React, { useRef, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

function Calendar({ header, columns, handleFilterColumnChange, filterColumn, ...rest }) {
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
      ...el,
      className: validClassNames.find((item) => item === el.className)
        ? `event-${el.className}`
        : "event-info",
    }))
    : [];

  //const columns = [{ name: "test" }];



  const calendarRef = useRef(null);
  
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={2} px={2} lineHeight={1}>
        {header.title ? (
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </SoftTypography>
        ) : null}
        {header.date ? (
          <SoftTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </SoftTypography>
        ) : null}
      </SoftBox>
      <SoftBox mt={5} mb={3} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginRight: "15px" }}>
        <Select
          value={filterColumn}
          onChange={handleFilterColumnChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ ml: 2, minWidth: 120, display: 'inline-flex' }}
        >
          <MenuItem value="" disabled>
            <SoftBox sx={{ display: 'flex', alignItems: 'center' }} color="secondary">
              <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: 1 }} fontSize="small" color="secondary">filter_alt</Icon>
              <em>Başlık Seçin</em>
            </SoftBox>
          </MenuItem>
          {columns.map((column, index) => (
            <MenuItem key={index} value={column.name}>{column.name}</MenuItem>
          ))}
        </Select>
      </SoftBox>
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          ref={calendarRef}
          height="100%"
          locale={trLocale}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
  columns: [],
  filterColumn: "",
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
