/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
  gender: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  have: [
    { value: "Yok", label: "Yok" },
    { value: "Var", label: "Var" },
  ],
  country: [
    { value: "Türkiye", label: "Türkiye" },
    { value: "Suriye", label: "Suriye" },
    { value: "Karadağ", label: "Karadağ" },
    { value: "Fransa", label: "Fransa" },
    { value: "Zambiya", label: "Zambiya" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Wallis ve Futuna Adaları Bölgesi", label: "Wallis ve Futuna Adaları Bölgesi" },
    { value: "İzlanda", label: "İzlanda" },
    { value: "Lüksemburg", label: "Lüksemburg" },
    { value: "Mali", label: "Mali" },
    { value: "Komorlar", label: "Komorlar" },
    { value: "Avustralya", label: "Avustralya" },
    { value: "Estonya", label: "Estonya" },
    { value: "Kanada", label: "Kanada" },
    { value: "Belarus", label: "Belarus" },
    { value: "Guyana", label: "Guyana" },
    { value: "Gambiya", label: "Gambiya" },
    { value: "Tunus", label: "Tunus" },
    { value: "Kamerun", label: "Kamerun" },
    { value: "Ruanda", label: "Ruanda" },
    { value: "Kamboçya", label: "Kamboçya" },
    { value: "Benin", label: "Benin" },
    { value: "Yunanistan", label: "Yunanistan" },
    { value: "Güney Kore", label: "Güney Kore" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "ABD Virjin Adaları", label: "ABD Virjin Adaları" },
    { value: "Åland", label: "Åland" },
    { value: "San Marino", label: "San Marino" },
    { value: "Maldivler", label: "Maldivler" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Malavi", label: "Malavi" },
    { value: "Mısır", label: "Mısır" },
    { value: "Senegal", label: "Senegal" },
    { value: "Gürcistan", label: "Gürcistan" },
    { value: "Yeni Zelanda", label: "Yeni Zelanda" },
    { value: "Yeşil Burun", label: "Yeşil Burun" },
    { value: "İtalya", label: "İtalya" },
    { value: "Monako", label: "Monako" },
    { value: "Slovakya", label: "Slovakya" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Laos", label: "Laos" },
    { value: "Faroe Adaları", label: "Faroe Adaları" },
    { value: "Niue", label: "Niue" },
    { value: "Kuzey Makedonya", label: "Kuzey Makedonya" },
    { value: "Şili", label: "Şili" },
    { value: "Kıbrıs", label: "Kıbrıs" },
    { value: "Makao", label: "Makao" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Ürdün", label: "Ürdün" },
    { value: "Jamaika", label: "Jamaika" },
    { value: "Barbados", label: "Barbados" },
    { value: "Sahra Demokratik Arap Cumhuriyeti", label: "Sahra Demokratik Arap Cumhuriyeti" },
    { value: "Katar", label: "Katar" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Mikronezya", label: "Mikronezya" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Papua Yeni Gine", label: "Papua Yeni Gine" },
    { value: "Brezilya", label: "Brezilya" },
    { value: "Litvanya", label: "Litvanya" },
    { value: "Fransız Guyanası", label: "Fransız Guyanası" },
    { value: "Moldova", label: "Moldova" },
    { value: "Kırgızistan", label: "Kırgızistan" },
    { value: "Curaçao", label: "Curaçao" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Angola", label: "Angola" },
    { value: "Malezya", label: "Malezya" },
    { value: "İsviçre", label: "İsviçre" },
    { value: "Tayland", label: "Tayland" },
    { value: "Dominik Cumhuriyeti", label: "Dominik Cumhuriyeti" },
    { value: "Özbekistan", label: "Özbekistan" },
    { value: "Çad", label: "Çad" },
    { value: "Cocos (Keeling) Adaları", label: "Cocos (Keeling) Adaları" },
    { value: "Gine", label: "Gine" },
    { value: "Turks ve Caicos Adaları", label: "Turks ve Caicos Adaları" },
    { value: "Porto Riko", label: "Porto Riko" },
    { value: "Butan", label: "Butan" },
    { value: "Cayman Adaları", label: "Cayman Adaları" },
    { value: "Marshall Adaları", label: "Marshall Adaları" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Moritanya", label: "Moritanya" },
    { value: "Norveç", label: "Norveç" },
    { value: "Martinik", label: "Martinik" },
    { value: "İsrail", label: "İsrail" },
    { value: "Saint Barthélemy", label: "Saint Barthélemy" },
    { value: "Ekvador", label: "Ekvador" },
    { value: "Grenada", label: "Grenada" },
    { value: "Hırvatistan", label: "Hırvatistan" },
    { value: "Brunei", label: "Brunei" },
    { value: "Irak", label: "Irak" },
    { value: "Japonya", label: "Japonya" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Heard Adası ve McDonald Adaları", label: "Heard Adası ve McDonald Adaları" },
    { value: "Finlandiya", label: "Finlandiya" },
    { value: "Güney Sudan", label: "Güney Sudan" },
    { value: "Amerika Birleşik Devletleri Küçük Dış Adaları", label: "Amerika Birleşik Devletleri Küçük Dış Adaları" },
    { value: "Saint Helena", label: "Saint Helena" },
    { value: "Afganistan", label: "Afganistan" },
    { value: "Solomon Adaları", label: "Solomon Adaları" },
    { value: "Çin", label: "Çin" },
    { value: "Eritre", label: "Eritre" },
    { value: "Rusya", label: "Rusya" },
    { value: "Andorra", label: "Andorra" },
    { value: "Ermenistan", label: "Ermenistan" },
    { value: "Avusturya", label: "Avusturya" },
    { value: "Surinam", label: "Surinam" },
    { value: "İspanya", label: "İspanya" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Bahamalar", label: "Bahamalar" },
    { value: "Sint Maarten", label: "Sint Maarten" },
    { value: "Belize", label: "Belize" },
    { value: "Jersey", label: "Jersey" },
    { value: "İsveç", label: "İsveç" },
    { value: "Botsvana", label: "Botsvana" },
    { value: "Man Adası", label: "Man Adası" },
    { value: "Birleşik Arap Emirlikleri", label: "Birleşik Arap Emirlikleri" },
    { value: "İran", label: "İran" },
    { value: "Gabon", label: "Gabon" },
    { value: "Saint Kitts ve Nevis", label: "Saint Kitts ve Nevis" },
    { value: "Ekvator Ginesi", label: "Ekvator Ginesi" },
    { value: "São Tomé ve Príncipe", label: "São Tomé ve Príncipe" },
    { value: "Grönland", label: "Grönland" },
    { value: "Bangladeş", label: "Bangladeş" },
    { value: "Romanya", label: "Romanya" },
    { value: "Britanya Hint Okyanusu Toprakları", label: "Britanya Hint Okyanusu Toprakları" },
    { value: "Sudan", label: "Sudan" },
    { value: "Bosna-Hersek", label: "Bosna-Hersek" },
    { value: "Malta", label: "Malta" },
    { value: "Seyşeller", label: "Seyşeller" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Meksika", label: "Meksika" },
    { value: "Yemen", label: "Yemen" },
    { value: "Virjin Adaları", label: "Virjin Adaları" },
    { value: "Hollanda", label: "Hollanda" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Birleşik Krallık", label: "Birleşik Krallık" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Bouvet Adası", label: "Bouvet Adası" },
    { value: "Ukrayna", label: "Ukrayna" },
    { value: "Fas", label: "Fas" },
    { value: "Portekiz", label: "Portekiz" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Saint Vincent ve Grenadinler", label: "Saint Vincent ve Grenadinler" },
    { value: "Kuzey Kore", label: "Kuzey Kore" },
    { value: "Slovenya", label: "Slovenya" },
    { value: "Fildişi Sahili", label: "Fildişi Sahili" },
    { value: "Filistin", label: "Filistin" },
    { value: "Karayip Hollandası", label: "Karayip Hollandası" },
    { value: "Belçika", label: "Belçika" },
    { value: "Zimbabve", label: "Zimbabve" },
    { value: "Tanzanya", label: "Tanzanya" },
    { value: "Togo", label: "Togo" },
    { value: "Cook Adaları", label: "Cook Adaları" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Yeni Kaledonya", label: "Yeni Kaledonya" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Güney Georgia ve Güney Sandwich Adaları", label: "Güney Georgia ve Güney Sandwich Adaları" },
    { value: "Polonya", label: "Polonya" },
    { value: "Bağımsız Samoa Devleti", label: "Bağımsız Samoa Devleti" },
    { value: "Çekya", label: "Çekya" },
    { value: "Endonezya", label: "Endonezya" },
    { value: "Bolivya", label: "Bolivya" },
    { value: "Kolombiya", label: "Kolombiya" },
    { value: "Honduras", label: "Honduras" },
    { value: "Danimarka", label: "Danimarka" },
    { value: "Orta Afrika Cumhuriyeti", label: "Orta Afrika Cumhuriyeti" },
    { value: "Libya", label: "Libya" },
    { value: "Gine-Bissau", label: "Gine-Bissau" },
    { value: "Moğolistan", label: "Moğolistan" },
    { value: "Kongo Demokratik Cumhuriyeti", label: "Kongo Demokratik Cumhuriyeti" },
    { value: "Falkland (Malvina) Adaları", label: "Falkland (Malvina) Adaları" },
    { value: "Nauru", label: "Nauru" },
    { value: "Saint Pierre ve Miquelon", label: "Saint Pierre ve Miquelon" },
    { value: "Kosta Rika", label: "Kosta Rika" },
    { value: "Somali", label: "Somali" },
    { value: "İrlanda", label: "İrlanda" },
    { value: "Fransız Polinezyası", label: "Fransız Polinezyası" },
    { value: "Pitcairn Adaları", label: "Pitcairn Adaları" },
    { value: "Macaristan", label: "Macaristan" },
    { value: "Umman", label: "Umman" },
    { value: "Guam", label: "Guam" },
    { value: "Nepal", label: "Nepal" },
    { value: "Lihtenştayn", label: "Lihtenştayn" },
    { value: "Madagaskar", label: "Madagaskar" },
    { value: "Kazakistan", label: "Kazakistan" },
    { value: "Cibuti", label: "Cibuti" },
    { value: "Cebelitarık", label: "Cebelitarık" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Namibya", label: "Namibya" },
    { value: "Kongo Cumhuriyeti", label: "Kongo Cumhuriyeti" },
    { value: "Almanya", label: "Almanya" },
    { value: "Vatikan", label: "Vatikan" },
    { value: "Fransız Güney ve Antarktika Toprakları", label: "Fransız Güney ve Antarktika Toprakları" },
    { value: "Arnavutluk", label: "Arnavutluk" },
    { value: "Cezayir", label: "Cezayir" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Haiti", label: "Haiti" },
    { value: "Svalbard ve Jan Mayen", label: "Svalbard ve Jan Mayen" },
    { value: "Tonga", label: "Tonga" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Singapur", label: "Singapur" },
    { value: "Antigua ve Barbuda", label: "Antigua ve Barbuda" },
    { value: "Suudi Arabistan", label: "Suudi Arabistan" },
    { value: "Küba", label: "Küba" },
    { value: "Panama", label: "Panama" },
    { value: "Liberya", label: "Liberya" },
    { value: "Azerbaycan", label: "Azerbaycan" },
    { value: "Letonya", label: "Letonya" },
    { value: "Antarktika", label: "Antarktika" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Etiyopya", label: "Etiyopya" },
    { value: "Nijer", label: "Nijer" },
    { value: "Trinidad ve Tobago", label: "Trinidad ve Tobago" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Mozambik", label: "Mozambik" },
    { value: "Palau", label: "Palau" },
    { value: "Fiji", label: "Fiji" },
    { value: "Réunion", label: "Réunion" },
    { value: "Norfolk Adası", label: "Norfolk Adası" },
    { value: "Türkmenistan", label: "Türkmenistan" },
    { value: "Tacikistan", label: "Tacikistan" },
    { value: "Lübnan", label: "Lübnan" },
    { value: "Nijerya", label: "Nijerya" },
    { value: "Amerika Birleşik Devletleri", label: "Amerika Birleşik Devletleri" },
    { value: "Arjantin", label: "Arjantin" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Christmas Adası", label: "Christmas Adası" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Nikaragua", label: "Nikaragua" },
    { value: "Esvatini", label: "Esvatini" },
    { value: "Bahreyn", label: "Bahreyn" },
    { value: "Kenya", label: "Kenya" },
    { value: "Sırbistan", label: "Sırbistan" },
    { value: "Doğu Timor", label: "Doğu Timor" },
    { value: "Dominika", label: "Dominika" },
    { value: "Kuzey Mariana Adaları", label: "Kuzey Mariana Adaları" },
    { value: "Filipinler", label: "Filipinler" },
    { value: "Kosova", label: "Kosova" },
    { value: "Kuveyt", label: "Kuveyt" },
    { value: "Tayvan", label: "Tayvan" },
    { value: "Burundi", label: "Burundi" },
    { value: "Amerikan Samoası", label: "Amerikan Samoası" },
    { value: "Bulgaristan", label: "Bulgaristan" },
    { value: "Güney Afrika", label: "Güney Afrika" },
    { value: "Peru", label: "Peru" },
    { value: "Aruba", label: "Aruba" },
    { value: "Gana", label: "Gana" },
    { value: "Saint Martin", label: "Saint Martin" },
    { value: "Hindistan", label: "Hindistan" },
    { value: "Uganda", label: "Uganda" },
    { value: "Adana", label: "Adana" },
    { value: "Adıyaman", label: "Adıyaman" },
    { value: "Afyon", label: "Afyon" },
    { value: "Ağrı", label: "Ağrı" },
    { value: "Aksaray", label: "Aksaray" },
    { value: "Amasya", label: "Amasya" },
    { value: "Ankara", label: "Ankara" },
    { value: "Antalya", label: "Antalya" },
    { value: "Ardahan", label: "Ardahan" },
    { value: "Artvin", label: "Artvin" },
    { value: "Aydın", label: "Aydın" },
    { value: "Balıkesir", label: "Balıkesir" },
    { value: "Bartın", label: "Bartın" },
    { value: "Batman", label: "Batman" },
    { value: "Bayburt", label: "Bayburt" },
    { value: "Bilecik", label: "Bilecik" },
    { value: "Bingöl", label: "Bingöl" },
    { value: "Bitlis", label: "Bitlis" },
    { value: "Bolu", label: "Bolu" },
    { value: "Burdur", label: "Burdur" },
    { value: "Bursa", label: "Bursa" },
    { value: "Çanakkale", label: "Çanakkale" },
    { value: "Çankırı", label: "Çankırı" },
    { value: "Çorum", label: "Çorum" },
    { value: "Denizli", label: "Denizli" },
    { value: "Diyarbakır", label: "Diyarbakır" },
    { value: "Düzce", label: "Düzce" },
    { value: "Edirne", label: "Edirne" },
    { value: "Elazığ", label: "Elazığ" },
    { value: "Erzincan", label: "Erzincan" },
    { value: "Erzurum", label: "Erzurum" },
    { value: "Eskişehir", label: "Eskişehir" },
    { value: "Gaziantep", label: "Gaziantep" },
    { value: "Giresun", label: "Giresun" },
    { value: "Gümüşhane", label: "Gümüşhane" },
    { value: "Hakkari", label: "Hakkari" },
    { value: "Hatay", label: "Hatay" },
    { value: "Iğdır", label: "Iğdır" },
    { value: "Isparta", label: "Isparta" },
    { value: "İstanbul", label: "İstanbul" },
    { value: "İzmir", label: "İzmir" },
    { value: "Kahramanmaraş", label: "Kahramanmaraş" },
    { value: "Karabük", label: "Karabük" },
    { value: "Karaman", label: "Karaman" },
    { value: "Kars", label: "Kars" },
    { value: "Kastamonu", label: "Kastamonu" },
    { value: "Kayseri", label: "Kayseri" },
    { value: "Kilis", label: "Kilis" },
    { value: "Kırıkkale", label: "Kırıkkale" },
    { value: "Kırklareli", label: "Kırklareli" },
    { value: "Kırşehir", label: "Kırşehir" },
    { value: "Kocaeli", label: "Kocaeli" },
    { value: "Konya", label: "Konya" },
    { value: "Kütahya", label: "Kütahya" },
    { value: "Malatya", label: "Malatya" },
    { value: "Manisa", label: "Manisa" },
    { value: "Mardin", label: "Mardin" },
    { value: "Mersin", label: "Mersin" },
    { value: "Muğla", label: "Muğla" },
    { value: "Muş", label: "Muş" },
    { value: "Nevşehir", label: "Nevşehir" },
    { value: "Niğde", label: "Niğde" },
    { value: "Ordu", label: "Ordu" },
    { value: "Osmaniye", label: "Osmaniye" },
    { value: "Rize", label: "Rize" },
    { value: "Sakarya", label: "Sakarya" },
    { value: "Samsun", label: "Samsun" },
    { value: "Şanlıurfa", label: "Şanlıurfa" },
    { value: "Siirt", label: "Siirt" },
    { value: "Sinop", label: "Sinop" },
    { value: "Şırnak", label: "Şırnak" },
    { value: "Sivas", label: "Sivas" },
    { value: "Tekirdağ", label: "Tekirdağ" },
    { value: "Tokat", label: "Tokat" },
    { value: "Trabzon", label: "Trabzon" },
    { value: "Tunceli", label: "Tunceli" },
    { value: "Uşak", label: "Uşak" },
    { value: "Van", label: "Van" },
    { value: "Yalova", label: "Yalova" },
    { value: "Yozgat", label: "Yozgat" },
    { value: "Zonguldak", label: "Zonguldak" }
],


  gorevtur: [
    { value: "Yurtiçi", label: "Yurtiçi" },
    { value: "Yurtdışı", label: "Yurtdışı" },
    { value: "İstanbul", label: "İstanbul" },
    { value: "Sınır", label: "Sınır" },
  ],

  yolculuk_tur: [
    { value: "Uçak", label: "Uçak" },
    { value: "Araç / Karayolu", label: "Araç / Karayolu" },
    { value: "Otobüs", label: "Otobüs" },
    { value: "Tren", label: "Tren" },
    { value: "Yataklı Tren", label: "Yataklı Tren" },
    { value: "Yüksek Hızlı Tren", label: "Yüksek Hızlı Tren" },
    { value: "Kiralık Araç", label: "Kiralık Araç" },
    { value: "Kurum Aracı", label: "Kurum Aracı" },
    { value: "Özel Araç", label: "Özel Araç" },
    { value: "Vapur / Gemi", label: "Vapur / Gemi" }
  ],
  

  currency: [
    { value: "TL", label: "TL" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },

  ]

};

export default selectData;
