/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import { useState } from "react";

const Target = ({ id, image, name, email, tel, variant, index }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "300px" }}>
                    <SoftBox mr={2}>
                        <SoftAvatar src={image} size="sm" variant="rounded" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a>
    );
};

const Repeat = ({ name, email, tel, variant, index, toggleExpand, isExpanded, children }) => {
    return (
        <a onClick={toggleExpand}>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox mr={2}>
                        <Icon sx={{ fontWeight: "bold", fontSize: "16px !important", color: "error" }}>
                            repeat
                        </Icon>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
            {isExpanded && (
                <SoftBox mt={2}>
                    {children}
                </SoftBox>
            )}
        </a>
    );
};

const Translate = ({ id, image, name, email, tel, variant, index }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "300px" }}>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a>
    );
};

function createItems(title, text) {
    let items = [];

    // Öğe için TimelineItem oluştur
    if (title) {
        items.push(
            <SoftBox pt={3} px={3}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong> <br />
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                </SoftTypography>
            </SoftBox>
        );
    }

    return items;
}

const openVideo = (url) => {
    if (url) {
        window.open(url, '_blank');
    } else {
        console.error('Geçersiz URL');
    }
};

const Index = (data) => {
    const [expandedItemId, setExpandedItemId] = useState(null);

    const toggleExpand = (id) => {
        setExpandedItemId(expandedItemId === id ? null : id);
    };

    try {
        let terfiRows = data.data[0]?.bolumler.map(item => (
            <>
            
                <TimelineItem
                    key={item.id}
                    color={item.bolum_link ? "info" : "secondary"}
                    icon={"video_library"}
                    description={<>
                    <a key={item.id} onClick={() => openVideo(item.bolum_link)} style={{ cursor: item.bolum_link ? "pointer" : "auto" }}>
                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                            label_important
                        </Icon>

                        Bölüm Süresi: {item.sure}</a><br />{item.bolum_tekrar.length > 0 ? <>
                            <br /><Repeat name={item.bolum_tekrar.length + " Yayın Tekrarı"} email="" tel="" variant="gradient" toggleExpand={() => toggleExpand(item.id)} isExpanded={expandedItemId === item.id}>
                                {item.bolum_tekrar.map(item2 => (
                                    <TimelineItem
                                        key={item2.id}
                                        color={"dark"}
                                        icon={"subdirectory_arrow_right"}
                                        description={`Süre: ${item2.sure}`}
                                        title={"Tekrar"}
                                        dateTime={`${item2.yayin_tarihi}`}
                                    />
                                ))}
                            </Repeat>
                        </> : ""} </>}
                    title={`${item.bolum_no}. Bölüm`}
                    dateTime={item.yayin_tarihi}
                    onClick={() => openVideo(item.bolum_link)}
                />
            
            </>
        ));

        return (
            <>
                <Grid item xs={12} md={8} xl={8}>
                    <Card className="h-100">
                        <SoftBox py={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Program Bilgileri
                            </SoftTypography>

                            {createItems("Program Çevirileri", data.data[0].translations?.map((x, i) => (<>
                                <Translate key={i} name={x.language.code + "- " + x.translated_text} tel={x.language.name} variant="">
                                    ({x.language.name})
                                </Translate>
                            </>)))}
                            {createItems("Yayın Başlangıç Tarihi", data.data[0].yayin_baslangic_tarihi)}
                            {createItems("Tür", data.data[0].tur)}
                            {createItems("Yayın Sıklığı", data.data[0].yayin_sikligi)}
                            {createItems("Stratus Kodu", data.data[0].stratus_kodu)}
                            {createItems("Bağlı Olduğu Müdürlük", data.data[0].mudurluk.isim)}
                            {createItems("Prodüktör", data.data[0].produktor?.map((x, i) => (<>
                                <Target image={window.BASE_URL + "/" + x.personel_fotograf} key={i} name={(i + 1) + "- " + x.name + " " + x.surname} tel={x.trt_arabi_unvan} variant="">
                                    ({x.trt_arabi_unvan})
                                </Target>
                            </>)))}
                            {createItems("Açıklama", data.data[0].aciklama)}
                        </SoftBox>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                    <Card className="h-100">
                        <SoftBox pt={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Bölümler
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                    </SoftTypography>
                                    &nbsp;
                                    <SoftTypography variant="button" color="text" fontWeight="medium">
                                        {terfiRows.length}
                                    </SoftTypography>{" "}
                                    Bölüm Kaydı
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox p={2}>
                            {terfiRows}
                        </SoftBox>
                    </Card>
                </Grid>
            </>
        );
    } catch (error) {
        console.log(error)
        return (
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h2" fontWeight="medium">
                        Yetkilendirilmemiş Giriş
                    </SoftTypography>
                </SoftBox>
            </Grid>
        );
    }
};

export default Index;
