/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


// @mui material components
import { makeStyles } from '@mui/styles';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarCheck, faCalendarMinus, faChartLine, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faSpellCheck, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
//import curved0 from "assets/images/curved-images/curved-trt-servis-hd1.webp";

/* eslint-disable react/prop-types */
/* eslint-disable-next-line */

function Header(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const curved0 = props.data["image"];

  const color = props.data["color"];

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
  
    //const curved0 = ""

    

    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };
  //const classes = useStyles();
  return (
    <SoftBox position="absoloute" margin-top="200px">
      <SoftBox

        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        //minHeight="18.75rem"
        height="18rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients }, props }) =>
          `${linearGradient(
            rgba(
              color === "dark" ? gradients.dark.main : gradients.info.main,
              color === "dark" ? 0.8 : 0.7
            ),
            rgba(gradients.dark.state, color === "dark" ? 0.8 : 0.7)
          )}, url(${curved0})`,
        
        
          backgroundSize: "cover",
          backgroundPosition: "50%",
          filter: "contrast(1.1)"
        }}
      >
        <SoftBox mb={3} pt={1} my={1}>
          <SoftTypography variant="h3" color="white" fontWeight="bold" textAlign="Center">
            {props.data["title"]}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2} px={5} py={0}>
          <SoftTypography variant="body2" color="white" textAlign="Center" >
            {props.data["line1"]}
          </SoftTypography>
          <SoftTypography variant="body2" color="white" textAlign="Center" >
            {props.data["line2"]}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default Header;
