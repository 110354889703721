/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";



const Target = ({ id, image, name, email, tel, variant, index, color="light" }) => {
    return (
        <a >
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{color:"white !important"}} >
                    <SoftBox mr={2}>
                    <Icon sx={{ fontWeight: "bold",fontSize:"16px !important"}}>
                        payments
                    </Icon>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography  variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color={color} size="xl" container fullWidth />
        </a>
    );
};

const Total = ({ id, image, name, email, tel, variant, index, color="light" }) => {
    return (
        <a >
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{color:"white !important"}} >
                    <SoftBox mr={2}>
                    <Icon sx={{ fontWeight: "bold",fontSize:"16px !important", color: "white !important" }}>
                        payments
                    </Icon>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography sx={{color: "white !important"}} variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color={color} size="sm" container fullWitdh />
        </a>
    );
};


const Translate = ({ id, image, name, email, tel, variant, index }) => {
    return (
        <a >
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "300px" }} >

                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a>
    );
};

function createItems(title, text) {
    let items = [];

    // Öğe için TimelineItem oluştur
    if (title) {
        items.push(
            <SoftBox pt={3} px={3}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong>  <br></br>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                    {/* <br/> <SoftTypography variant="caption" color="secondary" fontWeight="medium"> Aylık Performans %{"s"} </SoftTypography> */}
                </SoftTypography>
            </SoftBox>
        );
    }


    return items;
}

const openVideo = (url) => {
    if (url) {
        window.open(url, '_blank');
    } else {
        console.error('Geçersiz URL');
    }
};


const index = (data) => {


    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    try {
        //const terfiRows1 = data.data.branchler.flatMap(branch => createTimelineItems(branch,data.data.trt_arabi_unvan));

        let terfiRows = data.data[0]?.odemeler.map(item => (

            <TimelineItem
                key={item.id}
                color={item.bolum_link ? "info" : "info"}
                icon={"sell"}
                //description={`TRY: ${item.doviz_try}`}
                description={ <>    {item.notu ? <>{item.notu} <br/><br/></> : ""} <Target name={item.doviz_try+" TRY"}/>    {item.odemeler_detay.map(item2 => (<TimelineItem
                    key={item2.id}
                    color={"dark"}
                    icon={"subdirectory_arrow_right"}
                    description={`TRY: ${item2.doviz_try}`}
                    title={capitalizeFirstLetter(item2.alt_baslik)}
                    dateTime={`Tutar: ${item2.tutar} ${item2.para_birimi}` }
                    
                />))} </>}
                title={capitalizeFirstLetter(item.kategori)}
                dateTime={`Tutar: ${item.tutar} ${item.para_birimi}` }
                onClick={() => openVideo(item.bolum_link)}
            />

        ));


        return (
            <>
                <Grid item xs={12} md={8} xl={8}>
                    <Card className="h-100" >
                        <SoftBox py={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Program Bütçesi
                            </SoftTypography>


                            {createItems("Kur Bilgileri",

                                <>
                                    <Translate key={"i"} name={"USD Kur Bilgisi"} tel={data.data[0].doviz_kuru_usd + " TRY"} variant="">
                                        ({data.data[0].doviz_kuru_usd + " TRY"})
                                    </Translate>

                                    <Translate key={"i"} name={"EUR Kur Bilgisi"} tel={data.data[0].doviz_kuru_eur + " TRY"} variant="">
                                        ({data.data[0].doviz_kuru_eur + " TRY"})
                                    </Translate>
                                </>


                            )}
                            {createItems("Toplam Bütçe", <SoftBadge variant="gradient" badgeContent={data.data[0].toplam_butce + " TRY"} color="info" size="xs" container />)}
                            {createItems("Program Süresi", data.data[0].sure)}

                            {createItems("Açıklama", data.data[0].aciklama)}

                        </SoftBox>

                    </Card>
                </Grid>


                <Grid item xs={12} md={4} xl={4}>
                    <Card className="h-100">
                        <SoftBox pt={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Ödemeler
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                    </SoftTypography>
                                    &nbsp;
                                    <SoftTypography variant="button" color="text" fontWeight="medium">
                                        {terfiRows.length}
                                    </SoftTypography>{" "}
                                    Yapılan Ödeme
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox p={2}>
                        <Total name={"TOPLAM "+ data.data[0].toplam_butce + " TRY"} color="dark"/>
                        <br/>
                        <br/>
                            {terfiRows}
                        </SoftBox>
                    </Card>
                </Grid>

            </>
        );
    }
    catch (error) {
        console.log(error)
        return (
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h2" fontWeight="medium">
                        Yetkilendirilmemiş Giriş
                    </SoftTypography>
                </SoftBox>
            </Grid>
        );
    }
}

export default index;
