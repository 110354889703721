/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
  gender: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  country: [
    { value: "turkey", label: "Türkiye" },
    { value: "syria", label: "Suriye" },
    { value: "montenegro", label: "Karadağ" },
    { value: "france", label: "Fransa" },
    { value: "zambia", label: "Zambiya" },
    { value: "myanmar", label: "Myanmar" },
    { value: "wallis_and_futuna", label: "Wallis ve Futuna Adaları Bölgesi" },
    { value: "iceland", label: "İzlanda" },
    { value: "luxembourg", label: "Lüksemburg" },
    { value: "mali", label: "Mali" },
    { value: "comoros", label: "Komorlar" },
    { value: "australia", label: "Avustralya" },
    { value: "estonia", label: "Estonya" },
    { value: "canada", label: "Kanada" },
    { value: "belarus", label: "Belarus" },
    { value: "guyana", label: "Guyana" },
    { value: "gambia", label: "Gambiya" },
    { value: "tunisia", label: "Tunus" },
    { value: "cameroon", label: "Kamerun" },
    { value: "rwanda", label: "Ruanda" },
    { value: "cambodia", label: "Kamboçya" },
    { value: "benin", label: "Benin" },
    { value: "greece", label: "Yunanistan" },
    { value: "south_korea", label: "Güney Kore" },
    { value: "mauritius", label: "Mauritius" },
    { value: "us_virgin_islands", label: "ABD Virjin Adaları" },
    { value: "aland", label: "Åland" },
    { value: "san_marino", label: "San Marino" },
    { value: "maldives", label: "Maldivler" },
    { value: "vanuatu", label: "Vanuatu" },
    { value: "malawi", label: "Malavi" },
    { value: "egypt", label: "Mısır" },
    { value: "senegal", label: "Senegal" },
    { value: "georgia", label: "Gürcistan" },
    { value: "new_zealand", label: "Yeni Zelanda" },
    { value: "cape_verde", label: "Yeşil Burun" },
    { value: "italy", label: "İtalya" },
    { value: "monaco", label: "Monako" },
    { value: "slovakia", label: "Slovakya" },
    { value: "uruguay", label: "Uruguay" },
    { value: "laos", label: "Laos" },
    { value: "faroe_islands", label: "Faroe Adaları" },
    { value: "niue", label: "Niue" },
    { value: "north_macedonia", label: "Kuzey Makedonya" },
    { value: "chile", label: "Şili" },
    { value: "cyprus", label: "Kıbrıs" },
    { value: "macau", label: "Makao" },
    { value: "el_salvador", label: "El Salvador" },
    { value: "jordan", label: "Ürdün" },
    { value: "jamaica", label: "Jamaika" },
    { value: "barbados", label: "Barbados" },
    { value: "sahrawi_arab_democratic_republic", label: "Sahra Demokratik Arap Cumhuriyeti" },
    { value: "qatar", label: "Katar" },
    { value: "guatemala", label: "Guatemala" },
    { value: "micronesia", label: "Mikronezya" },
    { value: "montserrat", label: "Montserrat" },
    { value: "papua_new_guinea", label: "Papua Yeni Gine" },
    { value: "brazil", label: "Brezilya" },
    { value: "lithuania", label: "Litvanya" },
    { value: "french_guiana", label: "Fransız Guyanası" },
    { value: "moldova", label: "Moldova" },
    { value: "kyrgyzstan", label: "Kırgızistan" },
    { value: "curacao", label: "Curaçao" },
    { value: "vietnam", label: "Vietnam" },
    { value: "angola", label: "Angola" },
    { value: "malaysia", label: "Malezya" },
    { value: "switzerland", label: "İsviçre" },
    { value: "thailand", label: "Tayland" },
    { value: "dominican_republic", label: "Dominik Cumhuriyeti" },
    { value: "uzbekistan", label: "Özbekistan" },
    { value: "chad", label: "Çad" },
    { value: "cocos_keeling_islands", label: "Cocos (Keeling) Adaları" },
    { value: "guinea", label: "Gine" },
    { value: "turks_and_caicos_islands", label: "Turks ve Caicos Adaları" },
    { value: "puerto_rico", label: "Porto Riko" },
    { value: "bhutan", label: "Butan" },
    { value: "cayman_islands", label: "Cayman Adaları" },
    { value: "marshall_islands", label: "Marshall Adaları" },
    { value: "anguilla", label: "Anguilla" },
    { value: "mauritania", label: "Moritanya" },
    { value: "norway", label: "Norveç" },
    { value: "martinique", label: "Martinik" },
    { value: "israel", label: "İsrail" },
    { value: "saint_barthelemy", label: "Saint Barthélemy" },
    { value: "ecuador", label: "Ekvador" },
    { value: "grenada", label: "Grenada" },
    { value: "croatia", label: "Hırvatistan" },
    { value: "brunei", label: "Brunei" },
    { value: "iraq", label: "Irak" },
    { value: "japan", label: "Japonya" },
    { value: "lesotho", label: "Lesotho" },
    { value: "tuvalu", label: "Tuvalu" },
    { value: "heard_island_and_mcdonald_islands", label: "Heard Adası ve McDonald Adaları" },
    { value: "finland", label: "Finlandiya" },
    { value: "south_sudan", label: "Güney Sudan" },
    { value: "us_minor_outlying_islands", label: "Amerika Birleşik Devletleri Küçük Dış Adaları" },
    { value: "saint_helena", label: "Saint Helena" },
    { value: "afghanistan", label: "Afganistan" },
    { value: "solomon_islands", label: "Solomon Adaları" },
    { value: "china", label: "Çin" },
    { value: "eritrea", label: "Eritre" },
    { value: "russia", label: "Rusya" },
    { value: "andorra", label: "Andorra" },
    { value: "armenia", label: "Ermenistan" },
    { value: "austria", label: "Avusturya" },
    { value: "suriname", label: "Surinam" },
    { value: "spain", label: "İspanya" },
    { value: "tokelau", label: "Tokelau" },
    { value: "bahamas", label: "Bahamalar" },
    { value: "sint_maarten", label: "Sint Maarten" },
    { value: "belize", label: "Belize" },
    { value: "jersey", label: "Jersey" },
    { value: "sweden", label: "İsveç" },
    { value: "botswana", label: "Botsvana" },
    { value: "isle_of_man", label: "Man Adası" },
    { value: "united_arab_emirates", label: "Birleşik Arap Emirlikleri" },
    { value: "iran", label: "İran" },
    { value: "gabon", label: "Gabon" },
    { value: "saint_kitts_and_nevis", label: "Saint Kitts ve Nevis" },
    { value: "equatorial_guinea", label: "Ekvator Ginesi" },
    { value: "sao_tome_and_principe", label: "São Tomé ve Príncipe" },
    { value: "greenland", label: "Grönland" },
    { value: "bangladesh", label: "Bangladeş" },
    { value: "romania", label: "Romanya" },
    { value: "british_indian_ocean_territory", label: "Britanya Hint Okyanusu Toprakları" },
    { value: "sudan", label: "Sudan" },
    { value: "bosnia_and_herzegovina", label: "Bosna-Hersek" },
    { value: "malta", label: "Malta" },
    { value: "seychelles", label: "Seyşeller" },
    { value: "sri_lanka", label: "Sri Lanka" },
    { value: "mexico", label: "Meksika" },
    { value: "yemen", label: "Yemen" },
    { value: "virgin_islands", label: "Virjin Adaları" },
    { value: "netherlands", label: "Hollanda" },
    { value: "paraguay", label: "Paraguay" },
    { value: "united_kingdom", label: "Birleşik Krallık" },
    { value: "venezuela", label: "Venezuela" },
    { value: "bouvet_island", label: "Bouvet Adası" },
    { value: "ukraine", label: "Ukrayna" },
    { value: "morocco", label: "Fas" },
    { value: "portugal", label: "Portekiz" },
    { value: "pakistan", label: "Pakistan" },
    { value: "saint_vincent_and_the_grenadines", label: "Saint Vincent ve Grenadinler" },
    { value: "north_korea", label: "Kuzey Kore" },
    { value: "slovenia", label: "Slovenya" },
    { value: "ivory_coast", label: "Fildişi Sahili" },
    { value: "palestine", label: "Filistin" },
    { value: "caribbean_netherlands", label: "Karayip Hollandası" },
    { value: "belgium", label: "Belçika" },
    { value: "zimbabwe", label: "Zimbabve" },
    { value: "tanzania", label: "Tanzanya" },
    { value: "togo", label: "Togo" },
    { value: "cook_islands", label: "Cook Adaları" },
    { value: "guadeloupe", label: "Guadeloupe" },
    { value: "new_caledonia", label: "Yeni Kaledonya" },
    { value: "saint_lucia", label: "Saint Lucia" },
    { value: "south_georgia_and_the_south_sandwich_islands", label: "Güney Georgia ve Güney Sandwich Adaları" },
    { value: "poland", label: "Polonya" },
    { value: "samoa", label: "Bağımsız Samoa Devleti" },
    { value: "czechia", label: "Çekya" },
    { value: "indonesia", label: "Endonezya" },
    { value: "bolivia", label: "Bolivya" },
    { value: "colombia", label: "Kolombiya" },
    { value: "honduras", label: "Honduras" },
    { value: "denmark", label: "Danimarka" },
    { value: "central_african_republic", label: "Orta Afrika Cumhuriyeti" },
    { value: "libya", label: "Libya" },
    { value: "guinea_bissau", label: "Gine-Bissau" },
    { value: "mongolia", label: "Moğolistan" },
    { value: "democratic_republic_of_the_congo", label: "Kongo Demokratik Cumhuriyeti" },
    { value: "falkland_islands", label: "Falkland (Malvina) Adaları" },
    { value: "nauru", label: "Nauru" },

    { value: "saint_pierre_and_miquelon", label: "Saint Pierre ve Miquelon" },
    { value: "costa_rica", label: "Kosta Rika" },
    { value: "somalia", label: "Somali" },
    { value: "ireland", label: "İrlanda" },
    { value: "french_polynesia", label: "Fransız Polinezyası" },
    { value: "pitcairn_islands", label: "Pitcairn Adaları" },
    { value: "hungary", label: "Macaristan" },
    
    { value: "oman", label: "Umman" },
    { value: "guam", label: "Guam" },
    { value: "nepal", label: "Nepal" },
    { value: "liechtenstein", label: "Lihtenştayn" },
    { value: "madagascar", label: "Madagaskar" },
    { value: "kazakhstan", label: "Kazakistan" },
    { value: "djibouti", label: "Cibuti" },
    { value: "gibraltar", label: "Cebelitarık" },
    { value: "guernsey", label: "Guernsey" },
    { value: "namibia", label: "Namibya" },
    { value: "republic_of_the_congo", label: "Kongo Cumhuriyeti" },
    { value: "germany", label: "Almanya" },
    { value: "vatican_city", label: "Vatikan" },
    { value: "french_southern_and_antarctic_lands", label: "Fransız Güney ve Antarktika Toprakları" },
    { value: "albania", label: "Arnavutluk" },
    { value: "algeria", label: "Cezayir" },
    { value: "mayotte", label: "Mayotte" },
    { value: "haiti", label: "Haiti" },
    { value: "svalbard_and_jan_mayen", label: "Svalbard ve Jan Mayen" },
    { value: "tonga", label: "Tonga" },
    { value: "hong_kong", label: "Hong Kong" },
    { value: "singapore", label: "Singapur" },
    { value: "antigua_and_barbuda", label: "Antigua ve Barbuda" },
    { value: "saudi_arabia", label: "Suudi Arabistan" },
    { value: "cuba", label: "Küba" },
    { value: "panama", label: "Panama" },
    { value: "liberia", label: "Liberya" },
    { value: "azerbaijan", label: "Azerbaycan" },
    { value: "latvia", label: "Letonya" },
    { value: "antarctica", label: "Antarktika" },
    { value: "kiribati", label: "Kiribati" },
    { value: "ethiopia", label: "Etiyopya" },
    { value: "niger", label: "Nijer" },
    { value: "trinidad_and_tobago", label: "Trinidad ve Tobago" },
    { value: "sierra_leone", label: "Sierra Leone" },
    { value: "mozambique", label: "Mozambik" },
    { value: "palau", label: "Palau" },
    { value: "fiji", label: "Fiji" },
    { value: "reunion", label: "Réunion" },
    { value: "norfolk_island", label: "Norfolk Adası" },
    { value: "turkmenistan", label: "Türkmenistan" },
    { value: "tajikistan", label: "Tacikistan" },
    { value: "lebanon", label: "Lübnan" },
    { value: "nigeria", label: "Nijerya" },
    { value: "united_states", label: "Amerika Birleşik Devletleri" },
    { value: "argentina", label: "Arjantin" },
    { value: "burkina_faso", label: "Burkina Faso" },
    { value: "christmas_island", label: "Christmas Adası" },
    { value: "bermuda", label: "Bermuda" },
    { value: "nicaragua", label: "Nikaragua" },
    { value: "eswatini", label: "Esvatini" },
    { value: "bahrain", label: "Bahreyn" },
    { value: "kenya", label: "Kenya" },
    { value: "serbia", label: "Sırbistan" },
    { value: "east_timor", label: "Doğu Timor" },
    { value: "dominica", label: "Dominika" },
    { value: "northern_mariana_islands", label: "Kuzey Mariana Adaları" },
    { value: "philippines", label: "Filipinler" },
    { value: "kosovo", label: "Kosova" },
    { value: "kuwait", label: "Kuveyt" },
    { value: "taiwan", label: "Tayvan" },
    { value: "burundi", label: "Burundi" },
    { value: "american_samoa", label: "Amerikan Samoası" },
    { value: "bulgaria", label: "Bulgaristan" },
    { value: "south_africa", label: "Güney Afrika" },
    { value: "peru", label: "Peru" },
    { value: "aruba", label: "Aruba" },
    { value: "ghana", label: "Gana" },
    { value: "saint_martin", label: "Saint Martin" },
    { value: "india", label: "Hindistan" },
    { value: "uganda", label: "Uganda" },


    { value: "adana", label: "Adana" },
    { value: "adiyaman", label: "Adıyaman" },
    { value: "afyon", label: "Afyon" },
    { value: "agri", label: "Ağrı" },
    { value: "aksaray", label: "Aksaray" },
    { value: "amasya", label: "Amasya" },
    { value: "ankara", label: "Ankara" },
    { value: "antalya", label: "Antalya" },
    { value: "ardahan", label: "Ardahan" },
    { value: "artvin", label: "Artvin" },
    { value: "aydin", label: "Aydın" },
    { value: "balikesir", label: "Balıkesir" },
    { value: "bartin", label: "Bartın" },
    { value: "batman", label: "Batman" },
    { value: "bayburt", label: "Bayburt" },
    { value: "bilecik", label: "Bilecik" },
    { value: "bingol", label: "Bingöl" },
    { value: "bitlis", label: "Bitlis" },
    { value: "bolu", label: "Bolu" },
    { value: "burdur", label: "Burdur" },
    { value: "bursa", label: "Bursa" },
    { value: "canakkale", label: "Çanakkale" },
    { value: "cankiri", label: "Çankırı" },
    { value: "corum", label: "Çorum" },
    { value: "denizli", label: "Denizli" },
    { value: "diyarbakir", label: "Diyarbakır" },
    { value: "duzce", label: "Düzce" },
    { value: "edirne", label: "Edirne" },
    { value: "elazig", label: "Elazığ" },
    { value: "erzincan", label: "Erzincan" },
    { value: "erzurum", label: "Erzurum" },
    { value: "eskisehir", label: "Eskişehir" },
    { value: "gaziantep", label: "Gaziantep" },
    { value: "giresun", label: "Giresun" },
    { value: "gumushane", label: "Gümüşhane" },
    { value: "hakkari", label: "Hakkari" },
    { value: "hatay", label: "Hatay" },
    { value: "igdir", label: "Iğdır" },
    { value: "isparta", label: "Isparta" },
    { value: "istanbul", label: "İstanbul" },
    { value: "izmir", label: "İzmir" },
    { value: "kahramanmaras", label: "Kahramanmaraş" },
    { value: "karabuk", label: "Karabük" },
    { value: "karaman", label: "Karaman" },
    { value: "kars", label: "Kars" },
    { value: "kastamonu", label: "Kastamonu" },
    { value: "kayseri", label: "Kayseri" },
    { value: "kilis", label: "Kilis" },
    { value: "kirikkale", label: "Kırıkkale" },
    { value: "kirklareli", label: "Kırklareli" },
    { value: "kirsehir", label: "Kırşehir" },
    { value: "kocaeli", label: "Kocaeli" },
    { value: "konya", label: "Konya" },
    { value: "kutahya", label: "Kütahya" },
    { value: "malatya", label: "Malatya" },
    { value: "manisa", label: "Manisa" },
    { value: "mardin", label: "Mardin" },
    { value: "mersin", label: "Mersin" },
    { value: "mugla", label: "Muğla" },
    { value: "mus", label: "Muş" },
    { value: "nevsehir", label: "Nevşehir" },
    { value: "nigde", label: "Niğde" },
    { value: "ordu", label: "Ordu" },
    { value: "osmaniye", label: "Osmaniye" },
    { value: "rize", label: "Rize" },
    { value: "sakarya", label: "Sakarya" },
    { value: "samsun", label: "Samsun" },
    { value: "sanliurfa", label: "Şanlıurfa" },
    { value: "siirt", label: "Siirt" },
    { value: "sinop", label: "Sinop" },
    { value: "sirnak", label: "Şırnak" },
    { value: "sivas", label: "Sivas" },
    { value: "tekirdag", label: "Tekirdağ" },
    { value: "tokat", label: "Tokat" },
    { value: "trabzon", label: "Trabzon" },
    { value: "tunceli", label: "Tunceli" },
    { value: "usak", label: "Uşak" },
    { value: "van", label: "Van" },
    { value: "yalova", label: "Yalova" },
    { value: "yozgat", label: "Yozgat" },
    { value: "zonguldak", label: "Zonguldak" }
],

  gorevtur: [
    { value: "Yurtiçi", label: "Yurtiçi" },
    { value: "Yurtdışı", label: "Yurtdışı" },
    { value: "İstanbul", label: "İstanbul" },
    { value: "Sınır", label: "Sınır" },
  ],

  yolculuk_tur: [
    { value: "Uçak", label: "Uçak" },
    { value: "Araç / Karayolu", label: "Araç / Karayolu" },
    { value: "Otobüs", label: "Otobüs" },
    { value: "Tren", label: "Tren" },
    { value: "Yataklı Tren", label: "Yataklı Tren" },
    { value: "Yüksek Hızlı Tren", label: "Yüksek Hızlı Tren" },
    { value: "Kiralık Araç", label: "Kiralık Araç" },
    { value: "Kurum Aracı", label: "Kurum Aracı" },
    { value: "Özel Araç", label: "Özel Araç" },
    { value: "Vapur / Gemi", label: "Vapur / Gemi" },
    { value: "Deniz Aracı", label: "Deniz Aracı" }
  ],
  



};

export default selectData;
