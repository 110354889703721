/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import MapChart from "examples/Charts/MapCharts";

import Header from "layouts/headers/NormalHeader"
import curved0 from "assets/images/studio-5.jpg";


// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";


// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";


import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


function Tables() {


  const theme = createTheme({
    typography: {
      //fontSize: '12px !important',
      color: "#fff",
      fill: "#fffff",

      fontSize: "12",
      fontFamily: [
        "Roboto", "Helvetica", "Arial",
        'sans-serif'
        // Diğer fontlar...
      ].join(','),
    },
    // Diğer tema özelleştirmeleri...



  });



  const { size } = typography;
  let { chart, items } = reportsBarChartData;

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/kurum`, {
          withCredentials: true
        });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);



  let datatable = []
  let datatable_free = []
  let datatable_sozlesme = []
  let datatable_gender = []
  let datatable_gender_free = []
  let datatable_gender_sozlesme = []
  let datatable_nation = []
  let datatable_nation_free = []
  let datatable_nation_sozlesme = []
  let complexReportsDoughnutChartData = {}
  let complexReportsDoughnutChartDataFree = {}
  if (data && data?.uyruklar) {

    let uyruklar = data?.uyruklar;
    datatable = uyruklar
      .filter(item => item.uyruk !== null) // `item.uyruk` null olmayanları filtrele
      .map((item, index) => ({
        id: index,
        value: item.count,
        label: item.uyruk + " (" + item.count + ")",
        label2: "%" + Math.round(item.count / data?.toplam * 100),
        // innerRadius: '160%',
        // outerRadius: '180%',
      }));

    uyruklar = data?.uyruklar_free;
    datatable_free = uyruklar
      .filter(item => item.calisma_yeri !== null) // `item.uyruk` null olmayanları filtrele
      .map((item, index) => ({
        id: index,
        value: item.count,
        label: item.calisma_yeri + " (" + item.count + ")",
        label2: "%" + Math.round(item.count / data?.toplam * 100),
        // innerRadius: '160%',
        // outerRadius: '180%',
      }));

    uyruklar = data?.uyruklar_sozlesme;
    datatable_sozlesme = uyruklar
      .filter(item => item.uyruk !== null) // `item.uyruk` null olmayanları filtrele
      .map((item, index) => ({
        id: index,
        value: item.count,
        label: item.uyruk + " (" + item.count + ")",
        label2: "%" + Math.round(item.count / data?.toplam * 100),
        // innerRadius: '160%',
        // outerRadius: '180%',
      }));

    // Function to calculate percentage and prevent rounding to zero for small values
    const calculatePercentage = (value, total) => {
      const percentage = (value / total) * 100;
      return percentage < 1 && percentage > 0 ? percentage.toFixed(2) : Math.round(percentage);
    };

    // Generate complexReportsDoughnutChartData with precise percentages
    complexReportsDoughnutChartData = {
      images: ["", "", "", ""], // This can be populated based on your requirements
      labels: datatable_sozlesme.map(item => item.label), // Use the `label` from datatable_sozlesme
      datasets: {
        label: "Referrals",
        backgroundColors: ["info", "dark"], // Customize or expand as needed
        data: datatable_sozlesme.map(item => calculatePercentage(item.value, data?.toplam)), // Use the custom percentage calculation
      },
    };

    // Generate complexReportsDoughnutChartDataFree with precise percentages
    complexReportsDoughnutChartDataFree = {
      images: ["", "", "", ""], // This can be populated based on your requirements
      labels: datatable_free.map(item => item.label), // Use the `label` from datatable_free
      datasets: {
        label: "Referrals",
        backgroundColors: ["info", "dark"], // Customize or expand as needed
        data: datatable_free.map(item => calculatePercentage(item.value, data?.toplam)), // Use the custom percentage calculation
      },
    };



    datatable_gender = [
      {
        id: 1,
        value: data?.erkek,
        label: "Erkek (" + data?.erkek + ")",
        label2: "%" + Math.round(data?.erkek / data?.toplam * 100),
      },
      {
        id: 2,
        value: data?.kadın,
        label: "Kadın (" + data?.kadın + ")",
        label2: "%" + Math.round(data?.kadın / data?.toplam * 100),
      },
    ]

    datatable_gender_free = [
      {
        id: 1,
        value: data?.erkek_free,
        label: "Erkek (" + data?.erkek_free + ")",
        label2: "%" + Math.round(data?.erkek_free / data?.toplam_free * 100),
      },
      {
        id: 2,
        value: data?.kadın_free,
        label: "Kadın (" + data?.kadın_free + ")",
        label2: "%" + Math.round(data?.kadın_free / data?.toplam_free * 100),
      },
    ]

    datatable_gender_sozlesme = [
      {
        id: 1,
        value: data?.erkek_sozlesme,
        label: "Erkek (" + data?.erkek_sozlesme + ")",
        label2: "%" + Math.round(data?.erkek_sozlesme / data?.toplam_sozlesme * 100),
      },
      {
        id: 2,
        value: data?.kadın_sozlesme,
        label: "Kadın (" + data?.kadın_sozlesme + ")",
        label2: "%" + Math.round(data?.kadın_sozlesme / data?.toplam_sozlesme * 100),
      },
    ]

    datatable_nation = [
      {
        id: 1,
        value: data?.yerli,
        label: "Yerli (" + data?.yerli + ")",
        label2: "%" + Math.round(data?.yerli / data?.toplam * 100),

      },
      {
        id: 2,
        value: data?.yabancı,
        label: "Yabancı (" + data?.yabancı + ")",
        label2: "%" + Math.round(data?.yabancı / data?.toplam * 100),
      },
    ]

    datatable_nation_free = [
      {
        id: 1,
        value: data?.yerli_free,
        label: "Yerli (" + data?.yerli_free + ")",
        label2: "%" + Math.round(data?.yerli_free / data?.toplam_free * 100),

      },
      {
        id: 2,
        value: data?.yabancı_free,
        label: "Yabancı (" + data?.yabancı_free + ")",
        label2: "%" + Math.round(data?.yabancı_free / data?.toplam_free * 100),
      },
    ]

    datatable_nation_sozlesme = [
      {
        id: 1,
        value: data?.yerli_sozlesme,
        label: "Yerli (" + data?.yerli_sozlesme + ")",
        label2: "%" + Math.round(data?.yerli_sozlesme / data?.toplam_sozlesme * 100),

      },
      {
        id: 2,
        value: data?.yabancı_sozlesme,
        label: "Yabancı (" + data?.yabancı_sozlesme + ")",
        label2: "%" + Math.round(data?.yabancı_sozlesme / data?.toplam_sozlesme * 100),
      },
    ]

  }


  //let { chart, items } = reportsBarChartData;

  const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];


  // HEDEFLER

  const [data_hedef, setDataHedef] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/hedefler/son-6-ay`, {
          withCredentials: true
        });
        setDataHedef(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  // GÖREVLER

  const [data_gorev, setDataGorev] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevler/son-6-ay`, {
          withCredentials: true
        });
        setDataGorev(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  function ayAdiniAl(ayYil) {
    const bolunmus = ayYil.split('-'); // "2023-07" gibi bir string'i '-' karakterine göre böler
    const yil = bolunmus[0]; // Yılı alır
    const ayIndex = parseInt(bolunmus[1]) - 1; // Ay sayısını alır ve 1 çıkarır (çünkü diziler 0'dan başlar)
    console.log(`${ayIsimleri[ayIndex]} ${yil}`);
    return String(`${ayIsimleri[ayIndex]}`); // Ayın adını ve yılı birleştirir
  }

  //const cevrilmisAyIsimleri = ayIsimCevirici(tarihler);

  const convertToDesiredFormat = (obj, all_count) => {
    // Object.entries ile nesneyi anahtar-değer çiftleri şeklinde bir diziye çevir
    return Object.entries(obj)
      .slice(0, 4) // İlk beş öğeyi al
      .map(([label, progress], index) => ({
        icon: { color: "info", component: "assignment" },
        label: label,
        progress: { content: `${progress}`, percentage: Math.round((progress / all_count) * 100) } // Burada 'percentage' değerini örnek olarak ayarladım
      }));
  };


  if (data_gorev.kategoriye_gore_toplamlar) {
    items = convertToDesiredFormat(data_gorev.kategoriye_gore_toplamlar, data_gorev.genel_toplam);
  }


  //let label = data_gorev.map(time => time)

  let labels;
  let datalabel;

  if (data_gorev.genel_aylik_toplamlar) {
    labels = Object.keys(data_gorev.genel_aylik_toplamlar).map(ayAdiniAl);

    datalabel = Object.values(data_gorev.genel_aylik_toplamlar);

    // Bu noktada `labels` ve `datalabel` ile istediğiniz işlemleri yapabilirsiniz
    console.log(labels, datalabel);
  }


  let labels2;
  let datalabel2;

  if (data_hedef.genel_aylik_toplamlar) {
    labels2 = Object.keys(data_hedef.genel_aylik_toplamlar);
    datalabel2 = Object.values(data_hedef.genel_aylik_toplamlar);

    // Bu noktada `labels` ve `datalabel` ile istediğiniz işlemleri yapabilirsiniz
    console.log(labels2, datalabel2);
  }


  chart = {
    labels: labels,
    datasets: { label: "Yapılan İşler", data: datalabel }
  }

  const gradientLineChartData = {
    labels: labels,
    datasets: [
      {
        label: "Yapılan İşler",
        color: "info",
        data: datalabel,
      },
      {
        label: "Hedef",
        color: "dark",
        data: datalabel2,
      },
    ],
  };


  const renderLabel = (props) => {
    const { x, y, textAnchor, value, id } = props;
    // Etiketlerin konumunu ayarlayın
    const newX = x + (textAnchor === 'end' ? -100 : 100); // Örnek değer, ihtiyaca göre ayarlayın
    const newY = y;

    return (
      <text x={newX} y={newY} textAnchor={textAnchor} fill="#fff">
        {value}
      </text>
    );
  };

  try {

    let dataHeader1 = {
      "title": "Grafikler",
      "line1": "Kanal Hakkındaki Grafikler",
      "image": curved0,
    }

    let dataHeader2 = {
      "title": "Cinsiyet Dağılımı",
      "line1": "Kanaldaki Cinsiyet Grafikler",
      "image": curved0,
      "color": "dark"


    }

    let dataHeader3 = {
      "title": "Uyruk Dağılımı",
      "line1": "Kanaldaki Uyruk Grafikler",
      "image": curved0,
      "color": "dark"


    }

    let dataHeader4 = {
      "title": "Müdürlük Dağılımı",
      "line1": "Müdürlük Personel Veriler",
      "image": curved0,
      "color": "dark"


    }



    let items_mudurluk = []

    data?.mudurlukler.forEach(mudurluk => {
      if (mudurluk.isim) {  // Only add if mudurluk__isim is not null
        items_mudurluk.push({
          icon: { color: "info", component: "person" },
          label: mudurluk.isim,
          label1: mudurluk.total_hedef,
          progress: { content: `${mudurluk.count + "//" + mudurluk.total_hedef}` }
        });
      }
    });


    return (
      <DashboardLayout>
        < DashboardNavbar />

        <Grid item xs={12} md={12} xl={12} mb={2}>
          <Header data={dataHeader1} />
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <ReportsBarChart
                  title="Son 6 Ayda Yapılan Haberler"
                  chart={chart}
                  items={items}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <GradientLineChart
                  title="Yapılan Görevler"
                  description={
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox fontSize={size.lg} color="dark" mb={0.3} mr={0.5} lineHeight={0}>
                        <Icon className="font-bold">trending_up</Icon>
                      </SoftBox>
                      <SoftTypography variant="button" color="text" fontWeight="medium">
                        6 Aylık{" "}
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          grafik görünümü
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                  }
                  height="20.25rem"
                  chart={gradientLineChartData}
                />
              </Grid>
            </Grid>
          </SoftBox>

        </Grid>


        <Grid item xs={12} md={12} xl={12} mb={2}>
          <Header data={dataHeader2} />
        </Grid>



        <Grid container spacing={3}>
          <>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Çalışan Sayısı" }}
                count={data?.toplam}
                percentage={{ color: "secondary", text: "" }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Erkek Sayısı" }}
                count={data?.erkek}
                percentage={{ color: "light", text: "%" + Math.round(data?.erkek / data?.toplam * 100) }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Kadın Sayısı" }}
                count={data?.kadın}
                percentage={{ color: "light", text: "%" + Math.round(data?.kadın / data?.toplam * 100) }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>
          </>

          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Sözleşmeli Cinsiyet Dağılımı ({data?.toplam_sozlesme})
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.label2} `,
                      arcLabelMinAngle: 45,
                      data: datatable_gender_sozlesme,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },

                    },
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}
                  width={500}
                  height={200}
                  labelClassName="custom-label"
                  colors={["#2787f4", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                />
              </ThemeProvider>

            </Card>
          </Grid>

          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Freelancer Cinsiyet Dağılımı ({data?.toplam_free})
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.label2} `,
                      arcLabelMinAngle: 45,
                      data: datatable_gender_free,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },

                    },
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}
                  width={500}
                  height={200}
                  labelClassName="custom-label"
                  colors={["#2787f4", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                />
              </ThemeProvider>

            </Card>
          </Grid>


          <Grid item xs={12} md={12} xl={12} >
            <Header data={dataHeader4} />
          </Grid>

          <Grid item xs={12} md={12} xl={12} mt={2}>
            <ReportsBarChart
              title=""

              description={
                <>
                  <strong>Ünvan Listesi:</strong>

                </>
              }
              altdesc={""}
              maintitle={`Müdürlük Personel Dağılımı  `}
              chart={{}}

              items={items_mudurluk}

              color="dark"
              size="2.3"
              xdesc="true"

            />
          </Grid>



          <Grid item xs={12} md={12} xl={12} >
            <Header data={dataHeader3} />
          </Grid>

          <>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Çalışan Sayısı" }}
                count={data?.toplam}
                percentage={{ color: "secondary", text: "" }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Yabancı Çalışan" }}
                count={data?.yabancı}
                percentage={{ color: "light", text: "%" + Math.round(data?.yabancı / data?.toplam * 100) }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Yerli Çalışan" }}
                count={data?.yerli}
                percentage={{ color: "light", text: "%" + Math.round(data?.yerli / data?.toplam * 100) }}
                icon={{ color: "dark", component: "people" }}
              />
            </Grid>

          </>
          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Sözleşmeli Uyruk Dağılımı ({data?.toplam_sozlesme})
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {

                      arcLabel: (item) => `${item.label2} `,
                      arcLabelMinAngle: 45,
                      data: datatable_nation_sozlesme,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },



                    },
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}

                  width={500}
                  height={200}
                  labelClassName="custom-label"

                  colors={["#2787f4", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                />
              </ThemeProvider>

            </Card>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Freelancer Uyruk Dağılımı ({data?.toplam_free})
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {

                      arcLabel: (item) => `${item.label2} `,
                      arcLabelMinAngle: 45,
                      data: datatable_nation_free,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },



                    },
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}

                  width={500}
                  height={200}
                  labelClassName="custom-label"

                  colors={["#2787f4", "#354868", "#96AFB8", "#5CE8FF", "#37CFF5", "#00B092", "#0F7161", "#243935"]}
                />
              </ThemeProvider>

            </Card>
          </Grid>
          {/* <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Sözleşmeli Uyruk Ülke Dağılımı
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.value} `,
                      arcLabelMinAngle: 45,
                      data: datatable_sozlesme,

                    },
                  ]}
                  width={500}
                  height={200}
                  labelClassName="custom-label"
                  colors={['#2787f4', "#354868", '#4463b4', '#7387b1', "#354868", '#7891ba', '#3f5caa', '#5c73aa']}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}
                />
                <PieChart
                  series={[
                    {
                      data: datatable_sozlesme,
                      outerRadius: 0,
                    },
                  ]}
                  //width={600}
                  height={200}
                  labelClassName="custom-label"
                  colors={['#2787f4', "#354868", '#4463b4', '#7387b1', "#354868", '#7891ba', '#3f5caa', '#5c73aa']}
                />
              </ThemeProvider>

            </Card>
          </Grid>



          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Freelancer Çalışma Yeri Dağılımı
                </SoftTypography>

              </SoftBox>
              <ThemeProvider theme={theme}>
                <PieChart
                  series={[
                    {
                      arcLabel: (item) => `${item.value} `,
                      arcLabelMinAngle: 45,
                      data: datatable_free,

                    },
                  ]}
                  width={500}
                  height={200}
                  labelClassName="custom-label"
                  colors={['#2787f4', "#354868", '#4463b4', '#7387b1', "#354868", '#7891ba', '#3f5caa', '#5c73aa']}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: 'white',
                      fontWeight: 'light',
                    },
                  }}
                />
                <PieChart
                  series={[
                    {
                      data: datatable_free,
                      outerRadius: 0,
                    },
                  ]}
                  //width={600}
                  height={200}
                  labelClassName="custom-label"
                  colors={['#2787f4', "#354868", '#4463b4', '#7387b1', "#354868", '#7891ba', '#3f5caa', '#5c73aa']}
                />
              </ThemeProvider>

            </Card>



          </Grid> */}



          <Grid item xs={12} md={12} xl={12}>


            <SoftBox display="flex" alignItems="center">

              <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                Sözleşmeli Uyruk Dağılımı Haritası
              </SoftTypography>

            </SoftBox>
            <div style={{marginTop: "-60px",marginBottom: "-60px"}}>
              <MapChart data={datatable_sozlesme} />
            </div>



          </Grid>




          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Sözleşmeli Uyruk Dağılımı
                </SoftTypography>

              </SoftBox>
              <ComplexReportsDoughnutChart
                chart={complexReportsDoughnutChartData}
              />

            </Card>

          </Grid>

          <Grid item xs={12} md={12} xl={6}>

            <Card>
              <SoftBox display="flex" alignItems="center">

                <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                  Freelancer Uyruk Dağılımı
                </SoftTypography>

              </SoftBox>
              <ComplexReportsDoughnutChart
                chart={complexReportsDoughnutChartDataFree}
              />

            </Card>




          </Grid>


        </Grid>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
