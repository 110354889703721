/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import { Margin } from "@mui/icons-material";

function ReportsBarChartItem({ color, icon, label, progress }) {
  const { size } = typography;

  const hasSpecialDelimiter = typeof progress.content === 'string' && progress.content.includes('//');
  let firstPart = progress.content;
  let secondPart = '';

  // Eğer "//" varsa, string'i böl ve ayır
  if (hasSpecialDelimiter) {
    const parts = progress.content.split('//');
    firstPart = parts[0];
    secondPart = parts[1];
  }

  return (
    <SoftBox width="100%">
      <SoftBox display="flex" alignItems="flex-start" mb={1}>
        <SoftBox
          bgColor={icon.color}
          width="1.25rem"
          height="1.25rem"
          borderRadius="sm"
          color="white"
          fontSize={size.xs}
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          mr={1}
          variant="gradient"
          flexShrink={0}
        >
          <Icon>{icon.component}</Icon>
        </SoftBox>
        <SoftTypography
          variant="caption"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
          height="2rem"
          width="70%"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1}>
        <SoftTypography variant="h4" fontWeight="bold" color={color}>
          {firstPart}
          {secondPart && (
            <span style={{ color: "rgb(181 191 205)", fontSize: "15px", margin: "4px" }}>
              / {secondPart}
            </span>
          )}
        </SoftTypography>
        <SoftBox width="75%" mt={0.5}>
          <SoftProgress value={progress.percentage} color={color} />
        </SoftBox>
        {!String(progress.content).includes('%') && (
          <SoftTypography variant="button" color="secondary">
            {progress.percentage ? String("%" + progress.percentage).slice(0, 4) : ""}

          </SoftTypography>
        )}



      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of ReportsBarChartItem
ReportsBarChartItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the ReportsBarChartItem
ReportsBarChartItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"])
      .isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    content: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReportsBarChartItem;
