import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";



import PerformansCardAylik from "examples/PerformansCards/PerformansCardAylik";
import PerformansCard3Aylik from "examples/PerformansCards/PerformansCard3Aylik";
import PerformansCard6Aylik from "examples/PerformansCards/PerformansCard6Aylik";
import PerformansCardYillik from "examples/PerformansCards/PerformansCardYillik";
import PerformansCardGenel from "examples/PerformansCards/PerformansCardGenel";


// Overview page components
import Header from "layouts/performans-list/components/Header";


import { useState , useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';


function Overview() {
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (index) => {
    setTabIndex(index);
  }
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/isealim?format=json`);
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header onChange={e => onTabChange(e)} data={data} />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>

          {tabIndex === 0 && (
            <>
              <PerformansCardAylik  />
              
              
            </>
          )}
          {tabIndex === 1 && (
            <>
              <PerformansCard3Aylik  />
              
            </>
          )}
          {tabIndex === 2 && (
            <>
              <PerformansCard6Aylik  />
              
            </>
          )}

          {tabIndex === 3 && (
            <>
              <PerformansCardYillik  />
              
            </>
          )}


          {tabIndex === 4 && (
            <>
              <PerformansCardGenel  />
              
            </>
          )}
        
          

        </Grid>
      </SoftBox>
      

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
