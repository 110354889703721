import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Swal from 'sweetalert2'

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";

// Settings page components
import FormField from "layouts/planlama/gorev-organizasyon/components/FormField";

// Data
import selectData from "layouts/planlama/gorev-organizasyon/components/BasicInfo/data/selectData";

import { v4 as uuidv4 } from 'uuid';

function BasicInfo() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [avans2FA, setAvans2FA] = useState(false);

  const [dataUser, setDataUser] = useState([]);

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/api/sessions?format=json`, { withCredentials: true });
        setSessions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);




  const [formData, setFormData] = useState({
    id: id,
    aciklama: '',
    calisma: '',
    organizator: '',
    akreditasyon: '',
    etkinlik_bilgi_baglantisi: '',
    konu: '',
    yer: '',
    tarih: '',
    saat: '',
    bitis_tarih: '',
    bitis_saat: '',
    kanal_cikis_saati: '',
    iletisim_numarasi: '',
    muhabir: [],
    avans_personel: null,
    yolculuk: '',
    yolculuk_not: '',
    ekipman: '',
    notlar: '',
    tur: '',
    is_avansi: 0,
    is_avansi_doviz: 'tl',
    konaklama: false,
    yol: false,
    harcirah: false,
    yemek: false,
    tum_masraf: false,
    katilim_ucreti: '',
    fazla_bagaj: false
  });



  useEffect(() => {
    const convertDate = (date) => {
      if (!date) return '';
      const dateParts = date.split('/');
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts;
        return `${year}-${month}-${day}`;
      }
      return date;
    };

    if (data.length > 0) {
      const fields = ["id", "aciklama", "calisma", "organizator", "akreditasyon", "etkinlik_bilgi_baglantisi", "konu", "yer", "tarih", "saat", "bitis_tarih", "bitis_saat", "kanal_cikis_saati", "iletisim_numarasi", "muhabir", "yolculuk_not", "notlar", "tur", "is_avansi", "is_avansi_doviz", "avans_personel", "konaklama", "yol", "harcirah", "yemek", "tum_masraf", "ekipman", "katilim_ucreti", "fazla_bagaj"];
      const newFormData = {};
      fields.forEach(field => {
        if (field === "tarih" || field === "bitis_tarih") {
          newFormData[field] = convertDate(data[0][field]) || '';
        } else {
          newFormData[field] = data[0][field] || '';
        }
      });
      setFormData(newFormData);

      if (data[0].muhabir) {
        const formattedMuhabir = formatFilteredOptions(dataUser, data[0].muhabir);
        console.log(formattedMuhabir)
        setSelectedOptions(formattedMuhabir);
      }

      if (data[0].yolculuk) {
        const parsedYolculuklar = JSON.parse(data[0].yolculuk);
        if (Array.isArray(parsedYolculuklar)) {
          setYolculuklar(parsedYolculuklar);
        } else {
          console.error("Invalid format for yolculuklar: ", parsedYolculuklar);
        }
      }
    }
  }, [data, dataUser]);



  const formatOptions = () => {
    return dataUser.map((item) => ({
      value: item.id,
      label: item.name + " " + item.surname + " (" + item.trt_arabi_unvan + ")",
    }));
  };

  const formatFilteredOptions = (dataUser, filterList) => {
    if (!filterList || filterList.length === 0) {
      return [];
    }
    return dataUser
      .filter(item => filterList.includes(item.id))
      .map(item => ({
        value: item.id,
        label: `${item.name} ${item.surname} (${item.trt_arabi_unvan})`
      }));
  };

  const handleSelectChange = (selected, action) => {
    setFormData({
      ...formData,
      [action.name]: selected.value
    });
  };

  const checkData = (name) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }

    const firstItem = data[0];
    if (!firstItem.hasOwnProperty('talep')) {
      return null;
    }


    const value = firstItem[name];
    if (value != "" || value != null) {
      const talepValue = firstItem.talep[name];
      if (talepValue === "" || talepValue === null) {
        return null;
      }
      return talepValue;
    }
    return value;
  };

  const filteredOptions = formatFilteredOptions(dataUser, checkData("muhabir"));

  const [selectedOptions, setSelectedOptions] = useState(filteredOptions);



  const handleMuhabirSelectChange = (selected) => {
    setSelectedOptions(selected);
    setFormData({
      ...formData,
      "muhabir": selected
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date, name) => {
    const formattedDate = formatDate(date);
    setFormData({
      ...formData,
      [name]: formattedDate
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const findPersonel = (id) => {

    let muhabir_name = ""
    dataUser.forEach(muhabir => {
      if (String(muhabir.id) === String(id)) {
        muhabir_name = muhabir.name + " " + muhabir.surname
      }

    })
    return `${muhabir_name}`;
  };


  //const [yolculuklar, setYolculuklar] = useState([{ id: 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' }]);
  const [yolculuklar, setYolculuklar] = useState([]);

  useEffect(() => {
    if (sessions.length > 0) {
      const parsedData = sessions.map(session => {
        const userAgent = session.user_agent;
        let browser = "Bilinmiyor", operatingSystem = "Bilinmiyor", device = "Bilinmiyor";

        // Log the user_agent to see what it looks like
        console.log("User Agent String:", userAgent);

        // Parsing user_agent if it is known
        if (userAgent && userAgent !== "Bilinmiyor") {
          const browserInfo = userAgent.match(/Tarayıcı: ([^,]*)/);
          const osInfo = userAgent.match(/İşletim Sistemi: ([^,]*)/);
          const deviceInfo = userAgent.match(/Cihaz: ([^,]*)/);

          browser = browserInfo ? browserInfo[1].trim() : "Bilinmiyor";
          operatingSystem = osInfo ? osInfo[1].trim() : "Bilinmiyor";
          device = deviceInfo ? deviceInfo[1].trim() : "Bilinmiyor";
        }

        return {
          session_key: session.session_key,
          ip: session.ip,
          expire_date: session.expire_date,
          browser,
          operatingSystem,
          device,
        };
      });

      setYolculuklar(parsedData);
    }
  }, [sessions]);

  const handleYolculukFieldChange = (id, fieldName, value) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [fieldName]: value } : yolculuk
      )
    );
  };

  const handleYolculukSelectChange = (id, fieldName) => (selectedOption) => {
    handleYolculukFieldChange(id, fieldName, selectedOption.value);
  };

  const handleYolculukInputChange = (id) => (e) => {
    const { name, value } = e.target;
    handleYolculukFieldChange(id, name, value);
  };

  const handleBooleanChange = (id, field) => {
    setYolculuklar(prevState =>
      prevState.map(yolculuk =>
        yolculuk.id === id ? { ...yolculuk, [field]: !yolculuk[field] } : yolculuk
      )
    );
  };

  const handleBooleanMasrafChange = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name]
    });
  };

  const handleYolculukSil = (id) => {
    setYolculuklar(prevState => prevState.filter(yolculuk => yolculuk.id !== id));
  };

  const handleYolculukEkle = () => {
    const newYolculuk = { id: yolculuklar.length + 1, nereden: '', nereye: '', yolculuk_turu: '', gidisDonus: false, someFieldName: '' };
    setYolculuklar([...yolculuklar, newYolculuk]);
  };



  const Toast = Swal.mixin({
    toast: true,
    position: 'top', // Bildirimi sayfanın üstüne yerleştirir
    iconColor: '#2091ff',
    backdropFilter: "",
    filter: "",
    customClass: {
      popup: 'colored-toast',
    },

    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    backdrop: false, // Arka planın blur olmasını engeller

  })

  const handleSubmit = async () => {
    Toast.fire({
      icon: 'success',
      title: '<SoftTypography variant="button" color="text" fontWeight="medium">Başarıyla Kaydedildi</SoftTypography>', // HTML tagleri kullanırken string içinde belirtmelisin
    })


    const finalFormData = {
      ...formData,
      yolculuk: JSON.stringify(yolculuklar?.map(yolculuk => ({
        id: yolculuk.id,
        nereden: yolculuk.nereden,
        nereye: yolculuk.nereye,
        yolculuk_turu: yolculuk.yolculuk_turu,
        gidisDonus: yolculuk.gidisDonus,
      })))
    };

    try {
      const response = await axios.post(`${window.BASE_URL}/gorevlendirme/save`, finalFormData, { withCredentials: true });
      if (response.status === 200) {
        navigate(`/gorevlendirme/gorev-bilgi/${id}`);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };


  const handleSessionLogout = async (session_key) => {
    try {
      const response = await axios.post(`${window.BASE_URL}/api/logout-session`, { session_key }, { withCredentials: true });
      if (response.status === 200) {
        setSessions(prevSessions => prevSessions.filter(session => session.session_key !== session_key));
        toast.success('Session çıkışı yapıldı');
      }
    } catch (error) {
      console.error("Error logging out session:", error);
      Swal.fire('Hata', 'Session çıkışı yapılamadı', 'error');
    }
  };
  



  const handlePasswordSubmit = async () => {
    console.log(formData)
    const finalFormData = {
      current_password: formData.current_password,
      new_password: formData.new_password,
      confirm_password: formData.confirm_password,
    };
  
    if (finalFormData.new_password !== finalFormData.confirm_password) {
      Swal.fire('Hata', 'Yeni şifreler eşleşmiyor', 'error');
      return;
    }
  
    try {
      const response = await axios.post(`${window.BASE_URL}/api/change-password`, finalFormData, { withCredentials: true });
      if (response.status === 200) {
        Swal.fire('Başarılı', 'Şifre başarıyla kaydedildi', 'success');
      }
    } catch (error) {
      console.error("Error saving password:", error);
      Swal.fire('Hata', 'Şifre kaydedilemedi', 'error');
    }
  };
  
  // Şifre onaylama switch fonksiyonu
  const [isConfirmed, setIsConfirmed] = useState(false);
  
  const handleConfirmSwitch = () => {
    setIsConfirmed(!isConfirmed);
  };





  return (
    <Grid>
      {/* Profile Card */}

      <Card id="profile" style={{ marginTop: "-24px" }}>
        <SoftBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src="-"
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"

                icon="settings"
                bgColor={"info"}
              />
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {"Hesap Ayarları"}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {"Kullanıcı Hesap Bilgileri"}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      {/* Görev Bilgileri Card */}
      <Card id="giris-bilgi" sx={{ overflow: "visible" }} style={{ marginTop: "24px" }}>
        <SoftBox p={3}>
          <SoftTypography variant="h5">Giriş Bilgileri</SoftTypography>
        </SoftBox>
        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField name="konu" label="Kullanıcı Adı" placeholder="Kullanıcı Adı" value={formData.konu} onChange={handleInputChange} disabled />
            </Grid>
            <Grid item xs={0} sm={6}></Grid>

            <Grid item xs={12} sm={6}>
              <FormField name="current_password" label="Güncel Şifre" placeholder="Güncel Şifre" value={formData.current_password} onChange={handleInputChange} type="password" />
            </Grid>
            <Grid item xs={0} sm={6}></Grid>

            <Grid item xs={12} sm={6}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <FormField name="new_password" label="Yeni Şifre" placeholder="Yeni Şifre" value={formData.new_password} onChange={handleInputChange} type="password" />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <FormField name="confirm_password" label="Şifre Onay" placeholder="Yeni Şifre" value={formData.confirm_password} onChange={handleInputChange} type="password" />
              </SoftBox>
            </Grid>
          </Grid>

          <SoftBox pb={3} pt={5} px={3} display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
              <Switch checked={isConfirmed} onChange={handleConfirmSwitch} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography display="block" variant="button" fontWeight="medium">
                  Onayla
                </SoftTypography>
                <SoftTypography variant="caption" color="text">
                  Yapılan değişiklikler adınıza kaydedilecektir
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  sx={{ height: "100%" }}
                  onClick={isConfirmed ? handlePasswordSubmit : () => Swal.fire('Hata', 'Lütfen değişiklikleri onaylayın', 'error')}
                >
                  Kaydet
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>


      <Card id="2fa" style={{ marginTop: "24px", marginBottom: "24px" }}>
        <SoftBox p={3} lineHeight={1}>
          <SoftBox mb={1}>
            <SoftTypography variant="h5">Doğrulama</SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox pt={2} pb={5} px={3}>

          <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftAvatar src="-" alt="Slack logo" variant="rounded" icon="lock" bgColor={"info"} />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="medium">
                  2 Adımlı Doğrulama
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Mail Doğrumala Kodu
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
              <SoftBox lineHeight={0} mx={2}>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {formData.konaklama ? "Açık" : "Açık"}
                </SoftTypography>
              </SoftBox>
              <SoftBox mr={1}>
                <Switch name="konaklama" checked={true} onChange={() => handleBooleanMasrafChange('konaklama')} disabled />
              </SoftBox>
            </SoftBox>
          </SoftBox>


          <SoftBox pt={5} px={3} display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
            <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
              <Switch disabled />
              <SoftBox ml={2} lineHeight={0}>
                <SoftTypography display="block" variant="button" fontWeight="medium">
                  Onayla
                </SoftTypography>
                <SoftTypography variant="caption" color="text">
                  Özel Yetki Gerekmektedir
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={handleSubmit}>
                  Kaydet
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>


        </SoftBox>
      </Card>

      {/* Ulaşım Card */}
      <Card id="sessions" style={{ marginTop: "24px" }}>
  <SoftBox p={3} lineHeight={1}>
    <SoftBox mb={1}>
      <SoftTypography variant="h5">Sessionlar</SoftTypography>
    </SoftBox>
  </SoftBox>
  <SoftBox pt={2} pb={3} px={3}>
    {yolculuklar?.map((session) => (
      <div key={session.session_key}>
        <SoftBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox display="flex" alignItems="center">
            <SoftAvatar src="-" alt="logo" variant="rounded" icon="devices" bgColor="dark" />
            <SoftBox ml={2}>
              <SoftTypography variant="h5" fontWeight="medium">
                {session.device + " "}
              </SoftTypography>
              <SoftBox display="flex" alignItems="flex-end">
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  {session.operatingSystem}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", sm: "auto" }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton variant="gradient" color="error" size="small" onClick={() => handleSessionLogout(session.session_key)}>
              Çıkış
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {"IP Adresi: " + session.ip}
          </SoftTypography>
          <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={1} py={2} pl={{ xs: 1, sm: 2 }} pr={1}>
            <SoftTypography variant="button" fontWeight="medium" color="text">
              Tarayıcı: {session.browser}
            </SoftTypography>
          </SoftBox>
          <SoftBox bgColor="grey-100" borderRadius="lg" display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }} my={1} py={2} pl={{ xs: 1, sm: 2 }} pr={1}>
            <SoftTypography variant="button" fontWeight="medium" color="text">
              Session Key: {session.session_key}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <Divider />
      </div>
    ))}
  </SoftBox>
</Card>




    </Grid>
  );
}

export default BasicInfo;
