

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";


// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
//import routes from "routes";
import { getFilteredRoutes } from './routes';

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

//import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { store, persistor } from './store';
import { setIsLoggedIn, setUserId, setUserRole, setUsername, setUserKanal, setUserPhoto } from 'store/actions/authActions';
// Images
import brand from "assets/images/TRT_blue_logo.png";
import ReactLoading from "react-loading";
import SoftBadge from "components/SoftBadge";



export default function App() {


  const noScrollbarStyle = {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  useEffect(() => {
    // Mevcut scroll bar stilini saklayın
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Scroll bar'ı gizleyin
    //document.body.style.overflow = 'hidden';

    // Chrome, Safari ve diğer WebKit tabanlı tarayıcılar için
    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerText = ' div::-webkit-scrollbar { width: 0px; }';
    document.head.appendChild(styleSheet);

    // Temizleme fonksiyonu
    return () => {
      document.body.style.overflow = originalStyle;
      document.head.removeChild(styleSheet);
    };
  }, []);
  

  const { id } = useParams();
  const navigate = useNavigate();



  const [data, setData] = useState([]);


  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //const isAuthenticated = store.getState().auth.isLoggedIn;

  const userId = useSelector(state => state.auth.userId);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const dispatchRedux = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/api/check_user_logged_in?format=json`, { withCredentials: true });
        setIsAuthenticated(true);
        // console.log("SUCCESS");
         console.log(response.data);
        setIsLoading(false);

        
      
        store.dispatch(setUsername(response.data.name))
        store.dispatch(setUserRole(response.data.role))
        store.dispatch(setUserKanal(response.data.kanal_choice))
        store.dispatch(setUserPhoto(response.data.photo))
        store.dispatch(setUserId(response.data.id))
        store.dispatch(setIsLoggedIn(true))
        console.log("kanal-")
        console.log(response.data.kanal_choice)

      } catch (error) {
        // console.log("SUCCESS");
        // console.log(error);
        setIsLoading(false);

      }
    };

    fetchData();
  }, []);



  //const isLoggedIn = useSelector(state => state.auth.isLoggedIn);  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/permissions?format=json`, { withCredentials: true });
        setData(response.data);
        console.log(response);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  let userPermissions = {

    //Kurum
    PageKurum: true,
  
    AccGraphs: false,
    AccOrgchar: false,
  
    //IK
    PageIK: true,
  
    AccTables: true,
    AccIsealim: true,
    AccPerformans: true,
    AccBilgiNot: true,
    AccIntibak: true,
  
    //MALI
    PageMali: true,
  
    AccKonuk: true,
    AccFreelance: true,
    AccStoryHunter: true,
  
    //HUKUKI
    PageHukuki: true,
  
    AccMahkeme: true,
    AccDisiplins: true,
    AccSozlesme: true,
    AccGorusler: true,
  
    //KOORDINASYON
    PageKoordinasyon: true,
  
    AccIzinler: true,
    AccRota: true,
    AccServis: true,
  
    //STRATEJI
    PageStrateji: true,
  
    AccMuhabir: true,
    AccYayin: true,
    AccKpi: true,
    AccEgitim: true,
  
    //POLITIKALAR
    PagePolitikalar: true,
  
    AccMevzuat: true,
    AccKisaltmalar: true,
    AccIstanimi: true,
    AccSozluk: true,
    AccEditoryel: true,
  
  };
  //console.log("PERMİSSOSN UDAPTE")
  //console.log(data);
  //console.log(userPermissions);
  userPermissions = data;


  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();


  const [showSubRoutes, setShowSubRoutes] = useState(false);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  //console.log(allRoutes);
  const getRoutes = (allRoutes) => {
    const routesArray = [];

    allRoutes.forEach(route => {
      if (route.children) {
        routesArray.push(...getRoutes(route.children));
      }

      if (route.route) {
        routesArray.push(
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }
    });

    return routesArray;
  };
  if (!isLoading) {
    if (!isAuthenticated && !location.pathname.startsWith('/authentication')) {
      //console.log(isLoggedIn, isAuthenticated);
      console.log("LOGIN FIRST")
      return <Navigate to="/authentication/sign-in"  />;
    }
    else if (isAuthenticated && location.pathname.startsWith('/authentication')) {
      console.log("ALREADY LOGGED IN")
      return <Navigate to="/kurum/anasayfa" />;
    }
    else{
      console.log("IMPOSSIBLE" +  isAuthenticated + location.pathname)
    }
  }
  else {
    return <div style={{ background: "#f8f9fa", textAlign: "center", width: "100%", height: "100%", position: "fixed", display:"flex", justifyContent:"center", alignItems:"center" }}>
      <ReactLoading type="bubbles" color="#1398e6"
                height={100} width={50} />
    </div>;
  }

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return direction === "rtl" ? (
    
    <CacheProvider value={rtlCache}>
                  <style>
                {`
                @media print {
                    #printButton {
                        display: none;
                    }
                }
                `}
            </style>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        <div style={noScrollbarStyle}>
      
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName=" Yönetim -"
                routes={getFilteredRoutes(userPermissions)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator />
              {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(getFilteredRoutes(userPermissions))}
            <Route path="*" element={<Navigate to="/kurum/anasayfa" />} />
          </Routes>
        </div>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
                  <style>
                {`
                @media print {
                    #printButton {
                        display: none;
                    }
                }
                `}
            </style>
      <CssBaseline />
      <div style={noScrollbarStyle}>
  
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName=" Yönetim +"
              routes={getFilteredRoutes(userPermissions)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes data={data} >
          {getRoutes(getFilteredRoutes(userPermissions))}
          {/* <Route path="*" element={<Navigate to="/kurum/anasayfa" />} /> */}

        </Routes>
      </div>
    </ThemeProvider>
  );

}
