/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
    maxHeight: '90vh',
    maxWidth: '150vw',
    overflowY: 'auto',
};

const useStyles = makeStyles({
    stepper: {
        fontSize: '10px !important',
    },
    stepLabel: {
        fontSize: '10px !important',
    },
});

const CustomStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        fontSize: '14px !important',
    },
}));

function createItems(title, text, file) {
    let items = [];



    function onDocumentLoadFail(error) {
        console.error("PDF yüklenirken bir hata oluştu: ", error);
    }

    const filePath = `${window.BASE_URL}/${file}`;
    const encodedFilePath = encodeURIComponent(filePath);


    const handleLinkClick = (url) => {
        window.open(url, '_blank');
    };



    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function goToPrevPage() {
        setPageNumber(prevPageNumber => prevPageNumber > 1 ? prevPageNumber - 1 : 1);
    }

    function goToNextPage() {
        setPageNumber(prevPageNumber => prevPageNumber < numPages ? prevPageNumber + 1 : numPages);
    }

    if (title) {
        items.push(
            <SoftBox pt={3} px={3} key={title}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong>  <br></br>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                </SoftTypography>

                <div style={{ width: '100%', marginLeft: "10px",boxShadow: 'inset 0rem 0rem 0.0625rem 0.0625rem rgba(255, 255, 255, 0.9),0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)', marginTop:"30px", marginBottom:"30px" }}>
                    <Document style={{ width: '100%' }}
                        file={filePath}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} renderTextLayer={false} />
                    </Document>

                    <div style={{ width: '100%', padding: "30px", paddingTop: "0px" }}>

                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px" >
                            Sayfa {pageNumber} / {numPages}
                        </SoftTypography>

                        <SoftButton onClick={goToPrevPage} sx={{ ml: 2 }} >
                            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                arrow_left
                            </Icon> ÖNCEKİ SAYFA
                        </SoftButton>

                        <SoftButton onClick={goToNextPage} sx={{ ml: 2 }} >
                            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                arrow_right
                            </Icon> SONRAKİ SAYFA
                        </SoftButton>

                        <SoftButton onClick={() => handleLinkClick(`${filePath}`)} sx={{ ml: 2 }} >
                            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                get_app
                            </Icon>  İNDİR
                        </SoftButton>

                    </div>
                </div>


            </SoftBox>
        );
    }

    return items;
}


export default function BasicModal({ open, handleClose, content }) {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {createItems("Evrak Önizleme", content.title, content.pdf_file)}
                    <SoftBadge variant="gradient" badgeContent={content.durum} color={content.durum === "acik" ? "info" : "error"} size="md" style={{ maxHeight: "50px" }} />
                </div>

            </SoftBox>
        </Modal>
    );
}
