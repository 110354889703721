import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";



import TumYapimlar from "layouts/mevzuatlar/tum-yapimlar";
import Uluslararasi from "layouts/mevzuatlar/uluslararasi";
import Anayasa from "layouts/mevzuatlar/anayasa";
import Yonetmelikler from "layouts/mevzuatlar/yonetmelikler";
import Yonergeler from "layouts/mevzuatlar/yonergeler";
import UsulEsas from "layouts/mevzuatlar/usul-esas";
import Talimatlar from "layouts/mevzuatlar/talimatlar";


import MevzuatCardAll from "examples/MevzuatCards/MevzuatCardAll";

// Overview page components
import Header from "layouts/mevzuatlar/components/Header";


import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';


function Overview() {
    const [tabIndex, setTabIndex] = useState(0);

    const onTabChange = (index) => {
        setTabIndex(index);
    }
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/mevzuat?format=json`);
                setData(response.data);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, [id, navigate]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Header onChange={e => onTabChange(e)} data={data} />
            <SoftBox mt={5} mb={3}>
                <Grid container spacing={3}>

                    {tabIndex === 0 && (
                        <>
                            <TumYapimlar data={data} />


                        </>
                    )}
                    {tabIndex === 1 && (
                        <>
                            <Uluslararasi data={data} />

                        </>
                    )}
                    {tabIndex === 2 && (
                        <>
                            <Anayasa data={data} />

                        </>
                    )}

                    {tabIndex === 3 && (
                        <>
                            <Yonetmelikler data={data} />

                        </>
                    )}


                    {tabIndex === 4 && (
                        <>
                            <Yonergeler data={data} />

                        </>
                    )}

                    {tabIndex === 5 && (
                        <>
                            <UsulEsas data={data} />

                        </>
                    )}

                    {tabIndex === 6 && (
                        <>
                            <Talimatlar data={data} />

                        </>
                    )}







                </Grid>
            </SoftBox>


            <Footer />
        </DashboardLayout>
    );
}

export default Overview;
