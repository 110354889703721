/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/konuk-odemeleri?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ image, name }) {
      return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };



    const tableRows = data.map(item => {
      return {
          "Konuk": [
              <img key="logoNewspaper" src="" alt="Logo" />,
              <span key="task1">{item.konugun_adi}</span>
          ],
          "TC No": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.tc_no}</SoftTypography>,
          "Yayın Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.yayin_tarihi}</SoftTypography>,
          "Haberin Konusu": <SoftBadge variant="gradient" badgeContent={item.haberin_konusu} color="info" size="xs" />,
          "Saat": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.saat}</SoftTypography>,
          "Görüntülü": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.goruntulu}</SoftTypography>,
          "Ücret (Brüt)": <SoftBadge variant="gradient" badgeContent={`${item.ucret_brut} ${item.ucret_doviz}`} color="success" size="xs" />,
          //"Ücret (Döviz)": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.ucret_doviz}</SoftTypography>,
          "IBAN No": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.iban_no}</SoftTypography>,
          "SWIFT Kodu": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.swift_kodu}</SoftTypography>,
          "Banka İsmi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.banka_ismi}</SoftTypography>,
          "Banka Ülke": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.banka_ulke}</SoftTypography>,
          "Fatura Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.fatura_tarihi}</SoftTypography>,
          "Fatura Numarası": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.fatura_numarasi}</SoftTypography>,
          "Gider Pusulası Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.gider_pusulasi_tarihi}</SoftTypography>,
          "Gider Pusulası": <>
              <Icon sx={{ fontWeight: "bold", color: "success"}}> get_app </Icon>
              <SoftTypography variant="caption" color="text" fontWeight="medium">
                  <Link href={`${item.gider_pusulasi}`} download>
                      {item.gider_pusulasi}
                  </Link>
              </SoftTypography>
          </>,
          "İmzalı Sözleşme Evrakları Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.imzali_sozlesme_evraklari_tarihi}</SoftTypography>,
          "İmzalı Sözleşme Evrakları": <>
              <Icon sx={{ fontWeight: "bold", color: "success"}}> get_app </Icon>
              <SoftTypography variant="caption" color="text" fontWeight="medium">
                  <Link href={`${item.imzali_sozlesme_evraklari}`} download>
                      {item.imzali_sozlesme_evraklari}
                  </Link>
              </SoftTypography>
          </>,
          "Ödeme Gerçekleştiren Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.odeme_gerceklestiren_tarihi}</SoftTypography>
    
        };
  });
  
  const projectsTableData = {
    columns: [
      { name: "Konuk", align: "left" },
      { name: "TC No", align: "center" },

      { name: "Yayın Tarihi", align: "center" },
      { name: "Haberin Konusu", align: "center" },
      { name: "Saat", align: "center" },
      { name: "Görüntülü", align: "center" },
      { name: "Ücret (Brüt)", align: "center" },
      //{ name: "Ücret (Döviz)", align: "center" },
      { name: "IBAN No", align: "center" },
      { name: "SWIFT Kodu", align: "center" },
      { name: "Banka İsmi", align: "center" },
      { name: "Banka Ülke", align: "center" },
      { name: "Fatura Tarihi", align: "center" },
      { name: "Fatura Numarası", align: "center" },
      { name: "Gider Pusulası Tarihi", align: "center" },
      { name: "Gider Pusulası", align: "center" },
      { name: "İmzalı Sözleşme Evrakları Tarihi", align: "center" },
      { name: "İmzalı Sözleşme Evrakları", align: "center" },
      { name: "Ödeme Gerçekleştiren Tarihi", align: "center" }
    ],
      rows: tableRows
  };
  










    return (
      <DashboardLayout>
        < DashboardNavbar/>
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Konuk Ödemeleri Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>

        </Grid>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
