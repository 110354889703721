import React, { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";

/* eslint-disable react/prop-types */

const Target = ({ name, email, tel, variant, color = "light" }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ color: "white !important" }}>
                    <SoftBox mr={2}>
                        <Icon sx={{ fontWeight: "bold", fontSize: "16px !important" }}>
                            payments
                        </Icon>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color={color} size="xl" container fullWidth />
        </a>
    );
};

const Total = ({ name, email, tel, variant, color = "light" }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ color: "white !important" }}>
                    <SoftBox mr={2}>
                        <Icon sx={{ fontWeight: "bold", fontSize: "16px !important", color: "white !important" }}>
                            payments
                        </Icon>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography sx={{ color: "white !important" }} variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color={color} size="sm" container fullWidth />
        </a>
    );
};

const Translate = ({ name, tel, variant }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "120px" }}>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a>
    );
};

const Butce = ({ name, tel, variant }) => {
    return (
        <a>
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "200px" }}>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {tel}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {""}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a>
    );
};

function createItems(title, text) {
    return title ? (
        <SoftBox pt={3} px={3}>
            <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                </Icon>
                <strong>{title} </strong> <br />
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px">
                    {text}
                </SoftTypography>
            </SoftTypography>
        </SoftBox>
    ) : null;
}

const openVideo = (url) => {
    if (url) {
        window.open(url, '_blank');
    } else {
        console.error('Geçersiz URL');
    }
};

const index = (data) => {
    const [showDetails, setShowDetails] = useState({});
    const [allDetailsVisible, setAllDetailsVisible] = useState(false);

    const toggleShowDetails = (id) => {
        setShowDetails(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const toggleAllDetails = () => {
        setAllDetailsVisible(!allDetailsVisible);
        const newShowDetails = {};
        data?.data?.forEach(dataItem => {
            newShowDetails[dataItem.id] = !allDetailsVisible;
        });
        setShowDetails(newShowDetails);
    };

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    try {
        let total_butce = 0;
        let categoryTotals = {
            'Haber Bültenleri': 0,
            'Belgeseller': 0,
            'Programlar': 0,
            'Dış Yapımlar': 0
        };
        
        const categoryMapping = {
            'haber_bulteni': 'Haber Bültenleri',
            'belgeseller': 'Belgeseller',
            'ic_yapimlar': 'Programlar',
            'commissioning': 'Dış Yapımlar',
            'reversioning': 'Programlar',
            'promo': 'Programlar',
            'advertising': 'Programlar',
        };
        
        data?.data?.forEach(dataItem => {
            total_butce += dataItem.toplam_butce;
        
            const category = categoryMapping[dataItem.kategori];
        
            if (category in categoryTotals) {
                categoryTotals[category] += dataItem.toplam_butce;
            }
        });

        const complexReportsDoughnutChartData = {
            images: ["", "", "", ""],
            labels: ["Haber Bültenleri", "Belgeseller", "Programlar", "Dış Yapımlar"],
            datasets: {
                label: "Referrals",
                backgroundColors: ["primary", "info", "warning", "success", "dark"],
                data: [
                    Math.round(categoryTotals["Haber Bültenleri"] / total_butce * 100),
                    Math.round(categoryTotals["Belgeseller"] / total_butce * 100),
                    Math.round(categoryTotals["Programlar"] / total_butce * 100),
                    Math.round(categoryTotals["Dış Yapımlar"] / total_butce * 100)
                ],
            },
        };

        let terfiRows = data?.data?.map(dataItem => (
            <>
                <br />
                <SoftBox pt={3} pb={showDetails[dataItem.id] ? 2 : 0} px={2} style={{backgroundColor: "#f8f9fa", borderRadius: "1rem"}}>
                    <SoftBox px={-3} onClick={() => toggleShowDetails(dataItem.id)} style={{ cursor: "pointer" }}>
                        <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                            <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                label_important
                            </Icon>
                            <strong>{dataItem.program_adi} </strong><br />
                            <SoftBadge variant="gradient" badgeContent={dataItem.toplam_butce + " TRY"} color="info" size="xs" container />
                            <SoftTypography variant="caption" color="secondary" fontWeight="medium"> <strong> %{( dataItem.toplam_butce /  total_butce *100).toFixed(1)} </strong> Bütçe Oranı </SoftTypography>
                        </SoftTypography>

                        <div>
                            <Translate key={"i"} name={"USD Kur Bilgisi"} tel={data?.data?.[0]?.doviz_kuru_usd + " TRY"} variant="">
                                ({data?.data?.[0]?.doviz_kuru_usd + " TRY"})
                            </Translate>

                            <Translate key={"i"} name={"EUR Kur Bilgisi"} tel={data?.data?.[0]?.doviz_kuru_eur + " TRY"} variant="">
                                ({data?.data?.[0]?.doviz_kuru_eur + " TRY"})
                            </Translate>
                        </div>
                    </SoftBox>

                    {showDetails[dataItem.id] && dataItem?.odemeler.map(item => (
                        <TimelineItem
                            key={item.id}
                            color={item.bolum_link ? "info" : "info"}
                            icon={"sell"}
                            description={
                                <>
                                    {item.notu ? <>{item.notu} <br /><br /></> : ""}
                                    <Target name={item.doviz_try + " TRY"} />
                                    {item.odemeler_detay.map(item2 => (
                                        <TimelineItem
                                            key={item2.id}
                                            color={"dark"}
                                            icon={"subdirectory_arrow_right"}
                                            description={`TRY: ${item2.doviz_try}`}
                                            title={capitalizeFirstLetter(item2.alt_baslik)}
                                            dateTime={`Tutar: ${item2.tutar} ${item2.para_birimi}`}
                                        />
                                    ))}
                                </>
                            }
                            title={capitalizeFirstLetter(item.kategori)}
                            dateTime={`Tutar: ${item.tutar} ${item.para_birimi}`}
                            onClick={() => openVideo(item.bolum_link)}
                        />
                    ))}
                </SoftBox>
            </>
        ));

        return (
            <>
                <Grid item xs={12} md={8} xl={8}>
                    <Card className="h-100">
                        <SoftBox py={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Tüm Yapımlar Bütçesi
                            </SoftTypography>

                            <ComplexReportsDoughnutChart
                                chart={complexReportsDoughnutChartData}
                            />

                            {createItems("Bütçe Detay",
                                <>
                                    <Butce key={"i"} name={"Haber Bülteni Bütçe"} tel={categoryTotals["Haber Bültenleri"] + " TRY"} variant="">
                                        ({data?.data?.[0]?.doviz_kuru_usd + " TRY"})
                                    </Butce>

                                    <Butce key={"i"} name={"Belgeseller Bütçe"} tel={categoryTotals["Belgeseller"] + " TRY"} variant="">
                                        ({data?.data?.[0]?.doviz_kuru_usd + " TRY"})
                                    </Butce>
                                    <Butce key={"i"} name={"Programlar Bütçe"} tel={categoryTotals["Programlar"] + " TRY"} variant="">
                                        ({data?.data?.[0]?.doviz_kuru_usd + " TRY"})
                                    </Butce>
                                    <Butce key={"i"} name={"Dış Yapımlar Bütçe"} tel={categoryTotals["Dış Yapımlar"] + " TRY"} variant="">
                                        ({data?.data?.[0]?.doviz_kuru_usd + " TRY"})
                                    </Butce>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} xl={4}>
                    <Card className="h-100">
                        <SoftBox pt={3} px={3}>
                            <div style={{ display: "flex", justifyContent: "space-between"}}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Ödemeler
                            </SoftTypography>
                            <Icon
                                sx={{ fontSize: "1.5rem", cursor: "pointer", ml: 2 }}
                                onClick={toggleAllDetails}
                            >
                                {allDetailsVisible ? "visibility_off" : "visibility"}
                            </Icon>
                            </div>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                    </SoftTypography>
                                    &nbsp;
                                    <SoftTypography variant="button" color="text" fontWeight="medium">
                                        {terfiRows?.length}
                                    </SoftTypography>{" "}
                                    Yapılan Ödeme
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox p={2}>
                            <Total name={"TOPLAM " + total_butce + " TRY"} color="dark" />
                            <br />
                            {terfiRows}
                        </SoftBox>
                    </Card>
                </Grid>
            </>
        );
    } catch (error) {
        console.log(error);
        return (
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h2" fontWeight="medium">
                        Yetkilendirilmemiş Giriş
                    </SoftTypography>
                </SoftBox>
            </Grid>
        );
    }
}

export default index;
