/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/yapayzeka/Header";

import TableSkor from "layouts/yapayzeka/Category/Skor"
import TableCalisan from "layouts/yapayzeka/Category/Calisan"
import TableUnvan from "layouts/yapayzeka/Category/Unvan"


function Tables() {

  const { id } = useParams();


  


  try {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    const onTabChange = (index) => {
      setTabIndex(index);
    }

    const [data, setData] = useState([]);

    return (
      <DashboardLayout>
        < DashboardNavbar />
        <Header onChange={e => onTabChange(e)} data={data} />
        <SoftBox mt={5} mb={3}>
          <Grid container spacing={3}>
            

          {tabIndex === 0 && (
            <>
              <TableSkor />
              
              
            </>
          )}
          {tabIndex === 1 && (
            <>
              <TableCalisan />
              
              
            </>
          )}
          {tabIndex === 2 && (
            <>
              <TableUnvan  />
              
            </>
          )}


          </Grid>
        </SoftBox>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
