/* eslint-disable react/prop-types */
import Header from "layouts/puanlama-form/Header";
import axios from "axios";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import FormField from "layouts/puanlama-form/FormField";

import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import MenuItem from "@mui/material/MenuItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { useSelector, useDispatch } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ListItemText } from "@mui/material";

import { PieChart } from '@mui/x-charts/PieChart';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

const theme = createTheme({
    typography: {
        color: "#aaa",
        fontFamily: ["Roboto", "Helvetica", "Arial", 'sans-serif'].join(','),
    },
});

function createTimelineItems(branch, mudurluk) {
    let items = [];

    if (branch.Sorumlu_kisi) {
        items.push(
            <TimelineItem
                key={branch.id}
                color="info"
                icon="subdirectory_arrow_right"
                title={(branch.Yetkili_birim_adi === "-" ? mudurluk : branch.Yetkili_birim_adi).toUpperCase()}
                dateTime={branch.Sorumlu_kisi ? branch.Sorumlu_kisi.name + " " + branch.Sorumlu_kisi.surname : ''}
            />
        );
    }

    if (branch.ust_birim) {
        items = items.concat(createTimelineItems(branch.ust_birim, mudurluk));
    }

    return items;
}

function Target({ id, image, name, email, tel, color, dataPer, avarages }) {
    return (
        <a href={`/profile/${id}`} style={{ width: "100%" }}>
            <SoftBadge variant="contained" badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "1000px" }} flexWrap="wrap" justifyContent="space-around">
                    <SoftBox mr={2}>
                        <SoftAvatar src={image} size="xl" variant="rounded" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>

                    {/* <SoftBox>
                        <SoftBox display="flex" alignItems="center" gap="5px" py={1}>
                            <SoftBox
                                width="1.5rem"
                                height="1.5rem"
                                borderRadius=".45rem"
                                sx={(theme) => menuImage(theme, { color: "info" })}
                            >
                                <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                    star
                                </Icon>
                            </SoftBox>
                            <SoftTypography variant="button" textTransform="capitalize" fontWeight="small">
                                <strong>{"Değerler"}</strong> {(avarages?.["GENEL"] ?? 0) + "/10"}
                            </SoftTypography>
                        </SoftBox>
                        {dataPer?.PERFORMANS ? (
                            <SoftBox display="flex" alignItems="center" gap="5px" py={1}>
                                <SoftBox
                                    width="1.5rem"
                                    height="1.5rem"
                                    borderRadius=".45rem"
                                    sx={(theme) => menuImage(theme, { color: "info" })}
                                >
                                    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                        star
                                    </Icon>
                                </SoftBox>
                                <SoftTypography variant="button" textTransform="capitalize" fontWeight="small">
                                    <strong>{"Performans"}</strong> {(avarages?.["PERFORMANS"] ?? 0) + "/10"}
                                </SoftTypography>
                            </SoftBox>
                        ) : <></>}
                    </SoftBox> */}

                    <SoftBox display="flex" alignItems="center" gap="5px" py={1}>
                        <SoftBox
                            width="1.5rem"
                            height="1.5rem"
                            borderRadius=".45rem"
                            sx={(theme) => menuImage(theme, { color: "dark" })}
                        >
                            <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                star
                            </Icon>
                        </SoftBox>
                        <SoftTypography variant="button" textTransform="capitalize" fontWeight="small">
                            <strong>{"Toplam Puan "}</strong>
                            {(() => {
                                const genel = avarages?.["GENEL"];
                                const performans = avarages?.["PERFORMANS"];
                                const toplam = (genel !== undefined && performans !== undefined)
                                    ? ((genel + performans) / 2).toFixed(2)
                                    : (genel !== undefined ? genel : (performans !== undefined ? performans : 0));
                                return toplam + "/10";
                            })()}
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>
            } color={"secondary"} style={{ width: "100%" }} />
        </a>
    );
}

const Index = () => {
    const navigate = useNavigate();
    const { id, view } = useParams();
    const [languageCode, setLanguageCode] = useState("TR");
    const [languageOptions, setLanguageOptions] = useState([]);
    

    const handleLanguageChange = (selectedOption) => {
        setLanguageCode(selectedOption.value);
    };

    const userId = useSelector(state => state.auth.userId);
    const username = useSelector(state => state.auth.username);
    const userRole = useSelector(state => state.auth.userRole);
    const userPhoto = useSelector(state => state.auth.userPhoto);

    const [data, setData] = useState({ egitimler: [], istecrubeler: [], meslekegitimler: [], egitimdiller: [], branchler: [], terfiler: [] });
    const [dataPer, setDataPer] = useState([]);
    const [categoryScores, setCategoryScores] = useState({});
    const [categoryAverages, setCategoryAverages] = useState({});

    const [izindata, setIzinData] = useState({ izinler: [], yillik_toplam: {} }); // izinler özelliği ile başlat

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/izinler/${id}`, { withCredentials: true });
                setIzinData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id]);

    const [dataLang, setDataLang] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/translation/languages`, { withCredentials: true });
                setDataLang(response.data);

                // languageOptions listesini API yanıtına göre oluşturun
                const options = response.data.map(lang => ({
                    value: lang.code,
                    label: lang.name
                }));
                setLanguageOptions(options);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/user/${id}?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/notlar/sorular/${id}/${languageCode}`, { withCredentials: true });
                setDataPer(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [languageCode]);

    const handleSelectChange = (categoryName, questionId, score) => {
        setCategoryScores(prevScores => {
            const newScores = { ...prevScores };
            if (!newScores[categoryName]) {
                newScores[categoryName] = {};
            }
            newScores[categoryName][questionId] = score;

            const categoryQuestions = Object.values(newScores[categoryName]);
            const categoryAverage = categoryQuestions.reduce((sum, val) => sum + val, 0) / categoryQuestions.length;

            setCategoryAverages(prevAverages => ({
                ...prevAverages,
                [categoryName]: categoryAverage
            }));

            return newScores;
        });
    };

    const [formData, setFormData] = useState({
        aciklama: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        const genel = categoryAverages?.["GENEL"];
        const performans = categoryAverages?.["PERFORMANS"];
        const finalFormData = {
            skor: (genel !== undefined && performans !== undefined)
                ? ((genel + performans) / 2).toFixed(2)
                : (genel !== undefined ? genel : (performans !== undefined ? performans : 0)),

            hedef_user: id,
            questions: categoryScores,
            not: formData.aciklama,


        };

        try {
            const response = await axios.post(`${window.BASE_URL}/notlar/save`, finalFormData, { withCredentials: true });
            if (response.status === 200) {
                navigate(`/puanlama/puanlama-liste`);
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const genelCategories = dataPer?.GENEL ? Object.keys(dataPer?.GENEL) : [];
    const performansCategories = dataPer?.PERFORMANS ? Object.keys(dataPer?.PERFORMANS) : [];



    const QuestionsComponent = ({ questions, categoryName }) => {
        return (
            <>
                {questions.map((question, questionIndex) => (
                    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" key={question.id}>
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="medium" color="text" textTransform="capitalize">
                                {question.text}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox style={{ margin: '10px', width: '30%', paddingBottom: "20px" }}>

                            <SoftSelect
                                name={`select_${question.id}`}
                                placeholder="Puan Giriniz"
                                value={categoryScores[categoryName]?.[question.id] ? { value: categoryScores[categoryName][question.id], label: categoryScores[categoryName][question.id].toString() } : null}
                                options={[
                                    { value: "1", label: "1" },
                                    { value: "2", label: "2" },
                                    { value: "3", label: "3" },
                                    { value: "4", label: "4" },
                                    { value: "5", label: "5" },
                                    { value: "6", label: "6" },
                                    { value: "7", label: "7" },
                                    { value: "8", label: "8" },
                                    { value: "9", label: "9" },
                                    { value: "10", label: "10" }
                                ]}
                                onChange={(selectedOption) => handleSelectChange(categoryName, question.id, parseInt(selectedOption.value))}

                            />

                        </SoftBox>
                    </SoftBox>
                ))}
            </>
        );
    };


    const terfiRows1 = data.branchler.flatMap(branch => createTimelineItems(branch, data.mudurluk + "/" + data.birim));

    let terfiRows = data.terfiler.map(terfi => (
        <TimelineItem
            key={terfi.id}
            color={terfi.terfi_tenzil === "Terfi" ? "success" : "error"}
            icon={terfi.terfi_tenzil === "Terfi" ? "unarchive" : "archive"}
            description={`Eski Unvan: ${terfi.eski_rutbe}`}
            title={`${terfi.yeni_rutbe} (${terfi.ilgili_bolum})`}
            dateTime={terfi.degisiklik_tarihi}
        />
    ));

    let terfiRows2 = [];
    if (izindata && izindata.total_izinler) {
        terfiRows2 = Object.entries(izindata.total_izinler)
            .sort(([year1], [year2]) => year2 - year1) // Sorting years in descending order
            .map(([year, index]) => (
                <TimelineItem
                    key={year}
                    color="dark" // or any logic for determining color
                    icon="today" // or any logic for determining icon
                    description={[
                        <span key="line1">Yıllık İzin:<strong> {index.yillik} Gün</strong></span>,
                        <br key="br1" />,
                        <span key="line2">Sağlık Raporu:<strong> {index.rapor} Gün </strong></span>,
                        index.annelik !== "0" && <><br key="br2" /><span key="line3">*Annelik:<strong> {index.annelik} Gün </strong></span></>
                    ]}

                    title={`${year}`}
                    dateTime={``} // replace with actual data if available
                />
            ));
    }



    return (
        <DashboardLayout>


            <div style={{ position: 'absolute', zIndex: '1', right: '1%', top: '100px', marginRight: '30px' }}>
                <SoftButton id="printButton" onClick={"changeView"} sx={{ ml: 2 }} color="white">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                        visibility
                    </Icon> {dataPer?.["FORM_DATA"]?.["request_role"]}
                </SoftButton>
            </div>

            <div style={{ position: 'absolute', zIndex: '1', right: '1%', top: '150px', marginRight: '30px', maxWidth: "175px" }}>
                <SoftSelect
                    name="language_select"
                    placeholder="Dil Seçiniz"
                    value={languageOptions.find(option => option.value === languageCode)}
                    options={languageOptions}
                    onChange={handleLanguageChange}
                    style={{ paddingTop: "10px" }}
                />
            </div>



            <div
                id="divToPrint"
            >
                <div>
                    <img src="/static/media/TRT_blue_logo.7560177b32c04ebe9f8e.png" alt="logo" style={{ position: 'absolute', width: '7%', right: '10%', top: '60px', zIndex: '1' }} />
                </div>

                <Header onChange={e => onTabChange(e)} data={data} />

                <Grid container spacing={0} >
                    <Grid item xs={11.8} md={11.8} xl={11.8}>

                    </Grid>
                    <Grid item xs={12} md={4} xl={4} display={{ xs: "none", sm: "unset" }}>
                        <Card className="h-100" sx={{
                            mt: 2,
                            ml: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 10
                        }}>
                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Kurum Bilgileri
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            {/* <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                label_important
                                            </Icon> */}
                                        </SoftTypography>
                                        {/* &nbsp; */}
                                        <SoftTypography variant="button" color="text" fontWeight="medium">

                                        </SoftTypography>{" "}
                                        <strong>Sicil No:</strong> {data.sicil_no}<br />
                                        <strong>Sözleşme Türü:</strong> {data.sozlesme}<br />
                                        <strong>Sözleşme Başlangıç:</strong> {data.sozlesme_baslangic}<br />

                                        {/* <strong>Sözleşme Bitiş:</strong> {data.sozlesme_bitis}<br /> */}
                                        {/* <strong>TRT Ünvan:</strong> {data.trt_unvan}<br /> */}
                                        <strong>TRT Başlanıç:</strong> {data.trt_baslangic}<br />
                                        <strong>Çalışma Yeri:</strong> {data.calisma_yeri}<br />

                                        {/* <strong>Müdürlük:</strong> {data.mudurluk}<br />
                                        <strong>Birim:</strong> {data.birim}<br /> */}
                                        {/* <strong>Local / Expat:</strong> {data.local_expat}<br /> */}
                                        {/* <strong>Çalışma İzni Bitiş:</strong> {data.calisma_izni_bitis_tarihi}<br /> */}


                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>



                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Üst Birim Şeması
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>

                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows1}
                            </SoftBox>

                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Terfi geçmişi
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                                                arrow_upward
                                            </Icon>
                                        </SoftTypography>
                                        &nbsp;
                                        <SoftTypography variant="button" color="text" fontWeight="medium">
                                            {terfiRows.length}
                                        </SoftTypography>{" "}
                                        Terfi Güncellemesi
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows}
                            </SoftBox>



                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    İzin geçmişi
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                                                arrow_upward
                                            </Icon>
                                        </SoftTypography>
                                        &nbsp;
                                        <SoftTypography variant="button" color="text" fontWeight="medium">
                                            {terfiRows2.length}
                                        </SoftTypography>{" "}
                                        İzin Güncellemesi
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>
                                {terfiRows2}
                            </SoftBox>



                            <SoftBox p={2}>

                            </SoftBox>
                        </Card>
                    </Grid>







                    <Grid item xs={12} md={8} xl={8}>
                        <Card className="h-100" sx={{
                            mt: 2,
                            mx: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 0
                        }}>
                            <SoftBox pt={3} px={3}>

                                <SoftTypography variant="h5" fontWeight="medium">
                                    Personel Değerlendirme Formu
                                </SoftTypography>
                                <SoftBox mt={0} mb={0} px={0} >

                                </SoftBox>

                            </SoftBox>
                            <SoftBox p={2} pb={0}>

                                <Grid item xs={12} sm={12} p={3} py={0}>
                                    {genelCategories.map((category, categoryIndex) => {
                                        const questions = dataPer?.GENEL[category] || [];
                                        return (
                                            <SoftBox key={categoryIndex} mb={4}>
                                                <SoftTypography variant="h6" fontWeight="medium" pb={3}>
                                                    <Icon sx={{ fontWeight: 'bold', color: 'error' }}>
                                                        label_important
                                                    </Icon>
                                                    {category}
                                                </SoftTypography>
                                                <QuestionsComponent questions={questions} categoryName="GENEL" avarages={categoryAverages} />
                                            </SoftBox>
                                        );
                                    })}
                                </Grid>

                            </SoftBox>



                            {/* <Divider /> */}



                            {dataPer?.PERFORMANS ? <>
                                <SoftBox p={2}>

                                    <Grid item xs={12} sm={12} p={3} py={0} >
                                        {performansCategories.map((category, categoryIndex) => {
                                            const questions = dataPer?.PERFORMANS[category] || [];
                                            return (
                                                <SoftBox key={categoryIndex} mb={4}>
                                                    <SoftTypography variant="h6" fontWeight="medium" pb={3}>
                                                        <Icon sx={{ fontWeight: 'bold', color: 'error' }}>
                                                            label_important
                                                        </Icon>
                                                        {category}
                                                    </SoftTypography>
                                                    <QuestionsComponent questions={questions} categoryName="PERFORMANS" avarages={categoryAverages} />
                                                </SoftBox>
                                            );
                                        })}
                                    </Grid>

                                    <Divider />
                                </SoftBox></> : <></>}

                            <SoftBox component="form" pb={3} px={4}>
                                <FormField name="aciklama" label="Personel Hakkında Not" placeholder="Notunuzu Yazın" value={formData.aciklama} onChange={handleInputChange} multiline rows={5} />
                            </SoftBox>
                            <SoftBox p={3}>

                                <SoftTypography variant="h5" fontWeight="medium" pb={-3}>
                                    Formu Bitir
                                </SoftTypography >
                                <SoftBox mt={0} mb={0} px={0}  >
                                    <SoftTypography component="label" variant="caption" fontWeight="medium" color="text" pb={3} > Yaptığınız Değerlendirmelerin Sonucu aşağıda verilmiştir formu göndermek için devam edin </SoftTypography>
                                </SoftBox>

                            </SoftBox>

                            <SoftBox mx={3}>

                                <Target id={data.id} image={window.BASE_URL + "/" + data.personel_fotograf} name={data.name + " " + data.surname} email={data.trt_unvan} tel="Değerlendirilen Kişi" color="light" dataPer={dataPer} avarages={categoryAverages}  ></Target>
                            </SoftBox>







                            <SoftBox pb={3} pt={2} px={3} display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                                <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
                                    <Switch />
                                    <SoftBox ml={2} lineHeight={0}>
                                        <SoftTypography display="block" variant="button" fontWeight="medium">
                                            Onayla
                                        </SoftTypography>
                                        <SoftTypography variant="caption" color="text">
                                            Yapılan değişiklikler {userRole + " " + username} ({dataPer?.["FORM_DATA"]?.["request_role"]}) adına kaydedilecektir.
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                                    <SoftButton variant="outlined" color="secondary">
                                        Kaydetme
                                    </SoftButton>
                                    <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                        <SoftButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={handleSubmit}>
                                            Kaydet
                                        </SoftButton>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>




                        </Card>
                    </Grid>



                </Grid>

            </div>
        </DashboardLayout>

    )
}

export default Index;