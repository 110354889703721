/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import TransparentInfoCard from "examples/Cards/InfoCards/TransparentInfoCard";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

const index = ({ data, dataKurum }) => {

    try {

        function Author({ image, name }) {
            return (
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox mr={2}>
                        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            );
        }


        const truncatedTextStyle = {
            width: '300px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            height: '20px',
            WebkitBoxOrient: 'vertical'
        };



        const { size } = typography;

        let tableRows = data?.pozisyon.map(index => {
            return {
                "Müdürlük": [
                    <img key="logoNewspaper" src="" alt="Logo" />,
                    <span key="task1" > {index.mudurluk} </span>
                ],
                "İhtiyaç Sayısı": <SoftBadge variant="gradient" badgeContent={index.ihtiyac_sayisi} color="success" size="xs" container />,
                "Pozisyon": <SoftBadge variant="gradient" badgeContent={index.pozisyon} color="default" size="xs" container />,
                "Uyruk": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.uyruk}</SoftTypography>,
                "Statü": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.statu}</SoftTypography>,
                "Müdürlük": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.mudurluk}</SoftTypography>,
                "Birim": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.birim}</SoftTypography>,
                "Cinsiyet": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cinsiyet}</SoftTypography>,
                "Notlar": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.notlar}</SoftTypography>,


            };
        });


        const projectsTableData = {
            columns: [
                { name: "Müdürlük", align: "center" },
                { name: "Birim", align: "center" },
                { name: "Pozisyon", align: "center" },
                { name: "Cinsiyet", align: "center" },
                { name: "İhtiyaç Sayısı", align: "center" },
                { name: "Notlar", align: "left" },



            ],

            rows: [
                ...tableRows,

            ],
        };


        let items_mudurluk = []

        dataKurum?.mudurlukler.forEach(mudurluk => {
            if (mudurluk.isim) {  // Only add if mudurluk__isim is not null
                items_mudurluk.push(<Grid item xs={6} md={2.4}>
                    <TransparentInfoCard
                        color={ (mudurluk.total_hedef - mudurluk.count) > 0 ? "info" : "light"}
                        icon="people"
                        description={mudurluk.isim + " AÇIK"}
                        value={
                            <>
                                {mudurluk.total_hedef - mudurluk.count}
                                {/* <SoftTypography component="span" variant="button">
                                    %
                                </SoftTypography> */}
                            </>
                        }
                    />
                </Grid>);
            }
        });



        let totalHedefSum = 0;

        if (dataKurum?.mudurlukler) {
            totalHedefSum = dataKurum.mudurlukler.reduce((sum, mudurluk) => {
                return sum + (mudurluk.total_hedef || 0);
            }, 0);
        }


        return (
            <>
                <Grid item xs={12} lg={12}>
                    <SoftTypography variant="h6" fontWeight="medium" ml={2}>
                        Müdürlük Alım Hedefleri
                    </SoftTypography>
                    <SoftBox mb={2} ml={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            Kanal müdürlükler toplam hedef 
                            <SoftTypography variant="button" color="text" fontWeight="medium">
                                {" "+totalHedefSum} Personel
                            </SoftTypography>
                        </SoftTypography>
                    </SoftBox>
                    <Grid container spacing={3}>

                        {items_mudurluk}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Açık Pozisyonlar Listesi</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                        </SoftBox>
                    </Card>

                </Grid>
            </>
        );
    }
    catch (error) {
        console.log(error);
        return (
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h2" fontWeight="medium">
                        Yetkilendirilmemiş Giriş
                    </SoftTypography>
                </SoftBox>
            </Grid>
        );
    }
}

export default index;
