import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableAcord from "examples/Tables/Table";
import axios from 'axios';
import Swal from 'sweetalert2';

import BasicModal from 'layouts/disiplins/modal';


/* eslint-disable react/prop-types */




const ShowAlertContent = ({ index }) => {
  const statusColor = (status) => status ? 'dodgerblue' : 'red';

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', border: '1px solid #ccc', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9', fontSize: "14px" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
        <div>
          <strong>Dosya No:</strong> {index?.dosya_isim}
        </div>
        <div style={{ textAlign: 'right' }}>
          <span style={{
            display: 'inline-block',
            padding: '0.3em 0.6em',
            fontSize: '80%',
            fontWeight: '700',
            lineHeight: '1',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            borderRadius: '0.4rem',
            backgroundColor: '#d3d3d3',
            color: '#000',
            textTransform: 'uppercase',
          }}>
            {index?.durum}
          </span>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: '0 0 48%' }}>
          <strong>Şikayet Olunan:</strong>
          {index?.sikayet_edilen?.map((x, i) => (
            <div key={i}>
              {i + 1}- {x.name} {x.surname} ({x.trt_arabi_unvan})
            </div>
          ))}
        </div>

        <div style={{ flex: '0 0 48%', textAlign: 'right' }}>
          <strong>Şikayet Eden:</strong>
          {index?.sikayet_eden?.map((x, i) => (
            <div key={i}>
              {i + 1}- {x.name} {x.surname} ({x.trt_arabi_unvan})
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: '12px' }}>
        <strong>Sorumlu Kişi:</strong> {index?.sorumlu_kisi?.name + " " + index?.sorumlu_kisi?.surname} ({index?.sorumlu_kisi?.trt_arabi_unvan}) <br />
        <strong>Açıklama:</strong> {index?.aciklama} <br />
        {index?.sonuc === "null" ? (
          "ChatBubble"
        ) : (
          <>
            <br />
            <div style={{ marginBottom: '6px' }}>
              <span style={{ fontWeight: 'bold', color: statusColor(index?.savunmanin_istenmesi) }}>
                <Icon sx={{ fontWeight: "bold", fontSize: "7px" }}>
                  label_important
                </Icon>
              </span> <strong>Savunmanın İstenmesi:</strong> {index?.savunmanin_istenmesi ? 'Evet' : 'Hayır'}
            </div>
            <div style={{ marginBottom: '6px' }}>
              <span style={{ fontWeight: 'bold', color: statusColor(index?.savunmanin_alinmasi) }}>
                <Icon sx={{ fontWeight: "bold", fontSize: "7px" }}>
                  label_important
                </Icon>
              </span> <strong>Savunmanın Alınması:</strong> {index?.savunmanin_alinmasi ? 'Evet' : 'Hayır'}
            </div>
            <div style={{ marginBottom: '6px' }}>
              <span style={{ fontWeight: 'bold', color: statusColor(index?.tercumenin_yapilmasi) }}>
                <Icon sx={{ fontWeight: "bold", fontSize: "7px" }}>
                  label_important
                </Icon>
              </span> <strong>Tercümenin Yapılması:</strong> {index?.tercumenin_yapilmasi ? 'Evet' : 'Hayır'}
            </div>
            <div style={{ marginBottom: '6px' }}>
              <span style={{ fontWeight: 'bold', color: statusColor(index?.karar_alinmasi_ve_yazilmasi) }}>
                <Icon sx={{ fontWeight: "bold", fontSize: "7px" }}>
                  label_important
                </Icon>
              </span> <strong>Karar Alınması ve Yazılması:</strong> {index?.karar_alinmasi_ve_yazilmasi ? 'Evet' : 'Hayır'}
            </div>
            <div style={{ marginBottom: '6px' }}>
              <span style={{ fontWeight: 'bold', color: statusColor(index?.kararın_teblig_edilmesi) }}>
                <Icon sx={{ fontWeight: "bold", fontSize: "7px" }}>
                  label_important
                </Icon>
              </span> <strong>Kararın Tebliğ Edilmesi:</strong> {index?.kararın_teblig_edilmesi ? 'Evet' : 'Hayır'}
            </div>
            <br />
            <span style={{
              display: 'inline-block',
              padding: '0.3em 0.6em',
              fontSize: '80%',
              fontWeight: '700',
              lineHeight: '1',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              verticalAlign: 'baseline',
              borderRadius: '0.4rem',
              backgroundColor: '#e57373',
              color: '#fff',
              marginRight: '6px',
            }}>
              GÜNCEL DURUM/KARAR
            </span>
            <br />
            {index?.sonuc}
          </>
        )}
      </div>
    </div>
  );
}



const showAlert = (index) => {
  const wrapper = document.createElement('div');

  const root = createRoot(wrapper);
  root.render(<ShowAlertContent index={index} />);

  Swal.fire({
    html: wrapper,
    confirmButtonText: 'Kapat',
    showConfirmButton: true,
    confirmButtonColor: "rgb(226 226 226)",
    willClose: () => {
      root.unmount();

    }
  });
};


function Tables() {
  const [data, setData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleModalOpen = (index) => {
    setModalContent(index);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/disiplin?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const Target = ({ id, image, name, email, tel, variant, index }) => {
    return (
      <a onClick={() => handleModalOpen(index)}>
        <SoftBadge variant={variant} badgeContent={
          <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "300px" }} >
            <SoftBox mr={2}>
              <SoftAvatar src={image} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
              <SoftTypography variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="dark">
                {email}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        } color="light" size="xl" container />
      </a>
    );
  };

  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const egitimRows = data.map(index => {
    return {
      "Şikayet Olunan": (
        <Target
          id={index?.sikayet_edilen?.map(x => x.id).join(', ')}
          image={index?.sikayet_edilen?.map(x => x.personel_fotograf)[0]}  // Sadece ilk resim gösteriliyor
          name={
            <div style={{ textAlign: "left", wordBreak: "break-word", whiteSpace: "normal", maxWidth: "100%", overflow: "hidden" }}>
              {index?.sikayet_edilen?.map((x, i) => (
                <React.Fragment key={`sikayet_edilen-${i}`}>
                  {index?.sikayet_edilen.length > 1 && `${i + 1}-`} {x.name} {x.surname}
                  <br key={`sikayet_edilen-br-${i}`} />
                </React.Fragment>
              ))}
            </div>

          }
          email={index?.acilma_tarihi + " - " + (index?.kapanma_tarihi ? index?.kapanma_tarihi : "")}
          variant="gradient"
          tel={<SoftBadge variant="gradient" badgeContent={index.durum} color={index.durum == "acik" ? "info" : "error"} size="xs" />}
          index={index}
        />
      ),
      "Dosya No": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.dosya_isim}</SoftTypography>,
      "Durum": <SoftBadge variant="gradient" badgeContent={index.durum} color="success" size="xs" container />,
      "Şikayet Eden": (
        <Target
          id={index?.sikayet_eden?.map(x => x.id).join(', ')}
          image={index?.sikayet_eden?.map(x => x.personel_fotograf)[0]}  // Sadece ilk resim gösteriliyor
          name={
            index?.sikayet_eden?.length > 0 ? (
              <div style={{ textAlign: "left", wordBreak: "break-word", whiteSpace: "normal", maxWidth: "100%", overflow: "hidden" }}>
                {index?.sikayet_eden?.map((x, i) => (
                  <React.Fragment key={`sikayet_eden-${i}`}>
                    {index?.sikayet_eden.length > 1 && `${i + 1}-`} {x.name} {x.surname}
                    <br key={`sikayet_eden-br-${i}`} />
                  </React.Fragment>
                ))}
              </div>

            ) : (
              "KOORDİNATÖRLÜK"
            )
          }
          email={""} //index?.sikayet_eden?.map(x => x.trt_unvan).join(', ')}
          variant=""
          index={index}
        />
      ),

      "Güncel Durum / Karar": <SoftTypography variant="caption" color="text" fontWeight="medium">{index?.aciklama}</SoftTypography>,
    };
  });

  const projectsTableData = {
    columns: [
      { name: "Şikayet Olunan", align: "left" },
      { name: "Şikayet Eden", align: "left" },
      { name: "Dosya No", align: "center" },
      { name: "Güncel Durum / Karar", align: "center" },
    ],
    rows: egitimRows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <BasicModal open={modalOpen} handleClose={handleModalClose} content={modalContent} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Disiplin Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <TableAcord columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
