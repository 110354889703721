import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import store from "store";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import Footer from "examples/Footer";



import IsealimCardMulakat from "examples/IsealimCards/IsealimCardMulakat";
import IsealimCardAlim from "examples/IsealimCards/IsealimCardAlim";
import IsealimCardOzgecmis from "examples/IsealimCards/IsealimCardOzgecmis";
import IsealimCardPozisyon from "examples/IsealimCards/IsealimCardPozisyon";
import IsealimCardSinav from "examples/IsealimCards/IsealimCardSinav";


// Overview page components
import Header from "layouts/ise-alim/components/Header";


import { useState , useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';


function Overview() {
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (index) => {
    setTabIndex(index);
  }
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/isealim?format=json`,{withCredentials: true});
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);

  const [dataKurum, setDataKurum] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/kurum?format=json`,{withCredentials: true});
        setDataKurum(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);



  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header onChange={e => onTabChange(e)} data={data} />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>

          {tabIndex === 1 && (
            <>
              <IsealimCardMulakat data={data} />
              
              
            </>
          )}
          {tabIndex === 0 && (
            <>
              <IsealimCardPozisyon data={data} dataKurum={dataKurum} />
              
            </>
          )}
          {tabIndex === 2 && (
            <>
              <IsealimCardAlim data={data} />
              
            </>
          )}

          {tabIndex === 3 && (
            <>
              <IsealimCardOzgecmis data={data} />
              
            </>
          )}


          {tabIndex === 4 && (
            <>
              <IsealimCardSinav data={data} />
              
            </>
          )}
        
          

        </Grid>
      </SoftBox>
      

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
