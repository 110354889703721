/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import axios from "axios"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftProgress from "components/SoftProgress";



// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import team2 from "assets/images/team-takvim.jpg";

//const BASE_URL = process.env.REACT_APP_URL

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];


function Author({ image, name, email, tel }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="dark">
          {email}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {tel}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const index = (data) => {


  let [dataNews, setDataNews] = useState({ veriler: [] });
  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${window.BASE_URL}/inews/${data.data.id}`, { withCredentials: true });
              setDataNews(response.data);
          } catch (error) {
              console.log(error);
          }
      };
      fetchData();
  }, []);

  const aylikVeriler = [];

  dataNews.veriler.forEach(yilVerisi => {
      const yil = yilVerisi.yil;
      Object.keys(yilVerisi.aylar).forEach(ay => {
          const ayVerisi = yilVerisi.aylar[ay];
          aylikVeriler.push({
              tarih: `${ay}-${yil}`,
              ay_toplam: ayVerisi.ay_toplam,
              haber_turleri: ayVerisi.haber_turleri
          });
      });
  });

  aylikVeriler.sort((a, b) => {
    const tarihA = new Date(a.tarih.split('-').reverse().join('-'));
    const tarihB = new Date(b.tarih.split('-').reverse().join('-'));
    return tarihB - tarihA; // Büyükten küçüğe sıralama
});

//console.log("BURADAN")
const rows = [];
Object.keys(aylikVeriler).forEach(monthYear => {
  const items = aylikVeriler[monthYear].haber_turleri;
  const aytoplam = aylikVeriler[monthYear].ay_toplam
  console.log(items)
  console.log()

  const tarih = aylikVeriler[monthYear].tarih
  const tarihlist = tarih.split("-");
  const ay = tarihlist[0]
  const ayname = ayIsimleri[ay-1]
  const yil = tarihlist[1]

  rows.push({
  
    Adı: <Author image={team2} name={`${ayname} ${yil} Tarihi`} tel="" />,
  //   Adı: <Author image={team2} name={`${ayIsimleri[items[0].ay - 1]} ${items[0].yil} Tarihi`} tel="" />,
  //   Pozisyon: <Function job={data.data.trt_arabi_unvan} org="" />,
  //   "Yapılan Özel İşler": <SoftBadge variant="gradient" badgeContent={totalOzel} color="default" size="xs" container />,
     "Kategori": <SoftBadge variant="gradient" badgeContent="TÜMÜ" color="dark" size="xs" container />,
  //   "Son Yapılan İşler": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {totalPerformance}/<strong>{totalHedef}</strong> </SoftTypography>,
     "Aylık Performans": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {aytoplam} </SoftTypography>,
     "Ay": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {ayname} </SoftTypography>,
     "Yıl": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {yil} </SoftTypography>,

  //   "field_name": ayIsimleri[items[0].ay - 1] + " " + items[0].yil,
  //   "field_position": data.data.trt_arabi_unvan,
     "field_kategori": "TÜMÜ",
  //   "field_ozelis": totalOzel,

   });

   Object.keys(items).forEach(item => {
       rows.push({
        Adı: <SoftTypography variant="caption" color="secondary" fontWeight="medium" px={3} > Aylık Veriler </SoftTypography>,
        "Kategori": <SoftBadge variant="gradient" badgeContent={item} color="info" size="xs" container />,
        "Aylık Performans": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {items[item]} </SoftTypography>,
        "Ay": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {ayname} </SoftTypography>,
        "Yıl": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {yil} </SoftTypography>,

        "field_kategori": item,
        "field_ay": ayname,
        "field_yil": yil,
       })
  })

})




  function calculateTotalPerformanceForMonth(monthData) {
    return monthData.reduce((total, item) => total + item.yapilan_is_sayisi, 0);
  }

  function calculateTotalHedefForMonth(monthData) {
    return monthData.reduce((total, item) => total + item.aylik_hedefler, 0);
  }

  function calculateTotalOzelForMonth(monthData) {
    return monthData.reduce((total, item) => total + item.yapilan_ozel_isler.length, 0);
  }

  // Verilerinizin bulunduğu ana dizi
  const monthData = data.data.gorevler;

  // Aylara göre gruplandırma
  const groupedByMonth = monthData.reduce((acc, item) => {
    const key = `${item.ay}-${item.yil}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  


  const columns = [
    { name: "Adı", align: "left", exportableField: "field_name" },
    //{ name: "Pozisyon", align: "left", exportableField: "field_position" },
    { name: "Kategori", align: "left", exportableField: "field_kategori", searchable: true, searchField: ["field_kategori"]},
    //{ name: "Yapılan Özel İşler", align: "center", exportableField: "field_ozelis" },
    //{ name: "Son Yapılan İşler", align: "center" },
    { name: "Aylık Performans", align: "center" },
    { name: "Ay", align: "center" ,searchable: true,searchField: ["field_ay"]},
    { name: "Yıl", align: "center" ,searchable: true,searchField: ["field_yil"]},

  ]



  return (
    <Grid item xs={12} md={12} xl={12}>
      <SoftBox pt={3} px={3}></SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Aylık Performans Tablosu</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </Card>
    </Grid>

  );
}

export default index;