/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/editoryel?format=json`);
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ image, name }) {
      return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }


    //   const truncatedTextStyle = {
    //     width: '300px',
    //     overflow: 'hidden',
    //     display: '-webkit-box',
    //     WebkitLineClamp: 1,
    //     height: '20px',
    //     WebkitBoxOrient: 'vertical'
    //   };



    //   const tableRows = data.map(item => {
    //     return {
    //         "Başlık": [
    //             <img key="logoNewspaper" src="" alt="Logo" />,
    //             <span key="task1">{item.baslik}</span>
    //         ],
    //         "Dosya": <><Icon sx={{ fontWeight: "bold", color: "success"}}> get_app </Icon><SoftTypography variant="caption" color="text" fontWeight="medium"> <Link href={`${item.dosya}`} download>
    //             {item.dosya}
    //         </Link> </SoftTypography></>,
    //       };
    // });

    // const projectsTableData = {
    //     columns: [
    //         { name: "Başlık", align: "left" },
    //         { name: "Dosya", align: "Center" },
    //     ],
    //     rows: tableRows
    // };



    function createParagraphs(text) {
      return text.split('\n').map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ));
    }

    function formatText(text) {
      // Önce metni paragraflara böleriz
      const paragraphs = text.split('\n');
    
      return paragraphs.map((paragraph, paraIndex) => {
        // Her paragraf içinde kalın metni işleme
        const boldRegex = /\*\*(.*?)\*\*/g;
        let parts = [];
        let match;
        let lastIndex = 0;
    
        // Regex kullanarak kalın metinleri bul
        while ((match = boldRegex.exec(paragraph)) !== null) {
          // Önceki match ile bu match arasındaki normal metni al
          parts.push(paragraph.slice(lastIndex, match.index));
          // Kalın metni al
          parts.push(<strong key={`strong-${paraIndex}-${match.index}`}>{match[1]}</strong>);
          // Son indexi güncelle
          lastIndex = match.index + match[0].length;
        }
    
        // Kalan normal metni al
        parts.push(paragraph.slice(lastIndex));
    
        // Bölümleri tek bir span içinde döndür, her paragraf sonunda br etiketi ekle
        return (
          <span key={`paragraph-${paraIndex}`}>
            {parts}
            <br />
          </span>
        );
      });
    }





    return (
      <DashboardLayout>
        <DashboardNavbar />
        {data.map((item, index) => (
          <Grid item xs={12} md={12} xl={12} key={index}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h5">{item.baslik}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography fontWeight="light" variant="button">{formatText(item.aciklama_metni)}</SoftTypography>
              </SoftBox>
            </Card>
          </Grid>
        ))}
      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
