/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
    maxHeight: '90vh',
    maxWidth: '150vw',
    overflowY: 'auto',
};

const useStyles = makeStyles({
    stepper: {
        fontSize: '10px !important',
    },
    stepLabel: {
        fontSize: '10px !important',
    },
});

const CustomStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        fontSize: '14px !important',
    },
}));

function createItems(title, text) {
    let items = [];

    if (title) {
        items.push(
            <SoftBox pt={3} px={3} key={title}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong>  <br></br>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                </SoftTypography>
            </SoftBox>
        );
    }

    return items;
}

function getSteps() {
    return ["1. Savunma Talep Edildi", "2. Savunma Alındı", "3. Tercüme Yapıldı", "4. Karar Alındı", "5. Karar Yazıldı", "6. Karar Tebliğ Edildi"];
}

const Target = ({ id, image, name, email, tel, variant }) => {
    return (
        <a href={`/profile/${id}`} >
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}  >
                    <SoftBox mr={2}>
                        <SoftAvatar src={image} size="sm" variant="rounded" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            } color="light" size="xl" container />
        </a >
    );
};

const Evrak = ({ id, image, name, aciklama, tel, variant, fileType }) => {
    const renderPreview = () => {
        switch (fileType) {
            case 'video':
                return <video controls src={id} style={{ width: '300px', boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 22%)", borderRadius: "0.5rem" }} />;
            case 'audio':
                return <audio controls src={id} style={{ width: '300px', boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 22%)", borderRadius: "0.5rem", background: "#f2f3f4" }} />;
            case 'image':
                return <img src={image} alt={name} style={{ width: '300px', boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 22%)", borderRadius: "0.5rem" }} />;
            default:
                return (
                    <a href={`${id}`} target="_blank" rel="noopener noreferrer">
                        {name}
                    </a>
                );
        }
    };

    return (
        <>
            <Divider />
            <SoftBadge variant={variant} badgeContent={
                <SoftBox display="flex" flexDirection="column" alignItems="flex-start" px={1} py={0.5}  >
                    <SoftBox mr={2}>
                        {renderPreview()}
                    </SoftBox>
                    <a href={id} target="_blank" rel="noopener noreferrer">
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" pt={6}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {aciklama}
                            </SoftTypography>
                            <SoftTypography variant="caption" color="dark" >
                                {name}
                            </SoftTypography>
                            {/* <SoftTypography variant="caption" color="secondary">
                        {name}
                    </SoftTypography> */}
                        </SoftBox>
                    </a>
                </SoftBox>
            } color="light" size="xl" container />
        </>
    );
};

export default function BasicModal({ open, handleClose, content }) {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const classes = useStyles();

    const stepsName = [
        'savunmanin_istenmesi',
        'savunmanin_alinmasi',
        'tercumenin_yapilmasi',
        'karar_alinmasi',
        'karar_yazilmasi',
        'kararın_teblig_edilmesi'
    ];

    const getStepIndex = (content) => {
        for (let i = 0; i < stepsName.length; i++) {
            if (content?.[stepsName[stepsName.length - i - 1]] || content?.[stepsName[stepsName.length - i]] === "true") {
                return stepsName.length - i - 1;
            }
        }
        return -1;
    };

    useEffect(() => {
        const activeStepId = getStepIndex(content);
        setActiveStep(activeStepId);
    }, [content]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={style}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {createItems("DOSYA NO", content.dosya_isim)}
                    <SoftBadge variant="gradient" badgeContent={content.durum} color={content.durum === "acik" ? "info" : "error"} size="md" style={{ maxHeight: "50px" }} />
                </div>
                <div style={{ display: "flex" }}>
                    {createItems("AÇILIŞ TARİHİ", content?.acilma_tarihi)}
                    {createItems("KAPANIŞ TARİHİ", content?.kapanma_tarihi ? content?.kapanma_tarihi : "")}
                </div>
                <div display="flex">
                    {createItems("ŞİKAYET OLUNAN", content.sikayet_edilen?.map((x, i) => (
                        <React.Fragment key={i}>
                            <Target id={x.id} name={(i + 1) + "- " + x.name + " " + x.surname} email={x.sicil_no} tel={x.sozlesme} image={window.BASE_URL + "/" + x.personel_fotograf} variant="">
                                ({x.trt_arabi_unvan})
                            </Target>
                            <br />
                        </React.Fragment>
                    )))}
                    {createItems("ŞİKAYET EDEN", content.sikayet_eden && content.sikayet_eden.length > 0 ? content.sikayet_eden.map((x, i) => (
                        <React.Fragment key={i}>
                            <Target id={x.id} name={(i + 1) + "- " + x.name + " " + x.surname} email={x.sicil_no} tel={x.sozlesme} image={window.BASE_URL + "/" + x.personel_fotograf} variant="">
                                ({x.trt_arabi_unvan})
                            </Target>
                            <br />
                        </React.Fragment>
                    )) : (
                        <Target key="default" name="KOORDİNATÖRLÜK" tel="" variant="">
                            KOORDİNATÖRLÜK
                        </Target>
                    ))}
                    {createItems("SORUMLU KİŞİ",
                        <div style={{ marginTop: "10px" }}>
                            <Target id={content.sorumlu_kisi?.id} name={1 + "- " + content.sorumlu_kisi?.name + " " + content.sorumlu_kisi?.surname} email={content.sorumlu_kisi?.sicil_no} tel={content.sorumlu_kisi?.trt_arabi_unvan} image={window.BASE_URL + "/" + content.sorumlu_kisi?.personel_fotograf} variant="gradient">
                                ({content.sorumlu_kisi?.trt_arabi_unvan})
                            </Target>
                        </div>
                    )}
                </div>
                <CustomStepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </CustomStepper>
                {createItems("AÇIKLAMA", content.aciklama)}
                {createItems("GÜNCEL DURUM/KARAR", content.sonuc)}
                {createItems("EVRAK", content.evrak?.map((x, i) => {
                    const fileType = x.dosya.split('.').pop(); // Dosya uzantısını al
                    let type;
                    if (['mp4', 'mov', 'avi'].includes(fileType)) {
                        type = 'video';
                    } else if (['mp3', 'wav', 'ogg'].includes(fileType)) {
                        type = 'audio';
                    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
                        type = 'image';
                    } else {
                        type = 'default';
                    }

                    return (
                        <React.Fragment key={i}>
                            <Evrak id={window.BASE_URL + "/" + x.dosya} name={(i + 1) + "- " + x.dosya} aciklama={x.aciklama} tel={x.sozlesme} image={window.BASE_URL + "/" + x.dosya} variant="" fileType={type} />
                            <br />
                        </React.Fragment>
                    );
                }))}
            </SoftBox>
        </Modal>
    );
}
