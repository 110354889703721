/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarCheck, faCalendarMinus, faChartLine, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faSpellCheck, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-trt-performans-blur2.png";

/* eslint-disable react/prop-types */

function Header(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };

  return (
    <SoftBox position="absoloute" margin-top="200px">
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        //minHeight="18.75rem"
        height="18rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.0),
              rgba(gradients.info.state, 0.0)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          "@media print": {
            height: '200px', // Yazdırma sırasında backdrop-filter efektini kaldır


          }
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "absoloute",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          marginTop: 2
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                <Tab value={0} label="Çalışan Skor Tablosu" icon={<FontAwesomeIcon icon={faThList} />} />
                <Tab value={1} label="Çalışan Performans" icon={<FontAwesomeIcon icon={faList} />} />
                <Tab value={2} label="Unvan Performans" icon={<FontAwesomeIcon icon={faListAlt} />} />
                {/* <Tab value= {2} label="Yönetmelikler" icon={<FontAwesomeIcon icon={faThList} />} /> */}

              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>

      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "absoloute",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          marginTop: 2
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
              Görev başarımı {' '}<strong>
              <span>&#123;</span>
              <span>&#8721;</span> (<span>&#123;</span>Y/X<span>&#125;</span> &times; Değer<span>&#125;</span>)/<span>&#8721;</span> Değer
              <span>&#125;</span> </strong>formülüyle hesaplanır; burada Y tamamlanan görev sayısı, X hedef görev sayısı ve Değer haber türlerinin ağırlığıdır. Bu, her görev türünün hedeflere ulaşma başarısını ağırlıklı bir skor olarak verir.

              <br/>
              <br/>

            Unvanların yıllık başarım ortalaması, toplam gerçekleştirilen iş sayısının AY_SAYI ile çarpılıp 12ye bölünmesiyle hesaplanmaktadır. Hesaplama formülü: {' '}<strong>
  <span>&#123;</span>
  TOPLAM_SAYI / (AY_SAYI * 12)
  <span>&#125;</span></strong> şeklindedir. Bu hesaplama, unvanlara göre kişilerin yıllık performansını değerlendirmek için kullanılır.
            </SoftTypography>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
