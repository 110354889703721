/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";


function Tables() {






  const navigate = useNavigate();
  //const [data, setData] = useState([]);



  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const [data, setData] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/rota`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  console.log(data)
  



 
  let egitimRows = [];
  if (data && data.lines && Array.isArray(data.lines)) {
    egitimRows = data.lines.map(line => {
      const rowData = {};
      if (data.header && Array.isArray(data.header)) {
        data.header.forEach((header, i) => {
          rowData[header] = <SoftTypography variant="caption" color="text" fontWeight="medium">{line[i]}</SoftTypography>;
        });
      }
  
      return rowData; // Burada her satırı temsil eden nesneyi direkt döndürüyoruz
    });
  };
  
  // columns ve projectsTableData kısmı aynı kalabilir
  

let columns = [];
if (data && data.header ) {
  columns = data.header.map(header => ({
    name: header, align: "left"
  }));
}

const projectsTableData = {
  columns: columns,
  rows: egitimRows,
};

console.log(columns)
console.log(egitimRows)









  return (

    <Grid item xs={12} md={12} xl={12}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">NewsRoom Rota Listesi</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={projectsTableData.columns} rows={projectsTableData.rows} edit="/admin/base/izinler/" />
        </SoftBox>
      </Card>

    </Grid>


  );

}

export default Tables;
