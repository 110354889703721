import Header from "layouts/cv/Header";
import axios from "axios";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";


import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';




function index() {


    const printDocument = () => {
        window.print();
    }



    const { id } = useParams();

    const [data, setData] = useState({ egitimler: [], istecrubeler: [], meslekegitimler: [], egitimdiller: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/user/${id}?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    return (
        <DashboardLayout>

            <div style={{ position: 'absolute', zIndex: '1', right: '10%', top: '120px' }}>
                <SoftButton id="printButton" onClick={printDocument} sx={{ ml: 2 }} color="dark">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                        get_app
                    </Icon> PDF indir
                </SoftButton>

            </div>

            <div
                id="divToPrint"
            >
                <div>
                    <img src="/static/media/TRT_blue_logo.7560177b32c04ebe9f8e.png" alt="logo" style={{ position: 'absolute', width: '7%', right: '10%', top: '60px', zIndex: '1' }} />
                </div>

                <Header onChange={e => onTabChange(e)} data={data} />
                <Grid container spacing={0} >
                    <Grid item xs={4} md={4} xl={4}>
                        <Card className="h-100" sx={{
                            mt: 2,
                            ml: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 10
                        }}>
                            <SoftBox pt={3} px={3}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    Kişisel Bilgiler
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>
                                    <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                        <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                            {/* <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                label_important
                                            </Icon> */}
                                        </SoftTypography>
                                        {/* &nbsp; */}
                                        <SoftTypography variant="button" color="text" fontWeight="medium">

                                        </SoftTypography>{" "}
                                        <strong>Telefon:</strong> {data.kisisel_cep_no}<br />
                                        <strong>Eposta:</strong> {data.kisisel_mail}<br />
                                        <strong>Adres:</strong> {data.ev_adresi}<br />

                                        <strong>Uyruk:</strong> {data.uyruk}<br />
                                        <strong>Vatandaşlık:</strong> {data.vatandaslik}<br />
                                        <strong>Doğum Tarihi:</strong> {data.dogum_tarihi}<br />
                                        <strong>Doğum Yeri:</strong> {data.dogum_yeri}<br />

                                        <strong>Medeni Durum:</strong> {data.medeni_durum}<br />
                                        <strong>Ehliyet:</strong> {data.ehliyet_turu}<br />
                                        <strong>Askerlik Bilgisi:</strong> {data.askerlik_bilgileri}<br />



                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox p={2}>

                            </SoftBox>
                        </Card>
                    </Grid>







                    <Grid item xs={8} md={8} xl={8}>
                        <Card className="h-100" sx={{
                            mt: 2,
                            mx: 3,
                            py: 0,
                            px: 0,
                            marginBottom: 10
                        }}>
                            <SoftBox pt={3} px={3}>


                                <SoftTypography variant="h6" fontWeight="medium">
                                    İş Tecrübesi
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>

                                    {
                                        data.istecrubeler.map(egitim => {
                                            return (<>

                                                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                            label_important
                                                        </Icon>
                                                        {egitim.sirket_adi} ({egitim.pozisyon}) <br />
                                                    </SoftTypography>

                                                    {egitim.baslangic_tarihi} - {egitim.bitis_tarihi} <br />
                                                    <strong>Açıklama:</strong> {egitim.aciklama} <br />

                                                </SoftTypography>
                                            </>

                                            );
                                        })
                                    }
                                </SoftBox>




                                <SoftTypography variant="h6" fontWeight="medium">
                                    Eğitim Bilgileri
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>





                                    {
                                        data.egitimler.map(egitim => {
                                            return (<>

                                                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                            label_important
                                                        </Icon>
                                                        {egitim.bolum} ({egitim.okul_adi}) <br />
                                                    </SoftTypography>

                                                    {egitim.ogrenim_durumu} - {egitim.ogrenim_suresi} Yıl<br />
                                                    <strong>Mezuniyet:</strong> {egitim.mezuniyet_tarihi} <br />

                                                </SoftTypography>
                                            </>

                                            );
                                        })
                                    }


                                </SoftBox>





                                <SoftTypography variant="h6" fontWeight="medium">
                                    Dil Eğitimleri
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>





                                    {
                                        data.egitimdiller.map(egitim => {
                                            return (<>

                                                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                            label_important
                                                        </Icon>
                                                        {egitim.dil} <br />
                                                    </SoftTypography>

                                                    {egitim.sinav_turu} - {egitim.sertifika_puan} <br />
                                                    <strong>Seviye:</strong> {egitim.seviye} <br />

                                                </SoftTypography>
                                            </>

                                            );
                                        })
                                    }


                                </SoftBox>



                                <SoftTypography variant="h6" fontWeight="medium">
                                    Mesleki Eğitimler
                                </SoftTypography>
                                <SoftBox mt={1} mb={2}>





                                    {
                                        data.meslekegitimler.map(egitim => {
                                            return (<>

                                                <SoftTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                                            label_important
                                                        </Icon>
                                                        {egitim.alinan_egitim} ({egitim.kurum}) <br />
                                                    </SoftTypography>

                                                    {egitim.sertifika} <br />
                                                    <strong>Açıklama:</strong> {egitim.aciklama} <br />

                                                </SoftTypography>
                                            </>

                                            );
                                        })
                                    }


                                </SoftBox>





                            </SoftBox>
                            <SoftBox p={2}>

                            </SoftBox>
                        </Card>
                    </Grid>

                </Grid>

            </div>
        </DashboardLayout>

    )
}

export default index;