/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import axios from "axios"

import React, { useEffect, useState } from 'react';
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {


  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/latest-user-details`,{
          withCredentials: true 
        });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);





  let timeRows = data.map(time => (
    <TimelineItem
        key={time.id}
        color="info"
        icon="person_add"
        title={`${time.name} ${time.surname}`}
        dateTime={`${time.trt_arabi_unvan} `} 
        badges= {["TRT "+ time.kanal.kanal_adi]}
    />
));

  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Son İşe Girenler
        </SoftTypography>
        <SoftBox mt={1} mb={2}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            <SoftTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main }}>
                people_Alt 
              </Icon>
            </SoftTypography>
            &nbsp;
            <SoftTypography variant="button" color="text" fontWeight="medium">
              {data.length}
            </SoftTypography>{" "}
            yeni kişi
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        {timeRows}
      </SoftBox>
    </Card>
  );
}

export default OrdersOverview;
