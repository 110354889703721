/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import axios from "axios"

import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";

function Dashboard() {
  const { size } = typography;
  let { chart, items } = reportsBarChartData;

  const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

  // DATA

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/kurum`,{
          withCredentials: true 
        });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  // HEDEFLER

  const [data_hedef, setDataHedef] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevler/son-6-ay`,{
          withCredentials: true 
        });
        setDataHedef(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

// GÖREVLER

  const [data_gorev, setDataGorev] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevler/son-6-ay`,{
          withCredentials: true 
        });
        setDataGorev(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  function ayAdiniAl(ayYil) {
    const bolunmus = ayYil.split('-'); // "2023-07" gibi bir string'i '-' karakterine göre böler
    const yil = bolunmus[0]; // Yılı alır
    const ayIndex = parseInt(bolunmus[1]) - 1; // Ay sayısını alır ve 1 çıkarır (çünkü diziler 0'dan başlar)
    console.log(`${ayIsimleri[ayIndex]} ${yil}`);
    return String(`${ayIsimleri[ayIndex]}`); // Ayın adını ve yılı birleştirir
  }
  
  //const cevrilmisAyIsimleri = ayIsimCevirici(tarihler);

  const convertToDesiredFormat = (obj, all_count) => {
    // Object.entries ile nesneyi anahtar-değer çiftleri şeklinde bir diziye çevir
    return Object.entries(obj)
      .slice(0, 4) // İlk beş öğeyi al
      .map(([label, progress], index) => ({
        icon: { color: "info", component: "assignment" },
        label: label,
        progress: { content: `${progress}`, percentage: Math.round((progress / all_count) * 100)      } // Burada 'percentage' değerini örnek olarak ayarladım
      }));
  };
  

  if (data_gorev.kategoriye_gore_toplamlar) {
    items = convertToDesiredFormat(data_gorev.kategoriye_gore_toplamlar, data_gorev.genel_toplam);
  }
  

  //let label = data_gorev.map(time => time)

  let labels;
  let datalabel;
  
  if (data_gorev.genel_aylik_toplamlar) {
    labels = Object.keys(data_gorev.genel_aylik_toplamlar).map(ayAdiniAl);

    datalabel = Object.values(data_gorev.genel_aylik_toplamlar);

    // Bu noktada `labels` ve `datalabel` ile istediğiniz işlemleri yapabilirsiniz
    console.log(labels, datalabel);
  }


  let labels2;
  let datalabel2;
  
  if (data_hedef.genel_aylik_toplamlar) {
    labels2 = Object.keys(data_hedef.genel_aylik_toplamlar);
    datalabel2 = Object.values(data_hedef.genel_aylik_toplamlar);

    // Bu noktada `labels` ve `datalabel` ile istediğiniz işlemleri yapabilirsiniz
    console.log(labels2, datalabel2);
  }


  chart = {
    labels: labels,
    datasets: { label: "Yapılan İşler", data: datalabel }
  }

  const gradientLineChartData = {
    labels: labels,
    datasets: [
      {
        label: "Yapılan İşler",
        color: "info",
        data: datalabel,
      },
      {
        label: "Hedef",
        color: "dark",
        data: datalabel2,
      },
    ],
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Çalışan Sayısı" }}
                count={data.toplam_sozlesme}
                percentage={{ color: "light", text: "" }}
                icon={{ color: "info", component: "peopleAltIcon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Erkek Çalışan" }}
                count={data.erkek_sozlesme}
                percentage={{ color: "light", text: "%"+Math.round(data.erkek/data.toplam*100) }}
                icon={{ color: "info", component: "workHistory" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Kadın Çalışan" }}
                count={data.kadın_sozlesme}
                percentage={{ color: "light", text: "%"+Math.round(data.kadın/data.toplam*100) }}
                icon={{ color: "info", component: "assignmentTurnedInIcon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Yabancı Çalışan" }}
                count={data.yabancı_sozlesme}
                percentage={{ color: "light", text: "%"+Math.round(data.yabancı/data.toplam*100) }}
                icon={{
                  color: "info",
                  component: "public",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart 
                title="Son 6 Ayda Yapılan Görevler"
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Yapılan Görevler"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="dark" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">trending_up</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      6 Aylık{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        grafik görünümü
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
