

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Table from "examples/Tables/Table";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

import logoNewspaper from "assets/images/small-logos/application-4.png";

const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

const index = (data) => {

    let zimmetRows = data.data.zimmetler.map(zimmet => {
        return {
            "Cihaz Marka": [
                <img key="logoNewspaper" src={logoNewspaper} alt="Logo" />,
                <span key="task1" style={truncatedTextStyle}> {`${zimmet.model} (${zimmet.marka})`} </span>
              ],
            "Cihaz Adı": <SoftTypography variant="caption" color="text" fontWeight="medium">{zimmet.cihaz_adi}</SoftTypography>,
            "Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{zimmet.zimmet_tarih}</SoftTypography>,
            "Seri No": <SoftTypography variant="caption" color="text" fontWeight="medium">{zimmet.seri_no}</SoftTypography>,
        };
    });
    
    const projectsTableData = {
        columns: [
          { name: "Cihaz Marka", align: "left" },
          { name: "Cihaz Adı", align: "left" },
          { name: "Tarih", align: "left" },
          { name: "Seri No", align: "center" },
        ],
      
        rows: [
            ...zimmetRows,
  
        ],
    };
    

    return (
        <Grid item xs={12} md={12} xl={12}>
            <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
        </Grid>
    );
}

export default index;
