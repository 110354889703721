/* eslint-disable react/prop-types */


import { useEffect } from "react";
import axios from "axios"
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import SidenavSubCollapse from "examples/Sidenav/SidenavSubCollapse";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import NotificationItem from "examples/Items/NotificationItem";

//import styled from 'styled-components';

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";
import index from "examples/IsealimCards/IsealimCardAlim";

import { store, persistor } from "store";
import { setUserKanal } from 'store/actions/authActions';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const collapseSubName = pathname.split("/").slice(1)[1];



  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
const handleCloseMenu = () => setOpenMenu(false);

const handleItemCloseMenu = (kanal) => {
  //store.dispatch(setUserKanal(kanal));

  axios.get(`${window.BASE_URL}/kanal-choice/${kanal}`, { withCredentials: true })
  .then(response => {
    window.location.href = '/kurum/anasayfa';
  })
  .catch(error => {
    console.error('Kanal seçimi sırasında hata oluştu:', error);
  });

  //window.location.href = '/kurum/anasayfa'
};
 


const [data, setData] = useState([]);


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${window.BASE_URL}/kanal?format=json`, { withCredentials: true });
      setData(response.data);
    } catch (error) {
      console.log(error);
      
    }
  };

  fetchData();
}, []);

const kanalitems = data.map(index =>{
  if (index.kanal_adi == "Global") {
    return (
      <NotificationItem
      key=""
      color="dark"
      image={
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          public
        </Icon>
      }
      title={[index.kanal_adi]}
      date="Kanalları Görüntüle"
      icon="visibility"
      onClick={() => handleItemCloseMenu(`Global`)}
    />
    );
  }
  else if (index.kanal_adi == "HR") {
    return (
      <NotificationItem
      key=""
      color={index.kanal_type == "IK" ? "dark" : "secondary"}
      image={
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          {index.kanal_type == "IK" ? "groups" : "devices"}
        </Icon>
      }
      title={[index.kanal_adi]}
      date="Kanalı Görüntüle"
      icon="visibility"
      onClick={() => handleItemCloseMenu(`${index.kanal_adi}`)}
    />
    );
  }
  else if (index.kanal_adi == "Developer") {
    return (
      <NotificationItem
      key=""
      color={index.kanal_type == "Developer" ? "dark" : "dark"}
      image={
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          {index.kanal_type == "Developer" ? "terminal" : "terminal"}
        </Icon>
      }
      title={[index.kanal_adi]}
      date="Kanalı Görüntüle"
      icon="visibility"
      onClick={() => handleItemCloseMenu(`${index.kanal_adi}`)}
    />
    );
  }
  else if (index.kanal_adi == "Scheduling") {
    return (
      <NotificationItem
      key=""
      color={index.kanal_type == "Scheduling" ? "dark" : "secondary"}
      image={
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          {index.kanal_type == "Scheduling" ? "event_note" : "devices"}
        </Icon>
      }
      title={[index.kanal_adi]}
      date="Kanalı Görüntüle"
      icon="visibility"
      onClick={() => handleItemCloseMenu(`${index.kanal_adi}`)}
    />
    );
  }
  else {
    return (
      <NotificationItem
      key=""
      color={index.kanal_type == "TV" ? "info" : "secondary"}
      image={
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          {index.kanal_type == "TV" ? "tv" : "devices"}
        </Icon>
      }
      title={[index.kanal_adi]}
      date="Kanalı Görüntüle"
      icon="visibility"
      onClick={() => handleItemCloseMenu(`${index.kanal_adi}`)}
    />
    );
  }
  
})

const renderMenu = () => (
  <Menu
    anchorEl={openMenu}
    anchorReference={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={Boolean(openMenu)}
    onClose={handleCloseMenu}
    sx={{ mt: 2 }}
  >
    {kanalitems}
    
    
  </Menu>
);

  //const CustomSoftButton = styled(SoftButton)`padding: 10px;`;
  const [target, setTarget] = useState(null); // Add this line to create a target state

  const handleItemClick = (key) => {
    if (key !== target) {
      setTarget(key);
    }
    else {
      setTarget(null);
    }
  };


  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, children }) => {
    let returnValue;
    //let target;

    if (type === "collapse") {
      const mainCollapse = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} onClick={() => handleItemClick(key)}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );


      const childCollapses = children ? children.map(child => (
        <NavLink to={child.route} key={child.key}>
          <SidenavSubCollapse
            color={color}
            key={child.key}
            name={child.name}
            icon="arrow_right"
            active={child.key === collapseSubName}
            noCollapse={child.noCollapse || false}
          />
        </NavLink>
      )) : null;

      console.log(target);

      returnValue = (
        <>
          {mainCollapse}
          {/* Collapse bileşenini kullanarak alt başlıkları gizliyoruz veya gösteriyoruz */}
          <Collapse in={key === collapseName || target === key}>
            {childCollapses}
          </Collapse>
        </>
      );
    } else if (type === "title") {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  //const vfalse = true
  const userKanal = useSelector(state => state.auth.userKanal);

  return (

    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }} id="printButton">
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="TRT Logo" width="2.3rem" />}
          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium" sx={{marginInline:"4px"}}>
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      <button onClick={handleOpenMenu} style={{
      background: userKanal != "Global" ? 'linear-gradient(310deg, rgb(34 107 255), rgba(33, 212, 253, 1))' : "linear-gradient(310deg, #141727, #3a416f)",
      boxShadow: userKanal != "Global" ? 'rgb(44 110 187 / 20%) 0 -25px 18px -14px inset, rgb(44 110 187 / 15%) 0 1px 2px, rgb(44 109 187 / 15%) 0 2px 4px, rgb(44 108 187 / 15%) 0 4px 8px, rgb(44 106 187 / 15%) 0 8px 16px, rgb(44 112 187 / 15%) 0 16px 32px' : "rgb(180 180 180 / 20%) 0px -25px 18px -14px inset, rgb(0 44 94 / 15%) 0px 1px 2px, rgb(0 30 65 / 15%) 0px 2px 4px, rgb(0 32 72 / 15%) 0px 4px 8px, rgb(0 25 57 / 15%) 0px 8px 16px, rgb(0 28 60 / 15%) 0px 16px 32px",
      display: "flex",
      padding: "9px",
      justifyContent: "flex-start",
      borderRadius: "0.5rem",
      marginInline: "25px",
      marginBlock: "10px",
      minHeight: "max-content",
      alignItems:"center",
      border: "red"
      
    }}>
      <div style={{
        background: 'transparent',
        padding: '0px',
        marginInline: '15px',
        minHeight: '25px',
        height: "5px"
        //paddingTop: "5px",
      }}>
        <Icon sx={{ cursor: "pointer", fontWeight: "bold", paddingLeft:"-20px", fontSize:"10px", color:"white !important" }} fontSize="small" color="dark">
          {userKanal == "Global" ? "public" : (userKanal == "HR" ? "groups" : "tv")}
          </Icon>   
      </div>
      <div style={{display:"inherit"}}>
        <span style={{
          color: '#fff',
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          fontWeight: '600',
          fontSize: '0.80rem',
          whiteSpace: "normal",
          //lineHeight: "0.5"
        }}>TRT {userKanal}</span>
      </div>
    </button>
    {renderMenu()}
      
      <Divider />
      <List>{renderRoutes}</List>
      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
        <SoftBox mt={2}>

        </SoftBox>
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
