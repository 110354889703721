// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import React, { useEffect, useState } from 'react';


import axios from "axios"
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import MenuItem from "@mui/material/MenuItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import Header from "layouts/headers/NormalHeader"
import curved0 from "assets/images/studio-5.jpg";
import { useNavigate } from 'react-router-dom';
import SoftButton from "components/SoftButton";

const index = (data) => {

    const currentDate = new Date();
        const currentMonth = currentDate.getMonth() - 1; // JavaScript'te Ocak 0, Aralık 11'dir.
        const currentYear = currentDate.getFullYear();


    const navigate = useNavigate();
    const handleRatingPage = () => {
        navigate(`/insan-kaynaklari/degerlendirme`);  // Anasayfaya yönlendirme
    };

    const handleEditComment = (edit) => {
        window.open(`${window.BASE_URL}/admin/base/yoneticinotlari/${edit}/change/`, '_blank');  // Anasayfaya yönlendirme
    };

    const handleAddComment = () => {
        window.open(`${window.BASE_URL}/admin/base/yoneticinotlari/add/`, '_blank');  // Anasayfaya yönlendirme
    };

    let dataHeader = {
        "title": "Değerlendirme Detayları",
        "line1": "Yönetici Yorumları",
        "image": curved0,


    }

    let [dataPuan, setDataPuan] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/notlar/puanlama/${data.data.id}/${currentYear}?format=json`, { withCredentials: true });
                setDataPuan(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [dataNot, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/notlar/user/${data.data.id}?format=json`, { withCredentials: true });
                setData(response.data || []);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, []);


    //let dataNot = dataNots.kategorize_notlar
    let notRows
    console.log((dataNot))
    if (dataNot.kategorize_notlar) {
        notRows = Object.entries(dataNot.kategorize_notlar).map(([nots, value]) => (



            <>
                <Grid item xs={12} md={8} xl={8} key={nots.id}>

                    <Card className="h-100">
                        <SoftBox pt={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                {dataNot.short_labels[nots]}
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                        <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                            label_important
                                        </Icon>
                                    </SoftTypography>
                                    &nbsp;
                                    <SoftTypography variant="button" color="text" fontWeight="medium">

                                    </SoftTypography>{" "}
                                    {"Yönetici Yorumları"}
                                    {/* <strong> {(dataNot.kategorize_notlar[nots] ? dataNot.kategorize_notlar[nots][0].toplam : "-") + " ★"}</strong> */}
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox p={2}>
                            {value.map(not => (
                                <>
                                    <SoftButton onClick={() => handleEditComment(not.id)} sx={{
                                        ml: 5, mb: 5, position: 'absolute',
                                        width: '50px',
                                        right: '20px',
                                        zIndex: '10',
                                        boxShadow: 'none'
                                    }} >
                                        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="secondary">
                                            create
                                        </Icon>
                                    </SoftButton>

                                    <TimelineItem
                                        key={not.id}
                                        color="info"
                                        icon="chat"
                                        description={<span>{not.notu}<br /><br /></span>}

                                        title={not.not_yazan_user.name + " " + not.not_yazan_user.surname}
                                        dateTime={not.degerlendirme_tarihi}
                                    //     <SoftBox p={2}>

                                    //     {value.map(not => (
                                    //         <><MenuItem sx={(theme) => ({ ...menuItem(theme), height: '35px' })}>
                                    //                 <SoftBox
                                    //                     width="1.5rem"
                                    //                     height="1.5rem"
                                    //                     mt={0.25}
                                    //                     mr={1}
                                    //                     ml={-2}
                                    //                     mb={.25}
                                    //                     borderRadius=".45rem"
                                    //                     sx={(theme) => menuImage(theme, { color: not.aktif ? "info" : "secondary" })}
                                    //                 >
                                    //                     <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                    //                         star
                                    //                     </Icon>
                                    //                 </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="small" >
                                    //                     <strong>{"Toplam Performans"}</strong> {not.toplam_performans + "/10"}
                                    //                 </SoftTypography> </MenuItem>

                                    //                 <MenuItem sx={(theme) => ({ ...menuItem(theme), marginTop: '-10px', height: '35px' })}>
                                    //                     <SoftBox
                                    //                         width="1.5rem"
                                    //                         height="1.5rem"
                                    //                         mt={0.25}
                                    //                         mr={1}
                                    //                         ml={-2}
                                    //                         mb={.25}
                                    //                         borderRadius=".45rem"
                                    //                         sx={(theme) => menuImage(theme, { color: not.aktif ? "info" : "secondary" })}
                                    //                     >
                                    //                         <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                    //                             star
                                    //                         </Icon>
                                    //                     </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="small" >
                                    //                         <strong>{"Toplam Değerler"}</strong> {not.toplam_deger + "/10"}
                                    //                     </SoftTypography> </MenuItem>

                                    //                 <MenuItem sx={(theme) => ({ ...menuItem(theme), marginTop: '-10px', height: '35px' })} onClick={handleRatingPage}>
                                    //                     <SoftBox
                                    //                         width="1.5rem"
                                    //                         height="1.5rem"
                                    //                         mt={0.25}
                                    //                         mr={1}
                                    //                         ml={-2}
                                    //                         mb={0.25}
                                    //                         borderRadius=".45rem"
                                    //                         sx={(theme) => menuImage(theme, { color: "light" })}
                                    //                     >
                                    //                         <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                    //                             launch
                                    //                         </Icon>
                                    //                     </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="regular" >
                                    //                         {"Detaylar İçin"} {"Tıklayın"}
                                    //                     </SoftTypography> </MenuItem>
                                    //             </>

                                    //     ))}

                                    // </SoftBox>}
                                    //badges={[`Tarih: ${not.degerlendirme_tarihi} `]}
                                    />


                                </>
                            ))}

                        </SoftBox>
                        <SoftButton onClick={() => handleAddComment()} variant="contained" color="white" sx={{
                            boxShadow: "rgb(171 171 171 / 10%) 0px 2px 40px 0px"
                            , borderRadius: "10px"
                        }} ><Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                                add
                            </Icon>  Yorum Ekle</SoftButton>
                    </Card>
                </Grid>


                <Grid item xs={12} md={4} xl={4} key={nots.id}>
                    <Card className="h-100">
                        <SoftBox pt={3} px={3}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                Değerlendirme Notları
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox p={2}>

                            {value.map(not => (
                                <TimelineItem
                                    key={not.id}
                                    color="dark"
                                    icon="assignment"
                                    title={`${not.not_yazan_user.name} ${not.not_yazan_user.surname}`}
                                    dateTime={<>
                                        <MenuItem sx={(theme) => ({ ...menuItem(theme), height: '35px' })}>
                                            <SoftBox
                                                width="1.5rem"
                                                height="1.5rem"
                                                mt={0.25}
                                                mr={1}
                                                ml={-2}
                                                mb={.25}
                                                borderRadius=".45rem"
                                                sx={(theme) => menuImage(theme, { color: not.aktif ? "dark" : "light" })}
                                            >
                                                <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                                    star
                                                </Icon>
                                            </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="small" >
                                                <strong>{"Genel"}</strong> {not.skor + "/10"}
                                            </SoftTypography> </MenuItem>


                                        {/* <MenuItem sx={(theme) => ({ ...menuItem(theme), marginTop: '-10px', height: '35px' })}>
                                            <SoftBox
                                                width="1.5rem"
                                                height="1.5rem"
                                                mt={0.25}
                                                mr={1}
                                                ml={-2}
                                                mb={.25}
                                                borderRadius=".45rem"
                                                sx={(theme) => menuImage(theme, { color: not.aktif ? "info" : "light" })}
                                            >
                                                <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                                    star
                                                </Icon>
                                            </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="small" >
                                                <strong>{"Performans"}</strong> {not.toplam_performans + "/10"}
                                            </SoftTypography> </MenuItem> */}

                                        <MenuItem sx={(theme) => ({ ...menuItem(theme), marginTop: '-10px', height: '35px' })}>
                                            <SoftBox
                                                width="1.5rem"
                                                height="1.5rem"
                                                mt={0.25}
                                                mr={1}
                                                ml={-2}
                                                mb={.25}
                                                borderRadius=".45rem"
                                                sx={(theme) => menuImage(theme, { color: not.aktif ? "info" : "light" })}
                                            >
                                                <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                                    history
                                                </Icon>
                                            </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="small" >
                                                <strong>{"Tarih"}</strong> {not.degerlendirme_tarihi}
                                            </SoftTypography> </MenuItem>

                                        <MenuItem sx={(theme) => ({ ...menuItem(theme), marginTop: '-10px', height: '35px' })} onClick={handleRatingPage}>
                                            <SoftBox
                                                width="1.5rem"
                                                height="1.5rem"
                                                mt={0.25}
                                                mr={1}
                                                ml={-2}
                                                mb={0.25}
                                                borderRadius=".45rem"
                                                sx={(theme) => menuImage(theme, { color: "light" })}
                                            >
                                                <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                                                    launch
                                                </Icon>
                                            </SoftBox><SoftTypography variant="button" textTransform="capitalize" fontWeight="regular" >
                                                {"Detaylar İçin"} {"Tıklayın"}
                                            </SoftTypography> </MenuItem>
                                    </>}
                                //badges={[`${not.not_yazan_user.name} ${not.not_yazan_user.surname}`]}

                                />
                            ))}

                        </SoftBox>
                    </Card>

                </Grid>
            </>

        ));
    }

    const kategoriler = ["DC", "MG", "EP", "BP"]; // Kategorilerin listesi
    let toplamOrtalama = 0;
    let kategoriSayaci = 0;

    kategoriler.forEach(kategori => {
        const kategoriNotlari = dataNot?.kategorize_notlar?.[kategori];
        if (kategoriNotlari && kategoriNotlari.length > 0) {
            let aktifToplamDeger = 0;
            let aktifToplamPerformans = 0;
            let aktifToplamGenel = 0;
            let aktifSayisi = 0;

            kategoriNotlari.forEach(not => {
                if (not.aktif) {
                    aktifToplamDeger += not.toplam_deger || 0;
                    aktifToplamPerformans += not.toplam_performans || 0;
                    aktifToplamGenel += not.toplam || 0;
                    aktifSayisi++;
                }
            });
            console.log("toplsalama " + kategori)
            console.log(aktifToplamDeger)
            console.log(aktifSayisi)

            if (aktifSayisi > 0) {
                toplamOrtalama += (aktifToplamDeger / aktifSayisi); // Aktif notların ortalamasını toplam ortalamaya ekler
                kategoriSayaci++;
            }
        }
        console.log("toplamOrtalama " + kategori)
        console.log(toplamOrtalama)
        //console.log(aktifSayisi)
    });

    const ortalama_degerler = (kategoriSayaci > 0) ? (toplamOrtalama / kategoriSayaci).toFixed(1) : "-";
    console.log(ortalama_degerler);


    // const ortalama_degerler = ((
    //     (dataNot?.kategorize_notlar?.["DC"]?.[0]?.toplam_deger || 0) +
    //     (dataNot?.kategorize_notlar?.["MG"]?.[0]?.toplam_deger || 0) +
    //     (dataNot?.kategorize_notlar?.["EP"]?.[0]?.toplam_deger || 0) +
    //     (dataNot?.kategorize_notlar?.["BP"]?.[0]?.toplam_deger || 0)) / 4).toFixed(1);

    //const ortalama_degerler = genelOrtalama;

    const ortalama_performans = ((
        (dataNot?.kategorize_notlar?.["DC"]?.[0]?.toplam_performans || 0) +
        (dataNot?.kategorize_notlar?.["MG"]?.[0]?.toplam_performans || 0) +
        (dataNot?.kategorize_notlar?.["EP"]?.[0]?.toplam_performans || 0) +
        (dataNot?.kategorize_notlar?.["BP"]?.[0]?.toplam_performans || 0)) / 4).toFixed(1);

    const ortalama_total = ((
        (dataNot?.kategorize_notlar?.["DC"]?.[0]?.toplam || 0) +
        (dataNot?.kategorize_notlar?.["MG"]?.[0]?.toplam || 0) +
        (dataNot?.kategorize_notlar?.["EP"]?.[0]?.toplam || 0) +
        (dataNot?.kategorize_notlar?.["BP"]?.[0]?.toplam || 0)) / 4).toFixed(1);

    const len_not =
        ((dataNot?.kategorize_notlar?.["DC"]?.length) || 0) +
        ((dataNot?.kategorize_notlar?.["MG"]?.length) || 0) +
        ((dataNot?.kategorize_notlar?.["EP"]?.length) || 0) +
        ((dataNot?.kategorize_notlar?.["NC"]?.length) || 0) +
        ((dataNot?.kategorize_notlar?.["BP"]?.length) || 0);

    return (
        <>

            <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                    title={{ text: "Genel Puanı" }}
                    count={dataPuan?.survey?.["skor"]}
                    percentage={{ color: "secondary", text: "/40" }}
                    icon={{ color: "dark", component: "assignment" }}
                />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                    title={{ text: "Performans Puanı" }}
                    count={ortalama_performans}
                    percentage={{ color: "secondary", text: "/10" }}
                    icon={{ color: "dark", component: "assignment" }}
                />
            </Grid>
            <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                    title={{ text: "Değer Puanı" }}
                    count={ortalama_degerler}
                    percentage={{ color: "secondary", text: "/10" }}
                    icon={{ color: "dark", component: "assignment" }}
                />
            </Grid>

            {/* <Grid item xs={12} md={12} xl={12} >

                <ReportsBarChart
                    title=""

                    description={
                        <>
                            <strong>{len_not}</strong> Farklı Değerlendirme
                        </>
                    }
                    altdesc={""}
                    maintitle={"Ortalama Değerlendirme Skorları "}
                    chart={{}}

                    items={[
                        {
                            icon: { color: "dark", component: "assignment" },
                            label: "Genel",
                            progress: { content: `${ortalama_total}`, percentage: ortalama_total * 10 }
                        },
                        {
                            icon: { color: "dark", component: "assignment" },
                            label: "Değerler",
                            progress: { content: `${ortalama_degerler}`, percentage: ortalama_degerler * 10 }
                        },
                        {
                            icon: { color: "dark", component: "assignment" },
                            label: "Performans",
                            progress: { content: `${ortalama_performans}`, percentage: ortalama_performans * 10 }
                        },

                        // {
                        //     icon: { color: "info", component: "person" },
                        //     label: "Director of TRT Arabi",
                        //     progress: { content: `${dataNot?.kategorize_notlar?.["DC"]?.[0]?.toplam ?? "-"}` }
                        // },
                        // {
                        //     icon: { color: "info", component: "person" },
                        //     label: "Manager",
                        //     progress: { content: `${dataNot?.kategorize_notlar?.["MG"]?.[0]?.toplam ?? "-"}` }
                        // },
                        // {
                        //     icon: { color: "info", component: "person" },
                        //     label: "Executive Producer",
                        //     progress: { content: `${dataNot?.kategorize_notlar?.["EP"]?.[0]?.toplam ?? "-"}` }
                        // },
                        // {
                        //     icon: { color: "info", component: "person" },
                        //     label: "Business Partner",
                        //     progress: { content: `${dataNot?.kategorize_notlar?.["BP"]?.[0]?.toplam ?? "-"}` }
                        // },
                        // {
                        //     icon: { color: "secondary", component: "person" },
                        //     label: "Newsroom Coordinator",
                        //     progress: { content: `${dataNot?.kategorize_notlar?.["NC"]?.[0]?.toplam ?? "-"}` }
                        // },

                    ]}

                    color="darsk"
                    size="4"
                    xdesc="true"

                />
            </Grid> */}
            <Grid item xs={12} md={12} xl={12} mt={3} >
                <ReportsBarChart
                    title=""

                    altdesc={""}
                    maintitle={"Yetkili Değerlendirmeleri " + currentYear}
                    chart={{}}

                    items={[
                        // {
                        //     icon: { color: "dark", component: "people" },
                        //     label: "DEĞERLENDİRME PUANI",
                        //     progress: { content: `${dataPuan?.survey?.["skor"] ?? "-"}` }
                        // },
                        {
                            icon: { color: dataPuan?.survey?.["DC"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                            label: "Director of TRT Arabi (" + dataPuan?.survey?.["DC"]?.degerlendirme_sayisi + ")",
                            progress: { content: `${dataPuan?.survey?.["DC"]?.ortalama_skor ?? "-"}` }
                        },
                        {
                            icon: { color: dataPuan?.survey?.["MG"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                            label: "Manager (" + dataPuan?.survey?.["MG"]?.degerlendirme_sayisi + ")",
                            progress: { content: `${dataPuan?.survey?.["MG"]?.ortalama_skor ?? "-"}` }
                        },
                        {
                            icon: { color: dataPuan?.survey?.["EP"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                            label: "Executive Producer (" + dataPuan?.survey?.["EP"]?.degerlendirme_sayisi + ")",
                            progress: { content: `${dataPuan?.survey?.["EP"]?.ortalama_skor ?? "-"}` }
                        },
                        {
                            icon: { color: dataPuan?.survey?.["BP"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                            label: "Business Partner (" + dataPuan?.survey?.["BP"]?.degerlendirme_sayisi + ")",
                            progress: { content: `${dataPuan?.survey?.["BP"]?.ortalama_skor ?? "-"}` }
                        },
                        {
                            icon: { color: dataPuan?.survey?.["NC"]?.degerlendirme_sayisi > 0 ? "info" : "secondary", component: "person" },
                            label: "Newsroom Coordinator (" + dataPuan?.survey?.["NC"]?.degerlendirme_sayisi + ")",
                            progress: { content: `${dataPuan?.survey?.["NC"]?.ortalama_skor ?? "-"}` }
                        },


                    ]}

                    color="dark"
                    size="2.4"
                    xdesc="true"

                />

            </Grid>

            <Grid item xs={12} md={12} xl={12} >
                <Header data={dataHeader} />
            </Grid>

            {notRows}

        </>
    );
}

export default index;
