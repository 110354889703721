

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import axios from "axios"

import { Provider } from "react-redux";
import { createStore } from "redux";
import { store, persistor } from './store';
import rootReducer from "./store/reducers";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";


import { PersistGate } from 'redux-persist/integration/react';


axios.interceptors.response.use(response => {
  // Başarılı yanıt döndüğünde
  return response;
}, error => {
  // Hata durumunda
  console.log("TOKEN REFRESH2")
  console.log(error.response)
  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;
    console.log("401")
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      console.log("BAŞLADI")
      return axios.post(`${window.BASE_URL}/api/token/refresh/`, {}, {
          withCredentials: true
      }).then(res => {
          if (res.status === 200) {
              // Yeni token ile header'ı güncelle
              const accessToken = res.data.access;
              axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
              console.log("TOKEN 2")
              return axios(originalRequest);
          }
          else{
            console.log("----------");
            console.log(res.data)
          }
      });
    }
  }
  else{
    console.log(error.response && error.response.status === 401)
  }
  return Promise.reject(error);
});


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then(registration => {
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }).catch(err => {
//       console.log('ServiceWorker registration failed: ', err);
//     });
//   });
// }


window.BASE_URL = process.env.REACT_APP_URL;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <BrowserRouter>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);