
import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import BarReportsChartItem from "examples/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

function ReportsBarChart({ color, title, description, chart, items, altdesc, size, xdesc, maintitle }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, xdesc);

  const renderItems = items.map(({ icon, label, progress }) => (
    <Grid item xs={6} sm={3} lg={size} key={label}>
      <BarReportsChartItem
        color={color}
        icon={{ color: icon.color, component: icon.component }}
        label={label}
        progress={{ content: progress.content, percentage: progress.percentage }}
      />
    </Grid>
  ));

  const isChartDataAvailable = chart.labels;

  return (
    <>
      <style>
        {`
    @media print {
      body, html {
        -webkit-print-color-adjust: exact; /* Chrome, Safari */
        color-adjust: exact;  /* Firefox */
      }
      .print-card {
        background-color: #f0f0f0 !important; // İstediğiniz arka plan rengi
      }
      .print-chart-container {
        width: 100%;
        height: 120px;
        
        padding-bottom: 14px;
      }
      .print-chart-container canvas {
        width: 100% !important;
        height: 120px !important;
        
        padding-bottom: 14px;
      }
    }
  `}
      </style>
      <Card className="chart-container" sx={{ height: "auto", width: "100%", background: "#fff" }}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" px={3} mt={3} mb={-1}>
          {maintitle}
        </SoftTypography>
        <SoftBox padding="1rem">
          {isChartDataAvailable && useMemo(
            () => (
              <SoftBox
                variant="gradient"
                bgColor={color}
                borderRadius="lg"
                py={2}
                pr={0.5}
                mb={3}
                height="12.5rem"
                className="print-chart-container"
              >
                <Bar data={data} options={options} style={{ color: "#fff" }} />
              </SoftBox>
            ),
            [chart, color]
          )}
          <SoftBox px={1}>
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {title}
              </SoftTypography>
              <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                {description}
              </SoftTypography>
            </SoftBox>
            <SoftBox py={2} px={0.5}>
              <Grid container spacing={2}>
                {renderItems}
              </Grid>

            </SoftBox>
            <SoftTypography component="div" variant="button" color="text" fontWeight="light" fontSize="11px">
              {altdesc}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Card>
    </>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
  items: [],
  altdesc: "",
  size: 3,
  xdesc: false,
  maintitle: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  altdesc: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  xdesc: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  maintitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ReportsBarChart;
