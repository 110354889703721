/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";


function Tables() {






  const navigate = useNavigate();
  //const [data, setData] = useState([]);

  function Author({ id, image, name, email, tel }) {
    return (
      <a href={`/rapor/${id}/yillik`}>
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="sm" variant="rounded" />
        </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="dark">
              {email}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {tel}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </a>
    );
  }

  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]); // Sütunlar için bir state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/inews-ai`, { withCredentials: true });
        console.log(response.data); // Veriyi konsolda incele
        const responseData = response.data.results || []; // 'results' anahtarını kontrol et, eğer yoksa boş dizi kullan
        console.log(response.data.results)


        // Eğer gelen veri bir obje ve bu objenin içinde veri dizisi varsa
        if (response.data) {
          setData(response.data); // Veri dizisini state'e ata
          console.log("KEY")
          const columnData = [];
          // Anahtarları (örn. "Web Developer", "Web Developer2", ...) ve onların değerlerini al
          let one_time = true
          Object.entries(response.data).forEach(([key, value]) => {
            
            
            
            // Her anahtar için (örn. "Web Developer", "Web Developer2", ...) "CVR" ve "PKG" değerlerini al
            if (value.CVR !== undefined && value.PKG !== undefined && one_time) {
              //console.log(value);
              one_time = false
              Object.entries(value).forEach(([key2, value2]) => {
                if (key2 == "NAME") {
                  columnData.push({
                    name: `${key2}`,
                    align: 'left',
                    exportableField: `field_name`,
                    searchable: true,
                    searchField: [`field_name`],
                  });
                }
                else if (key2!= "PHOTO"){
                  columnData.push({
                    name: `${key2}`,
                    align: 'left',
                  });
                }
                //columnData.push(key2)
              })
            }
          });
          setColumns(columnData);
        } else {
          setData([]); // Eğer beklenen yapıda veri yoksa, data'yı boş dizi olarak ayarla
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  console.log(columns)
  
  let egitimRows = []
  if (data && typeof data === 'object' && !Array.isArray(data)) {
    const dataArray = Object.values(data);
      egitimRows = dataArray.map((item) => {
        item = item === 0 ? "" : item;

        return {

          ...item,

          "NAME": <Author id={item.ID} image={window.BASE_URL + item.PHOTO} name={item.NAME}  />,
          "field_name": item.NAME,
        };
      });
    

    
  }

  const projectsTableData = {
    columns: columns,
    rows: [
      ...egitimRows,
    ],
  };



  console.log(egitimRows);
  console.log(projectsTableData);







  return (

    <Grid item xs={12} md={12} xl={12}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Çalışan Performans Listesi</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
        </SoftBox>
      </Card>

    </Grid>


  );

}

export default Tables;
