/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import TimelineItem from "examples/Timeline/TimelineItem";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Header from "layouts/headers/NormalHeader"

import curved0 from "assets/images/header/IMG_9638.jpg";
import { RowingSharp } from "@mui/icons-material";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataHedef, setDataHedef] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("VERİ")
      try {
        const response = await axios.get(`${window.BASE_URL}/inews-ai/group`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("VERİ")
      try {
        const response = await axios.get(`${window.BASE_URL}/inews-hedefler`, { withCredentials: true });
        setDataHedef(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  let dataheader = {
    "title": "Ünvan Hedefleri",
    "line1": "Bu sayfada, her bir ünvandaki çalışanın bir yıl boyunca üretmiş olduğu formatların",
    "line2": "ortalaması ve ortalamanın üzerinden belirlenen asgari hedefler belirtilmiştir",
    "image": curved0,
  }


  try {



    let rows = []

    let items = []
    let same_groups = ""
    let same_ungroups = []
    let ungroup_count = 0
    let ungroup_total = 0
    let used_package = []

    if (data) {


      Object.entries(data).map(index => {
        items = []
        used_package = []
        if (dataHedef[index[0]]) {
          Object.entries(dataHedef[index[0]]).map(index_paket => {
            console.log("-")
            console.log(data[index[0]][index_paket[0]])
            items.push(
              {
                icon: { color: "info", component: "person" },
                label: index_paket[0] + " (" + dataHedef[index[1]["NAME"]]?.[index_paket[0]] + " HEDEF)",

                progress: { content: `${data[index[0]][index_paket[0]] + "//" + dataHedef[index[1]["NAME"]]?.[index_paket[0]]}` }
              }
            )
            used_package.push(index_paket[0])


          })
        }


        Object.entries(index[1]).map(index_paket => {
          //console.log(index_paket)
          if (index_paket[0] != "NAME" && index_paket[0] != "TOPLAM" && index_paket[0] != "AY_SAYI" && index_paket[0] != "KISI_SAYISI" && index_paket[0] != "LIST" && !used_package.includes(index_paket[0])) {
            if (index_paket[1] > 5) {
              items.push(
                {
                  icon: { color: dataHedef[index[1]["NAME"]]?.[index_paket[0]] != null ? "info" : "secondary", component: "person" },
                  label: dataHedef[index[1]["NAME"]]?.[index_paket[0]] != null ? index_paket[0] + " (" + dataHedef[index[1]["NAME"]]?.[index_paket[0]] + " HEDEF)" : index_paket[0],

                  progress: { content: `${dataHedef[index[1]["NAME"]]?.[index_paket[0]] != null ? index_paket[1] + "//" + dataHedef[index[1]["NAME"]]?.[index_paket[0]] : index_paket[1]}` }
                }
              )

            }
          }
          else if (index_paket[0] == "LIST") {
            same_groups = index_paket[1].join(', ');

          }

          else if (index_paket[0] == "TOPLAM" || index_paket[0] == "KISI_SAYISI") {
            //same_groups = index_paket[1].join(', ');
            items.push(
              {
                icon: { color: "dark", component: "person" },
                label: index_paket[0],
                label1: index_paket[0],
                progress: { content: `${index_paket[1]}` }
              }
            )


          }

        })
        if (index[0].includes("(Group)")) {
          rows.push(
            <Grid item xs={12} md={12} xl={12} mt={2}>
              <ReportsBarChart
                title=""

                description={
                  <>
                    <strong>Aynı Sayılacak Ünvanlar:</strong> {same_groups}

                  </>
                }
                altdesc={""}
                maintitle={`${index[0]}   `}
                chart={{}}

                items={items}

                color="dark"
                size="2.3"
                xdesc="true"

              />

            </Grid>
          )
        }
        else {
          same_ungroups.push(index[1]["NAME"])
          ungroup_count += index[1]["KISI_SAYISI"]
          ungroup_total += index[1]["TOPLAM"]
        }
      })



      items = []

      items.push(
        {
          icon: { color: "dark", component: "person" },
          label: "TOPLAM",

          progress: { content: `${ungroup_total}` }
        }
      )
      items.push(
        {
          icon: { color: "dark", component: "person" },
          label: "KISI_SAYISI",
          progress: { content: `${ungroup_count}` }
        }
      )

      rows.push(<Grid item xs={12} md={12} xl={12} mt={2}>
        <ReportsBarChart
          title=""

          description={
            <>
              <strong>Ünvan Listesi:</strong> {same_ungroups.join(', ')}

            </>
          }
          altdesc={""}
          maintitle={`Hesaplanmayan Ünvanlar - Ortalama Görevleri  `}
          chart={{}}

          items={items}

          color="dark"
          size="2.3"
          xdesc="true"

        />
      </Grid>

      )


    }


    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Header data={dataheader} />
        {rows}

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
