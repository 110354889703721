import { useState } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useNavigate } from "react-router-dom";

function TaskRequestForm() {

  const navigate = useNavigate();
  // Form verileri için state
  const [formData, setFormData] = useState({
    baslik: "",
    aciklama: ""
  });

  // Input değişimlerini yakalama
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Formu gönderme fonksiyonu
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${window.BASE_URL}/destek/talep-olustur`, formData, {
        withCredentials: true
      });
      navigate('/destek')
      console.log("Talep başarıyla oluşturuldu:", response.data);
    } catch (error) {
      console.log("Talep oluşturma hatası:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <SoftBox mt={3} mb={4} ml={3} style={{ width: "100%" }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={9}>

                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={2} lineHeight={1}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      Yeni Destek Talep Formu
                    </SoftTypography>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                      Destek Talep Formu Oluştur
                    </SoftTypography>
                    <Divider />

                    {/* Talep Başlığı */}
                    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Talep Başlığı
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        name="baslik"
                        placeholder="Talep Başlığı"
                        value={formData.baslik}
                        onChange={handleInputChange}
                      />
                    </SoftBox>

                    {/* Açıklama */}
                    <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3} mb={3}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Açıklama
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        name="aciklama"
                        type="text"
                        placeholder="Ek bilgi veya açıklamalar"
                        value={formData.aciklama}
                        onChange={handleInputChange}
                        multiline
                        rows={5}
                      />
                    </SoftBox>

                    {/* Gönder Butonu */}
                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                      <SoftButton variant="gradient" color="info" onClick={handleSubmit}>
                        Gönder
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Card>

              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TaskRequestForm;
