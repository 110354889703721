import { useState, useEffect } from "react";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";
import SoftTimePicker from "components/SoftTimePicker";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftEditor from "components/SoftEditor";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftEditorRoot from "components/SoftEditor/SoftEditorRoot";

function TaskRequestForm() {
  const [formData, setFormData] = useState({
    calisma: "",
    organizator: "",
    akreditasyon: "",
    etkinlik_bilgi_baglantisi: "",
    konu: "",
    yer: "",
    tarih: null,
    saat: null,
    kanal_cikis_saati: null,
    iletisim_numarasi: "",
    muhabir: "",
    note: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      muhabir: selectedOptions ? selectedOptions.map(option => option.value) : []
    });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };


  
  const handleSubmit = async () => {

    const email = 'example@example.com';
    const subject = `Görev Talep Formu - ${encodeURIComponent(formData.calisma)}`;
    const body = `Sayın Yetkili,%0A%0AAşağıda detaylarını belirttiğimiz görev talep formunu bilgilerinize sunarız:%0A%0AÇalışma: ${encodeURIComponent(formData.calisma)}%0AOrganizatör: ${encodeURIComponent(formData.organizator)}%0AAkreditasyon: ${encodeURIComponent(formData.akreditasyon)}%0AEtkinlik Bilgi Bağlantısı: ${encodeURIComponent(formData.etkinlik_bilgi_baglantisi)}%0AKonu: ${encodeURIComponent(formData.konu)}%0AYer: ${encodeURIComponent(formData.yer)}%0ATarih: ${encodeURIComponent(formData.tarih)}%0ASaat: ${encodeURIComponent(formData.time)}%0AKanal Çıkış Saati: ${encodeURIComponent(formData.kanal_cikis_saati)}%0Aİletişim Numarası: ${encodeURIComponent(formData.iletisim_numarasi)}%0AMuhabir: ${encodeURIComponent(formData.muhabir)}%0ANot: ${encodeURIComponent(formData.note)}%0A%0Aİyi çalışmalar dileriz.%0A[Gönderen Kuruluş]`;
    const cc = '';

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}&cc=${encodeURIComponent(cc)}`;
    window.location.href = mailtoLink;

    const formattedDate = formatDate(formData.tarih);
    const formattedTime = formatTime(formData.saat);
    const formattedChannelExitTime = formatTime(formData.kanal_cikis_saati);

    try {

      const response = await axios.post(`${window.BASE_URL}/gorevlendirme/create`, {
        ...formData,
        tarih: formattedDate,
        saat: formattedTime,
        kanal_cikis_saati: formattedChannelExitTime
      }, { withCredentials: true });
      console.log(response.data);

    } catch (error) {
      console.log(error);
    }
  };



  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/list/user?format=json`, { withCredentials: true });
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);



  const formatOptions = () => {
    return data.map((item) => ({
      value: item.id,
      label: item.name + " " + item.surname + " (" + item.trt_arabi_unvan + ")",
    }));
  };



  return (

    <SoftBox mt={3} mb={4} ml={3} style={{width: "100%"}}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={9}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2} lineHeight={1}>
              <SoftTypography variant="h6" fontWeight="medium">
                Yeni Görevlendirme Talep Formu
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Talep Formu Oluştur
              </SoftTypography>
              <Divider />

              {/* Görev Adı */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Görev Adı
                  </SoftTypography>
                </SoftBox>
                <SoftInput name="calisma" placeholder="Görev Adı" value={formData.calisma} onChange={handleInputChange} />
              </SoftBox>

              {/* Organizatör */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Organizatör
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;&nbsp;(opsiyonel)
                </SoftTypography>
                </SoftBox>
                <SoftInput name="organizator" placeholder="Organizatör" value={formData.organizator} onChange={handleInputChange} required />
              </SoftBox>

              {/* Akreditasyon */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Akreditasyon
                  </SoftTypography>
                </SoftBox>
                <SoftInput name="akreditasyon" placeholder="Akreditasyon" value={formData.akreditasyon} onChange={handleInputChange} />
              </SoftBox>

              {/* Etkinlik Bilgi Bağlantısı */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Etkinlik Bilgi Bağlantısı
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;&nbsp;(opsiyonel)
                </SoftTypography>
                </SoftBox>
                <SoftInput name="etkinlik_bilgi_baglantisi" placeholder="Etkinlik Bilgi Bağlantısı" value={formData.etkinlik_bilgi_baglantisi} onChange={handleInputChange} />
              </SoftBox>

              {/* Konu */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Konu
                  </SoftTypography>
                </SoftBox>
                <SoftInput name="konu" placeholder="Görevin Konusu" value={formData.konu} onChange={handleInputChange} />
              </SoftBox>

              {/* Yer */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Yer
                  </SoftTypography>
                </SoftBox>
                <SoftInput name="yer" placeholder="Görevin Konumu" value={formData.yer} onChange={handleInputChange} />
              </SoftBox>

              {/* Tarih */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Tarih
                  </SoftTypography>
                </SoftBox>
                <SoftDatePicker value={formData.date} onChange={(date) => handleDateChange("tarih", date)} />
              </SoftBox>

              {/* Saat */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Saat
                  </SoftTypography>
                </SoftBox>
                <SoftTimePicker value={formData.time} onChange={(time) => handleDateChange("saat", time)} />
              </SoftBox>

              {/* Kanal Çıkış Saati */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Kanal Çıkış Saati
                  </SoftTypography>
                </SoftBox>
                <SoftTimePicker value={formData.kanal_cikis_saati} onChange={(time) => handleDateChange("kanal_cikis_saati", time)} />
              </SoftBox>

              {/* İletişim Numarası */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    İletişim Numarası
                  </SoftTypography>
                </SoftBox>
                <SoftInput name="iletisim_numarasi" placeholder="İletişim Numarası" value={formData.iletisim_numarasi} onChange={handleInputChange} />
              </SoftBox>

              {/* Muhabir */}
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Talep Edilen Kişiler
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;&nbsp;(opsiyonel)
                </SoftTypography>
                </SoftBox>
                <SoftSelect
                  defaultValue={[

                  ]}
                  options={formatOptions()}
                  placeholder="Kişi ekle..."
                  onChange={handleSelectChange}
                  isMulti
                />
              </SoftBox>

              {/* Not */}
              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mt={3} mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Not
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="regular" color="text">
                  &nbsp;&nbsp;(opsiyonel)
                </SoftTypography>
                </SoftBox>
                {/* <SoftEditor /> */}
                <SoftInput name="note" type="text" placeholder="Ek bilgi veya açıklamalar" value={formData.note} onChange={handleInputChange} multiline rows={5} /> 
              </SoftBox>

              {/* Butonlar */}
              <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                <SoftBox mr={1}>
                  <SoftButton color="light">İptal</SoftButton>
                </SoftBox>
                <SoftButton variant="gradient" color="info" onClick={handleSubmit}>
                  Gönder
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default TaskRequestForm;
