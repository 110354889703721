// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";

function SoftTimePicker({ input, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      options={{
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true, // 24 saat formatını kullanmak için
      }}
      render={({ defaultValue }, ref) => (
        <SoftInput {...input} defaultValue={defaultValue} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of SoftTimePicker
SoftTimePicker.defaultProps = {
  input: {},
};

// Typechecking props for the SoftTimePicker
SoftTimePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default SoftTimePicker;
