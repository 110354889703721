/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";
import SoftProgress from "components/SoftProgress";


function Tables() {






  const navigate = useNavigate();
  //const [data, setData] = useState([]);

  function Author({ id, image, name, email, tel }) {
    return (
      <a href={`/rapor/${id}/yillik`}>
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={ window.BASE_URL+ "/" +image} size="sm" variant="rounded" />
        </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="dark">
              {email}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {tel}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </a>
    );
  }

  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/notlar/skorlar/2024?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  let egitimRows = Object.keys(data).map(key => {
    let index = data[key];
    return {
        "Personel": <Author 
        id={index.user_info.id} 
        image={index.user_info.personel_fotograf} 
        name={`${index.user_info.name} ${index.user_info.surname}`} 

    />,
      "Skor": <a href={"/rapor/"+index.user_info.id+"/yillik"}><SoftProgress variant="gradient" value ={index.toplam_skor} label="user" color="info"  /> </a>,
      "Unvan": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.user_info.trt_arabi_unvan} </SoftTypography>,
      "field_username": index.user_info.name + " " + index.user_info.surname,
      "field_unvan": index.user_info.trt_arabi_unvan,
      "field_skor": index.toplam_skor
    };
  });

  const projectsTableData = {
    columns: [
      { name: "Personel", align: "left", exportableField: "field_username", searchable: true, searchField: ["field_username"] },
      { name: "Unvan", align: "center",exportableField: "field_unvan", searchable: true, searchField: ["field_unvan"] },
      { name: "Skor", align: "center",exportableField: "field_skor", searchable: true, searchField: ["field_skor"] },

    ],

    rows: [
      ...egitimRows,

    ],
 
    

    
  }




  return (

    <Grid item xs={12} md={12} xl={12}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Çalışan Yıllık Skor Tablosu</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
        </SoftBox>
      </Card>

    </Grid>


  );

}

export default Tables;
