/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/storyhunter-personel`);
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ image, name }) {
      return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };



    const tableRows = data.map(item => {
      return {
          "İsim": [
              <img key="logoNewspaper" src="" alt="Logo" />,
              <span key="task1">{item.isim}</span>
          ],
          "Başlangıç Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.baslangic_tarihi}</SoftTypography>,
          "Bitiş Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.bitis_tarihi}</SoftTypography>,
          "Birim": <SoftBadge variant="gradient" badgeContent={item.birim} color="info" size="xs" />,
          "Pozisyon": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.pozisyon}</SoftTypography>,
          "Uyruk": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.uyruk}</SoftTypography>,
          "Net Ücret": <SoftBadge variant="gradient" badgeContent={`${item.ucret} ${item.ucret_doviz}`} color="success" size="xs" />,
          //"Ücret (Döviz)": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.ucret_doviz}</SoftTypography>,
          "Eposta": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.eposta_kisisel}</SoftTypography>,
          "Telefon": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.telefon}</SoftTypography>,
          "Talep Eden":  <>
          <Icon sx={{ fontWeight: "medium", color: "success"}}> person  </Icon> 
          <SoftTypography variant="caption" color="text" fontWeight="medium">
              <Link href={`/profile/${item.talep_eden.id}`} >
                  {item.talep_eden.name}
              </Link>
          </SoftTypography>
      </>,
 
        };
  });
  
  const projectsTableData = {
    columns: [
      { name: "İsim", align: "left" },
      { name: "Başlangıç Tarihi", align: "center" },
      { name: "Bitiş Tarihi", align: "center" },
      { name: "Birim", align: "center" },
      { name: "Pozisyon", align: "center" },
      { name: "Uyruk", align: "center" },
      { name: "Net Ücret", align: "center" },
      { name: "Eposta", align: "center" },
      { name: "Telefon", align: "center" },
      { name: "Talep Eden", align: "center" },
    ],
      rows: tableRows
  };
  










    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Storyhunter Personel Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>

        </Grid>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
