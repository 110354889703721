/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/gorevlendirme/user-bilgi?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ image, name, detail }) {
      return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {detail}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };



    const tableRows = (data || []).map((item) => {
        // Check if item exists and has the required properties
        if (item && item.tk_no) {
            return {
                "Personel": <Author name={item.name + " " + item.surname} detail={item.trt_unvan} />,
                "Pasaport No": <SoftBadge variant="gradient" badgeContent={item.passport_no+ "‎ "} color="info" size="xs" />,
                "TK No": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.tk_no}</SoftTypography>,
    
                field_name: String(item.name),
                field_tur: String(item.iban_type),
            };
        }
        // Return null or handle cases where item doesn't meet the condition
        return null;
    }).filter(Boolean);  // Filter out null values
    
    const projectsTableData = {
        columns: [
            { name: "Personel", align: "left", exportableField: "field_name", searchable: true, searchField: ["field_name"] },
            { name: "Pasaport No", align: "center", exportableField: "field_tur", searchable: true, searchField: ["field_tur"] },
            { name: "TK No", align: "center" },
        ],
        rows: tableRows
    };
    











    return (

        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Çalışan TK Hesap Bilgileri</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>

        </Grid>

    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar />
      </DashboardLayout>
    );
  }
}

export default Tables;
