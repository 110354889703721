/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import { faPencilSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import Alert from "layouts/planlama/gorev-takip/list/alert";


function Tables() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/gorevlendirme/list?format=json`, { withCredentials: true });
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [id, navigate]);




    function Author({ id, image, name, loc, tel }) {
        return (
          <a href={`gorev-organizasyon/${id}`}>
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
              <SoftBox mr={2}>
                <SoftAvatar src={image} size="sm" variant="rounded" />
              </SoftBox>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                  {name}
                </SoftTypography>
                <SoftTypography variant="caption" color="dark">
                  {loc}
                </SoftTypography>
                <SoftTypography variant="caption" color="secondary">
                  {tel}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </a>
        );
      }


    const truncatedTextStyle = {
        width: '300px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        height: '20px',
        WebkitBoxOrient: 'vertical'
    };



    const tableRows = data?.map(item => {
        return {
            "Başlık": [
                <img key="logoNewspaper" src="" alt="Logo" />,
                <span key="task1">{item.konu}</span>,
            ],
            "Başlık": <Author id={item.id} name={item.konu} loc={item.tur} tel={item.tarih} />,
            "Konum": < SoftTypography variant="caption" color="text" fontWeight="medium" > {item.yer}</SoftTypography >,

            "Durum": <Alert title={item.asama_display} color={ item.asama > 8 ? "info" : "secondary" } stage={item.asama} />,

            "Takip Eden": <>
                <Icon sx={{ fontWeight: "medium", color: "success" }}> person  </Icon>
                <SoftTypography variant="caption" color="text" fontWeight="medium">
                    <Link href={`/profile/${item.talep_eden?.id}`} >
                        {item.talep_eden?.name + " " + item.talep_eden?.surname}
                    </Link>
                </SoftTypography>
            </>,
            "Düzenleme": (<SoftTypography component="a" href={`gorev-organizasyon/${item.id}`} variant="caption" color="secondary" fontWeight="medium" > <FontAwesomeIcon icon={faPenToSquare} /></SoftTypography>),

        };
    });

    const projectsTableData = {
        columns: [
            { name: "Başlık", align: "left" },
            { name: "Konum", align: "center" },
            { name: "Durum", align: "center" },
            { name: "Takip Eden", align: "center" },
            { name: "Düzenleme", align: "center" },
        ],
        rows: tableRows
    };



    return (

        <Grid item xs={12} md={12} xl={12}>
            <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftTypography variant="h6">Görevlendirme Takip</SoftTypography>
                </SoftBox>
                <SoftBox
                    sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                    }}
                >
                    <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                </SoftBox>
            </Card>

        </Grid>


    );


}

export default Tables;
