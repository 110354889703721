import React from 'react'

import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";

// @mui icons
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
    console.log(data);
    const safeValue = (value) => value === null ? "-" : value;
    
    return (
        <Grid item xs={12} md={6} xl={6}>
            <ProfileInfoCard
              title="Kurum Bilgileri"
              description="Kurum bilgileri detayları"
              info={{
                "Sicil No": safeValue(data.data.sicil_no),
                "Sözleşme Türü": safeValue(data.data.sozlesme),
                "Sözleşme Başlangıç": safeValue(data.data.sozlesme_baslangic),
                "Sözleşme Bitiş": safeValue(data.data.sozlesme_bitis),
                "TRT Ünvan": safeValue(data.data.trt_unvan),
                "TRT Arabi Ünvan": safeValue(data.data.trt_arabi_unvan),
                "TRT Başlanıç": safeValue(data.data.trt_baslangic),
                "Çalışma Yeri": safeValue(data.data.calisma_yeri),
                "Müdürlük": safeValue(data.data.mudurluk),
                "Birim": safeValue(data.data.birim),
                "Çalışma İzni Bitiş": safeValue(data.data.calisma_izni_bitis_tarihi),
                
                
          
                

              }}
              social={[]}
              action={[]}
            />
          </Grid>
    )
}

export default index
