/* eslint-disable react/prop-types */
import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import clsx from "clsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Bookmark";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import organization from "./org.json";
import Icon from "@mui/material/Icon";

import SoftButton from "components/SoftButton";

import axios from "axios"
import { useEffect, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { ThemeProvider, createTheme } from '@mui/styles';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import { useNavigate } from 'react-router-dom';
import './style.css';


import {
  makeStyles,

} from "@mui/styles";
import boxShadow from "assets/theme/functions/boxShadow";


function useZoom(initialZoom = 1) {
  const [zoomLevel, setZoomLevel] = useState(initialZoom);

  const zoomIn = () => setZoomLevel((currentZoom) => currentZoom + 0.1);
  const zoomOut = () => setZoomLevel((currentZoom) => currentZoom - 0.1);

  const zoomStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: 'center center',
    transition: 'transform 0.2s',
  };

  return { zoomLevel, zoomIn, zoomOut, zoomStyle };
}

const useStyles = makeStyles((theme) => {



  return {
    root: {
      background: 'white', // Provide a fallback value
      display: 'inline-block',
      borderRadius: 70,
      width: '300px',
      color: "#2b313c",
      // Add other style rules as needed
    },
    root_title: {
      background: 'linear-gradient(310deg, rgb(131 148 165), #b8c0d3)', // Provide a fallback value
      background: 'linear-gradient(310deg, rgb(99 130 161), #adb7ce)',
      background: 'linear-gradient(310deg, rgb(138 160 182), #adb7ce)',
      background: '#627594',
      background: '#637aa0',
      background: '#a1b7cd',
      background: '#97acc1',
      background: 'linear-gradient(140deg, #d2e0ed, #97acc1 50%)',
      display: 'inline-block',
      borderRadius: 16,
      width: '300px',
      border: "black",
      color: "white !important",
      boxShadow: "0rem 0.15rem 1rem 0rem rgb(219 219 219 / 64%)",
      // Add other style rules as needed
    },
    root_main: {
      //background: 'linear-gradient(310deg, #1a76d3, #25aef6)', // Provide a fallback value
      //background: '#1a76d3',
      color: "white !important",
      display: 'inline-block',
      borderRadius: 16,
      width: '300px',
      border: "black",
      boxShadow: "0rem 0.15rem 1.0rem 0rem rgb(25 119 212 / 64%)",
      // Add other style rules as needed
    },
    expand: {
      transform: 'rotate(0deg)',
      marginTop: -10,
      marginLeft: 'auto',
      transition: theme?.transitions?.create('transform', {
        duration: theme?.transitions?.duration?.short,
      }) || 'none', // Provide a fallback value
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      //backgroundColor: '#fff',
      background: "linear-gradient(136deg, white, #ffffffbf)"
    },

    infoBadge: {
      background: 'linear-gradient(310deg, #2152ff, #21d4fd)',
      color: '#ffffff',
      left: '-5px',


    },
    infoBadgeEmpty: {
      display: "none",
      color: "transparent"
    },

    // Add other style rules as needed
  };
});



function Organization({ org, onCollapse, collapsed }) {
  const badgeSize = _.size(org?.organizationChildRelationship);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleProfileClose = (tradingID) => {
    setAnchorEl(null);
    navigate(`/profile/${tradingID}`);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'ACCOUNT',  // <-- This should match the type defined in useDrag
    drop: () => ({ name: org.tradingName }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  const colors = ['#181c35', '#1a76d3', '#75ace4','#11bef7', '#74acf3', '#7eb6f8'];

  return (
    <Card
      variant="outlined"
      className={_.size(org.organizationChildRelationship) === 0 ? classes.root : (org.tradingName === null ? classes.root_title : classes.root_main)}
      ref={drop}
      sx={{ backgroundColor: colors[org.organizationDepth] }}
    >
      <CardHeader
        avatar={
          <Tooltip
            title={`${_.size(
              org.organizationChildRelationship
            )} Alt Çalışan`}
            arrow
          >

            <Badge
              style={{ cursor: "pointer" }}
              classes={{ badge: badgeSize > 0 ? classes.infoBadge : classes.infoBadgeEmpty }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              showZero
              invisible={!collapsed}
              overlap="circle"
              badgeContent={_.size(org.organizationChildRelationship)}
              onClick={onCollapse}
            >
              <Avatar src={window.BASE_URL + org.tradingPhoto} className={classes.avatar}>
                <BusinessIcon color="primary" />
              </Avatar>
            </Badge>
          </Tooltip>
        }
        title={org.tradingName !== null ? org.tradingName : org.tradingPosition}
        subheader={org.tradingName !== null ? org.tradingPosition : ""}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
      
      />

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={() => handleProfileClose(org.tradingID)}>
          <ListItemIcon>
            <BusinessIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Profili Görüntüle" />
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBalanceIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Add Sub Account" />
        </MenuItem> */}
      </Menu>
      {_.size(org.organizationChildRelationship) > 0 && (
        <IconButton
          size="small"
          onClick={onCollapse}
          className={clsx(classes.expand, {
            [classes.expandOpen]: !collapsed
          })}
        >
          <ExpandMoreIcon />
        </IconButton>
      )}

    </Card>
  );
}
function Account({ a }) {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag({
    type: 'ACCOUNT',  // <-- Define a type for your draggable item
    item: { name: a.name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        alert(`You moved ${item.name} to ${dropResult.name}`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  return (
    <Card
      variant="outlined"
      className={classes.root}
      ref={drag}
      style={{ cursor: "pointer", opacity }}
    >
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <AccountBalanceIcon color="secondary" />
          </Avatar>
        }
        title={a.name}
      />
    </Card>
  );
}
function Product({ p }) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <Typography variant="subtitle2">{p.name}</Typography>
      </CardContent>
    </Card>
  );
}
const Node = ({ o, parent, allExpanded, forceUpdate }) => {
  if (!o) {
    return null; // Veri tanımsız ise null döndür
  }
  const classes = useStyles();

  // 'o' nesnesi null değilse, 'collapsed' özelliğine güvenle erişebilirsiniz.
  const [collapsed, setCollapsed] = useState(o.collapsed);
  useEffect(() => {
    if (forceUpdate) {
      setCollapsed(!allExpanded);
    }
  }, [allExpanded, forceUpdate]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    // Kullanıcı tarafından yapılan değişiklik olduğunda, bu değişikliğin forceUpdate mekanizmasından etkilenmemesi için
    // bir yöntem düşünülmeli. Bu örnekte, forceUpdate'in bu lokal değişiklikte bir etkisi yok.
  };

  const T = parent ? TreeNode : (props) => (
    <Tree {...props} lineWidth="2px" lineColor="#bbc" lineBorderRadius="12px" className={classes.ul}>{props.children} </Tree>
  );

  // `collapsed` durumuna bağlı olarak çocukların gösterilip gösterilmeyeceğini kontrol et.
  const renderChildren = !collapsed ? (
    <>
      {o.account && o.account.map((a, index) => (
        <TreeNode className={classes.ul} key={`account-${index}`} label={<Account a={a} />}>
          <TreeNode className={classes.ul} key={`product-${a.product.id}`} label={<Product p={a.product} />} />
        </TreeNode>
      ))}
      {o.organizationChildRelationship && o.organizationChildRelationship.map((c, index) => (
        // Her bir alt Node için, allExpanded ve forceUpdate prop'larını geçir.
        <Node className={classes.ul} key={`child-${index}`} o={c} parent={o} allExpanded={allExpanded} forceUpdate={forceUpdate} />
      ))}
    </>
  ) : null;

  return (
    <T className={classes.ul} label={<Organization org={o} onCollapse={handleCollapse} collapsed={collapsed} />}>
      {renderChildren}
    </T>
  );
};


const theme = createTheme({
  palette: {
    background: {
      default: "#fff"
    }
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: 'white'
  },

  root: {
    display: "flow",
    flexDirection: "column",
  }

});


export default function App(props) {
  const [data, setData] = useState([]);
  const [organization, setOrganization] = useState(null);


  const [allExpanded, setAllExpanded] = useState(false);

  // Tüm düğümleri genişletme veya daraltma fonksiyonu
  const toggleAllNodes = () => {
    setAllExpanded(prevState => !prevState);
  };

  // const [zoomLevel, setZoomLevel] = useState(1); // Başlangıç zoom seviyesi 1

  // const zoomIn = (event) => {
  //   event.stopPropagation();
  //   setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
  // };

  // const zoomOut = (event) => {
  //   event.stopPropagation();
  //   setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/api/branches/?format=json`, {
          withCredentials: true
        });
        //setData(response.data);
        setOrganization(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);




  const { zoomIn, zoomOut, zoomStyle } = useZoom();


  function useDraggable() {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    const handleMouseDown = (e) => {
      setDragging(true);
      // Mouse ve eleman pozisyonlarını kaydet
      e.target.style.cursor = 'grabbing';
    };

    const handleMouseMove = (e) => {
      if (dragging) {
        e.preventDefault();
        setPosition((prev) => ({
          x: prev.x + e.movementX,
          y: prev.y + e.movementY,
        }));
      }
    };

    const handleMouseUp = (e) => {
      setDragging(false);
      e.target.style.cursor = 'grab'; // Sürükleme bittiğinde cursor'u güncelle
    };

    return { position, handleMouseDown, handleMouseMove, handleMouseUp };
  }

  // Komponentinizin içinde useDraggable hook'unu kullanın
  function DraggableContent({ children }) {
    const { position, handleMouseDown, handleMouseMove, handleMouseUp } = useDraggable();

    return (
      <div
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} // Mouse alanı terk ettiğinde sürüklemeyi durdur
        style={{
          cursor: 'grab',
          position: 'relative',
          left: position.x,
          top: position.y,
          // İçeriğiniz için diğer stil tanımlamaları
        }}
      >
        {children}
      </div>
    );
  }

  const classes = useStyles();

  return (
    <DashboardLayout  >
      <DashboardNavbar />
      <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>
        {/* <SoftButton onClick={zoomIn} sx={{ mr: 10.5, position: "fixed", zIndex: 2 }} >
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
            zoom_in
          </Icon>
        </SoftButton>

        <SoftButton onClick={zoomOut} sx={{ mr: 2, position: "fixed", zIndex: 2 }} >
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
            zoom_out
          </Icon>
        </SoftButton> */}
        <SoftButton onClick={toggleAllNodes} sx={{ mr: 2, position: "fixed", zIndex: 2, mt: 7, bottom: '30px' }}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">

            {allExpanded ? "close_fullscreen" : "open_in_full"}
          </Icon>

        </SoftButton>

      </div>
      <ThemeProvider theme={theme}>
        <DraggableContent>
          <Box bgcolor="background" padding={4} height="80vh">
            <DndProvider backend={HTML5Backend}>

              <div style={zoomStyle} > {/* Zoom stilini uygula */}
                <Node o={organization} allExpanded={allExpanded} forceUpdate={Date.now()} className={classes.ul} />
              </div>


            </DndProvider>
          </Box>
        </DraggableContent>

      </ThemeProvider>

    </DashboardLayout>
  );
}
