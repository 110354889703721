/* eslint-disable react/prop-types */

import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDatePicker from "components/SoftDatePicker";
import SoftSelect from "components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";
import { faPencilSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function Tables() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState({});

    const today = new Date().toISOString().split('T')[0];

    const [formData, setFormData] = useState({
      dateto: today,
      datefrom: today,
    });

    const handleDateChange = (name, date) => {
        const formattedDate = new Date(date).toISOString().split("T")[0];
        setFormData({
            ...formData,
            [name]: formattedDate,
        });
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/gorevlendirme/personelrapor?time_period=history&to=${formData.dateto}&from=${formData.datefrom}&format=json`, { withCredentials: true });
                setData(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [formData]);

    const selectData = Object.keys(data.results || {}).map(key => {
        return {
            value: key,
            label: key
        };
    });

    function Author({ id, name, loc, tel }) {
        return (
            <a href={`/profile/${id}`}>
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox display="flex" flexDirection="column" ml={2}>
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {loc}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </a>
        );
    }

    const tableRows = data.results?.["Inside"]?.gorevler?.map(item => {
        return {
            "Başlık": <Author name={item.konu} loc={item.yer} />,
            "Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.tarih}</SoftTypography>,
        };
    }) || [];

    const projectsTableData = {
        columns: [
            { name: "Başlık", align: "left" },
            { name: "Tarih", align: "left" },
        ],
        rows: tableRows
    };

    const tableRows2 = data.results?.["Inside"]?.muhabir_gorev_sayisi?.map(item => {
        return {
            "Muhabir": (
                <Author
                    name={`${item.muhabir.name} ${item.muhabir.surname}`}
                    loc={`Görev Sayısı: ${item.gorev_sayisi}, Görev Günü: ${item.gorev_gunu}`}
                />
            ),
            "Görev Sayısı": (
                <SoftTypography variant="caption" color="text" fontWeight="medium">
                    {item.gorev_sayisi}
                </SoftTypography>
            ),
            "Görev Günü": (
                <SoftTypography variant="caption" color="text" fontWeight="medium">
                    {item.gorev_gunu}
                </SoftTypography>
            ),
        };
    }) || [];
    
    const projectsTableData2 = {
        columns: [
            { name: "Muhabir", align: "left" },
            { name: "Görev Sayısı", align: "left" },
            { name: "Görev Günü", align: "left" },
        ],
        rows: tableRows2
    };


    const toplamGorevGunu = data.results?.["Inside"]?.muhabir_gorev_sayisi?.reduce(
        (acc, item) => acc + item.gorev_gunu, 
        0
    );
    
    const toplamGorevSayisi = data.results?.["Inside"]?.muhabir_gorev_sayisi?.reduce(
        (acc, item) => acc + item.gorev_sayisi, 
        0
    );
    
    // Görev Günleri için Kontrol
    const complexReportsDoughnutChartData = {
        images: ["", "", "", ""],
        labels: toplamGorevGunu > 0 
            ? data.results?.["Inside"]?.muhabir_gorev_sayisi?.map(item => {
                const gorevGunu = item.gorev_gunu;
                const yuzde = ((gorevGunu / toplamGorevGunu) * 100).toFixed(2);
                return `${item.muhabir.name} ${item.muhabir.surname} (${gorevGunu} Gün)`;
              })
            : ["Veri Bulunamadı"],
        datasets: {
            label: "Görev Günleri",
            backgroundColors: toplamGorevGunu > 0 ? ["info", "dark"] : ["#d3d3d3"],
            data: toplamGorevGunu > 0 
                ? data.results?.["Inside"]?.muhabir_gorev_sayisi?.map(item => {
                    const gorevGunu = item.gorev_gunu;
                    return ((gorevGunu / toplamGorevGunu) * 100).toFixed(2);
                  })
                : [100], // Veri yoksa 100% tek dilim
        },
    };
    
    // Görev Sayıları için Kontrol
    const complexReportsDoughnutChartDataCount = {
        images: ["", "", "", ""],
        labels: toplamGorevSayisi > 0 
            ? data.results?.["Inside"]?.muhabir_gorev_sayisi?.map(item => {
                const gorevSayisi = item.gorev_sayisi;
                const yuzde = ((gorevSayisi / toplamGorevSayisi) * 100).toFixed(2);
                return `${item.muhabir.name} ${item.muhabir.surname} (${gorevSayisi} Görev)`;
              })
            : ["Veri Bulunamadı"],
        datasets: {
            label: "Görev Sayıları",
            backgroundColors: toplamGorevSayisi > 0 ? ["dark", "dark"] : ["#d3d3d3"],
            data: toplamGorevSayisi > 0 
                ? data.results?.["Inside"]?.muhabir_gorev_sayisi?.map(item => {
                    const gorevSayisi = item.gorev_sayisi;
                    return ((gorevSayisi / toplamGorevSayisi) * 100).toFixed(2);
                  })
                : [100], // Veri yoksa 100% tek dilim
        },
    };

    const yurtiçiCount = data.results?.["Inside"]?.tur_sayilari?.Yurtiçi || 0;
    const yurtdışıCount = data.results?.["Inside"]?.tur_sayilari?.Yurtdışı || 0;
    const istanbulCount = data.results?.["Inside"]?.tur_sayilari?.İstanbul || 0;
    const sınırCount = data.results?.["Inside"]?.tur_sayilari?.Sınır || 0;


    return (
        <>
            <SoftBox mt={2} mb={0} style={{ marginInline: "3%" }}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                        Seçilen Tarih
                    </SoftTypography>
                </SoftBox>
                <Grid style={{ display: "flex", gap: "5%" }}>
                    <SoftDatePicker
                        value={formData.datefrom}
                        onChange={(date) => handleDateChange("datefrom", date)}
                    />
                    <SoftDatePicker
                        value={formData.dateto}
                        onChange={(date) => handleDateChange("dateto", date)}
                    />
                </Grid>
            </SoftBox>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Yurtiçi Görevleri</span>}
                        count={yurtiçiCount}
                        description="Tamamlanmış"
                        color={yurtiçiCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Yurtdışı Görevleri</span>}
                        count={yurtdışıCount}
                        description="Tamamlanmış"
                        color={yurtdışıCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>İstanbul Görevleri</span>}
                        count={istanbulCount}
                        description="Tamamlanmış"
                        color={istanbulCount > 0 ? "info" : "secondary"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniInfoCard
                        icon="flight_takeoff"
                        title={<span>Sınır Görevleri</span>}
                        count={sınırCount}
                        description="Tamamlanmış"
                        color={sınırCount > 0 ? "info" : "secondary"}
                    />
                </Grid>


                <Grid item xs={12} md={12} xl={12}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Görevlendirme Personel Raporu</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={projectsTableData2.columns} rows={projectsTableData2.rows} />
                        </SoftBox>
                    </Card>
                </Grid>


                <Grid item xs={12} md={12} xl={6}>

                    <Card>
                        <SoftBox display="flex" alignItems="center">

                            <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                Personel Gün Dağılımı
                            </SoftTypography>

                        </SoftBox>
                        <ComplexReportsDoughnutChart
                            chart={complexReportsDoughnutChartData}
                        />

                    </Card>

                </Grid>

                <Grid item xs={12} md={12} xl={6}>

                    <Card>
                        <SoftBox display="flex" alignItems="center">

                            <SoftTypography variant="h6" fontWeight="medium" style={{ padding: '24px' }}>
                                Personel Görev Dağılımı
                            </SoftTypography>

                        </SoftBox>
                        <ComplexReportsDoughnutChart
                            chart={complexReportsDoughnutChartDataCount}
                        />

                    </Card>

                </Grid>








            </Grid>
        </>
    );
}

export default Tables;
