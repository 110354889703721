import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarCheck, faCalendarMinus, faChartLine, faEnvelopeOpenText, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faList, faListAlt, faListDots, faListUl, faNoteSticky, faPersonWalkingLuggage, faSpellCheck, faThList, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-trt.png";

/* eslint-disable react/prop-types */

function Header(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

    const handleSetTabValue = (newValue) => {
      setTabValue(newValue);
      console.log(newValue);
      props.onChange(newValue);
    };

  return (
    <SoftBox position="absoloute" margin-top="200px">
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "absoloute",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          marginTop: 2
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                <Tab value= {0} label="Tümü" icon={<FontAwesomeIcon icon={faList} />} />
                <Tab value= {1} label="Uluslararası Sözleşmeler" icon={<FontAwesomeIcon icon={faListAlt} />} />
                <Tab value= {2} label="Anayasa, Kanun, CBK" icon={<FontAwesomeIcon icon={faThList} />} />
                
                <Tab value= {3} label="Yönetmelikler" icon={<FontAwesomeIcon icon={faEnvelopeOpenText} />} />
                <Tab value= {4} label="Yönergeler" icon={<FontAwesomeIcon icon={faCalendarCheck} />} />

                <Tab value= {5} label="Usul ve Esaslar" icon={<FontAwesomeIcon icon={faEnvelopeOpenText} />} />
                <Tab value= {6} label="Talimatlar" icon={<FontAwesomeIcon icon={faCalendarCheck} />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;  
