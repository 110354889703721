// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-scrollbar {
    overflow-x: scroll !important; /* Yatay kaydırma çubuğunu her zaman göster */
    overflow-y: scroll ;
  }
  
  /* WebKit tarayıcılar için özel kaydırma çubuğu stilleri */
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px; /* Kaydırma çubuğunun genişliği */
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Kaydırma yolu */
    background: #fff;
    /* background: linear-gradient(45deg, #ffffff00, #e2e2e287); */
    border-radius: 3px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #d2d7de; /* Kaydırma çubuğu */
    border-radius: 5px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #9ea1a7; /* Fareyle üzerine gelindiğinde */
  }
  `, "",{"version":3,"sources":["webpack://./src/examples/Tables/Table/style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B,EAAE,6CAA6C;IAC5E,mBAAmB;EACrB;;EAEA,0DAA0D;EAC1D;IACE,WAAW,EAAE,iCAAiC;IAC9C,WAAW;EACb;;EAEA;IACE,mBAAmB,EAAE,kBAAkB;IACvC,gBAAgB;IAChB,8DAA8D;IAC9D,kBAAkB;EACpB;;EAEA;IACE,mBAAmB,EAAE,oBAAoB;IACzC,kBAAkB;EACpB;;EAEA;IACE,mBAAmB,EAAE,iCAAiC;EACxD","sourcesContent":[".custom-scrollbar {\n    overflow-x: scroll !important; /* Yatay kaydırma çubuğunu her zaman göster */\n    overflow-y: scroll ;\n  }\n  \n  /* WebKit tarayıcılar için özel kaydırma çubuğu stilleri */\n  .custom-scrollbar::-webkit-scrollbar {\n    width: 10px; /* Kaydırma çubuğunun genişliği */\n    height: 8px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-track {\n    background: #f1f1f1; /* Kaydırma yolu */\n    background: #fff;\n    /* background: linear-gradient(45deg, #ffffff00, #e2e2e287); */\n    border-radius: 3px; \n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb {\n    background: #d2d7de; /* Kaydırma çubuğu */\n    border-radius: 5px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background: #9ea1a7; /* Fareyle üzerine gelindiğinde */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
